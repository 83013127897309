import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const firebaseConfiguration = firebase.initializeApp({
  apiKey: "AIzaSyCNhZCuIWrpunhCpkAP3ARqik7OWBvDClA",
  authDomain: "e-learning-dev-96753.firebaseapp.com",
  projectId: "e-learning-dev-96753",
  storageBucket: "e-learning-dev-96753.appspot.com",
  messagingSenderId: "411090142552",
  appId: "1:411090142552:web:2d757e11d67b21c54f912d",
  measurementId: "G-1GL4BVMK1M",
});

export const auth = firebaseConfiguration.auth();
export const db = firebase.firestore();
export const eLearningStorage = firebaseConfiguration.storage();
export const eLearningFunctions = firebaseConfiguration.functions();
export default firebaseConfiguration;
