import React, { useState, useEffect } from "react";
import "./css/navigation.css";
import Logo from "./Logo";
import notificationBell from "assets/images/icons/notification-bell.svg";
import { NavLinks } from "router/NavigationLinks";
import NotificationDropdown from "./NotificationDropdown";
import { Dropdown } from "antd";
import { useAuth } from "../auth/context/AuthContext";
import { ROLE } from "utils/ROLES";
import { ROUTES } from "router/CONSTANTS";
import { db } from "../firebase/firebase";
import { NOTIFICATIONS } from "services/CONSTANTS";
import hamburger from "assets/images/icons/hamburger-menu.svg";
import MobileNavigation from "./MobileNavigation";
import Languages from "./Languages";
import Logout from "./Logout";
import { useTranslation } from "react-i18next";
import { readNotificationsInDropdown } from "services/notificationService";
import arrowDown from "assets/images/icons/arrow-down.svg";

const Navigation = () => {
  const { currentUser, loggedRole } = useAuth();

  const { t } = useTranslation();

  const [notifications, setNotifications] = useState([]);
  const [isVisibleMobile, setIsVisibleMobile] = useState(false);
  const [isNewNotificationArrived, setIsNewNotificationArrived] =
    useState(false);

  useEffect(() => {
    if (!currentUser) return;

    if (loggedRole === null || loggedRole === undefined) return;

    if (loggedRole === ROLE.SUPER_ADMIN || loggedRole === ROLE.HUB_ADMIN)
      return;

    const unsubscribe = db
      .collection(NOTIFICATIONS())
      .where("recepient", "==", currentUser?.uid)
      .orderBy("notificationDateTime", "desc")
      .limit(4)
      .onSnapshot((allNotifications) => {
        if (!allNotifications.empty) {
          const notification = allNotifications.docs.map((doc) => {
            const linkTo = doc.data().linkTo.split("com")[1];
            return {
              id: doc.id,
              ...doc.data(),
              linkTo,
            };
          });

          setNotifications(notification);

          const countSeenProp = notification.filter(
            (oneNotification) => oneNotification.seen
          );

          if (countSeenProp.length !== notification.length) {
            setIsNewNotificationArrived(true);
          } else {
            setIsNewNotificationArrived(false);
          }
        }
      });

    return () => {
      unsubscribe();
    };
  }, [currentUser, loggedRole]);

  /**
   * Check logged role and show / hide navigation per logged role
   *
   * @returns boolean
   */
  const showNavigation = () => {
    if (loggedRole === ROLE.SUPER_ADMIN || loggedRole === ROLE.HUB_ADMIN) {
      return true;
    }
    return false;
  };

  /**
   * Show /hide notification badge per role
   * @returns boolean
   */
  const showNotificationBadge = () => {
    if (loggedRole === ROLE.STUDENT || loggedRole === ROLE.TEACHER) {
      return true;
    }
    return false;
  };

  /**
   * Show mobile navigation
   */
  const showMobileNavigation = () => {
    setIsVisibleMobile(true);
  };

  /**
   * Close mobile navigation
   *
   * @param {*} status => false
   */
  const closeMobile = (status) => {
    setIsVisibleMobile(status);
  };

  /**
   * Read first four notification
   *
   * @param {*} value => is dropdown open or closed
   * @returns false if dropdown is closed or if there is no new notifications
   */
  const readNotifications = async (value) => {
    if (!value) return;

    if (!isNewNotificationArrived) return;

    const documents = notifications.map((notification) => notification.id);

    const isReadedNotifications = await readNotificationsInDropdown(documents);

    if (isReadedNotifications) setIsNewNotificationArrived(false);
  };

  /**
   * Generate text per chosed language
   *
   * @returns selected language
   */
  const generateText = () => {
    const selectedLanguage = localStorage.getItem("i18nextLng");

    if (selectedLanguage === "en") {
      return "ENG";
    }

    return "BIH";
  };

  const showRoleName = () => {
    let roleName;

    switch (loggedRole) {
      case ROLE.TEACHER:
        roleName = t("ROLES.TEACHER");
        break;

      case ROLE.HUB_ADMIN:
        roleName = t("ROLES.HUB_ADMIN");
        break;

      case ROLE.STUDENT:
        roleName = t("ROLES.STUDENT");
        break;

      default:
        roleName = "Admin";
        break;
    }

    return roleName;
  };

  return (
    <div id="main-navigation-holder">
      <nav
        id="app-navigation"
        className="w-100 display-flex justify-content-between align-items-center"
      >
        <Logo />

        <div className="nav-links middle-side width-100 display-flex">
          {showNavigation() && <NavLinks />}
        </div>

        <div className="right-side display-flex">
          <div
            className="languages display-flex align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Dropdown
              overlay={Languages()}
              trigger={["click"]}
              overlayClassName="languages-dropdown-ant-design languages"
            >
              <div className="languages-dropdown">
                <p>{generateText()}</p>
                <img src={arrowDown} alt="Arrow down" />
              </div>
            </Dropdown>
          </div>
          <div className="user-data display-flex align-items-center">
            {showNotificationBadge() && (
              <div className="notification" style={{ cursor: "pointer" }}>
                <Dropdown
                  overlay={NotificationDropdown({ notifications, t })}
                  trigger={["click"]}
                  onVisibleChange={readNotifications}
                >
                  <img src={notificationBell} alt="Notification bell" />
                </Dropdown>
                {isNewNotificationArrived && (
                  <div className="new-notification"></div>
                )}
              </div>
            )}
            <p className="role-name-navigation role-name-mobile">
              {loggedRole !== null && showRoleName()}
            </p>
            <div className="hamburger" onClick={showMobileNavigation}>
              <img src={hamburger} alt="Hamburger menu" />
            </div>
            <p
              className={`role-name-navigation role-name-desktop ${
                loggedRole === ROLE.HUB_ADMIN && "hub-admin-role"
              }`}
            >
              {loggedRole !== null && showRoleName()}
            </p>
            <Logout />
          </div>
        </div>
      </nav>
      <MobileNavigation
        isMobileVisible={isVisibleMobile}
        onCloseMobile={closeMobile}
      />
    </div>
  );
};

export default Navigation;
