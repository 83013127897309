import moment from "moment";

/**
 * Format date
 *
 * @param {*} data => data where stored date
 * @returns formatted date
 */
const getFormattedDate = (date, format, role) => {
  if (date !== null && date !== undefined) {
    let timestampFormat;
    const formatType = format === "." ? "DD.MM.YYYY" : "DD-MM-YYYY";
    if (!role) {
      const timestampToDate = date?.toDate();
      timestampFormat = moment(timestampToDate).format(formatType);
    } else {
      timestampFormat = moment(date).format(formatType);
    }

    return timestampFormat;
  }
};

/**
 * Format time
 *
 * @param {*} time => time to format
 * @returns formatted time
 */
const getFormattedTime = (time) => {
  if (time !== null && time !== undefined) {
    const timestampTime = time.toDate();
    const timestampTimeFormat = moment(timestampTime).format("HH:mm");

    return timestampTimeFormat;
  }
};

/**
 * Generate timestamp for timeline
 *
 * @param {*} value => value
 * @returns formatted date
 */
const generateTimestampForTimeline = (value) => {
  const dateString = moment(value.date).format("YYYY, MM, DD").split(",");
  const timeString = moment(value.time).format("HH:mm").split(":");

  const month = +dateString[1] - 1;

  return new Date(
    dateString[0],
    month,
    dateString[2],
    timeString[0],
    timeString[1]
  );
};

const formatDateTimeEducation = (date) => {
  if (date !== null && date !== undefined) {
    const getDate = date;
    const timestampToDate = getDate.toDate();
    const timestampFormat = moment(timestampToDate).format("DD-MM-YYYY hh:mm");
    const splittedDate = timestampFormat.split(" ");

    return splittedDate;
  }
};

export {
  getFormattedDate,
  getFormattedTime,
  generateTimestampForTimeline,
  formatDateTimeEducation,
};
