import { eLearningStorage } from "../firebase/firebase";

/**
 * Upload document to firebase storage
 *
 * @param {*} file => selected file for upload
 * @param {*} folder => folder on firebase storage
 * @returns file ref
 */
const uploadDocument = async (file, folder) => {
  if (!file) {
    return;
  }
  const storageRef = eLearningStorage.ref();
  const fileRef = storageRef.child(`${folder}/${file.name}`);
  await fileRef.put(file);
  return fileRef;
};

/**
 * Handle file upload on firebase storage
 *
 * @param {*} file => selected file
 * @param {*} folder => where to save file
 * @returns uploaded file url
 */
const handleFileUpload = (file, folder) => {
  if (!file) return;
  return new Promise(async (resolve, reject) => {
    try {
      const storageRef = eLearningStorage.ref();
      const fileRef = storageRef.child(`${folder}/${file.name}`);
      const metadata = {
        fileName: file.name,
      };
      const uploadFile = await fileRef.put(file, metadata);
      const fileUrl = await uploadFile.ref.getDownloadURL();
      resolve(fileUrl);
    } catch (error) {
      reject(error);
      console.log("Cannot upload file: ", error);
    }
  });
};

/**
 * Delete file from storage
 *
 * @param {*} fileName => file name
 * @param {*} folder => name for folder where our file is
 * @returns delete file
 */
const deleteFileFromStorage = async (fileName, folder) => {
  try {
    const storageRef = eLearningStorage.ref();
    const fileRef = storageRef.child(`${folder}/${fileName}`);
    const deleteFile = await fileRef.delete();
    return deleteFile;
  } catch (error) {
    console.log("Cannot delete file: ", error);
    return error;
  }
};

export { uploadDocument, handleFileUpload, deleteFileFromStorage };
