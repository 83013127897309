import React from "react";
import { EducationProvider } from "../context/EducationContext";
import EducationsList from "./List";

const Educations = () => {
  return (
    <EducationProvider>
      <EducationsList />
    </EducationProvider>
  );
};

export default Educations;
