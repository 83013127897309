import { QUESTIONS_ANSWERS } from "./CONSTANTS";
import { db } from "../firebase/firebase";
import firebase from "firebase/app";

// create new question
const createNewQuestion = (newQuestion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const question = await db
        .collection(QUESTIONS_ANSWERS())
        .add(newQuestion);
      resolve(question);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// create question replay
const createQuestionReply = (questionId, questionReply) => {
  return new Promise(async (resolve, reject) => {
    try {
      const question = await db
        .collection(QUESTIONS_ANSWERS())
        .doc(questionId)
        .update({
          comments: firebase.firestore.FieldValue.arrayUnion(questionReply),
        });
      resolve(question);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// delete question
const deleteQuestion = (question) => {
  return new Promise(async (resolve, reject) => {
    try {
      const selectedQuestion = await db
        .collection(QUESTIONS_ANSWERS())
        .doc(question.id)
        .delete();
      resolve(selectedQuestion);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

export { createNewQuestion, createQuestionReply, deleteQuestion };
