import React from "react";
import { EducationProvider } from "../context/EducationContext";
import SingleEducation from "./SingleEducation";

const Education = () => {
  return (
    <EducationProvider>
      <SingleEducation />
    </EducationProvider>
  );
};

export default Education;
