import React from "react";
import PropTypes from "prop-types";
// MUI Stuff
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

const LoadingButton = (props) => {
  const { isLoading, text, ...rest } = props;
  return (
    <>
      <Button
        {...rest}
        variant={props.variant || "contained"}
        color={props.color || "primary"}
        disabled={isLoading || props.disabled}
        type={isLoading ? "button" : "submit"}
      >
        {isLoading && <CircularProgress size={20} />}
        {text}
      </Button>
    </>
  );
};

LoadingButton.propTypes = {
  isLoading: PropTypes.bool,
  btnVariant: PropTypes.string,
  onClickHandle: PropTypes.func,
};

export default LoadingButton;
