import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import EndCallButton from "../Buttons/EndCallButton/EndCallButton";
import GoBackButton from "../Buttons/BackButton/BackButton";
import { useTranslation } from "react-i18next";
import ShowDocumentsButton from "../Buttons/ShowDocuments/ShowDocuments";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: "white",
    paddingLeft: "1em",
    paddingRight: "1em",
    display: "none",
    height: `${theme.mobileTopBarHeight}px`,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  endCallButton: {
    height: "28px",
    fontSize: "0.85rem",
    padding: "0 0.6em",
  },
  settingsButton: {
    [theme.breakpoints.down("sm")]: {
      height: "28px",
      minWidth: "28px",
      border: "1px solid rgb(136, 140, 142)",
      padding: 0,
      margin: "0 1em",
    },
  },
}));

export default function MobileTopMenuBar() {
  const classes = useStyles();
  const { t } = useTranslation();

  /**
   * Generate text for end call button
   *
   * @returns text for end call button
   */
  const generateEndButtonText = () => {
    const getIsPrimary = localStorage.getItem("isPrimaryTeacher");

    if (getIsPrimary === "true") {
      return t("TWILIO.END_LESSON");
    }

    return t("TWILIO.LEAVE_MEETING");
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="space-between"
      className={classes.container}
    >
      <div>
        <GoBackButton
          className={classes.endCallButton}
          goBackText={t("ACTION_BUTTONS.GO_BACK")}
        />
        <ShowDocumentsButton
          className={classes.endCallButton}
          documentText={t("ACTION_BUTTONS.DOCUMENTS")}
        />
      </div>
      <div>
        <EndCallButton
          className={classes.endCallButton}
          leaveText={generateEndButtonText()}
        />
      </div>
    </Grid>
  );
}
