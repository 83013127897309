import { Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Header from "./Header";
import registeredTeachers from "assets/images/nav-links/teachers.svg";
import registeredEducations from "assets/images/nav-links/educations.svg";
import DefaultTeacher from "components/DefaultTeacher";
import EditSingleHub from "./EditSingleHub";
import { useHub } from "../context/HubContext";
import { useParams } from "react-router-dom";
import {
  editHub,
  getEducationPerHub,
  getHubBlockedStatus,
} from "services/hubService";
import { useAuth } from "auth/context/AuthContext";
import { ROLE } from "utils/ROLES";
import EditHub from "./EditHub";
import ConfirmationModalContent from "components/ConfirmationModalContent";
import { updateBlockedStatus } from "services/blockedStatusService";
import { HUBS } from "services/CONSTANTS";
import AppModal from "components/AppModal";
import { useHistory } from "react-router-dom";
import { ROUTES } from "router/CONSTANTS";
import { useTranslation } from "react-i18next";
import DataLoader from "components/DataLoader";

const SingleHub = () => {
  const { hubDetails, isDataLoading } = useHub();
  const { id } = useParams();
  const { loggedRole } = useAuth();
  const history = useHistory();
  const { t } = useTranslation();

  const [hubData, setHubData] = useState(false);
  const [hubEducationsCount, setHubEducationsCount] = useState(0);
  const [hubTeachersCount, setHubTeachersCount] = useState(0);

  const [isEditSidebar, setIsEditSidebar] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBlockVisible, setIsBlockVisible] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [confirmationModalData, setConfirmationModalData] = useState({
    data: {
      type: "block",
      actionButtonText: t("ACTION_BUTTONS.BLOCK"),
      title: "",
      description: t("HUBS.BLOCK_HUB.DESCRIPTION_BLOCK"),
    },
  });

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!hubDetails) return;

      const hubId = id !== undefined ? id : hubDetails.hubId;

      setHubData(hubDetails);

      (async () => {
        return getEducationPerHub(hubId)
          .then((res) => {
            const educations = res.docs.map((doc) => doc.data());
            if (educations.length <= 0) return;

            setHubEducationsCount(educations.length);

            const mapEducations = educations;

            // first get primary educator
            let allEducators = mapEducations.map(
              ({ primEducator: { id } }) => id
            );

            // get second educators and push all in array for all educators
            const secondEducators = mapEducations;
            secondEducators
              .map((educators) => educators.secondEducator)
              .forEach((elements) => {
                return elements.map((element) => allEducators.push(element.id));
              });

            const uniqueIds = [...new Set(allEducators)];
            setHubTeachersCount(uniqueIds.length);
          })
          .catch((err) => console.log("Cannot get educations per hub: ", err));
      })();
    }

    return () => {
      mounted = false;
      setHubData(null);
      setHubEducationsCount(0);
      setHubTeachersCount(0);
    };
  }, [hubDetails, id]);

  /**
   *  Close Edit Sidebar
   */
  const hideEditSidebar = () => {
    setIsEditSidebar(false);
  };

  /**
   * Show modal wrapper
   */
  const showModal = () => {
    setIsModalVisible(true);
  };

  /**
   * Handle edit mode form single hub page
   *
   * @returns which edit element needs to be shown
   */
  const handleEditMode = (type) => {
    if (loggedRole === ROLE.HUB_ADMIN) {
      setIsEditSidebar(true);
      setIsEditModal(false);
      return;
    }

    showModal();

    if (type === "edit") {
      setIsEditModal(true);
      setIsBlockVisible(false);
    } else {
      setConfirmationModalData((prevData) => ({
        data: {
          ...prevData.data,
          title: hubDetails.name,
        },
      }));
      setIsEditModal(false);
      setIsBlockVisible(true);
    }
  };

  /**
   * Hide modal wrapper and edit and block content
   */
  const hideModal = () => {
    setIsModalVisible(false);
    setIsEditModal(false);
    setIsBlockVisible(false);
  };

  /**
   * Check which modal needs to be showed
   *
   * @returns booelean
   */
  const handleModalState = () => {
    if (isEditModal) {
      return "edit-visible";
    }

    if (isBlockVisible) {
      return "block-visible";
    }

    return false;
  };

  /**
   * Block hub when user confirm that in modal
   */
  const blockHub = async () => {
    setIsLoadingButton(true);

    try {
      const hubStatus = await getHubBlockedStatus(hubDetails.hubId);

      await editHub(hubDetails.hubId, {
        blocked: hubDetails.blocked ? false : true,
      });

      updateBlockedStatus(HUBS(), hubDetails.hubId, hubStatus.id, {
        status: true,
      })
        .then(() => {
          setIsLoadingButton(false);
          hideModal();
          history.push(ROUTES.HUBS);
        })
        .catch((err) => {
          setIsLoadingButton(false);
          console.log(err);
        });
    } catch (error) {
      setIsLoadingButton(false);
      console.log("Error: ", error);
    }
  };

  /**
   * Generate full address
   *
   * @returns full address for HUB
   */
  const generateAddress = () => {
    const address =
      hubData?.address?.city !== "" ? `${hubData?.address?.city}, ` : "";
    const country =
      hubData?.address?.country !== ""
        ? `${hubData?.address?.country?.name}, `
        : "";
    const zip = hubData?.address?.zip !== "" ? `${hubData?.address?.zip}` : "";
    let fullAddress = address + country + zip;

    if (fullAddress === "") {
      fullAddress = "/";
    }
    return fullAddress;
  };

  const handleLoader = () => {
    if (!isDataLoading && hubData) {
      return false;
    }
    return true;
  };

  return (
    <div className="single-hub">
      {!handleLoader() ? (
        <>
          <Header
            loggedRole={loggedRole}
            hubHeader={hubData}
            editMode={handleEditMode}
          />
          <div className="single-hub-container padding-right-layout padding-left-layout routes-layout">
            <div className="info-block display-flex align-items-start justify-content-between">
              <div className="admin-details width-100">
                <Typography variant="subtitle2">
                  {t("HUBS.SINGLE_HUB.ADMIN")}
                </Typography>
                <DefaultTeacher teacher={hubData?.administrator} key={id} />
              </div>
              <div className="contact-details">
                <Typography variant="h1">
                  {t("HUBS.SINGLE_HUB.CONTACT")}
                </Typography>
                <div className="details">
                  <p>{t("HUBS.ADDRESS")}</p>
                  <Typography variant="h4">{generateAddress()}</Typography>
                </div>
                <div className="details">
                  <p>{t("HUBS.PHONE")}</p>
                  <Typography variant="h4">+{hubData?.phone}</Typography>
                </div>
                <div className="details">
                  <p>{t("HUBS.EMAIL")}</p>
                  <Typography variant="h4">
                    {hubData?.administrator?.email}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="teachers-educations">
              <Typography variant="subtitle2">
                {t("HUBS.SINGLE_HUB.TEACHERS_AND_EDUCATIONS")}
              </Typography>
              <div className="display-flex align-items-center registered-data-holder">
                <div className="registered-data display-flex align-items-center justify-content-between">
                  <img src={registeredTeachers} alt="Users" />
                  {t("HUBS.SINGLE_HUB.REGISTERED_TEACHERS")}
                  <span>{hubTeachersCount}</span>
                </div>
                <div className="registered-data display-flex align-items-center justify-content-between">
                  <img src={registeredEducations} alt="Educations" />
                  {t("HUBS.SINGLE_HUB.REGISTERED_EDUCATIONS")}
                  <span>{hubEducationsCount}</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <DataLoader className="single-hub-container" />
      )}
      {isEditSidebar && (
        <EditSingleHub
          isVisible={isEditSidebar}
          selectedHub={{ data: hubData }}
          onClose={hideEditSidebar}
        />
      )}
      <AppModal
        isVisible={isModalVisible}
        className={isEditModal && "add-edit-hub-modal"}
        content={
          handleModalState() === "edit-visible" ? (
            <EditHub
              selectedHub={{ data: hubDetails }}
              onCloseModal={hideModal}
            />
          ) : (
            <ConfirmationModalContent
              data={confirmationModalData.data}
              isLoadingButton={isLoadingButton}
              onConfirmAction={blockHub}
              onCancel={hideModal}
            />
          )
        }
      />
    </div>
  );
};

export default SingleHub;
