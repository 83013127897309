import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import "./css/app-date-time-picker.css";
import { useTranslation } from "react-i18next";

const AppDatePicker = (props) => {
  const {
    dateTimeKey,
    submitted,
    value,
    onChange,
    pickerLabel,
    placeholder,
    disabled,
    disabledDate,
  } = props;
  const { t } = useTranslation();
  const dateFormat = "DD-MM-YYYY";

  return (
    <div
      className={`app-date-time-picker ${
        submitted && !value && "picker-error"
      }`}
    >
      {pickerLabel && <label className="picker-label">{pickerLabel}</label>}

      <DatePicker
        key={dateTimeKey}
        // placeholder={t("DATE_PICKER.START_DATE")}
        placeholder={placeholder}
        inputReadOnly={true}
        clearIcon={null}
        showToday={false}
        format={dateFormat}
        value={value ? moment(value, dateFormat) : value}
        onChange={onChange}
        disabled={disabled}
        disabledDate={disabledDate}
      />
      {submitted && !value && (
        <p className="error-message">{t("DATE_PICKER.REQUIRED_DATE")}</p>
      )}
    </div>
  );
};

export default AppDatePicker;
