import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "auth/context/AuthContext";
import { GET_USERS } from "services/CONSTANTS";
import { db } from "../../../firebase/firebase";
import { ROLE } from "utils/ROLES";

const StudentContext = React.createContext();

export const useStudent = () => {
  return useContext(StudentContext);
};

const StudentProvider = ({ children }) => {
  const { currentUser } = useAuth();

  const [allStudents, setAllStudents] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    if (!currentUser) return;
    setIsDataLoading(true);

    const getAllStudents = db
      .collection(GET_USERS())
      .where("id", "!=", null)
      .where("type.roleId", "==", ROLE.STUDENT)
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          const userData = snapshot.docs.map((doc) => {
            return {
              studentId: doc.id,
              ...doc.data(),
              status: { subCollectionId: "", blocked: doc.data().blocked },
            };
          });

          if (userData) {
            setAllStudents(userData);
            setIsDataLoading(false);
          }
        } else {
          setIsDataLoading(false);
        }
      });

    return () => {
      getAllStudents();
    };
  }, [currentUser]);

  const data = {
    allStudents,
    isDataLoading,
  };

  return (
    <StudentContext.Provider value={data}>{children}</StudentContext.Provider>
  );
};

export { StudentProvider };
