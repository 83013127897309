import React from "react";
import eLearningLogo from "assets/images/app-logo.png";
import gradiskaLogo from "assets/images/logo-gradiska.png";
import daruvarLogo from "assets/images/logo-daruvar.png";
import lipikLogo from "assets/images/logo-lipik.png";
import kotorLogo from "assets/images/logo-kotor.png";
import facebookLogo from "assets/images/facebook-logo.png";
import instagramLogo from "assets/images/instagram-logo.png";
import euLogo from "assets/images/logo-eu.png";
import { useTranslation } from "react-i18next";
import "./css/footer.css";

const Footer = () => {
  const { t } = useTranslation();
  const partners = [
    {
      id: 1,
      name: "Gradiska",
      image: gradiskaLogo,
      link: "http://www.gradgradiska.com/",
    },
    {
      id: 2,
      name: "Daruvar",
      image: daruvarLogo,
      link: "https://daruvar.hr/",
    },
    {
      id: 3,
      name: "Lipik",
      image: lipikLogo,
      link: "https://lipik.hr/",
    },
    {
      id: 4,
      name: "Kotor",
      image: kotorLogo,
      link: "https://www.kotor.me/",
    },
  ];

  const socialNetworks = [
    {
      id: 1,
      name: "Facebook",
      image: facebookLogo,
      link: "https://www.facebook.com/",
    },
    {
      id: 2,
      name: "Instagram",
      image: instagramLogo,
      link: "https://www.instagram.com/hub078/",
    },
  ];

  return (
    <footer>
      <div className="footer-container">
        <div className="routes-layout padding-left-layout padding-right-layout">
          <div className="footer-content">
            <div className="row">
              <div className="side app-logo display-flex align-items-end">
                <img src={eLearningLogo} alt="Logo" />
              </div>
              <div className="side partners display-flex align-items-end">
                {partners.map((partner) => (
                  <a
                    key={partner.id}
                    href={partner.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={partner.image} alt={partner.name} />
                  </a>
                ))}
              </div>
            </div>
            <div className="row">
              <div className="side">
                <div className="social-networks">
                  {socialNetworks.map((social) => (
                    <a
                      key={social.id}
                      href={social.link}
                      target="_blank"
                      rel="noreferrer"
                      className="inline-block"
                    >
                      <img src={social.image} alt={social.name} />
                    </a>
                  ))}
                </div>
                <div className="description">
                  <p>{t("FOOTER.FIRST")}</p>
                  <p>{t("FOOTER.SECOND")}</p>
                </div>
              </div>
              <div className="side">
                <a
                  className="display-flex justify-content-end"
                  href="https://ec.europa.eu/regional_policy/en/funding/erdf/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={euLogo} alt="EU" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
