import { ROLE } from "./ROLES";

/**
 * Show role name per roleId
 *
 * @param {*} data
 * @returns role name
 */
const showRoleName = (data, t) => {
  let roleName;
  const userType = data?.user?.type?.roleId;

  switch (userType) {
    case ROLE.TEACHER:
      roleName = t("ROLES.TEACHER");
      break;

    case ROLE.HUB_ADMIN:
      roleName = t("ROLES.HUB_ADMIN");
      break;

    case ROLE.STUDENT:
      roleName = t("ROLES.STUDENT");
      break;

    default:
      roleName = t("ROLES.SUPER_ADMIN");
      break;
  }

  return roleName;
};

export { showRoleName };
