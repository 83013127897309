import React from "react";
// Handle console logs
import "utils/dropConsole";
import "./App.css";
// ROUTER
import { AuthProvider } from "auth/context/AuthContext";
// Redux
import { Provider } from "react-redux";
import { store } from "redux/store";

import { ThemeProvider } from "@material-ui/core";
import { theme } from "./styles/muiTheme";
import AppRouter from "router/AppRouter";
import AppStateProvider from "pages/Twilio/state";

function App() {
  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <AppStateProvider>
              <AppRouter />
            </AppStateProvider>
          </AuthProvider>
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default App;
