import React, { useState } from "react";
import avatar from "assets/images/icons/avatar.svg";
import check from "assets/images/icons/check-circle.svg";
import decline from "assets/images/icons/red-circle.svg";
import ReactTooltip from "react-tooltip";
import cv from "assets/images/icons/cv.svg";
import { Card, Button, Typography } from "@material-ui/core";
import "../css/StudentCard.css";
import { generateFullName } from "utils/generateFullName";
import { getFormattedDate } from "utils/dateFormat";
import { ROLE } from "utils/ROLES";
import { PARTICIPANT_STATUS } from "utils/participantStatus";
import { useTranslation } from "react-i18next";

const Student = (props) => {
  const { student, selectedStudent } = props;
  const { t } = useTranslation();
  const LOGGED_ROLE = +localStorage.getItem("uType");

  /**
   * Select student on click button
   *
   * @param {*} type => can be approve or dissaprove
   */
  const selectStudent = (type) => {
    selectedStudent(student.participantInformation, type);
  };

  /**
   * Show possible student actions per logged role
   *
   * @returns boolean => true / false
   */
  const showStudentActions = () => {
    if (LOGGED_ROLE === ROLE.TEACHER) {
      if (student?.isPrimary) {
        return true;
      }
    }
    return false;
  };

  /**
   * Show student applied date if logged teacher is primary on that education
   *
   * @returns boolean => true / false
   */
  const showStudentAppliedDate = () => {
    if (LOGGED_ROLE === ROLE.TEACHER) {
      if (student?.isPrimary) {
        return true;
      }
    }
    return false;
  };

  /**
   * Download user cv
   */
  const downloadCv = () => {
    if (student.participantInformation?.cvLink) {
      window.open(student.participantInformation?.cvLink);
    } else {
      alert("There is no cv!");
    }
  };

  return (
    <Card key={student?.participantInformation?.id} className="student-card">
      <div className="card-header justify-content-between display-flex ">
        <div className="display-flex">
          <img src={avatar} alt="avatar" className="avatar" />
          <div className="student-info">
            <Typography variant="h5">
              {generateFullName(
                student?.participantInformation?.firstName,
                student?.participantInformation?.lastName
              )}
            </Typography>
            <Typography variant="body1" color={"textSecondary"}>
              {showStudentAppliedDate()
                ? `${t("EDUCATIONS.STUDENTS_LIST.APPLIED")} 
								${getFormattedDate(student?.appliedDate, ".")}`
                : student?.participantInformation?.email}
            </Typography>
          </div>
        </div>

        <div className="cv-section" onClick={downloadCv}>
          <img src={cv} alt="cv" data-tip data-for="cv" />
          <ReactTooltip
            id="cv"
            place="right"
            effect="solid"
            className="cv-tooltip"
            arrowColor="transparent"
          >
            {t("EDUCATIONS.STUDENTS_LIST.DOWNLOAD_CV")}
          </ReactTooltip>
        </div>
      </div>

      {showStudentActions() && (
        <div className="card-actions">
          {student?.approvalStatus?.id !== PARTICIPANT_STATUS.PENDING.id ? (
            <div className="display-flex justify-content-center">
              <img
                src={
                  student?.approvalStatus?.id === PARTICIPANT_STATUS.APPROVED.id
                    ? check
                    : decline
                }
                alt="check-circle"
              />
            </div>
          ) : (
            <div className="display-flex">
              <Button
                color="primary"
                variant="contained"
                case="sentance-case"
                onClick={() => selectStudent("approve")}
              >
                {t("ACTION_BUTTONS.APPROVE")}
              </Button>
              <Button
                variant="outlined"
                onClick={() => selectStudent("decline")}
              >
                {t("ACTION_BUTTONS.DECLINE")}
              </Button>
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

export default Student;
