import React, { useState, useEffect } from "react";
import "../css/List.css";
import { useStudent } from "../context/StudentContext";
import Item from "./Item";
import PageTitle from "components/PageTitle";
import sortUp from "assets/images/icons/sort-arrow-up.svg";
import sortDown from "assets/images/icons/sort-arrow-down.svg";
import EmptyState from "components/EmptyState";
import EmptyImage from "assets/images/empty-state/students.svg";
import Pagination from "@material-ui/lab/Pagination";
import AppModal from "../../../components/AppModal";
import ConfirmationModalContent from "../../../components/ConfirmationModalContent";
import { updateBlockedStatus } from "services/blockedStatusService";
import { GET_USERS } from "services/CONSTANTS";
import { useTranslation } from "react-i18next";
import DataLoader from "components/DataLoader";
import { getUserBlockedStatus, updateUserDetails } from "services/userService";

const List = () => {
  const PER_PAGE = 7;
  const STUDENTS_COUNT = 1;

  const { allStudents, isDataLoading } = useStudent();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [paginateStudents, setPaginateStudents] = useState({
    data: allStudents,
  });
  const [studentCount, setHubsCount] = useState(STUDENTS_COUNT);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [selectedStudent, setSelectedStudent] = useState({ data: null });
  const [confirmationModalData, setConfirmationModalData] = useState({
    data: null,
  });

  useEffect(() => {
    if (!allStudents) return;
    setPaginateStudents({
      data: allStudents.slice(0, PER_PAGE),
    });
    const count = allStudents.length;
    setHubsCount(Math.ceil(count / PER_PAGE));

    return () => {
      localStorage.removeItem("sortedStudents");
    };
  }, [allStudents]);

  /**
   * Change page
   * @param {*} event => event
   * @param {*} value => selected value
   */
  const handleChange = (event, value) => {
    const selectedPage = value * PER_PAGE;
    const previousPage = selectedPage - PER_PAGE;
    const getSortedStudents = JSON.parse(
      localStorage.getItem("sortedStudents")
    );
    if (getSortedStudents !== null) {
      setPaginateStudents({
        data: getSortedStudents.slice(previousPage, selectedPage),
      });
    } else {
      setPaginateStudents({
        data: allStudents.slice(previousPage, selectedPage),
      });
    }
    setPage(value);
  };

  /**
   * Sort students
   *
   * @param {*} field => per which field to sort
   * @param {*} orderType => type of order (asc/desc)
   * @param {*} element => clicked arrow
   */
  const sortStudents = (field, orderType, element) => {
    const sortedStudents = [...allStudents].sort((a, b) => {
      if (orderType === "asc") {
        return a[field] > b[field] ? 1 : -1;
      }

      return a[field] < b[field] ? 1 : -1;
    });

    if (element !== null && element !== undefined) {
      const clickedArrow = element.target;
      const sortArrows = document.querySelectorAll(".arrow-sort");
      sortArrows.forEach((arrow) => {
        arrow.classList.remove("selected-arrow");
      });
      clickedArrow.classList.add("selected-arrow");
    }
    setPaginateStudents({
      data: sortedStudents.slice(0, PER_PAGE),
    });
    localStorage.setItem("sortedStudents", JSON.stringify(sortedStudents));
    setPage(1);
  };

  /**
   * Show modal
   */
  const showModal = () => {
    setIsModalVisible(true);
  };

  /**
   * Hide modal
   */
  const closeModal = () => {
    setIsModalVisible(false);
  };

  /**
   * Block/Unblock teacher
   */
  const updateBlockStatus = async () => {
    const isBlocked = selectedStudent.data.status.blocked;
    setIsLoadingButton(true);

    try {
      const userStatus = await getUserBlockedStatus(selectedStudent.data.id);

      await updateUserDetails(selectedStudent.data.id, {
        blocked: isBlocked ? false : true,
      });

      updateBlockedStatus(GET_USERS(), selectedStudent.data.id, userStatus.id, {
        status: isBlocked ? false : true,
      })
        .then(() => {
          setIsLoadingButton(false);
          closeModal();
        })
        .catch((err) => {
          setIsLoadingButton(false);
          console.log(err);
        });
    } catch (error) {
      console.log(error);
      setIsLoadingButton(false);
    }
  };

  const selectStudent = (student) => {
    const fullName = `${student.firstName} ${student.lastName}`;
    const isBlocked = student?.status.blocked;

    setSelectedStudent({ data: student });
    setConfirmationModalData(() => ({
      data: {
        type: `${isBlocked}` ? "block" : "unblock",
        title: `${
          `${isBlocked}`
            ? `${t("STUDENTS.BLOCK_STUDENT.UNBLOCK")} ${fullName}`
            : `${t("STUDENTS.BLOCK_STUDENT.BLOCK")} ${fullName}`
        }`,
        variant: isBlocked ? "contained" : "outlined",
        color: isBlocked ? "primary" : "default",
        actionButtonText: isBlocked
          ? t("ACTION_BUTTONS.UNBLOCK")
          : t("ACTION_BUTTONS.BLOCK"),
        description: isBlocked
          ? t("STUDENTS.BLOCK_STUDENT.UNBLOCK_DESCRIPTION")
          : t("STUDENTS.BLOCK_STUDENT.BLOCK_DESCRIPTION"),
      },
    }));
    showModal();
  };

  const handleEmptyStateVisibility = () => {
    if (!isDataLoading && allStudents.length <= 0) {
      return true;
    }
    return false;
  };

  const handleDataInTableVisibility = () => {
    if (!isDataLoading && allStudents.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <div className="students-list routes-layout padding-right-layout padding-left-layout padding-top-layout padding-bottom-layout">
      {handleDataInTableVisibility() && (
        <>
          <PageTitle title={t("STUDENTS.LIST")} />
          <div className="list width-100">
            <div className="item-headers display-flex">
              <div className="column-header display-flex justify-content-between align-items-center">
                <p>{t("STUDENTS.TABLE_HEADER.NAME")}</p>
                <div className="sort-icons display-flex flex-direction-column">
                  <img
                    className="arrow-sort"
                    src={sortUp}
                    alt="Sort"
                    onClick={(element) =>
                      sortStudents("firstName", "desc", element)
                    }
                  />
                  <img
                    className="arrow-sort"
                    src={sortDown}
                    alt="Sort"
                    onClick={(element) =>
                      sortStudents("firstName", "asc", element)
                    }
                  />
                </div>
              </div>
              <div className="column-header display-flex justify-content-between align-items-center">
                <p>{t("STUDENTS.TABLE_HEADER.EMAIL")}</p>
                <div className="sort-icons display-flex flex-direction-column">
                  <img
                    className="arrow-sort"
                    src={sortUp}
                    alt="Sort"
                    onClick={(element) =>
                      sortStudents("email", "desc", element)
                    }
                  />
                  <img
                    className="arrow-sort"
                    src={sortDown}
                    alt="Sort"
                    onClick={(element) => sortStudents("email", "asc", element)}
                  />
                </div>
              </div>
              <div className="column-header"></div>
            </div>
            {paginateStudents.data.map((student) => (
              <Item
                key={student?.studentId}
                student={student}
                choseStudent={selectStudent}
              />
            ))}
          </div>
        </>
      )}

      {handleEmptyStateVisibility() && (
        <EmptyState
          className="not-found"
          image={EmptyImage}
          title={t("STUDENTS.EMPTY_STATE.TITLE")}
          caption={t("STUDENTS.EMPTY_STATE.DESCRIPTION")}
          hasButton={false}
        />
      )}

      {isDataLoading && <DataLoader />}

      {paginateStudents.data.length > 0 && (
        <Pagination
          count={studentCount}
          page={page}
          shape="rounded"
          onChange={handleChange}
        />
      )}
      <AppModal
        isVisible={isModalVisible}
        content={
          <ConfirmationModalContent
            data={confirmationModalData.data}
            selectedStudent={selectedStudent}
            isLoadingButton={isLoadingButton}
            onConfirmAction={updateBlockStatus}
            onCancel={closeModal}
          />
        }
      />
    </div>
  );
};

export default List;
