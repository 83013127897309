const EDUCATION_STATUS = {
  SCHEDULED: { id: "scheduled-status", name: "Scheduled" },
  ACTIVE: { id: "active-status", name: "Active" },
  PLANNED: { id: "finalized-status", name: "Finalized" },
  BLOCKED: { id: "blocked-status", name: "Blocked" },
};

const getEducationStatus = (t) => {
  return [
    { id: "scheduled-status", name: t("EDUCATIONS.SCHEDULED") },
    { id: "active-status", name: t("EDUCATIONS.ACTIVE") },
    { id: "finalized-status", name: t("EDUCATIONS.FINALIZED") },
    { id: "blocked-status", name: t("EDUCATIONS.BLOCKED_STATUS") },
  ];
};

export { EDUCATION_STATUS, getEducationStatus };
