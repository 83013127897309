import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PageTitle from "components/PageTitle";
import addQuestion from "assets/images/icons/white-plus.svg";
import "./css/qa.css";
import AppModal from "components/AppModal";
import AddQuestion from "components/AddQuestion";
import QAItem from "components/QAItem";
import { useAuth } from "auth/context/AuthContext";
import { db } from "../../../../firebase/firebase";
import { QUESTIONS_ANSWERS } from "services/CONSTANTS";
import {
  createNewQuestion,
  createQuestionReply,
  deleteQuestion,
} from "services/qaService";
import firebase from "firebase/app";
import { getUserDetails } from "services/userService";
import * as uuid from "uuid";
import ConfirmationModalContent from "components/ConfirmationModalContent";
import EmptyState from "components/EmptyState";
import EmptyImage from "assets/images/empty-state/qa.svg";
import { ROLE } from "utils/ROLES";
import { useTranslation } from "react-i18next";
import { createNewNotification } from "services/notificationService";
import { APPLICATION_URL } from "utils/appUrl";
import { NOTIFICATION_TYPE } from "utils/notificationType";
import { ROUTES } from "router/CONSTANTS";
import { useEducation } from "pages/Educations/context/EducationContext";
import { getParticipants } from "services/educationService";
import { PARTICIPANT_STATUS } from "utils/participantStatus";
import { generateFullName } from "utils/generateFullName";

const QA = (props) => {
  const { currentUser, loggedRole } = useAuth();
  const { educationId } = props;

  const { singleEducation } = useEducation();

  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteQuestion, setIsDeleteQuestion] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [selectedForDelete, setSelectedForDelete] = useState(null);
  const { t } = useTranslation();

  // props for confirmation delete modal
  const confirmationModalData = {
    type: "delete",
    actionButtonText: "Delete",
    title: "Delete post",
    description:
      "It seems something was said or done  wrong?! By doing this action, post you have chosen to delete will not be visible anymore and you can undo it.",
  };

  useEffect(() => {
    if (!currentUser) return;
    if (loggedRole === undefined || loggedRole === null) return;
    if (!educationId) return;

    localStorage.removeItem("activeQuestion");

    const getQuestionsAndAnswers = db
      .collection(QUESTIONS_ANSWERS())
      .where("educationId", "==", educationId)
      .orderBy("qDateTime", "desc")
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          const QAData = snapshot?.docs.map((doc) => {
            return {
              id: doc?.id,
              ...doc.data(),
              expanded: false,
            };
          });

          if (QAData) {
            setQuestionsAndAnswers(QAData);
          }
        } else {
          setQuestionsAndAnswers(snapshot.docs);
        }
      });

    return () => {
      getQuestionsAndAnswers();
    };
  }, [currentUser, loggedRole, educationId]);

  /**
   * Show modal for adding new question
   */
  const showModal = () => {
    setIsModalVisible(true);
  };

  /**
   * Hide modal
   */
  const hideModal = () => {
    setIsModalVisible(false);
    setIsDeleteQuestion(false);
  };

  /**
   * Notify users after adding new question
   */
  const notifyUsers = async () => {
    const educatorIds = singleEducation.educators.filter(
      (id) => id !== currentUser.uid
    );
    const title = singleEducation.title;
    const loggedUser = JSON.parse(localStorage.getItem("user"));
    const creator = generateFullName(loggedUser.firstName, loggedUser.lastName);

    try {
      const firebaseResponse = await getParticipants(educationId);
      const participants = await firebaseResponse.docs
        .map((doc) => doc.data())
        .filter(
          (participant) =>
            participant?.approvalStatus?.id === PARTICIPANT_STATUS.APPROVED.id
        );

      const participantIds = participants.map(
        (participant) => participant.userId.split("/")[1]
      );

      const educatorsAndParticipants = [...educatorIds, ...participantIds];

      const notifyRecepients = educatorsAndParticipants.map(
        async (recepient) => {
          const newNotificationData = {
            sender: currentUser?.email,
            recepient,
            title,
            content: "",
            linkTo: `${APPLICATION_URL}${ROUTES.EDUCATIONS}/${educationId}`,
            educationId,
            timelineId: "",
            type: NOTIFICATION_TYPE.NEW_POST,
            notificationDateTime: firebase.firestore.Timestamp.fromDate(
              new Date()
            ),
            seen: false,
            qa: {
              creator,
            },
          };

          const recepientsNotification = await createNewNotification(
            newNotificationData
          );

          return recepientsNotification;
        }
      );
      await Promise.all(notifyRecepients);
    } catch (error) {
      console.log("Cannot get participants: ", error);
    }
  };

  /**
   * Add new question
   *
   * @param {*} editorValue value from editor
   */
  const addNewQuestion = async (editorValue) => {
    setIsButtonLoading(true);
    const userId = localStorage.getItem("uid");

    try {
      const loggedUser = await getUserDetails(userId);
      const { firstName, lastName, id, type } = loggedUser.data();

      const newQuestion = {
        educationId,
        postText: editorValue,
        qDateTime: firebase.firestore.Timestamp.fromDate(new Date()),
        user: { firstName, lastName, id, type },
        comments: [],
      };

      await createNewQuestion(newQuestion);
      await notifyUsers();

      setIsButtonLoading(false);
      hideModal();
    } catch (error) {
      console.log("Cannot add new question: ", error);
      setIsButtonLoading(false);
    }
  };

  /**
   * Handle expanded comments for question
   *
   * @param {*} qa => selected question
   */
  const selectedQuestion = (qa) => {
    const allQuestions = questionsAndAnswers.map((doc) => {
      if (doc.id === qa.id) {
        doc.expanded = !doc.expanded;
      }
      return doc;
    });
    setQuestionsAndAnswers(allQuestions);
  };

  /**
   * Add reply for question
   *
   * @param {*} editorValue => value from editor
   * @param {*} questionId => questionId for which we add reply
   */
  const addQuestionReply = async (editorValue, questionId) => {
    const userId = localStorage.getItem("uid");
    try {
      const loggedUser = await getUserDetails(userId);
      const { firstName, lastName, id, type } = loggedUser.data();

      const comment = {
        id: uuid.v4(),
        comment: editorValue,
        commentDateTime: firebase.firestore.Timestamp.fromDate(new Date()),
        user: { firstName, lastName, id, type },
      };

      await createQuestionReply(questionId, comment);
    } catch (error) {
      console.log("Cannot reply to question: ", error);
    }
  };

  /**
   * Open modal for delete
   *
   * @param {*} qa => selected question
   */
  const deleteQuestionModal = (qa) => {
    setSelectedForDelete(qa);
    setIsDeleteQuestion(true);
  };

  /**
   * Delete selected question
   */
  const deleteSelectedQuestion = async () => {
    setIsButtonLoading(true);
    try {
      await deleteQuestion(selectedForDelete);
      setIsButtonLoading(false);
      hideModal();
    } catch (error) {
      setIsButtonLoading(false);
      console.log("Cannot delete question: ", error);
    }
  };

  /**
   * Show modal for adding new question when there is no question
   */
  const handleEmptyState = () => {
    showModal();
  };

  return (
    <div className="qa-section">
      <div className="header display-flex align-items-center justify-content-between">
        {questionsAndAnswers.length > 0 && (
          <PageTitle title={t("EDUCATIONS.Q&A.TITLE")} />
        )}
        {questionsAndAnswers.length > 0 && loggedRole === ROLE.TEACHER && (
          <Button
            className="add-question"
            variant="contained"
            color="primary"
            startIcon={<img src={addQuestion} alt="Add question" />}
            onClick={showModal}
          >
            {t("ACTION_BUTTONS.ASK_QUESTION")}
          </Button>
        )}
      </div>
      <div className="qa-list">
        {questionsAndAnswers.length > 0 ? (
          <>
            {questionsAndAnswers.map((qa) => (
              <QAItem
                key={qa?.id}
                qa={qa}
                selectedQuestion={selectedQuestion}
                addQuestionReply={addQuestionReply}
                deleteQuestion={deleteQuestionModal}
                loggedRole={loggedRole}
              />
            ))}
          </>
        ) : (
          <EmptyState
            image={EmptyImage}
            title={t("EDUCATIONS.Q&A.EMPTY_STATE.TITLE")}
            caption={t("EDUCATIONS.Q&A.EMPTY_STATE.DESCRIPTION")}
            hasButton={true}
            emptyStateAction={handleEmptyState}
            buttonTitle={t("ACTION_BUTTONS.ASK_QUESTION")}
            link="/"
            hasButtonIcon={true}
          />
        )}
      </div>
      <AppModal
        isVisible={isModalVisible}
        content={
          <AddQuestion
            isButtonLoading={isButtonLoading}
            onConfirmAction={addNewQuestion}
            onCancel={hideModal}
          />
        }
      />
      <AppModal
        isVisible={isDeleteQuestion}
        content={
          <ConfirmationModalContent
            onConfirmAction={deleteSelectedQuestion}
            data={confirmationModalData}
            isLoadingButton={isButtonLoading}
            onCancel={hideModal}
          />
        }
      />
    </div>
  );
};

export default QA;
