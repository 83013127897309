import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";

import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { useEducation } from "pages/Educations/context/EducationContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: "white",
      "&:hover": {
        background: "lightgray",
        color: "black",
      },
    },
  })
);

export default function EndCallButton(props: {
  className?: string;
  leaveText?: string;
}) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { singleEducation, setLessonEndStatus } = useEducation();

  /**
   * Disconnect room for
   *
   * If logged user is primary teacher then lesson will be ended for all participants, and if user is not primary, then he will just end lesson for self
   */
  const disconnectRoom = () => {
    const selectedTimeline = localStorage.getItem("selectedTimeline");
    const timelineId =
      selectedTimeline !== null ? JSON.parse(selectedTimeline).id : "";
    const isPrimaryTeacher = localStorage.getItem("isPrimaryTeacher");
    const encodeUri =
      isPrimaryTeacher === "true"
        ? `/educations/${singleEducation.id}${
            window.location.search || ""
          }?end=true&for=${timelineId}`
        : `/educations/${singleEducation.id}${window.location.search || ""}`;

    if (isPrimaryTeacher === "true") {
      setLessonEndStatus(true);
    }
    room!.disconnect();
    window.history.replaceState(null, "", window.encodeURI(encodeUri));
  };

  return (
    <Button
      onClick={disconnectRoom}
      className={clsx(classes.button, props.className)}
      data-cy-disconnect
    >
      {props.leaveText}
    </Button>
  );
}
