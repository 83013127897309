import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { Button, Typography } from "@material-ui/core";

import ChatIcon from "pages/Twilio/icons/ChatIcon";

import "../../../../Educations/css/Lesson.css";
import SendMessageInput from "components/SendMessageInput";
import { useTranslation } from "react-i18next";
import { EDUCATIONS, TIMELINE } from "services/CONSTANTS";

import avatar from "assets/images/icons/avatar.svg";
import { useEducation } from "pages/Educations/context/EducationContext";

import { db } from "../../../../../firebase/firebase";
import { createTimelineChat } from "services/educationService";
import { getUserDetails } from "services/userService";
import firebase from "firebase/app";
import { generateFullName } from "utils/generateFullName";
import { getFormattedTime } from "utils/dateFormat";

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      "&[disabled]": {
        color: "#bbb",
        "& svg *": {
          fill: "#bbb",
        },
      },
      "&.has-new-message": {
        position: "relative",
        "&::after": {
          position: "absolute",
          top: "4px",
          left: "4px",
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          background: "#003399",
          color: "white",
          content: '""',
        },
      },
    },
  })
);

const chatContainerStyle = makeStyles((theme: Theme) =>
  createStyles({
    chatContainer: {
      position: "fixed",
      right: "23px",
      top: "36px",
      bottom: "0",
      width: "100%",
      maxWidth: "380px",
      height: "auto",
      maxHeight: "calc(100% - 144px)",
      borderRadius: "12px",
      opacity: "0",
      transition: "all .3s",
      transform: "translateX(120%)",
      "&.show-chat": {
        opacity: "1",
        transform: "translateX(0%)",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "90%",
        left: "0",
        right: "0",
        margin: "0 auto",
      },
    },
  })
);

interface TimelineChat {
  educationId: string;
  id: string;
  message: string;
  messageTime: any;
  user: { lastName: string; firstName: string; id: string };
}

export default function ToggleChatButton(props: { disabled?: boolean }) {
  const { t } = useTranslation();
  const { singleEducation } = useEducation();

  const isDisabled = props.disabled;

  const classes = useStyles();
  const chatContainerClasses = chatContainerStyle();

  const [isChatVisible, setIsChatVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewMessageArrived, setIsNewMessageArrived] = useState(false);

  useEffect(() => {
    if (!singleEducation) return;
    localStorage.removeItem("isMessageSent");

    const isMessagesExists = localStorage.getItem("storedMessages");

    if (isMessagesExists === null) {
      localStorage.setItem("storedMessages", JSON.stringify([]));
    }
    const selectedTimeline = localStorage.getItem("selectedTimeline");
    const timelineId =
      selectedTimeline !== null ? JSON.parse(selectedTimeline).id : "";

    const chatMessagesBlock = document.querySelector(".chat-messages");

    const unsubscribe = db
      .collection(EDUCATIONS())
      .doc(singleEducation?.id)
      .collection(TIMELINE())
      .doc(timelineId)
      .collection("chat")
      .orderBy("messageTime")
      .onSnapshot((chatMessages: any) => {
        if (!chatMessages.empty) {
          const messageData = chatMessages?.docs.map((doc: any) => {
            return {
              id: doc?.id,
              ...doc.data(),
            };
          });

          if (messageData) {
            setMessages(messageData);
            const storedMessages = localStorage.getItem("storedMessages");
            if (storedMessages !== null) {
              const parsedMessages = JSON.parse(storedMessages);
              if (parsedMessages.length !== messageData.length) {
                setIsNewMessageArrived(true);
              }
            }
            if (chatMessagesBlock !== null) {
              chatMessagesBlock.scroll({
                top: chatMessagesBlock.scrollHeight,
                behavior: "smooth",
              });
            }
          }
        }
      });

    return () => {
      console.log("Unmount chat container");
      // localStorage.removeItem("storedMessages");
      unsubscribe();
    };
  }, [singleEducation]);

  /**
   * Send new message for lesson
   *
   * @param {any} data => recieved message from component
   */
  const sendMessage = async (data: any) => {
    setIsLoading(true);
    localStorage.setItem("isMessageSent", "true");
    const userId = localStorage.getItem("uid");
    const { message } = data;
    const messageTime = firebase.firestore.Timestamp.fromDate(new Date());
    const selectedTimeline = localStorage.getItem("selectedTimeline");
    const timelineId =
      selectedTimeline !== null ? JSON.parse(selectedTimeline).id : "";

    try {
      const loggedUser = await getUserDetails(userId);
      const { firstName, lastName, id } = loggedUser.data();

      const newMessage = {
        educationId: singleEducation?.id,
        message,
        messageTime,
        user: { firstName, lastName, id },
      };

      await createTimelineChat(singleEducation.id, timelineId, newMessage);

      setIsLoading(false);
      localStorage.setItem("isMessageSent", "true");
    } catch (error) {
      console.log("Cannot create chat! ", error);
      setIsLoading(false);
      localStorage.setItem("isMessageSent", "false");
    }
  };

  /**
   * Show chat container
   */
  const handleChatContainer = () => {
    setIsChatVisible(!isChatVisible);
    setIsNewMessageArrived(false);
    localStorage.setItem("storedMessages", JSON.stringify(messages));
  };

  const renderChatContainer = () => {
    return (
      <div
        className={`${chatContainerClasses.chatContainer} ${
          isChatVisible ? "show-chat" : "hide-chat"
        } lesson-content`}
      >
        <div className="chat">
          <div className="chat-header">
            <Typography variant="h3">
              {t("EDUCATIONS.LIVE_LESSON.CHAT.TITLE")}
            </Typography>
            <div className="close-chat" onClick={handleChatContainer}>
              <span className="minimize-icon"></span>
            </div>
          </div>
          <div className="chat-content width-100">
            <div className="chat-messages">
              {messages.length > 0 && (
                <>
                  {messages?.map((message: TimelineChat) => (
                    <div key={message?.id} className="message display-flex">
                      <img src={avatar} alt="Avatar" />
                      <div className="message-content">
                        <p>
                          {generateFullName(
                            message?.user?.firstName,
                            message?.user?.lastName
                          )}
                        </p>
                        <p className="message-text">{message?.message}</p>
                        <span className="message-time">
                          {getFormattedTime(message?.messageTime)}
                        </span>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            <div className="new-message width-100">
              <SendMessageInput
                onSendMessage={sendMessage}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showNewMessageBadge = () => {
    if (!isChatVisible && isNewMessageArrived) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Button
        className={`${classes.button} ${
          showNewMessageBadge() && "has-new-message"
        }`}
        disabled={isDisabled}
        startIcon={<ChatIcon />}
        onClick={handleChatContainer}
      >
        {t("TWILIO.CHAT")}
      </Button>
      {renderChatContainer()}
    </>
  );
}
