import React from "react";
import "../i18n/i18n";
import { useTranslation } from "react-i18next";

const Languages = () => {
  const { i18n } = useTranslation();

  const selectedLanguage = localStorage.getItem("locale");

  /**
   * Click on language and load translations
   *
   * @param {*} lang => chosed language(eng or bih)
   */
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("locale", lang);
  };

  return (
    <>
      <div
        value="bhs"
        className={`bih ${selectedLanguage === "bhs" && "active"}`}
        style={{ cursor: "pointer" }}
        onClick={() => changeLanguage("bhs")}
      >
        BIH
      </div>
      <div
        value="en"
        className={`eng ${selectedLanguage !== "bhs" && "active"}`}
        style={{ cursor: "pointer" }}
        onClick={() => changeLanguage("en")}
      >
        ENG
      </div>
    </>
  );
};

export default Languages;
