import React, { useState, useEffect } from "react";
import "./css/upload-input.css";
import fileUploadIcon from "assets/images/icons/file-upload.svg";
import { useTranslation } from "react-i18next";

const UploadInput = (props) => {
  const {
    reference,
    text,
    maxFile,
    value,
    onChoseFile,
    fileName,
    formSubmitted,
    errorMessage,
  } = props;

  useEffect(() => {
    if (formSubmitted) {
      setFileError(false);
    }
  }, [formSubmitted, value]);
  const { t } = useTranslation();

  const [fileError, setFileError] = useState(false);

  // error message for big file
  const maxFileError = `${t("IMAGE_UPLOAD.MAX_FILE")} ${maxFile} MB`;

  /**
   * Chose file from input
   *
   * @param {*} e => target input type file
   */
  const fileHandler = (e) => {
    const file = e.target.files[0];
    const convertToMB = (file.size / (1000 * 1000)).toFixed(2);

    if (+convertToMB <= maxFile) {
      onChoseFile(file, reference);
      setFileError(false);
    } else {
      setFileError(true);
      onChoseFile("", reference);
    }
  };

  /**
   * Check if unplod input has errors
   *
   * @returns boolean
   */
  const checkForErros = () => {
    if (fileError || (formSubmitted && value === "")) {
      return true;
    }
    return false;
  };

  /**
   * Show error message per happend error
   *
   * @returns error message
   */
  const helperMessage = () => {
    if (fileError) {
      return maxFileError;
    }
    return errorMessage;
  };

  return (
    <>
      <div className="upload-input width-100 display-flex align-items-center">
        <label
          htmlFor={`file-upload-${reference}`}
          className={`display-flex ${fileName}`}
        >
          <input
            type="file"
            name={`file-upload-${reference}`}
            id={`file-upload-${reference}`}
            onChange={fileHandler}
            accept=".png, .jpg, .jpeg, .svg"
          />
          <img src={fileUploadIcon} alt="File upload" />
          {t("ACTION_BUTTONS.CHOOSE_FILE")}
        </label>
        <p className={`${fileName}`}>{value.name !== "" ? value.name : text}</p>
      </div>
      {checkForErros() && <p className="error-message">{helperMessage()}</p>}
    </>
  );
};

export default UploadInput;
