import { GET_INVITES } from "./CONSTANTS";
import { db } from "../firebase/firebase";
import { auth } from "../firebase/firebase";
import { ROUTES } from "router/CONSTANTS";

// get invites
const getInvites = (invite) => {
  return new Promise((resolve, reject) => {
    try {
      db.collection(GET_INVITES())
        .where("uuid", "==", invite)
        .get()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      console.log("ERROR: ", error);
      reject("SYSTEM ERROR");
    }
  });
};

// set invite on signup
const setInvite = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      db.collection(GET_INVITES())
        .add({ uuid })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      console.log("ERROR: ", error);
      reject("SYSTEM ERROR");
    }
  });
};

// logout user
const logoutUser = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const logout = await auth.signOut();
      localStorage.removeItem("uid");
      localStorage.removeItem("uType");
      localStorage.removeItem("user");
      resolve(logout);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR", error);
    }
  });
};

// check users per email in collection invitations
const checkInvitedUsers = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const invitesRef = await db
        .collection("invitations")
        .where("userId", "==", email)
        .get();

      if (invitesRef.empty) {
        resolve(false);
      } else {
        resolve(true);
      }
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR");
    }
  });
};

export { getInvites, setInvite, logoutUser, checkInvitedUsers };
