import React, { useState, useEffect } from "react";
import { Typography, Button } from "@material-ui/core";
import avatar from "assets/images/icons/avatar.svg";
import LoadingButton from "components/LoadingButton";
import UploadInput from "components/UploadInput";
import infoIcon from "assets/images/icons/info.svg";
import "../css/JoinEducation.css";
import { useTranslation } from "react-i18next";

const JoinEducation = (props) => {
  const {
    onCloseModal,
    selectedForJoining,
    modalDescription,
    joinEducationRequest,
    isLoading,
  } = props;

  const { t } = useTranslation();

  const [cvFile, setCvFile] = useState({ name: "" });
  const [isRequiredError, setIsRequiredError] = useState(false);
  const isCvRequired = selectedForJoining?.cvRequest;
  const isApprovalRequired = selectedForJoining?.approvalRequest;
  const maxNumberParticipant = selectedForJoining?.maxNumber;
  const participantsOnEducation = selectedForJoining?.participants;

  useEffect(() => {
    if (!isCvRequired) {
      const getApplyBtn = document.querySelector(".student-apply-education");
      getApplyBtn.click();
    }

    return () => {};
  }, [isCvRequired]);

  /**
   * close Join Modal
   *
   * @param {*} action => apply/cancel
   */
  const closeJoin = (action) => {
    onCloseModal("join", action);
  };

  /**
   * Apply and open new modal
   */
  const studentJoinEducation = () => {
    if (maxNumberParticipant === participantsOnEducation.length) {
      return;
    }

    if (isCvRequired) {
      if (cvFile?.name === "") {
        setIsRequiredError(true);
        return;
      }

      joinEducationRequest(selectedForJoining, cvFile, isApprovalRequired);

      return;
    }

    joinEducationRequest(selectedForJoining, cvFile, isApprovalRequired);
  };

  /**
   * Set chosed file from input
   *
   * @param {*} file => selected file form input
   */
  const selectFile = (file) => {
    setIsRequiredError(false);
    setCvFile(file);
  };

  /**
   * Create text for modal
   *
   * @returns text for modal
   */
  const generateText = () => {
    if (maxNumberParticipant === participantsOnEducation.length) {
      return {
        title: t("EDUCATIONS.JOIN_EDUCATION.EDUCATION_FULL"),
        description: `${t("EDUCATIONS.JOIN_EDUCATION.EDUCATION")} ${
          selectedForJoining?.title
        } ${t("EDUCATIONS.JOIN_EDUCATION.EDUCATION_FULL_CAPTION")}`,
        button: t("ACTION_BUTTONS.CLOSE"),
      };
    }

    return {
      title: t("EDUCATIONS.JOIN_EDUCATION.WELCOME"),
      description: modalDescription,
      button: t("ACTION_BUTTONS.CANCEL"),
    };
  };

  /**
   * Show form for upload CV
   *
   * @returns true or false
   */
  const handleFormAppereance = () => {
    if (maxNumberParticipant !== participantsOnEducation.length) {
      return true;
    }

    return false;
  };

  return (
    <div className="join-education">
      <div className="modal-header justify-content-center">
        <div>
          <img src={avatar} alt="avatar" className="avatar" />
        </div>
        <div className="teacher-label">
          <Typography variant="h4" color="textPrimary">
            {selectedForJoining?.primEducator?.name}
          </Typography>
          <span>{t("EDUCATIONS.JOIN_EDUCATION.TEACHER")}</span>
        </div>
        <Typography variant="h1">{generateText().title}</Typography>
        {isCvRequired && (
          <div className="description">
            <Typography color="textPrimary">
              <span>{generateText().description}</span>
            </Typography>
          </div>
        )}
        {handleFormAppereance() && isCvRequired && (
          <>
            <UploadInput
              key="student-upload-cv"
              reference="upload-cv"
              text={t("EDUCATIONS.JOIN_EDUCATION.UPLOAD_CV")}
              maxFile={1}
              value={cvFile}
              onChoseFile={selectFile}
              fileName="file-name"
            />
            {isRequiredError && (
              <p className="error-message">
                {t("EDUCATIONS.JOIN_EDUCATION.CV_REQUIRED")}
              </p>
            )}
            <span className="upload-info">
              <img src={infoIcon} alt="Info icon" className="info-icon" />
              {t("EDUCATIONS.JOIN_EDUCATION.SPAN")}
            </span>
          </>
        )}
      </div>
      <div className="footer display-flex justify-content-center">
        {handleFormAppereance() && (
          <LoadingButton
            className="font-weight-600 student-apply-education"
            isLoading={isLoading}
            onClick={studentJoinEducation}
            text={t("ACTION_BUTTONS.APPLY_NOW")}
          />
        )}
        <Button
          onClick={() => closeJoin("cancel")}
          className="font-weight-600"
          variant={!handleFormAppereance() ? "contained" : "outlined"}
          color={!handleFormAppereance() ? "primary" : "default"}
          disabled={isLoading}
        >
          {generateText().button}
        </Button>
      </div>
    </div>
  );
};

export default JoinEducation;
