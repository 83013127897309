import React, { useState, useEffect } from "react";
import PageTitle from "components/PageTitle";
import { Button } from "@material-ui/core";
import plus from "assets/images/icons/white-plus.svg";
import "../css/List.css";
import SuperAdminTeachers from "./SuperAdminTeachers";
import { useTeacher } from "../context/TeacherContext";
import Pagination from "@material-ui/lab/Pagination";
import HubAdminTeachers from "./HubAdminTeachers";
import { useAuth } from "auth/context/AuthContext";
import { ROLE } from "utils/ROLES";
import AppModal from "components/AppModal";
import AddTeacher from "./AddTeacher";
import ConfirmationModalContent from "components/ConfirmationModalContent";
import { updateBlockedStatus } from "services/blockedStatusService";
import { GET_USERS } from "services/CONSTANTS";
import {
  createInvitation,
  getUserBlockedStatus,
  updateUserDetails,
} from "services/userService";
import firebase from "firebase/app";
import TeacherEducations from "./TeacherEducations";
import { useTranslation } from "react-i18next";
import { checkUsersEmail, getMyHub } from "services/hubService";
import EmptyState from "components/EmptyState";
import EmptyImage from "assets/images/empty-state/teachers.svg";
import { useSnackbar } from "contexts/SnackbarProvider";
import DataLoader from "components/DataLoader";
import { checkInvitedUsers } from "services/authService";

const TeachersList = () => {
  const PER_PAGE = 12;
  const TEACHERS_COUNT = 1;
  const { t } = useTranslation();
  const { teachersList, headers, isDataLoading } = useTeacher();
  const { loggedRole, currentUser } = useAuth();
  const [page, setPage] = useState(1);
  const [isNewTeacherModal, setisNewTeacherModal] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [paginateTeachers, setPaginateTeachers] = useState(teachersList);
  const [teachersCount, setTeachersCount] = useState(TEACHERS_COUNT);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [isTeacherEducations, setIsTeacherEducations] = useState(null);
  const [selectTeacher, setSelectTeacher] = useState({ data: null });
  const [teacherType, setTeacherType] = useState("");
  const [confirmationModalData, setConfirmationModalData] = useState({
    data: null,
  });

  const [isUserExist, setIsUserExistError] = useState(false);

  const dispatch = useSnackbar();

  /**
   * Show snackbar on some action
   */
  const handleSnackbar = (message, type) => {
    dispatch({
      message: message,
      success: type,
    });
  };

  useEffect(() => {
    if (!teachersList) return;
    setPaginateTeachers(teachersList.slice(0, PER_PAGE));
    const count = teachersList.length;
    setTeachersCount(Math.ceil(count / PER_PAGE));

    return () => {
      localStorage.removeItem("sortedTeachers");
    };
  }, [teachersList, currentUser]);

  /**
   * Change page
   * @param {*} event => event
   * @param {*} value => selected value
   */

  const handleChange = (event, value) => {
    const selectedPage = value * PER_PAGE;
    const previousPage = selectedPage - PER_PAGE;

    const getSortedTeachers = JSON.parse(
      localStorage.getItem("sortedTeachers")
    );
    if (getSortedTeachers !== null) {
      setPaginateTeachers(getSortedTeachers.slice(previousPage, selectedPage));
    } else {
      setPaginateTeachers(teachersList.slice(previousPage, selectedPage));
    }
    setPage(value);
  };

  /**
   * Sort teachers
   *
   * @param {*} field => per which field to sort
   * @param {*} orderType => type of order (asc/desc)
   * @param {*} element => clicked arrow
   */
  const sortTeachers = (field, orderType, element) => {
    const sortTeachers = teachersList.sort((a, b) => {
      if (orderType === "asc") {
        return a[field] > b[field] ? 1 : -1;
      }

      return a[field] < b[field] ? 1 : -1;
    });

    if (element !== null && element !== undefined) {
      const clickedArrow = element.target;
      const sortArrows = document.querySelectorAll(".arrow-sort");
      sortArrows.forEach((arrow) => {
        arrow.classList.remove("selected-arrow");
      });
      clickedArrow.classList.add("selected-arrow");
    }
    setPaginateTeachers(sortTeachers.slice(0, PER_PAGE));
    localStorage.setItem("sortedTeachers", JSON.stringify(sortTeachers));
    setPage(1);
  };

  /**
   * Show modal
   */
  const showModal = () => {
    setisNewTeacherModal(true);
  };

  /**
   * Hide modal when this function is called
   */
  const hideModal = () => {
    setIsConfirmationModal(false);
    setisNewTeacherModal(false);
    setIsTeacherEducations(false);
    setIsUserExistError(false);
  };

  /**
   * Add teacher
   */
  const addTeacher = async (formData) => {
    setIsLoadingButton(true);

    const today = new Date();
    today.setDate(today.getDate() + 15);

    const myHubDetails = await getMyHub(currentUser.email);
    const hubId = await myHubDetails.docs[0].id;

    // send invitation
    const user = {
      status: false,
      type: {
        id: ROLE.TEACHER,
        name: "teacher",
      },
      userId: formData?.email.toLowerCase(),
      fullName: formData.name,
      validity: firebase.firestore.Timestamp.fromDate(today),
      hubId,
    };

    try {
      const isUserExist = await checkUsersEmail(user.userId);
      const isUserInvited = await checkInvitedUsers(user.userId);

      if (!isUserExist || isUserInvited) {
        setIsLoadingButton(false);
        setIsUserExistError(true);
        return;
      }

      await createInvitation(user);
      setIsLoadingButton(false);
      hideModal();
      handleSnackbar(t("TEACHERS.ADD_TEACHER.TEACHER_INVITED"), true);
    } catch (error) {
      console.log("Cannot create invitations: ", error);
      handleSnackbar(t("TEACHERS.ADD_TEACHER.INVITE_ERROR"), false);
      setIsLoadingButton(false);
    }
  };

  /**
   * Handle toggle change event
   *
   * @param {*} event => selected toogle box
   */
  const switchBlockStatusTeacher = (teacher) => {
    setSelectTeacher({ data: teacher });
    setConfirmationModalData(() => ({
      data: {
        type: `${teacher?.status.blocked}` ? "block" : "unblock",
        title: `${
          teacher?.status.blocked
            ? `${t("TEACHERS.BLOCK_TEACHER.UNBLOCK")} ${teacher.firstName} ${
                teacher.lastName
              } `
            : `${t("TEACHERS.BLOCK_TEACHER.BLOCK")} ${teacher.firstName} ${
                teacher.lastName
              } `
        }`,
        variant: teacher?.status.blocked ? "contained" : "outlined",
        color: teacher?.status.blocked ? "primary" : "default",
        actionButtonText: teacher?.status.blocked
          ? t("ACTION_BUTTONS.UNBLOCK")
          : t("ACTION_BUTTONS.BLOCK"),
        description: teacher?.status.blocked
          ? t("TEACHERS.BLOCK_TEACHER.UNBLOCK_DESCRIPTION")
          : t("TEACHERS.BLOCK_TEACHER.BLOCK_DESCRIPTION"),
      },
    }));
    setIsConfirmationModal(true);
  };

  /**
   * Block/Unblock teacher
   */
  const updateBlockStatus = async () => {
    const isBlocked = selectTeacher.data.status.blocked;
    setIsLoadingButton(true);

    try {
      const userStatus = await getUserBlockedStatus(selectTeacher.data.id);

      await updateUserDetails(selectTeacher.data.id, {
        blocked: isBlocked ? false : true,
      });

      updateBlockedStatus(GET_USERS(), selectTeacher.data.id, userStatus.id, {
        status: isBlocked ? false : true,
      })
        .then(() => {
          setIsLoadingButton(false);
          hideModal();
        })
        .catch((err) => {
          setIsLoadingButton(false);
          console.log(err);
        });
    } catch (error) {
      console.log(error);
      setIsLoadingButton(false);
    }
  };

  const showTeacherEducations = (teacher, type) => {
    setSelectTeacher({ data: teacher });
    setTeacherType(type);
    setIsTeacherEducations(true);
  };

  const generateTextFormEmptyState = () => {
    if (loggedRole === ROLE.SUPER_ADMIN) {
      return {
        title: t("TEACHERS.EMPTY_STATE.TITLE"),
        description: t("TEACHERS.EMPTY_STATE.DESCRIPTION"),
        hasButton: false,
        buttonTitle: "",
      };
    }

    if (loggedRole === ROLE.HUB_ADMIN) {
      return {
        title: t("TEACHERS.EMPTY_STATE_HUB_ADMIN.TITLE"),
        description: t("TEACHERS.EMPTY_STATE_HUB_ADMIN.DESCRIPTION"),
        hasButton: true,
        buttonTitle: t("ACTION_BUTTONS.ADD_TEACHER"),
      };
    }
  };

  const handleEmptyStateVisibility = () => {
    if (!isDataLoading && teachersList.length <= 0) {
      return true;
    }
    return false;
  };

  const handleDataVisibility = () => {
    if (!isDataLoading && teachersList.length > 0) {
      return true;
    }
    return false;
  };

  const setUserExist = (type) => {
    if (isUserExist) {
      setIsUserExistError(type);
    }
  };

  return (
    <div className="teachers-list routes-layout padding-right-layout padding-left-layout padding-top-layout padding-bottom-layout">
      {handleDataVisibility() > 0 && (
        <>
          <div className="teachers-header display-flex justify-content-between align-items-center">
            <PageTitle
              title={
                loggedRole === ROLE.SUPER_ADMIN
                  ? t("TEACHERS.TITLE")
                  : t("TEACHERS.TEACHERS")
              }
            />
            {loggedRole === ROLE.HUB_ADMIN && (
              <Button
                onClick={showModal}
                startIcon={<img src={plus} alt="New teacher" />}
                color="primary"
                variant="contained"
                className="font-weight-600 auto-width"
              >
                {t("ACTION_BUTTONS.ADD_TEACHER")}
              </Button>
            )}
          </div>
          {loggedRole === ROLE.SUPER_ADMIN ? (
            <SuperAdminTeachers
              teachers={paginateTeachers}
              headers={headers}
              openBlockStatus={switchBlockStatusTeacher}
              showTeacherEducations={showTeacherEducations}
              sortedTeachers={sortTeachers}
            />
          ) : (
            <HubAdminTeachers
              teachers={paginateTeachers}
              openBlockStatus={switchBlockStatusTeacher}
            />
          )}
          {teachersList.length > 0 && (
            <Pagination
              count={teachersCount}
              page={page}
              shape="rounded"
              onChange={handleChange}
            />
          )}
        </>
      )}

      {handleEmptyStateVisibility() && (
        <EmptyState
          image={EmptyImage}
          title={generateTextFormEmptyState()?.title}
          caption={generateTextFormEmptyState()?.description}
          buttonTitle={generateTextFormEmptyState()?.buttonTitle}
          hasButton={generateTextFormEmptyState()?.hasButton}
          emptyStateAction={showModal}
          link="/"
        />
      )}

      {isDataLoading && <DataLoader />}

      <AppModal
        isVisible={isNewTeacherModal}
        content={
          <AddTeacher
            isLoadingButton={isLoadingButton}
            addTeacher={addTeacher}
            onCloseModal={hideModal}
            isUserExist={isUserExist}
            setUserExist={setUserExist}
          />
        }
      />
      <AppModal
        isVisible={isTeacherEducations}
        content={
          <TeacherEducations
            teacher={selectTeacher}
            teacherType={teacherType}
            onCloseModal={hideModal}
          />
        }
      />
      <AppModal
        isVisible={isConfirmationModal}
        content={
          <ConfirmationModalContent
            data={confirmationModalData.data}
            selectTeacher={selectTeacher}
            isLoadingButton={isLoadingButton}
            onConfirmAction={updateBlockStatus}
            onCancel={hideModal}
          />
        }
      />
    </div>
  );
};

export default TeachersList;
