// A wrapper for <Route> that redirects to the login

import React from "react";
import { Redirect, Route } from "react-router-dom";
import { ROUTES } from "router/CONSTANTS";
import { useAuth } from "./context/AuthContext";

// screen if you're not yet authenticated.
function PrivateRoute({ children, roles, ...rest }) {
  const { currentUser } = useAuth();
  const userType = +localStorage.getItem("uType");

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!currentUser) {
          return (
            <Redirect
              to={{ pathname: ROUTES.LOGIN, state: { from: props.location } }}
            />
          );
        }

        // check if route restricted by role
        if (roles && roles.indexOf(userType) === -1) {
          // redirect user to educations
          return <Redirect to={{ pathname: ROUTES.EDUCATIONS }} />;
        }

        // authorised so return component
        return <>{children}</>;
      }}
    />
  );
}
export default PrivateRoute;
