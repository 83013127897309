import { EDUCATIONS, GET_BLOCKED, GET_USERS, HUBS } from "./CONSTANTS";
import { db } from "../firebase/firebase";

// get hubs
const getHubs = () => {
  return new Promise((resolve, reject) => {
    try {
      db.collection(HUBS())
        .get()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      console.log("SYSTEM ERROR");
      reject(error);
    }
  });
};

// get hub details
const getHubDetails = (uid) => {
  return new Promise((resolve, reject) => {
    try {
      db.collection(HUBS())
        .doc(uid)
        .get()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      console.log("ERROR: ", error);
      reject("SYSTEM ERROR");
    }
  });
};

//get My HUB
const getMyHub = (email) => {
  return new Promise((resolve, reject) => {
    try {
      db.collection(HUBS())
        .where("administrator.email", "==", email)
        .get()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      console.log("ERROR: ", error);
      reject(error);
    }
  });
};

// get educations per HUB uid
const getEducationPerHub = (id) => {
  return new Promise((resolve, reject) => {
    try {
      db.collection(EDUCATIONS())
        .where("hub", "!=", null)
        .where("hub.id", "==", id)
        .get()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      console.log("SYSTEM ERROR");
      reject(error);
    }
  });
};

// create new hub
const createHub = (hub) => {
  return new Promise(async (resolve, reject) => {
    try {
      const collectionRef = db.collection(HUBS()).add(hub);
      const created = await collectionRef;
      resolve(created);
    } catch (error) {
      console.log("ERROR: ", error);
      reject("SYSTEM ERROR");
    }
  });
};

// edit hub
const editHub = (hubId, hub) => {
  return new Promise(async (resolve, reject) => {
    try {
      const collectionRef = db.collection(HUBS()).doc(hubId);
      const doUpdate = await collectionRef.update(hub);
      resolve(doUpdate);
    } catch (error) {
      console.log("ERROR: ", error);
      reject("SYSTEM ERROR");
    }
  });
};

// check email for hub admin
const checkUsersEmail = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const request = await db
        .collection(GET_USERS())
        .where("id", "!=", null)
        .where("email", "==", email)
        .get();
      const requestResult = request.empty;
      resolve(requestResult);
    } catch (error) {
      reject(error);
      console.log("Cannot find admins for hubs: ", error);
    }
  });
};

// get list of hubs for filters
const hubsForFilter = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const hubsRef = await db.collection(HUBS()).get();

      const hubs = hubsRef.docs.map((doc) => {
        return {
          id: doc.id,
          name: doc.data().name,
        };
      });

      resolve(hubs);
    } catch (error) {
      reject(error);
      console.log("Cannot find admins for hubs: ", error);
    }
  });
};

/**
 * Get blocked status for hub
 *
 * @param {*} id => hub id
 * @returns new Promise
 */
const getHubBlockedStatus = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const hubRef = await db.collection(HUBS()).doc(id).get();
      const hubRefBlocked = await hubRef.ref.collection(GET_BLOCKED()).get();
      const data = hubRefBlocked.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      resolve(data[0]);
    } catch (error) {
      reject(error);
      console.log("Cannot find admins for hubs: ", error);
    }
  });
};

export {
  getHubs,
  getHubDetails,
  getMyHub,
  getEducationPerHub,
  createHub,
  checkUsersEmail,
  editHub,
  hubsForFilter,
  getHubBlockedStatus,
};
