import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import closeIcon from "assets/images/icons/close-modal.svg";
import avatar from "assets/images/icons/avatar.svg";
import SendMessageInput from "./SendMessageInput";
import "./css/live-chat.css";
import { createChat, getChat } from "services/educationService";
import { generateFullName } from "utils/generateFullName";
import { getFormattedTime } from "utils/dateFormat";
import firebase from "firebase/app";
import { getUserDetails } from "services/userService";
import { db } from "../firebase/firebase";
import { CHAT, EDUCATIONS } from "services/CONSTANTS";
import { useTranslation } from "react-i18next";

const EducationLiveChat = (props) => {
  const { chatWindow, showChat, education } = props;
  const { t } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!education) return;
    localStorage.removeItem("isMessageSent");

    const body = document.querySelector("body");
    const chatMessagesBlock = document.querySelector(
      ".live-chat-wrapper .chat-messages"
    );

    const unsubscribe = db
      .collection(EDUCATIONS())
      .doc(education?.id)
      .collection(CHAT())
      .orderBy("messageTime")
      .onSnapshot((chatMessages) => {
        if (!chatMessages.empty) {
          const messageData = chatMessages?.docs.map((doc) => {
            return {
              id: doc?.id,
              ...doc.data(),
            };
          });

          if (messageData) {
            setMessages(messageData);
            body.classList.add("overflow-hidden");
            chatMessagesBlock.scroll({
              top: chatMessagesBlock.scrollHeight,
              behavior: "smooth",
            });
          }
        }
      });

    return () => {
      unsubscribe();
    };
  }, [education]);

  /**
   * Show information if chat is empty
   *
   * @returns HTML element
   */
  const noMessagesInfo = () => {
    return (
      <p className="no-messages-in-chat">
        {t("EDUCATIONS.LIVE_LESSON.CHAT.NO_MESSAGES")}
      </p>
    );
  };

  /**
   * Send new message for lesson
   *
   * @param {*} data => recieved message from component
   */
  const sendMessage = async (data) => {
    setIsLoading(true);
    localStorage.setItem("isMessageSent", "false");
    const userId = localStorage.getItem("uid");
    const { message } = data;
    const messageTime = firebase.firestore.Timestamp.fromDate(new Date());

    try {
      const loggedUser = await getUserDetails(userId);
      const { firstName, lastName, id } = loggedUser.data();

      const newMessage = {
        educationId: education?.id,
        message,
        messageTime,
        user: { firstName, lastName, id },
      };

      await createChat(education?.id, newMessage);

      setIsLoading(false);
      localStorage.setItem("isMessageSent", "true");
    } catch (error) {
      console.log("Cannot create chat! ", error);
      setIsLoading(false);
      localStorage.setItem("isMessageSent", "false");
    }
  };

  return (
    <div className="live-chat">
      <div className="live-chat-container">
        <div className="live-chat-header">
          <Typography variant="h2">
            {" "}
            {t("EDUCATIONS.LIVE_LESSON.CHAT.WELCOME")}
          </Typography>
          <img src={closeIcon} alt="Close live chat" onClick={chatWindow} />
        </div>
        <div className="live-chat-wrapper">
          <div className="chat-messages">
            {messages.length > 0 ? (
              <>
                {messages.map((message) => (
                  <div key={message?.id} className="message display-flex">
                    <img src={avatar} alt="Avatar" />
                    <div className="message-content">
                      <p>
                        {generateFullName(
                          message?.user?.firstName,
                          message?.user?.lastName
                        )}
                      </p>
                      <p className="message-text">{message?.message}</p>
                      <span className="message-time">
                        {getFormattedTime(message?.messageTime)}
                      </span>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              noMessagesInfo()
            )}
          </div>
        </div>
        <div className="new-message">
          <div className="new-message-input">
            <SendMessageInput
              onSendMessage={sendMessage}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationLiveChat;
