import React, { useState, useEffect, useRef } from "react";
import { Typography, TextField, InputAdornment } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import searchIcon from "assets/images/icons/search-icon.svg";
import sortArrowUp from "assets/images/icons/sort-arrow-up.svg";
import sortArrowDown from "assets/images/icons/sort-arrow-down.svg";
import documentFolder from "assets/images/icons/document-folder.svg";
import download from "assets/images/icons/download.svg";
import deleteicon from "assets/images/icons/delete.svg";
import ReactTooltip from "react-tooltip";
import "./css/documents.css";
import EmptyState from "components/EmptyState";
import EmptyImage from "assets/images/empty-state/documents.svg";
import AppModal from "components/AppModal";
import ConfirmationModalContent from "components/ConfirmationModalContent";
import uploadIcon from "assets/images/icons/file-upload.svg";
import { useAuth } from "auth/context/AuthContext";
import { ROLE } from "utils/ROLES";
import {
  createDocument,
  deleteDocumentFromCollection,
} from "services/educationService";
import {
  deleteFileFromStorage,
  handleFileUpload,
} from "services/uploadService";
import firebase from "firebase/app";
import { getFormattedDate } from "utils/dateFormat";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import { createNewNotification } from "services/notificationService";
import { APPLICATION_URL } from "utils/appUrl";
import { NOTIFICATION_TYPE } from "utils/notificationType";
import { ROUTES } from "router/CONSTANTS";
import { useEducation } from "pages/Educations/context/EducationContext";
import { getParticipants } from "services/educationService";
import { PARTICIPANT_STATUS } from "utils/participantStatus";
import { generateFullName } from "utils/generateFullName";
import { db } from "../../../../firebase/firebase";
import { DOCUMENTS, EDUCATIONS } from "services/CONSTANTS";
import DataLoader from "components/DataLoader";

const Documents = (props) => {
  const PER_PAGE = 6;
  const DOCUMENTS_COUNT = 1;
  const { educationId } = props;
  const { currentUser, loggedRole } = useAuth();
  const { singleEducation } = useEducation();

  const { t } = useTranslation();

  const [documents, setDocuments] = useState([]);
  const [documentsCount, setDocumentsCount] = useState(DOCUMENTS_COUNT);
  const [page, setPage] = useState(1);
  const [paginateDocuments, setPaginateDocuments] = useState(documents);
  const [defaultDocuments, setDefaultDocuments] = useState();
  const [localStoredDocuments, setLocalStoredDocuments] = useState();

  const [openDelete, setOpenDelete] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [selectedForDelete, setSelectedForDelete] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(true);

  let searchTimeout = null;
  const searchField = useRef(null);

  /**
   * Update documents state
   *
   * @param {*} documents => update list of documents
   */
  const updateDocumentsState = (documents) => {
    setPaginateDocuments(documents.slice(0, PER_PAGE));
    const count = documents.length;
    setDocumentsCount(Math.ceil(count / PER_PAGE));
  };

  /**
   * Remove items from local storage
   */
  const reloadLocalStorage = () => {
    localStorage.removeItem("sortedDocuments");
    localStorage.removeItem("isSorted");
    localStorage.removeItem("isSearched");
  };

  const reloadSearch = () => {
    reloadLocalStorage();
    const sortArrows = document.querySelectorAll(".arrow-sort");
    sortArrows.forEach((arrow) => {
      arrow.classList.remove("selected-arrow");
    });
    updateDocumentsState(documents);

    if (searchField.current !== null) {
      searchField.current.value = "";
    }
  };

  useEffect(() => {
    if (!currentUser) return;
    if (loggedRole === undefined || loggedRole === null) return;
    if (!educationId) return;

    const getImageName = (image) => {
      if (image !== "") {
        return image.split("/").pop().split("?")[0].split("F")[1];
      }
      return "";
    };

    reloadLocalStorage();

    const getEducationDocuments = db
      .collection(EDUCATIONS())
      .doc(educationId)
      .collection(DOCUMENTS())
      .orderBy("uploadDate", "desc")
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          const documentsData = snapshot?.docs.map((doc) => {
            return {
              id: doc.id,
              documentName: getImageName(doc.data().link),
              ...doc.data(),
            };
          });

          if (documentsData) {
            setDocuments(documentsData);
            updateDocumentsState(documentsData);
            setDefaultDocuments(documentsData);
            setIsDataLoading(false);
            const sortArrows = document.querySelectorAll(".arrow-sort");
            sortArrows.forEach((arrow) => {
              arrow.classList.remove("selected-arrow");
            });
          }
        } else {
          setDocuments(snapshot.docs);
          setIsDataLoading(false);
        }

        const search = window.location.href;
        const splitUrl = search.split("?")[1];
        const educationTab = new URLSearchParams(splitUrl).get("tab");

        if (educationTab === "documents" && educationTab !== null) {
          window.history.replaceState(
            null,
            "",
            window.encodeURI(`/educations/${educationId}`)
          );
        }
      });

    return () => {
      reloadLocalStorage();
      getEducationDocuments();
    };
  }, [currentUser, loggedRole, educationId]);

  // define table header
  const tableHeaders = [
    {
      id: 0,
      name: "title",
      text: t("EDUCATIONS.DOCUMENTS.TABLE_HEADER.TITLE"),
      sort: "documentName",
    },
    {
      id: 1,
      name: "created_at",
      text: t("EDUCATIONS.DOCUMENTS.TABLE_HEADER.CREATION_DATE"),
      sort: "uploadDate",
    },
  ];

  // props for confirmation delete modal
  const confirmationModalData = {
    type: "delete",
    actionButtonText: t("ACTION_BUTTONS.DELETE"),
    title: t("EDUCATIONS.DOCUMENTS.DELETE_DOCUMENT.TITLE"),
    description: t("EDUCATIONS.DOCUMENTS.DELETE_DOCUMENT.DESCRIPTION"),
  };

  /**
   * Check logged role and show /hide field for upload
   *
   * @returns true / false
   */
  const showFieldForUpload = () => {
    if (loggedRole === ROLE.TEACHER) {
      return true;
    }
    return false;
  };

  /**
   * Check logged role and show / hide particular button
   *
   * @returns true /false
   */
  const showDownloadField = () => {
    if (loggedRole === ROLE.TEACHER || loggedRole === ROLE.STUDENT) {
      return true;
    }
    return false;
  };

  /**
   * Open delete modal
   *
   * @param {*} fileName => reference to file in storage
   * @param {*} documentId => id of document
   */
  const openDeleteModal = (fileName, documentId) => {
    setSelectedForDelete({ fileName, documentId });
    setOpenDelete(true);
  };

  /**
   * Close delete modal
   */
  const closeDeleteModal = () => {
    setSelectedForDelete(null);
    setOpenDelete(false);
  };

  /**
   * Delete document
   */
  const deleteDocument = async () => {
    setIsLoadingButton(true);
    try {
      await deleteDocumentFromCollection(
        educationId,
        selectedForDelete?.documentId
      );
      await deleteFileFromStorage(
        selectedForDelete?.fileName,
        "education-documents"
      );
      setIsLoadingButton(false);
      closeDeleteModal();
    } catch (error) {
      console.log("Cannot delete document: ", error);
      setIsLoadingButton(false);
    }
  };

  /**
   * Notify users after adding new document
   */
  const notifyUsers = async () => {
    const educatorIds = singleEducation.educators.filter(
      (id) => id !== currentUser.uid
    );
    const title = singleEducation.title;
    const loggedUser = JSON.parse(localStorage.getItem("user"));
    const creator = generateFullName(loggedUser.firstName, loggedUser.lastName);

    try {
      const firebaseResponse = await getParticipants(educationId);
      const participants = await firebaseResponse.docs
        .map((doc) => doc.data())
        .filter(
          (participant) =>
            participant?.approvalStatus?.id === PARTICIPANT_STATUS.APPROVED.id
        );

      const participantIds = participants.map(
        (participant) => participant.userId.split("/")[1]
      );

      const educatorsAndParticipants = [...educatorIds, ...participantIds];

      const notifyRecepients = educatorsAndParticipants.map(
        async (recepient) => {
          const newNotificationData = {
            sender: currentUser?.email,
            recepient,
            title,
            content: "",
            linkTo: `${APPLICATION_URL}${ROUTES.EDUCATIONS}/${educationId}`,
            educationId,
            timelineId: "",
            type: NOTIFICATION_TYPE.NEW_DOCUMENT_ADDED,
            notificationDateTime: firebase.firestore.Timestamp.fromDate(
              new Date()
            ),
            seen: false,
            document: {
              creator,
            },
          };

          const recepientsNotification = await createNewNotification(
            newNotificationData
          );

          return recepientsNotification;
        }
      );
      await Promise.all(notifyRecepients);
    } catch (error) {
      console.log("Cannot get participants: ", error);
    }
  };

  /**
   * Choose file from input
   *
   * @param {*} e => target input type file
   */
  const fileHandler = async (e) => {
    const file = e.target.files[0];
    setIsFileUploading(true);

    try {
      const addedDocument = await handleFileUpload(file, "education-documents");
      const newDocument = {
        userId: localStorage.getItem("uid"),
        link: addedDocument,
        uploadDate: firebase.firestore.Timestamp.fromDate(new Date()),
      };
      await createDocument(educationId, newDocument);
      await notifyUsers();
      setIsFileUploading(false);
    } catch (error) {
      console.log("Cannot add document: ", error);
      setIsFileUploading(false);
    }
  };

  /**
   * Upload file from document's empty state
   */
  const handleEmptyState = () => {
    const isSearched = localStorage.getItem("isSearched");
    const uploadDocument = document.querySelector("#upload-document");

    if (isSearched === "true") {
      reloadSearch();
    } else {
      if (loggedRole === ROLE.TEACHER) {
        uploadDocument.click();
      }
    }
  };

  /**
   * Render upload file input
   *
   * @returns upload file input
   */
  const renderUploadInput = () => {
    return (
      <label className="display-flex custom-file-upload">
        <input
          id="upload-document"
          type="file"
          onChange={fileHandler}
          accept=".png, .jpg, .jpeg, .pdf, .pptx, .doc, .docx"
        />
        <img src={uploadIcon} alt="Upload document" />
        {t("ACTION_BUTTONS.UPLOAD")}
      </label>
    );
  };

  /**
   * Download document
   *
   * @param {*} path => path to document on firebase storage
   */
  const downloadDocument = (path) => {
    window.open(path);
  };

  /**
   * Paginate documents
   *
   * @param {*} value => page number
   */
  const handleChange = (event, value) => {
    const selectedPage = value * PER_PAGE;
    const previousPage = selectedPage - PER_PAGE;

    const isSorted = localStorage.getItem("isSorted");

    if (isSorted !== null) {
      setPaginateDocuments(
        localStoredDocuments.slice(previousPage, selectedPage)
      );
    } else {
      setPaginateDocuments(defaultDocuments.slice(previousPage, selectedPage));
    }

    // setPaginateDocuments(documents.slice(previousPage, selectedPage));
    // if (getSortedDocs !== null) {
    //   setPaginateDocuments(getSortedDocs.slice(previousPage, selectedPage));
    // } else {
    //   setPaginateDocuments(documents.slice(previousPage, selectedPage));
    // }
    setPage(value);
  };

  /**
   * Sort documents by name and date
   *
   * @param {*} field => per which field to sort
   * @param {*} orderType => type of order (asc/desc)
   * @param {*} element => clicked arrow
   */
  const sortDocs = (field, orderType, element) => {
    localStorage.setItem("isSorted", "true");
    const sortedDocuments = defaultDocuments.sort((a, b) => {
      if (orderType === "asc") {
        return a[field] > b[field] ? 1 : -1;
      }

      return a[field] < b[field] ? 1 : -1;
    });

    if (element !== null && element !== undefined) {
      const clickedArrow = element.target;
      const sortArrows = document.querySelectorAll(".arrow-sort");
      sortArrows.forEach((arrow) => {
        arrow.classList.remove("selected-arrow");
      });
      clickedArrow.classList.add("selected-arrow");
    }

    updateDocumentsState(sortedDocuments);
    setLocalStoredDocuments(sortedDocuments);
    setPage(1);
  };

  /**
   * Search documents in list by document name
   *
   * @param {*} value => value from input field
   */
  const searchDocuments = (value) => {
    clearTimeout(searchTimeout);
    localStorage.setItem("isSearched", "true");

    const sortExists = localStorage.getItem("isSorted");

    const documentsForSearch =
      sortExists !== null ? localStoredDocuments : defaultDocuments;

    const searchedDocuments = documentsForSearch.filter((document) => {
      return document.documentName.toLowerCase().includes(value.toLowerCase());
    });

    searchTimeout = setTimeout(() => {
      updateDocumentsState(searchedDocuments);
      setPage(1);
    }, 500);
  };

  /**
   * Show table
   *
   * @returns boolean
   */
  const handleTableRender = () => {
    if (paginateDocuments.length > 0 && !isDataLoading) {
      return true;
    }

    return false;
  };

  /**
   * Show header
   *
   * @returns boolean
   */
  const handleDocumentHeader = () => {
    if (!isDataLoading && documents.length > 0) {
      return true;
    }
    return false;
  };

  const emptyStateData = () => {
    const isSearched = localStorage.getItem("isSearched");

    const showButton = isSearched !== "true" && loggedRole === ROLE.TEACHER;

    let data = {
      title: t("EDUCATIONS.DOCUMENTS.EMPTY_STATE.TITLE"),
      caption: t("EDUCATIONS.DOCUMENTS.EMPTY_STATE.DESCRIPTION"),
      buttonTitle: t("ACTION_BUTTONS.UPLOAD_DOCUMENT"),
      className: "",
      hasButton: showButton,
    };

    if (paginateDocuments.length <= 0 && isSearched === "true") {
      data.title = t("EDUCATIONS.DOCUMENTS.EMPTY_STATE.TITLE_FILTERS");
      data.caption = t("EDUCATIONS.DOCUMENTS.EMPTY_STATE.DESCRIPTION_FILTERS");
      data.buttonTitle = t("ACTION_BUTTONS.RESET_FILTERS");
      data.className = "filters-empty";
      data.hasButton = true;
    }

    return data;
  };

  /**
   * Show empty state
   *
   * @returns boolean
   */
  const handleEmptyStateVisibility = () => {
    if (!isDataLoading && paginateDocuments.length <= 0) {
      return true;
    }

    return false;
  };

  return (
    <div id="education-documents">
      {documents.length <= 0 && (
        <div className="upload-document empty-state-upload">
          {showFieldForUpload() && renderUploadInput()}
        </div>
      )}

      <>
        {handleDocumentHeader() && (
          <>
            <Typography variant="h1">
              {t("EDUCATIONS.DOCUMENTS.TITLE")}
            </Typography>
            <div className="upload-file display-flex">
              <TextField
                inputRef={searchField}
                variant="outlined"
                type="text"
                name="search"
                placeholder={t("EDUCATIONS.DOCUMENTS.SEARCH_PLACEHOLDER")}
                autoComplete="off"
                onInput={(e) => searchDocuments(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={searchIcon} alt="Search icon" />
                    </InputAdornment>
                  ),
                }}
              />
              <div className="upload-document">
                {isFileUploading && (
                  <div className="loader">
                    <CircularProgress size={20} />
                  </div>
                )}
                {showFieldForUpload() && renderUploadInput()}
              </div>
            </div>
          </>
        )}

        {handleTableRender() && (
          <>
            <p className="swipe-for-more">{t("EDUCATIONS.DOCUMENTS.SWIPE")}</p>
            <div className="documents-table width-100">
              <div className="scrollable-area width-100">
                <div className="table-header width-100 display-flex">
                  {tableHeaders.map((header) => (
                    <div
                      key={header.id}
                      className="header-cell display-flex align-items-center"
                    >
                      <p>{header.text}</p>
                      {header.sort && (
                        <div className="sort-icons display-flex flex-direction-column">
                          <img
                            src={sortArrowUp}
                            alt="Sort"
                            className="arrow-sort"
                            onClick={(element) =>
                              sortDocs(header.sort, "desc", element)
                            }
                          />
                          <img
                            src={sortArrowDown}
                            alt="Sort"
                            className="arrow-sort"
                            onClick={(element) =>
                              sortDocs(header.sort, "asc", element)
                            }
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="table-data width-100">
                  {paginateDocuments.map((data) => (
                    <div
                      key={data.id}
                      className="data-row width-100 display-flex align-items-center"
                    >
                      <div className="document-info display-flex align-items-center row-cell">
                        <img src={documentFolder} alt="Info" />
                        <p title={data?.documentName}>{data?.documentName}</p>
                      </div>
                      <div className="creation-date row-cell">
                        <p>{getFormattedDate(data?.uploadDate, "-")}</p>
                      </div>
                      <div className="display-flex align-items-center document-actions">
                        {showDownloadField() && (
                          <div
                            data-tip
                            data-for="download-doc"
                            className="action-icon-holder"
                          >
                            <img
                              src={download}
                              alt="download"
                              onClick={() => downloadDocument(data?.link)}
                            />

                            <ReactTooltip
                              className="custom-react-tooltip"
                              id="download-doc"
                              place="right"
                              effect="solid"
                            >
                              {t("EDUCATIONS.DOCUMENTS.DOWNLOAD")}
                            </ReactTooltip>
                          </div>
                        )}
                        {showFieldForUpload() && (
                          <div
                            onClick={() =>
                              openDeleteModal(data?.documentName, data?.id)
                            }
                            data-tip
                            data-for="delete-doc"
                            className="action-icon-holder"
                          >
                            <img
                              src={deleteicon}
                              alt="delete"
                              className="delete-icon"
                            />
                            <ReactTooltip
                              className="custom-react-tooltip"
                              id="delete-doc"
                              place="right"
                              effect="solid"
                            >
                              {t("EDUCATIONS.DOCUMENTS.DELETE")}
                            </ReactTooltip>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {paginateDocuments.length > 0 && (
                <Pagination
                  count={documentsCount}
                  page={page}
                  shape="rounded"
                  onChange={handleChange}
                />
              )}
            </div>
          </>
        )}
      </>

      <>
        {handleEmptyStateVisibility() && (
          <EmptyState
            image={EmptyImage}
            title={emptyStateData().title}
            caption={emptyStateData().caption}
            hasButton={emptyStateData().hasButton}
            emptyStateAction={handleEmptyState}
            buttonTitle={emptyStateData().buttonTitle}
            link="/"
          />
        )}
      </>

      {isDataLoading && <DataLoader />}

      <AppModal
        isVisible={openDelete}
        content={
          <ConfirmationModalContent
            onConfirmAction={deleteDocument}
            data={confirmationModalData}
            isLoadingButton={isLoadingButton}
            onCancel={closeDeleteModal}
          />
        }
      />
    </div>
  );
};

export default Documents;
