const authCode = {
  EMAIL_EXIST: "auth/email-already-in-use",
  INVALID_EMAIL: "auth/invalid-email",
  WRONG_PASSWORD: "auth/wrong-password",
  WEAK_PASSWORD: "auth/weak-password",
  NOT_FOUND: "auth/user-not-found",
  MISSING_URL: "auth/missing-continue-uri",
};

/**
 * Handle error messages
 *
 * @param {*} error => error
 * @param {*} t => translation
 * @returns error message
 */
const handleErrorMessages = (error, t) => {
  let errorMessage;
  switch (error.code) {
    case authCode.EMAIL_EXIST:
      errorMessage = t("AUTH.ERROR.EMAIL_EXIST");
      break;
    case authCode.INVALID_EMAIL:
      errorMessage = t("AUTH.ERROR.INVALID_EMAIL");
      break;
    case authCode.WRONG_PASSWORD:
      errorMessage = t("AUTH.ERROR.WRONG_PASSWORD");
      break;
    case authCode.WEAK_PASSWORD:
      errorMessage = t("AUTH.ERROR.WEAK_PASSWORD");
      break;
    case authCode.NOT_FOUND:
      errorMessage = t("AUTH.ERROR.NOT_FOUND");
      break;
    case authCode.MISSING_URL:
      errorMessage = t("AUTH.ERROR.MISSING_URL");
      break;
    default:
      errorMessage = t("AUTH.ERROR.DEFAULT_ERROR");
  }
  return errorMessage;
};

export { handleErrorMessages };
