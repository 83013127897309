import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import "../css/List.css";
import notificationBell from "assets/images/icons/notification-bell-list.svg";
import { useAuth } from "auth/context/AuthContext";
import { getAllNotifications } from "services/notificationService";
import { Link } from "react-router-dom";
import { ROUTES } from "router/CONSTANTS";
import { notificationText } from "utils/notificationType";
import EmptyState from "components/EmptyState";
import EmptyImage from "assets/images/empty-state/no-notifications.svg";
import { useTranslation } from "react-i18next";

const NotificationsList = () => {
  const { t } = useTranslation();
  const { currentUser, loggedRole } = useAuth();

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    let mounted = true;
    let allNotifications = null;
    if (!currentUser) return;
    if (mounted) {
      const userId = currentUser?.uid;
      if (loggedRole !== null && loggedRole !== undefined) {
        (async () => {
          allNotifications = await getAllNotifications(userId);
          if (!allNotifications.empty) {
            const notification = allNotifications.docs.map((doc) => {
              const linkTo =
                doc.data().educationId !== ""
                  ? `${ROUTES.EDUCATIONS}/${doc.data().educationId}`
                  : ROUTES.EDUCATIONS;
              return {
                id: doc.id,
                ...doc.data(),
                linkTo,
              };
            });
            setNotifications(notification);
          }
        })();
      }
    }
    return () => {
      mounted = false;
      allNotifications = null;
    };
  }, [currentUser, loggedRole]);

  return (
    <div
      id="notifications-list"
      className="padding-right-layout padding-left-layout routes-layout"
    >
      {notifications.length > 0 && (
        <Typography variant="h1">{t("NOTIFICATIONS.TITLE")}</Typography>
      )}
      {notifications.length > 0 ? (
        <div className="notifications width-100">
          {notifications?.map((notification) => (
            <div
              key={notification.id}
              className="one-notification width-100 display-flex align-items-center"
            >
              <img src={notificationBell} alt="Notification" />
              <p>
                {notificationText(notification)}
                <Link to={notification?.linkTo}>
                  {t("NOTIFICATIONS.VIEW_ALL")}
                </Link>
              </p>
            </div>
          ))}
        </div>
      ) : (
        <EmptyState
          image={EmptyImage}
          title={t("NOTIFICATIONS.NO_NOTIFICATIONS")}
          caption={t("NOTIFICATIONS.NO_NOTIFICATIONS_DESCRIPTION")}
          buttonTitle={t("ACTION_BUTTONS.BACK_TO_HOME")}
          hasButton={true}
          link={ROUTES.EDUCATIONS}
        />
      )}
    </div>
  );
};

export default NotificationsList;
