/**
 * All API urls and other constants will reside here.
 * It is always a good idea to keep a local copy of all API response to
 * keep your app working for UI changes and
 * make it independent of network requirements.
 */

// Local endpoints. Uncomment below section to use dummy local data.
export const EDUCATIONS = () => `/educations`;
export const GET_EDUCATION_DETAILS = (id) => `/educations${id}`;
export const GET_USERS = () => `/users`;
export const GET_MY_EDUCATIONS = () => "getMyEducationsPerUser";
export const GET_OTHER_EDUCATIONS = () => "getOtherEducationsPerUser";
export const GET_INVITES = () => "/invites";
export const GET_BLOCKED = () => "/blocked";
export const HUBS = () => "/hubs";
export const PARTICIPANTS = () => "/participants";
export const GET_PRIMARY_TEACHER_STUDENTS = () =>
  "listOfStudentsForPrimaryTeacher";
export const GET_SECONDARY_TEACHER_STUDENTS = () =>
  "listOfStudentsForSecondaryTeacher";
export const CHAT = () => "/chat";
export const DOCUMENTS = () => "/documents";
export const NOTIFICATIONS = () => "/notifications";
export const TIMELINE = () => "/timeline";
export const INVITATIONS = () => "/invitations";
export const QUESTIONS_ANSWERS = () => "/q&a";
export const TWILIO_TOKEN = () => "twillioFunction";
