import React, { useState } from "react";
import "components/css/tab-bar.css";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Students from "../pages/Educations/components/tabs/Students";
import Documents from "../pages/Educations/components/tabs/Documents";
import CourseTimeline from "../pages/Educations/components/tabs/Timeline";
import QA from "../pages/Educations/components/tabs/QA";
import { ROLE } from "utils/ROLES";
import Teachers from "../pages/Educations/components/tabs/Teachers";
import { useParams } from "react-router-dom";
import i18n from "../i18n/i18n";

/**
 * Create selected tab content
 * @param {*} props => props for selected tab
 * @returns selected tab content
 */
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      className="education-tab-content"
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box className="education-box">{children}</Box>}
    </div>
  );
};

// propTypes
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const SingleEducationTabBar = (props) => {
  const { onTabChange, selectedTab, role, isReadOnly } = props;
  const { id } = useParams();

  /**
   * Render Tabs
   *
   * @returns tabs
   */
  const renderTabs = () => {
    if (role === ROLE.SUPER_ADMIN || role === ROLE.STUDENT) {
      return (
        <Tabs
          value={selectedTab}
          onChange={onTabChange}
          aria-label="tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={i18n.t("EDUCATIONS.SINGLE_EDUCATION.TAB_BAR.TIMELINE")}
            {...a11yProps(0)}
          />
          <Tab
            label={i18n.t("EDUCATIONS.SINGLE_EDUCATION.TAB_BAR.QA")}
            {...a11yProps(1)}
          />
          <Tab
            label={i18n.t("EDUCATIONS.SINGLE_EDUCATION.TAB_BAR.DOCUMENTS")}
            {...a11yProps(2)}
          />
          <Tab
            label={i18n.t("EDUCATIONS.SINGLE_EDUCATION.TAB_BAR.TEACHERS")}
            {...a11yProps(3)}
          />
          <Tab
            label={i18n.t("EDUCATIONS.SINGLE_EDUCATION.TAB_BAR.STUDENTS")}
            {...a11yProps(4)}
          />
        </Tabs>
      );
    }

    return (
      <Tabs
        value={selectedTab}
        onChange={onTabChange}
        aria-label="tabs"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          label={i18n.t("EDUCATIONS.SINGLE_EDUCATION.TAB_BAR.STUDENTS")}
          {...a11yProps(0)}
        />
        <Tab
          label={i18n.t("EDUCATIONS.SINGLE_EDUCATION.TAB_BAR.DOCUMENTS")}
          {...a11yProps(1)}
        />
        <Tab
          label={i18n.t("EDUCATIONS.SINGLE_EDUCATION.TAB_BAR.TIMELINE")}
          {...a11yProps(2)}
        />
        <Tab
          label={i18n.t("EDUCATIONS.SINGLE_EDUCATION.TAB_BAR.QA")}
          {...a11yProps(3)}
        />
      </Tabs>
    );
  };
  /**
   * Tab panel
   *
   * @returns tab panel
   */
  const renderTabPanel = () => {
    if (role === ROLE.SUPER_ADMIN || role === ROLE.STUDENT) {
      return (
        <>
          <TabPanel value={selectedTab} index={0}>
            <CourseTimeline
              role={role}
              educationId={id}
              isReadOnly={isReadOnly}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <QA educationId={id} />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <Documents educationId={id} />
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <Teachers educationId={id} />
          </TabPanel>
          <TabPanel value={selectedTab} index={4}>
            <Students educationId={id} />
          </TabPanel>
        </>
      );
    }
    return (
      <>
        <TabPanel value={selectedTab} index={0}>
          <Students educationId={id} />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Documents educationId={id} />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <CourseTimeline educationId={id} />
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <QA educationId={id} />
        </TabPanel>
      </>
    );
  };

  return (
    <div className="tab-bar">
      <AppBar position="static">{renderTabs()}</AppBar>
      <div className="routes-layout padding-left-layout padding-right-layout">
        {renderTabPanel()}
      </div>
    </div>
  );
};

export default SingleEducationTabBar;
