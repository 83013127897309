import React, { useState } from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import FilterSelect from "components/FilterSelect";
import LoadingButton from "components/LoadingButton";
import closeModal from "assets/images/icons/close-modal.svg";
import ImageUploadInfo from "components/ImageUploadInfo";
import UploadInput from "components/UploadInput";
import "../css/AddHub.css";
import { countries } from "utils/country";
import { handleFileUpload } from "services/uploadService";
import { checkUsersEmail, createHub, editHub } from "services/hubService";
import { setSubcollectionBlocked } from "services/blockedStatusService";
import { useTranslation } from "react-i18next";
import firebase from "firebase/app";
import { ROLE } from "utils/ROLES";
import { createInvitation } from "services/userService";
import { useSnackbar } from "contexts/SnackbarProvider";
import trashIcon from "assets/images/icons/trash.svg";
import { checkInvitedUsers } from "services/authService";

const AddHub = (props) => {
  const { onCloseModal } = props;
  const { t } = useTranslation();
  const countriesSelection = countries(t);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedHubLogo, setSelectedHubLogo] = useState({ name: "" });
  const [selectedHubPicture, setselectedHubPicture] = useState({ name: "" });
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [isAdminExistError, setIsAdminExistError] = useState(false);

  const dispatch = useSnackbar();

  // useForm properties
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Select country from list
   *
   * @param {*} country => selected country
   */
  const handleCountryChange = (countryId) => {
    const selected = countriesSelection.find(
      (country) => country.id === countryId
    );

    setSelectedCountry(selected);
  };

  /**
   * Set chosed file from input
   *
   * @param {*} file => selected file form input
   */
  const chooseFile = (file, reference) => {
    if (reference === "add-hub-logo") {
      setSelectedHubLogo(file);
      return;
    }

    setselectedHubPicture(file);
  };

  /**
   * Show snackbar on some action
   */
  const handleSnackbar = (message, type) => {
    dispatch({
      message: message,
      success: type,
    });
  };

  /***
   * Remove selected image on click trash icon
   */
  const removeHubImage = (type) => {
    if (type === "logo") {
      setSelectedHubLogo({ name: "" });
    } else {
      setselectedHubPicture({ name: "" });
    }
  };

  /**
   * Submit form and proceed
   */
  const onSubmitForm = async (data) => {
    setIsLoadingButton(true);

    // new hub
    const hub = {
      address: {
        city: data.city === undefined ? "" : data.city,
        country: selectedCountry === null ? "" : selectedCountry,
        street: data.street === undefined ? "" : data.street,
        zip: data.zip === undefined ? "" : data.zip,
      },
      phone: data.phone === undefined ? "" : data.phone,
      name: data.name,
      email: data.email === undefined ? "" : data.email,
      logo: "",
      picture: "",
      administrator: {
        name: data.hubAdmin,
        email: data.hubAdminEmail.toLowerCase(),
      },
      created_at: firebase.firestore.Timestamp.fromDate(new Date()),
      blocked: false,
    };

    try {
      const isEmailValid = await checkUsersEmail(hub.administrator.email);
      const isHubInvited = await checkInvitedUsers(hub.administrator.email);

      if (!isEmailValid || isHubInvited) {
        setIsAdminExistError(true);
        setIsLoadingButton(false);
        return;
      }

      const created = await createHub(hub);
      await setSubcollectionBlocked("hubs", created?.id, { status: false });
      if ("name" in selectedHubLogo) {
        if (selectedHubLogo.name !== "") {
          const addedImage = await handleFileUpload(selectedHubLogo, "hubs");
          await editHub(created.id, { logo: addedImage });
        }
      }

      if ("name" in selectedHubPicture) {
        if (selectedHubPicture.name !== "") {
          const addedImage = await handleFileUpload(selectedHubPicture, "hubs");
          await editHub(created.id, { picture: addedImage });
        }
      }

      const today = new Date();
      today.setDate(today.getDate() + 15);

      // send invitation
      const user = {
        status: false,
        type: {
          id: ROLE.HUB_ADMIN,
          name: "hub admin",
        },
        userId: hub.administrator.email,
        validity: firebase.firestore.Timestamp.fromDate(today),
        fullName: hub.administrator.name,
      };

      await createInvitation(user);

      setIsLoadingButton(false);
      onCloseModal();
      handleSnackbar(t("HUBS.ADD_HUB.SUCCESS"), true);
    } catch (error) {
      console.log("Cannot create hub: ", error);
      setIsLoadingButton(false);
      handleSnackbar(t("HUBS.ADD_HUB.ERROR"), false);
    }
  };

  /**
   * Remove error that admin exist when user start to typing new email
   */
  const removeAdminError = () => {
    if (isAdminExistError) {
      setIsAdminExistError(false);
    }
  };

  return (
    <div className="add-hub">
      <div className="modal-content">
        <form
          className="width-100"
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit(onSubmitForm)}
        >
          <div className="form-holder">
            <div className="hub-title display-flex align-items-center justify-content-between">
              <Typography variant="subtitle2">
                {t("HUBS.ADD_HUB.NEW")}
              </Typography>
              <img src={closeModal} alt="Close modal" onClick={onCloseModal} />
            </div>
            <div className="display-flex justify-content-between form-block-holder">
              <div className="form-block hubs-info">
                <Typography variant="h2">{t("HUBS.ADD_HUB.INFO")}</Typography>
                <div className="form-separator"></div>
                <TextField
                  className="hub-input"
                  type="text"
                  name="name"
                  label={t("HUBS.ADD_HUB.NAME")}
                  variant="outlined"
                  {...register("name", {
                    required: {
                      value: true,
                      message: t("HUBS.ADD_HUB.NAME_ERROR"),
                    },
                  })}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                />
                <div className="form-separator"></div>
                <TextField
                  className="hub-input"
                  type="email"
                  name="email"
                  label={t("HUBS.ADD_HUB.EMAIL")}
                  variant="outlined"
                  {...register("email", {
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: t("HUBS.ADD_HUB.EMAIL_ERROR"),
                    },
                  })}
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                />
                <div className="form-separator"></div>
                <TextField
                  className="hub-input"
                  type="text"
                  name="street"
                  label={t("HUBS.ADD_HUB.ADDRESS")}
                  variant="outlined"
                  {...register("street")}
                />
                <div className="form-separator"></div>
                <div className="justify-content-between display-flex flex-column-xs">
                  <FilterSelect
                    key="add-hub-select-country"
                    placeholder={t("HUBS.ADD_HUB.COUNTRY")}
                    options={countriesSelection}
                    defaultValue={null}
                    onChange={handleCountryChange}
                    filterStyle="hub-select"
                  />
                  <TextField
                    className="input-city"
                    type="text"
                    name="city"
                    label={t("HUBS.ADD_HUB.CITY")}
                    variant="outlined"
                    {...register("city")}
                  />
                </div>
                <div className="form-separator"></div>
                <div className="display-flex justify-content-between flex-column-xs">
                  <TextField
                    className="input-number"
                    type="number"
                    name="zip"
                    label={t("HUBS.ADD_HUB.POSTAL")}
                    variant="outlined"
                    {...register("zip")}
                  />
                  <TextField
                    className="input-number"
                    type="number"
                    name="phone"
                    label={t("HUBS.ADD_HUB.PHONE")}
                    variant="outlined"
                    {...register("phone")}
                  />
                </div>
                <div className="form-separator"></div>
                <div className="upload-input-holder">
                  <UploadInput
                    key="add-hub-logo"
                    reference="add-hub-logo"
                    text={t("HUBS.ADD_HUB.FILE_LABEL.LOGO")}
                    maxFile={2}
                    value={selectedHubLogo}
                    onChoseFile={chooseFile}
                  />
                  {selectedHubLogo?.name !== "" && (
                    <img
                      onClick={() => removeHubImage("logo")}
                      className="trash"
                      src={trashIcon}
                      alt="Delete"
                    />
                  )}
                </div>
                <div className="form-separator"></div>
                <div className="upload-input-holder">
                  <UploadInput
                    key="add-hub-picture"
                    reference="add-hub-picture"
                    text={t("HUBS.ADD_HUB.FILE_LABEL.PICTURE")}
                    maxFile={2}
                    value={selectedHubPicture}
                    onChoseFile={chooseFile}
                  />
                  {selectedHubPicture?.name !== "" && (
                    <img
                      onClick={() => removeHubImage("picture")}
                      className="trash"
                      src={trashIcon}
                      alt="Delete"
                    />
                  )}
                </div>
                <ImageUploadInfo />
              </div>
              <div className="administrator form-block">
                <Typography variant="h2">
                  {t("HUBS.ADD_HUB.ADMINISTRATOR")}
                </Typography>
                <div className="form-separator"></div>
                <TextField
                  className="hub-input"
                  type="text"
                  name="hubAdmin"
                  label={t("HUBS.ADD_HUB.FULL_NAME")}
                  variant="outlined"
                  {...register("hubAdmin", {
                    required: {
                      value: true,
                      message: t("HUBS.ADD_HUB.FULL_NAME_ERROR"),
                    },
                  })}
                  error={Boolean(errors.hubAdmin)}
                  helperText={errors.hubAdmin?.message}
                />
                <div className="form-separator"></div>
                <TextField
                  className="hub-input"
                  type="email"
                  name="hubAdminEmail"
                  label={t("HUBS.ADD_HUB.ADMIN_EMAIL")}
                  variant="outlined"
                  onInput={removeAdminError}
                  {...register("hubAdminEmail", {
                    required: {
                      value: true,
                      message: t("HUBS.ADD_HUB.ADMIN_ERROR"),
                    },
                  })}
                  error={Boolean(
                    isAdminExistError && !errors.hubAdminEmail
                      ? isAdminExistError
                      : errors.hubAdminEmail
                  )}
                  helperText={
                    isAdminExistError && !errors.hubAdminEmail?.message
                      ? t("HUBS.ADD_HUB.EMAIL_EXIST")
                      : errors.hubAdminEmail?.message
                  }
                />
              </div>
            </div>
          </div>
          <div className="footer display-flex align-items-center">
            <LoadingButton
              className="font-weight-600"
              isLoading={isLoadingButton}
              text={t("ACTION_BUTTONS.CREATE")}
            />
            <Button
              className="font-weight-600"
              variant="outlined"
              onClick={onCloseModal}
              disabled={isLoadingButton}
            >
              {t("ACTION_BUTTONS.CANCEL")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddHub;
