/**
 * Generate fullname
 *
 * @param {*} firstName => firstName
 * @param {*} lastName => lastName
 * @returns fullName from firstName and lastName
 */
const generateFullName = (firstName, lastName) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
};

export { generateFullName };
