import React, { useState } from "react";
import avatar from "assets/images/icons/avatar.svg";
import reply from "assets/images/icons/reply.svg";
import replyComment from "assets/images/icons/reply-comment.svg";
import arrowDown from "assets/images/icons/arrow-down.svg";
import arrowUp from "assets/images/icons/arrow-up-comments.svg";
import addReplyIcon from "assets/images/icons/reply-white.svg";
import "./css/qa-item.css";
import Answer from "./Answer";
import { Button, Typography } from "@material-ui/core";
import QAEditor from "./QAEditor";
import { generateFullName } from "utils/generateFullName";
import { getFormattedDate, getFormattedTime } from "utils/dateFormat";
import { showRoleName } from "utils/generateRoleName";
import { SanitizeHTML } from "utils/sanitizeQAData";
import { ROLE } from "utils/ROLES";
import { useTranslation } from "react-i18next";

const QAItem = (props) => {
  const { qa, selectedQuestion, addQuestionReply, deleteQuestion, loggedRole } =
    props;

  const { t } = useTranslation();

  const [editorValue, setEditorValue] = useState("");
  const [editorError, setEditorError] = useState(false);
  const [isReplyClicked, setIsReplyClicked] = useState(false);

  /**
   * Show comments for selected question
   */
  const showComments = (type) => {
    setEditorValue("");
    setEditorError(false);
    if (qa?.comments.length > 0 || isReplyClicked) {
      selectedQuestion(qa);
      setIsReplyClicked(false);
    }
  };

  /**
   * Show reply editor
   */
  const showReplyEditor = () => {
    setIsReplyClicked(true);
    selectedQuestion(qa);
  };

  /**
   * Update value from editor on change
   *
   * @param {*} editor => value from QA editor
   */
  const handleEditorChange = (editor) => {
    setEditorValue(editor);
  };

  /**
   * Add reply
   */
  const addReply = () => {
    if (editorValue.length <= 0) {
      setEditorError(true);
    } else {
      addQuestionReply(editorValue, qa.id);
      setEditorValue("");
      setEditorError(false);
    }
  };

  /**
   * Open modal for deleting post / question
   */
  const deletePost = () => {
    deleteQuestion(qa);
  };

  return (
    <div
      className={`qa-item-content width-100 ${
        qa?.user?.type?.roleId === ROLE.TEACHER && "teacher-post"
      }`}
    >
      <div className="qa-item-container">
        <div className="qa-item width-100 display-flex align-items-start">
          <div className="icon">
            <img className="width-100" src={avatar} alt="Avatar" />
          </div>
          <div className="comment-content-main comment-content width-100">
            <div className="comment-header display-flex align-items-center justify-content-between">
              <div className="user-info">
                <Typography variant="h5" color="textPrimary">
                  {generateFullName(qa?.user?.firstName, qa?.user?.lastName)}
                </Typography>
                <Typography variant="body1" className="user-type">
                  {showRoleName(qa, t)}
                </Typography>
              </div>
              <p className="date">
                {getFormattedDate(qa?.qDateTime, ".")}.,
                <span>{getFormattedTime(qa?.qDateTime)}h</span>
              </p>
            </div>
            <div className="comment-body">
              <SanitizeHTML html={qa?.postText} />
              {!qa?.expanded && (
                <div className="display-flex align-items-center hold-action-buttons">
                  <div className="reply" onClick={showReplyEditor}>
                    <img src={reply} alt="Reply" />
                    {t("ACTION_BUTTONS.REPLY")}
                  </div>
                  {loggedRole === ROLE.SUPER_ADMIN && (
                    <div className="reply" onClick={deletePost}>
                      {t("ACTION_BUTTONS.DELETE")}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="all-comments width-100">
        {qa?.expanded && (
          <div className="answers-list width-100">
            {qa?.comments?.map((comment) => (
              <Answer key={comment?.id} comment={comment} />
            ))}
            <div className="qa-editor-container display-flex align-items-start">
              <div className="icon">
                <img src={avatar} alt="Avatar" />
              </div>
              <div className="qa-editor">
                <QAEditor
                  placeholder="Write your reply here..."
                  editorChange={handleEditorChange}
                  isError={editorError}
                />
                <Button
                  className="reply-answer"
                  color="primary"
                  variant="contained"
                  startIcon={<img src={addReplyIcon} alt="Add reply" />}
                  onClick={addReply}
                >
                  {t("ACTION_BUTTONS.REPLY")}
                </Button>
              </div>
            </div>
          </div>
        )}

        <div
          className="action width-100 display-flex align-items-center justify-content-center"
          onClick={showComments}
        >
          <img
            className="all-comments-img"
            src={replyComment}
            alt="All comments"
          />
          <p className="comments-length">
            {t("EDUCATIONS.Q&A.COMMENTS")} ({qa?.comments?.length})
          </p>
          <img
            className="arrow"
            src={qa?.expanded ? arrowUp : arrowDown}
            alt="Arrow down"
          />
        </div>
      </div>
    </div>
  );
};

export default QAItem;
