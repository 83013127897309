import React from "react";

// router
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import PrivateRoute from "auth/PrivateRoute";
import AuthRoute from "auth/AuthRoutes";
import { ROUTES } from "./CONSTANTS";

// components
import { NotFound } from "router/NotFound";
import Login from "auth/Login";
import Educations from "pages/Educations/components/Educations";
import Hubs from "pages/Hubs/components/Hubs";
import HubPage from "pages/Hubs/components/HubPage";
import Teachers from "pages/Teachers/components/Teachers";
import SignUp from "auth/SignUp";
import ForgotPassword from "auth/ForgotPassword";
import ResetPassword from "auth/ResetPassword";
import NotificationsList from "pages/Notifications/components/List";
import Education from "pages/Educations/components/Education";
import Students from "pages/Students/components/Students";
import Lesson from "pages/Educations/components/Lesson";
import { ROLE } from "utils/ROLES";

import { useAppState } from "pages/Twilio/state";

import ErrorDialog from "pages/Twilio/components/ErrorDialog/ErrorDialog";

// import { ChatProvider } from "pages/Twilio/components/ChatProvider";
import { VideoProvider } from "pages/Twilio/components/VideoProvider";

import useConnectionOptions from "pages/Twilio/utils/useConnectionOptions/useConnectionOptions";

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <Education />
    </VideoProvider>
  );
};

export const RouterConfig = () => {
  const pathSearch = useLocation().search;
  const searchValue = pathSearch.split("&")[0].split("=")[1];
  return (
    <div>
      <Switch>
        {/* List all public routes here */}
        <AuthRoute path={ROUTES.LOGIN}>
          <Login />
        </AuthRoute>

        <AuthRoute path={ROUTES.SIGNUP}>
          <SignUp />
        </AuthRoute>

        <AuthRoute path={ROUTES.FORGOT_PASSWORD}>
          <ForgotPassword />
        </AuthRoute>

        {searchValue === "resetPassword" ? (
          <AuthRoute
            render={(props) => {
              if (searchValue !== undefined) {
                if (searchValue === "resetPassword") {
                  return <ResetPassword {...props} />;
                }
              }
            }}
          />
        ) : (
          <AuthRoute exact path={ROUTES.RESET_PASSWORD}>
            <ResetPassword />
          </AuthRoute>
        )}

        {/* Private routes */}

        <PrivateRoute
          roles={[ROLE.SUPER_ADMIN, ROLE.HUB_ADMIN, ROLE.TEACHER, ROLE.STUDENT]}
          exact
          path={ROUTES.EDUCATIONS}
        >
          <Educations />
        </PrivateRoute>

        <PrivateRoute
          roles={[ROLE.SUPER_ADMIN, ROLE.HUB_ADMIN, ROLE.TEACHER, ROLE.STUDENT]}
          exact
          path={ROUTES.EDUCATIONS_ID}
        >
          <VideoApp />
        </PrivateRoute>

        <PrivateRoute
          roles={[ROLE.SUPER_ADMIN, ROLE.TEACHER, ROLE.STUDENT]}
          path={ROUTES.LESSON}
        >
          {/* <Lesson /> */}
          {/* <VideoApp /> */}
        </PrivateRoute>

        <PrivateRoute roles={[ROLE.SUPER_ADMIN]} exact path={ROUTES.HUBS}>
          <Hubs />
        </PrivateRoute>

        <PrivateRoute roles={[ROLE.SUPER_ADMIN]} exact path={ROUTES.HUBS_ID}>
          <HubPage />
        </PrivateRoute>

        <PrivateRoute roles={[ROLE.HUB_ADMIN]} exact path={ROUTES.MY_HUB}>
          <HubPage />
        </PrivateRoute>

        <PrivateRoute
          roles={[ROLE.SUPER_ADMIN, ROLE.HUB_ADMIN]}
          exact
          path={ROUTES.TEACHERS}
        >
          <Teachers />
        </PrivateRoute>

        <PrivateRoute roles={[ROLE.SUPER_ADMIN]} exact path={ROUTES.STUDENTS}>
          <Students />
        </PrivateRoute>

        <PrivateRoute
          roles={[ROLE.TEACHER, ROLE.STUDENT]}
          exact
          path={ROUTES.NOTIFICATIONS}
        >
          <NotificationsList />
        </PrivateRoute>

        <Redirect exact path="/" to="/educations" />

        {/* List a generic 404-Not Found route here */}
        <Route exact path="*">
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
};
