import { Typography } from "@material-ui/core";
import React from "react";
import notificationBell from "assets/images/icons/notification-bell-gray.svg";
import "./css/notification-dropdown.css";
import { Link } from "react-router-dom";
import { ROUTES } from "router/CONSTANTS";
import { notificationText } from "utils/notificationType";

const NotificationDropdown = (props) => {
  const { notifications, t } = props;

  return (
    <div className="notification-dropdown">
      <div className="title w-100">
        <Typography variant="h5">{t("NOTIFICATIONS.TITLE")}</Typography>
      </div>
      {notifications?.length > 0 && (
        <div className="list w-100">
          {notifications?.map((notification) => (
            <div
              key={notification?.id}
              className="notification w-100 display-flex align-items-center"
            >
              <img src={notificationBell} alt="Notification bell" />
              <p>
                {notificationText(notification)}
                <Link to={notification?.linkTo}>{t("NOTIFICATIONS.VIEW")}</Link>
              </p>
            </div>
          ))}
        </div>
      )}
      <Link
        to={ROUTES.NOTIFICATIONS}
        className="view-all-notifications w-100 display-flex align-items-center justify-content-center"
      >
        {t("NOTIFICATIONS.VIEW_ALL")}
      </Link>
    </div>
  );
};

export default NotificationDropdown;
