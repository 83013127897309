import React from "react";
import { TeacherProvider } from "../context/TeacherContext";
import List from "./List";

const Teachers = () => {
  return (
    <TeacherProvider>
      <List />
    </TeacherProvider>
  );
};

export default Teachers;
