import React from "react";
import PreJoinScreens from "pages/Twilio/components/PreJoinScreens/PreJoinScreens";

import "./css/join-lesson.css";

const JoinLesson = (props) => {
  const { selectedRoomName, readableRoomName, loggedUser } = props;

  return (
    <div className="pre-join-lesson">
      <PreJoinScreens
        selectedRoomName={selectedRoomName}
        readableRoomName={readableRoomName}
        loggedUser={loggedUser}
      />
    </div>
  );
};

export default JoinLesson;
