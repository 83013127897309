import React, { useEffect, useState } from "react";
import StudentCard from "../StudentCard";
import EmptyState from "components/EmptyState";
import EmptyImage from "assets/images/empty-state/students.svg";
import { Typography } from "@material-ui/core";
import "./css/students.css";
import { useAuth } from "auth/context/AuthContext";
import { ROLE } from "utils/ROLES";
import {
  getParticipants,
  checkPrimEducatorForEducation,
  updateParticipantStatus,
} from "services/educationService";
import { getUserDetails } from "services/userService";
import AppModal from "components/AppModal";
import StudentStatus from "components/StudentStatus";
import { generateFullName } from "utils/generateFullName";
import { PARTICIPANT_STATUS } from "utils/participantStatus";
import { useTranslation } from "react-i18next";
import { NOTIFICATION_TYPE } from "utils/notificationType";
import { APPLICATION_URL } from "utils/appUrl";
import firebase from "firebase/app";
import { ROUTES } from "router/CONSTANTS";
import { useEducation } from "pages/Educations/context/EducationContext";
import { createNewNotification } from "services/notificationService";
import DataLoader from "components/DataLoader";

const Students = (props) => {
  const { educationId } = props;
  const { currentUser, loggedRole } = useAuth();
  const { t } = useTranslation();
  const [students, setStudents] = useState([]);

  const { singleEducation } = useEducation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [studentStatus, setStudentStatus] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    let studentsList;
    let primEducator = "";
    let uid = "";
    if (!currentUser) return;
    if (!educationId) return;
    if (mounted) {
      if (
        loggedRole !== null &&
        loggedRole !== undefined &&
        loggedRole !== ROLE.HUB_ADMIN
      ) {
        (async () => {
          if (loggedRole === ROLE.TEACHER) {
            uid = localStorage.getItem("uid");
            primEducator = await checkPrimEducatorForEducation(educationId);
          }
          studentsList = await getParticipants(educationId);

          if (!studentsList.empty) {
            const isPrimary = primEducator?.id === uid ? true : false;
            Promise.all(
              studentsList.docs.map(async (res) => {
                const participant = await res.data();
                const participantId = await participant.userId.split("/")[1];

                const getParticipantFromUsers = await getUserDetails(
                  participantId
                );

                const participantInformation =
                  await getParticipantFromUsers.data();

                const studentInformation = {
                  approvalStatus: res.data().approvalStatus,
                  appliedDate: res.data().appliedDate,
                  participantInformation,
                  id: participantInformation.id,
                  isPrimary,
                };

                return studentInformation;
              })
            )
              .then((values) => {
                if (values.length) {
                  setStudents(values);
                  setIsDataLoading(false);
                }
              })
              .catch((err) => {
                console.log("Cannot get students: ", err);
                setIsDataLoading(false);
              });
          } else {
            setIsDataLoading(false);
          }
        })();
      }
    }

    return () => {
      mounted = false;
      studentsList = null;
      primEducator = "";
      uid = "";
      setStudents([]);
    };
  }, [currentUser, loggedRole, educationId]);

  /**
   * Select student on click buttons
   *
   * @param {*} student => selected student
   * @param {*} type => can be approve or dissaprove
   */
  const selectStudent = (student, type) => {
    console.log(type);
    const studentData = {
      type,
      title:
        type === "approve"
          ? t("EDUCATIONS.STUDENTS_LIST.APPROVE_STATUS.TITLE")
          : t("EDUCATIONS.STUDENTS_LIST.DECLINE_STATUS.TITLE"),
      description:
        type === "approve"
          ? `Student ${generateFullName(
              student?.firstName,
              student?.lastName
            )} ${t("EDUCATIONS.STUDENTS_LIST.APPROVE_STATUS.DESCRIPTION")}`
          : `${t(
              "EDUCATIONS.STUDENTS_LIST.DECLINE_STATUS.DESCRIPTION_FIRST"
            )} ${generateFullName(student?.firstName, student?.lastName)} ${t(
              "EDUCATIONS.STUDENTS_LIST.DECLINE_STATUS.DESCRIPTION_SECOND"
            )}`,
      buttonText:
        type === "approve"
          ? t("ACTION_BUTTONS.YES_APPROVE")
          : t("ACTION_BUTTONS.YES_DECLINE"),
      studentId: student?.id,
    };
    setStudentStatus(studentData);
    showModal();
  };

  /**
   * Show modal
   */
  const showModal = () => {
    setIsModalVisible(true);
  };

  /**
   * Hide modal
   */
  const hideModal = () => {
    setIsModalVisible(false);
  };

  /**
   * Handle student status for education
   */
  const handleStudentEducationStatus = async () => {
    setIsLoadingButton(true);

    const type =
      studentStatus.type === "approve"
        ? NOTIFICATION_TYPE.EDUCATION_APPROVED
        : NOTIFICATION_TYPE.EDUCATION_DISSAPROVED;

    const linkTo =
      studentStatus.type === "approve"
        ? `${APPLICATION_URL}${ROUTES.EDUCATIONS}/${educationId}`
        : `${APPLICATION_URL}${ROUTES.EDUCATIONS}}`;

    const newNotificationData = {
      sender: currentUser?.email,
      recepient: studentStatus.studentId,
      title: singleEducation.title,
      content: "",
      linkTo,
      educationId,
      timelineId: "",
      type,
      notificationDateTime: firebase.firestore.Timestamp.fromDate(new Date()),
      seen: false,
    };

    const approvalStatus =
      studentStatus?.type === "approve"
        ? PARTICIPANT_STATUS.APPROVED
        : PARTICIPANT_STATUS.DISSAPROVED;
    await updateParticipantStatus(educationId, studentStatus?.studentId, {
      approvalStatus,
    });

    let items = [...students];
    let itemIndex = items.findIndex(
      (obj) => obj.id === studentStatus.studentId
    );
    items[itemIndex].approvalStatus = approvalStatus;

    await createNewNotification(newNotificationData);
    setIsLoadingButton(false);
    hideModal();
  };

  const handleEmptyStateVisibility = () => {
    if (students.length <= 0 && !isDataLoading) {
      return true;
    }
    return false;
  };

  const handleStudentsVisibility = () => {
    if (students.length > 0 && !isDataLoading) {
      return true;
    }
    return false;
  };

  return (
    <div className="students-for-education">
      {students.length > 0 && (
        <Typography className="education-students" variant="h1">
          {t("EDUCATIONS.STUDENTS_LIST.TITLE")}
        </Typography>
      )}
      {handleStudentsVisibility() && (
        <div className="list-students display-flex flex-wrap">
          {students.map((student) => (
            <StudentCard
              key={student.participantInformation.id}
              student={student}
              selectedStudent={selectStudent}
            />
          ))}
        </div>
      )}

      {handleEmptyStateVisibility() && (
        <EmptyState
          image={EmptyImage}
          title={t("EDUCATIONS.STUDENTS_LIST.EMPTY_STATE.TITLE")}
          caption={t("EDUCATIONS.STUDENTS_LIST.EMPTY_STATE.DESCRIPTION")}
          hasButton={false}
        />
      )}

      {isDataLoading && <DataLoader />}

      <AppModal
        isVisible={isModalVisible}
        content={
          <StudentStatus
            studentData={studentStatus}
            isLoadingButton={isLoadingButton}
            onConfirmAction={handleStudentEducationStatus}
            onCancel={hideModal}
          />
        }
      />
    </div>
  );
};

export default Students;
