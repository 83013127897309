import { Button, TextField, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import "../css/AddEvent.css";
import closeModal from "assets/images/icons/close-modal.svg";
import AppDatePicker from "components/AppDatePicker";
import AppTimePicker from "components/AppTimePicker";
import LoadingButton from "components/LoadingButton";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import { useTranslation } from "react-i18next";

const EditEvent = (props) => {
  const { t } = useTranslation();
  const {
    isLoadingButton,
    editEvent,
    handleDateTimeError,
    isDateTimeError,
    onCloseModal,
    timeline,
  } = props;
  // useForm properties
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  // states
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [dateValue, setDateValue] = useState(false);
  const [timeValue, setTimeValue] = useState(false);

  useEffect(() => {
    const startDate = timeline?.scheduled;
    const timestampToDate = startDate.toDate();
    const timestampFormat = moment(timestampToDate).format("DD-MM-YYYY HH:mm");
    const splittedDate = timestampFormat.split(" ");
    const onlyDate = splittedDate[0].split("-");

    const onlyTime = splittedDate[1].split(":");
    const month = +onlyDate[1] - 1;

    const timeDateMoment = moment(
      new Date(onlyDate[2], month, onlyDate[0], onlyTime[0], onlyTime[1])
    );

    setDateValue(timeDateMoment);
    setTimeValue(timeDateMoment);
  }, [timeline]);

  /**
   * Select date from date picker
   *
   * @param {*} value => selected date from date picker
   */
  const handleDateChange = (value) => {
    const dateString = new Date(moment(value).format("YYYY, MM, DD"));
    setDateValue(dateString);
    handleDateTimeError(false);
  };

  /**
   * Select time from time picker
   *
   * @param {*} value => selected time from time picker
   */
  const handleTimeChange = (value) => {
    const timeString = new Date(moment(value).format("YYYY, MM, DD, HH:mm"));
    setTimeValue(timeString);
    handleDateTimeError(false);
  };

  /**
   * Submit form and proceed
   *
   * @param {*} data => submitted values from form
   */
  const onSubmitForm = (data) => {
    if (dateValue && timeValue) {
      const formData = {
        ...data,
        date: moment(new Date(dateValue)),
        time: moment(new Date(timeValue)),
      };

      editEvent(formData);
    }
  };

  return (
    <div className="add-event">
      <div className="event-header display-flex align-items-center justify-content-between">
        <Typography variant="h1">
          {t("EDUCATIONS.TIMELINE.EDIT_EVENT")}
        </Typography>
        <img src={closeModal} alt="Close modal" onClick={onCloseModal} />
      </div>
      <form
        className="width-100"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onSubmitForm)}
      >
        <div className="form-content width-100%">
          <Controller
            control={control}
            name="name"
            rules={{
              required: {
                value: true,
                message: t("EDUCATIONS.TIMELINE.NEW_EVENT.EVENT_NAME.ERROR"),
              },
            }}
            defaultValue={timeline?.name}
            render={({ field: { onChange, ref } }) => (
              <TextField
                variant="outlined"
                type="text"
                label={t("EDUCATIONS.TIMELINE.NEW_EVENT.EVENT_NAME.TITLE")}
                inputRef={ref}
                onChange={onChange}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
            )}
          />
          <div className="date-time-box display-flex justify-content-between flex-wrap">
            <AppDatePicker
              submitted={formSubmitted}
              value={dateValue}
              onChange={handleDateChange}
            />
            <AppTimePicker
              submitted={formSubmitted}
              value={timeValue}
              onChange={handleTimeChange}
            />
            {isDateTimeError && (
              <p className="error-message width-100">
                {t("EDUCATIONS.TIMELNE.NEW_EVENT.START_DATE.CHECK")}
              </p>
            )}
          </div>
        </div>
        <div className="footer display-flex align-items-center">
          <LoadingButton
            isLoading={isLoadingButton}
            text={t("ACTION_BUTTONS.EDIT")}
            onClick={() => setFormSubmitted(true)}
          />
          <Button variant="outlined" onClick={onCloseModal}>
            {t("ACTION_BUTTONS.CANCEL")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditEvent;
