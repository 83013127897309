import React, { useState, Suspense, useEffect } from "react";
import "auth/css/auth.css";
import Logo from "components/Logo";
import TextField from "@material-ui/core/TextField";
import { Typography, InputAdornment } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "router/CONSTANTS";
import showPasswordEye from "assets/images/icons/password-eye-open.svg";
import hidePasswordEye from "assets/images/icons/password-eye-closed.svg";
import { useAuth } from "./context/AuthContext";
import { useForm } from "react-hook-form";
import LoadingButton from "components/LoadingButton";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "contexts/SnackbarProvider";
import { handleErrorMessages } from "utils/handleAuthErrors";
const Ilustration = React.lazy(() => import("./Ilustration.js"));

export default function Login() {
  const { t } = useTranslation();
  const dispatch = useSnackbar();

  // useForm properties
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Submit form when all fields are valid
   * @param {*} data => form fields
   */
  const onSubmitForm = (data) => {
    if (Object.entries(errors).length === 0) {
      loginRequest(data);
    }
  };

  // states
  const [passwordType, setPasswordType] = useState(true);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  // use auth
  const { login, authHeightState, setAuthContainerHeight } = useAuth();

  useEffect(() => {
    setAuthContainerHeight();
  }, [setAuthContainerHeight]);

  /**
   * Show / hide password on click eye icon
   * @param {*} type => can be true or false
   */
  const showHidePassword = (type) => {
    setPasswordType(!type);
  };

  /**
   * Show snackbar
   *
   * @param {*} message => message in snackbar
   * @param {*} type => type of snackbar (true/false)
   */
  const handleSnackbar = (message, type) => {
    dispatch({
      message: message,
      success: type,
    });
  };

  /**
   * Login request to firebase
   * @param {*} email => user email
   * @param {*} password => user password
   */
  const loginRequest = async ({ email, password }) => {
    try {
      setLoading(true);
      await login(email, password);
      history.push(ROUTES.EDUCATIONS);
    } catch (error) {
      console.log("Error: ", error);
      handleSnackbar(handleErrorMessages(error, t), false);
    }
    setLoading(false);
  };

  return (
    <div className="auth-container" style={{ height: authHeightState }}>
      <Suspense fallback={<div>Loading ...</div>}>
        <div className="auth-content display-flex">
          <div className="auth-form login-form">
            <div className="data-holder">
              <Logo />
              <div className="form-box">
                <Typography
                  style={{ marginBottom: "12px" }}
                  variant="subtitle2"
                >
                  {t("AUTH.LOGIN.TITLE")}
                </Typography>
                <p style={{ marginBottom: "48px" }}>
                  {t("AUTH.LOGIN.DESCRIPTION")}
                </p>
                <form
                  className="width-100"
                  autoComplete="off"
                  noValidate
                  onSubmit={handleSubmit(onSubmitForm)}
                >
                  <TextField
                    type="email"
                    name="email"
                    label={t("AUTH.INPUTS.EMAIL")}
                    variant="outlined"
                    style={{ marginBottom: "20px" }}
                    {...register("email", {
                      required: {
                        value: true,
                        message: t("AUTH.ERROR.REQUIRED"),
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t("AUTH.ERROR.EMAIL_FORMAT"),
                      },
                    })}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                  />
                  <TextField
                    type={passwordType ? "password" : "text"}
                    label={t("AUTH.INPUTS.PASSWORD")}
                    variant="outlined"
                    name="password"
                    {...register("password", {
                      required: {
                        value: true,
                        message: t("AUTH.ERROR.REQUIRED"),
                      },
                    })}
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
                            onClick={() => showHidePassword(passwordType)}
                            src={
                              passwordType ? showPasswordEye : hidePasswordEye
                            }
                            alt="Password"
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Link
                    className="link forgot-password-link display-flex justify-content-end"
                    to={ROUTES.FORGOT_PASSWORD}
                  >
                    {t("AUTH.OTHER.FORGOT_PASSWORD")}
                  </Link>

                  <LoadingButton
                    isLoading={loading}
                    text={t("ACTION_BUTTONS.SIGN_IN")}
                  />
                  <p className="need-account">
                    {t("AUTH.OTHER.NO_ACCOUNT")}
                    <Link className="link" to={ROUTES.SIGNUP}>
                      {t("ACTION_BUTTONS.SIGN_UP")}
                    </Link>
                  </p>
                </form>
              </div>
            </div>
            <div className="disclaimer">
              <p>
                The project is co-financed by ERDF and IPA II funds of the
                European Union
              </p>
              <p>
                The development of this e-learning platform is co-financed by
                the European Union. The content of the e-learning platform is
                the sole responsibility of the project partners and in no way
                reflects the views of the European Union
              </p>
            </div>
          </div>
          <Ilustration />
        </div>
      </Suspense>
    </div>
  );
}
