import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import useParticipants from "pages/Twilio/hooks/useParticipants/useParticipants";

const useStyles = makeStyles(() =>
  createStyles({
    participantsNumber: {
      width: "100%",
      position: "relative",
      color: "white",
      paddingLeft: "1rem",
      marginTop: ".5rem",
    },
  })
);

const ParticipantNumber = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const participants = useParticipants();

  return (
    <div className={classes.participantsNumber}>
      {t("TWILIO.PARTICIPANTS")}: {participants.length + 1}
    </div>
  );
};

export { ParticipantNumber };
