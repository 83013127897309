import React from "react";
import EmptyState from "components/EmptyState";
import notFoundPage from "assets/images/empty-state/404.svg";
import { useAuth } from "../auth/context/AuthContext";
import { ROUTES } from "./CONSTANTS";
import { useTranslation } from "react-i18next";

export const NotFound = () => {
  // use auth
  const { currentUser } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      {/* Page Not Found! */}

      <EmptyState
        className="not-found"
        image={notFoundPage}
        title={t("EDUCATIONS.404_PAGE.TITLE")}
        caption={t("EDUCATIONS.404_PAGE.DESCRIPTION")}
        buttonTitle={t("ACTION_BUTTONS.BACK_HOME")}
        hasButton={true}
        link={currentUser ? ROUTES.EDUCATIONS : ROUTES.LOGIN}
      />
    </>
  );
};
