import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import "../css/SidebarDrawer.css";
import { Button, TextField } from "@material-ui/core";
import AppDatePicker from "components/AppDatePicker";
import AppTimePicker from "components/AppTimePicker";
import LoadingButton from "components/LoadingButton";
import { useForm, Controller } from "react-hook-form";
import DropdownCheckbox from "components/DropdownCheckbox";
import FilterSelect from "components/FilterSelect";
import ImageUploadInfo from "components/ImageUploadInfo";
import UploadInput from "components/UploadInput";
import { useAuth } from "auth/context/AuthContext";
import { getTeachers } from "services/teacherService";
import { getMyHub } from "services/hubService";
import moment from "moment";
import firebase from "firebase/app";
import { editEducation } from "services/educationService";
import { useParams } from "react-router";
import { handleFileUpload } from "services/uploadService";
import { useTranslation } from "react-i18next";
import { categories } from "utils/category";
import { useSnackbar } from "contexts/SnackbarProvider";
import trashIcon from "assets/images/icons/trash.svg";
import { formatDateTimeEducation } from "utils/dateFormat";
import {
  disabledDates,
  disabledHours,
  disabledMinutes,
} from "utils/dateTimeValidation";
import { useEducation } from "../context/EducationContext";

const EditEducation = (props) => {
  const { t } = useTranslation();
  const { isVisible, selectedEducation, onClose } = props;
  const { currentUser } = useAuth();
  const educationId = useParams();
  const educationCategories = categories(t);
  const { setEditedEducation } = useEducation();

  // useForm properties
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  // states
  const [dateValue, setDateValue] = useState(false);
  const [timeValue, setTimeValue] = useState(false);
  const [dateTimeValue, setDateTimeValue] = useState(
    selectedEducation?.startDate
  );

  const [endDateValue, setEndDateValue] = useState(false);
  const [endTimeValue, setEndTimeValue] = useState(false);
  const [endDateTimeValue, setEndDateTimeValue] = useState(
    selectedEducation?.endDate
  );

  const [selectedPrimaryTeacher, setSelectedPrimaryTeacher] = useState(
    selectedEducation?.primEducator
  );
  const [selectedSecondaryTeacher, setSelectedSecondaryTeacher] = useState({
    secondaryTeachers: selectedEducation?.secondEducator.map(
      (teacher) => teacher.id
    ),
  });
  const [selectedEducationCategory, setSelectedEducationCategory] = useState(
    selectedEducation?.category
  );
  const [maxNumber, setMaxNumber] = useState(selectedEducation.maxNumber);
  const [selectedFile, setSelectedFile] = useState({
    image: selectedEducation?.picture,
    name: selectedEducation?.picture,
  });
  const [allTeachers, setAllTeachers] = useState({
    primary: [],
    secondary: [],
  });

  const [cvRequest, setCvRequest] = useState(selectedEducation?.cvRequest);
  const [approvalRequest, setApprovalRequest] = useState(
    selectedEducation?.approvalRequest
  );
  const [myHubData, setMyHubData] = useState(null);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [reloadMinutes, setIsReloadMinutes] = useState(false);

  const dispatch = useSnackbar();

  useEffect(() => {
    let mounted = true;
    if (!currentUser) return;
    getTeachers()
      .then((teachers) => {
        if (mounted) {
          const teachersList = teachers.docs.map((doc) => {
            const teacher = {
              id: doc.data().id,
              name: `${doc.data().firstName} ${doc.data().lastName}`,
              email: doc.data().email,
            };
            return teacher;
          });
          setAllTeachers({ primary: teachersList, secondary: teachersList });
          localStorage.setItem(
            "eduFilterTeachers",
            JSON.stringify(teachersList)
          );
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
      });

    return () => {
      mounted = false;
      localStorage.removeItem("eduFilterTeachers");
    };
  }, [currentUser]);

  useEffect(() => {
    // get image name form url
    const getImageName = (image) => {
      if (image !== "") {
        return image.split("/").pop().split("?")[0].split("F")[1];
      }

      return "";
    };

    setSelectedFile({
      image: selectedEducation?.picture,
      name: getImageName(selectedEducation?.picture),
    });

    const getDateTime = () => {
      const startDate = formatDateTimeEducation(selectedEducation?.startDate);
      const endDate = formatDateTimeEducation(selectedEducation?.endDate);

      setDateValue(startDate[0]);
      setTimeValue(startDate[1]);
      setEndDateValue(endDate[0]);
      setEndTimeValue(endDate[1]);
    };
    getDateTime();

    return () => {
      setSelectedFile({ image: "", name: "" });
      getImageName("");
    };
  }, [selectedEducation]);

  useEffect(() => {
    let myHubMounted = true;
    let myHub = null;
    if (!currentUser) return;
    if (myHubMounted) {
      (async () => {
        return await getMyHub(currentUser.email)
          .then((res) => {
            const hubId = res.docs[0].id;
            myHub = {
              ...res.docs[0].data(),
              hubId,
            };
            if (myHub) {
              setMyHubData(myHub);
            }
          })
          .catch((err) => console.log("Cannot get hub: ", err));
      })();
    }
    return () => {
      myHubMounted = false;
      myHub = null;
      setMyHubData(null);
    };
  }, [currentUser]);

  /**
   * Select date from date picker
   *
   * @param {*} value => selected date from date picker
   */
  const handleDateChange = (value, formatedDate, type) => {
    const dateString = new Date(moment(value).format("YYYY, MM, DD, HH:mm"));

    if (type === "start-date") {
      setDateValue(dateString);
      setDateTimeValue("");
      setTimeValue(false);
      setEndDateValue(false);
      setEndTimeValue(false);
      return;
    }

    setEndDateValue(dateString);
    setEndTimeValue(false);
    setEndDateTimeValue("");
  };

  /**
   * Select time from time picker
   *
   * @param {*} value => selected time from time picker
   */
  const handleTimeChange = (value, formatedTime, type) => {
    const timeString = new Date(moment(value).format("YYYY, MM, DD, HH:mm"));

    if (type === "start-time") {
      setTimeValue(timeString);
      setDateTimeValue("");
      setEndTimeValue(false);
      return;
    }

    setEndTimeValue(timeString);
    setEndDateTimeValue("");
  };

  /**
   * Select time
   *
   * @param {*} value => selected value
   */
  const onSelectTime = (value, type) => {
    const selectedValue = moment(new Date(value));
    const selectedStart = moment(new Date(timeValue));

    const selectedHour = moment(new Date(value)).hour();
    const isToday = moment(dateValue).isSame(moment(), "date");
    const todayHour = moment(new Date()).hour();
    const isSameDay =
      moment(new Date(dateValue)).date() ===
      moment(new Date(endDateValue)).date();
    const selectedStartTime = moment(new Date(timeValue)).hour();

    if (isToday) {
      if (selectedHour > todayHour) {
        setIsReloadMinutes(true);
      } else {
        setIsReloadMinutes(false);
      }
    }

    if (isSameDay) {
      if (type === "end-minutes") {
        if (selectedValue.hour() === selectedStart.hour()) {
          setEndTimeValue(selectedStart.add(5, "minutes"));
        } else {
          setEndTimeValue(false);
        }
      }

      if (selectedHour > selectedStartTime) {
        setIsReloadMinutes(true);
      } else {
        setIsReloadMinutes(false);
      }
    }
  };

  /**
   * Select primary teacher from list
   *
   * @param {*} category => selected category
   */
  const handlePrimaryTeacherChange = (primaryTeacher) => {
    const selected = allTeachers.primary.find(
      (teacher) => teacher.id === primaryTeacher
    );

    const teachersCopy = JSON.parse(localStorage.getItem("eduFilterTeachers"));
    const filterSecondary = teachersCopy.filter(
      (secondary) => secondary.id !== primaryTeacher
    );

    setAllTeachers({
      primary: allTeachers.primary,
      secondary: filterSecondary,
    });

    setSelectedPrimaryTeacher(selected);
  };

  /**
   * Select secondary teachers
   *
   * @param {*} data => selected data
   */
  const secondaryTeacherCallback = (data) => {
    const selected = allTeachers.secondary.filter((teacher) =>
      data.some((id) => teacher.id === id)
    );

    const teachersCopy = JSON.parse(localStorage.getItem("eduFilterTeachers"));

    const filterPrimary = teachersCopy.filter((t) => !data.includes(t.id));

    setAllTeachers({
      primary: filterPrimary,
      secondary: allTeachers.secondary,
    });

    setSelectedSecondaryTeacher(selected);
  };

  /**
   * Select education category from list
   *
   * @param {*} secondaryTeacher => selected education category
   */
  const handleEducationCategoryChnage = (category) => {
    const selected = educationCategories.find(
      (education) => education.id === category
    );
    setSelectedEducationCategory(selected);
  };

  /**
   * Handle CV obligatory
   *
   * @param {*} event => selected radio btn for cv obligatory
   */
  const handleCvObligatory = (event) => {
    const value = event.target.value === "true" ? true : false;
    setCvRequest(value);
  };

  /**
   * Handle education approvall
   *
   * @param {*} event => selected radio btn for approval
   */
  const handleApprovall = (event) => {
    const value = event.target.value === "true" ? true : false;
    setApprovalRequest(value);
  };

  /**
   * Handle counter increment
   */
  const handleIncrement = () => {
    if (maxNumber === 50) {
      return;
    }
    setMaxNumber(maxNumber + 1);
  };

  /**
   * Handle counter decrement
   *
   * @returns counter
   */
  const handleDecrement = () => {
    if (maxNumber === 1) {
      setMaxNumber(1);
      return;
    }
    setMaxNumber(maxNumber - 1);
  };

  /**
   * Set chosed file from input
   *
   * @param {*} file => selected file form input
   */
  const chooseFile = (file) => {
    setSelectedFile(file);
  };

  /**
   * Generate date string for firebase timestamp
   *
   * @param {*} date => selected date
   * @param {*} time => selected time
   * @returns modified date and time
   */
  const generateDateString = (date, time) => {
    const dateModified =
      typeof date === "string"
        ? date.split("-")
        : moment(date).format("DD,MM,YYYY").split(",");
    const timeModified =
      typeof time === "string"
        ? time.split(":")
        : moment(time).format("hh:mm").split(":");

    const month = +dateModified[1] - 1;

    return new Date(
      dateModified[2],
      month,
      dateModified[0],
      timeModified[0],
      timeModified[1]
    );
  };

  /**
   * Generate value for image hub image, can be string if not updated or new File
   *
   * @param {*} src => selected hub image
   * @returns full src for image (string / File)
   */
  const generateValueForImage = (src) => {
    let fullPath = src;
    if ("image" in fullPath) {
      if (fullPath.image !== "") {
        fullPath = src.image;
      } else {
        fullPath = "";
      }
    }
    return fullPath;
  };

  /**
   * Check values for particular fields
   *
   * @returns boolean
   */
  const nonFormFieldsValidation = () => {
    if (
      selectedEducationCategory &&
      selectedPrimaryTeacher &&
      dateValue &&
      timeValue &&
      endDateValue &&
      endTimeValue
    ) {
      return true;
    }
    return false;
  };

  /**
   * Show snackbar on some action
   */
  const handleSnackbar = (message, type) => {
    dispatch({
      message: message,
      success: type,
    });
  };

  /***
   * Remove selected image on click trash icon
   */
  const removeEducationImage = () => {
    setSelectedFile({ name: "", image: "" });
  };

  /**
   * All fields valid, make request
   *
   * @param {*} data => form fields
   */
  const onSubmitForm = async (data) => {
    if (!nonFormFieldsValidation()) return;

    setIsLoadingButton(true);

    let educationDate = dateTimeValue;
    if (typeof dateTimeValue === "string") {
      educationDate = firebase.firestore.Timestamp.fromDate(
        generateDateString(dateValue, timeValue)
      );
    }

    let educationEndDate = endDateTimeValue;
    if (typeof endDateTimeValue === "string") {
      educationEndDate = firebase.firestore.Timestamp.fromDate(
        generateDateString(endDateValue, endTimeValue)
      );
    }

    let picture = generateValueForImage(selectedFile);

    let secondEducator = selectedSecondaryTeacher;
    if ("secondaryTeachers" in selectedSecondaryTeacher) {
      secondEducator = selectedEducation?.secondEducator;
    }

    let educators = secondEducator.map((teacher) => teacher.id);
    educators.push(selectedPrimaryTeacher.id);

    const educationData = {
      ...data,
      cvRequest,
      approvalRequest,
      maxNumber,
      hub: {
        id: myHubData.hubId,
        name: myHubData.name,
      },
      link: "",
      picture: typeof picture === "object" ? "" : picture,
      educators,
      primEducator: selectedPrimaryTeacher,
      secondEducator,
      startDate: educationDate,
      endDate: educationEndDate,
      category: selectedEducationCategory,
    };

    try {
      await editEducation(educationId.id, educationData);

      // if new picture added update education object
      if (typeof picture !== "string") {
        const updatedImage = await handleFileUpload(selectedFile, "educations");
        await editEducation(educationId.id, { picture: updatedImage });
      }

      setIsLoadingButton(false);
      onClose();
      handleSnackbar("Your changes have been saved.", true);
      setEditedEducation(true);
    } catch (error) {
      setIsLoadingButton(false);
      handleSnackbar("This action failed, an error ocured.", false);
      console.log("Cannot edit education: ", error);
    }
  };

  /**
   * Submit form
   */
  const submitForm = () => {
    const form = document.querySelector("#edit-education-btn");
    form.click();
    setFormSubmitted(true);
  };

  /**
   * Control fields when form is invalid
   */
  const onInvalidForm = () => {
    setFormSubmitted(true);
  };

  return (
    <div className="add-education-sidebar">
      <Drawer
        title="Edit education"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={isVisible}
        footer={
          <div className="drawer-footer display-flex align-items-center">
            <LoadingButton
              onClick={submitForm}
              className="auto-width font-weight-600 add-education-btn"
              isLoading={isLoadingButton}
              text={t("ACTION_BUTTONS.EDIT")}
            />
            <Button
              variant="outlined"
              className="auto-width font-weight-600 add-education-btn"
              onClick={onClose}
            >
              {t("ACTION_BUTTONS.CANCEL")}
            </Button>
          </div>
        }
      >
        <div className="add-education-form">
          <form
            className="width-100"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(onSubmitForm, onInvalidForm)}
          >
            <div className="width-100%">
              <Controller
                control={control}
                name="title"
                rules={{
                  required: {
                    value: true,
                    message: t("EDUCATIONS.ADD_EDUCATION.NAME_ERROR"),
                  },
                }}
                defaultValue={selectedEducation.title}
                render={({ field: { onChange, ref } }) => (
                  <TextField
                    variant="outlined"
                    type="text"
                    label={t("EDUCATIONS.ADD_EDUCATION.NAME")}
                    inputRef={ref}
                    onChange={onChange}
                    error={Boolean(errors.title)}
                    helperText={errors.title?.message}
                  />
                )}
              />
              <div className="drawer-spacer"></div>
              <Controller
                control={control}
                name="description"
                rules={{
                  required: {
                    value: true,
                    message: t("EDUCATIONS.ADD_EDUCATION.DESCRIPTION_ERROR"),
                  },
                }}
                defaultValue={selectedEducation.description}
                render={({ field: { onChange, ref } }) => (
                  <TextField
                    variant="outlined"
                    type="text"
                    label={t("EDUCATIONS.ADD_EDUCATION.DESCRIPTION")}
                    className="education-description"
                    multiline
                    minRows={4}
                    maxRows={4}
                    inputRef={ref}
                    onChange={onChange}
                    error={Boolean(errors.description)}
                    helperText={errors.description?.message}
                  />
                )}
              />
              <div className="drawer-spacer"></div>
              <div className="date-time-box display-flex justify-content-between">
                <AppDatePicker
                  dateTimeKey="edit-edu-start-date"
                  submitted={formSubmitted}
                  value={dateValue}
                  onChange={(value) =>
                    handleDateChange(value, "", "start-date")
                  }
                  pickerLabel="Start date"
                  disabled={false}
                  disabledDate={(value) =>
                    disabledDates(value, "start-date", dateValue)
                  }
                />
                <AppTimePicker
                  dateTimeKey="edit-edu-start-time"
                  submitted={formSubmitted}
                  value={timeValue}
                  onChange={(value) =>
                    handleTimeChange(value, "", "start-time")
                  }
                  pickerLabel="Start time"
                  disabled={dateValue ? false : true}
                  disabledHours={() =>
                    disabledHours(undefined, dateValue, endDateValue, timeValue)
                  }
                  disabledMinutes={() =>
                    disabledMinutes(
                      undefined,
                      dateValue,
                      endDateValue,
                      timeValue,
                      reloadMinutes
                    )
                  }
                  onSelect={onSelectTime}
                />
              </div>
              <div className="drawer-spacer"></div>
              <div className="date-time-box display-flex justify-content-between">
                <AppDatePicker
                  dateTimeKey="edit-edu-end-date"
                  submitted={formSubmitted}
                  value={endDateValue}
                  onChange={(value) => handleDateChange(value, "", "end-date")}
                  pickerLabel="End date"
                  disabled={timeValue ? false : true}
                  disabledDate={(value) =>
                    disabledDates(value, "end-date", dateValue)
                  }
                />
                <AppTimePicker
                  dateTimeKey="edit-edu-end-time"
                  submitted={formSubmitted}
                  value={endTimeValue}
                  onChange={(value) => handleTimeChange(value, "", "end-time")}
                  pickerLabel="End time"
                  disabled={endDateValue ? false : true}
                  disabledHours={() =>
                    disabledHours(
                      "end-hours",
                      dateValue,
                      endDateValue,
                      timeValue
                    )
                  }
                  disabledMinutes={() =>
                    disabledMinutes(
                      "end-minutes",
                      dateValue,
                      endDateValue,
                      timeValue,
                      reloadMinutes
                    )
                  }
                  onSelect={(value) => onSelectTime(value, "end-minutes")}
                />
              </div>
              <div className="drawer-spacer"></div>
              <div className="display-flex justify-content-between date-time-box">
                <FilterSelect
                  key="edit-education-select-primary"
                  placeholder={t("EDUCATIONS.TEACHERS.PRIMARY")}
                  options={allTeachers.primary}
                  value={selectedPrimaryTeacher.id}
                  defaultValue={selectedPrimaryTeacher.id}
                  onChange={handlePrimaryTeacherChange}
                  requiredField={selectedPrimaryTeacher}
                  formSubmitted={formSubmitted}
                  errorMessage={t("EDUCATIONS.ADD_EDUCATION.ERROR_MESSAGE")}
                  selectLabel="Primary teacher"
                />
                <DropdownCheckbox
                  key="edit-education-select-secondary"
                  placeholder={t("EDUCATIONS.TEACHERS.SECONDARY")}
                  options={allTeachers.secondary}
                  value={selectedSecondaryTeacher.secondaryTeachers}
                  defaultValue={selectedSecondaryTeacher.secondaryTeachers}
                  educationCallback={secondaryTeacherCallback}
                  selectLabel="Secondary teacher"
                />
              </div>
              <div className="drawer-spacer"></div>
              <FilterSelect
                placeholder={t("EDUCATIONS.ADD_EDUCATION.CATEGORY")}
                options={educationCategories}
                defaultValue={selectedEducationCategory.id}
                value={selectedEducationCategory.id}
                onChange={handleEducationCategoryChnage}
                requiredField={selectedEducationCategory}
                formSubmitted={formSubmitted}
                errorMessage={t("EDUCATIONS.ADD_EDUCATION.ERROR_MESSAGE")}
                selectLabel="Education category"
              />
              <div className="drawer-spacer"></div>
              <div className="display-flex cv-applied">
                <div className="custom-mui-field">
                  <span className="radio-btn-label">
                    {t("EDUCATIONS.ADD_EDUCATION.CV_OBLIGATORY")}
                  </span>
                  <div className="radio-btn display-flex">
                    <label key="cv-yes">
                      <input
                        type="radio"
                        name="cv-obligatory"
                        value={true}
                        defaultChecked={cvRequest}
                        onChange={handleCvObligatory}
                      />
                      <span className="checkmark">
                        {t("ACTION_BUTTONS.YES")}
                      </span>
                    </label>
                    <label key="cv-no">
                      <input
                        type="radio"
                        name="cv-obligatory"
                        value={false}
                        defaultChecked={!cvRequest}
                        onChange={handleCvObligatory}
                      />
                      <span className="checkmark">
                        {t("ACTION_BUTTONS.NO")}
                      </span>
                    </label>
                  </div>
                </div>
                <div className="custom-mui-field radio-approvall">
                  <span className="radio-btn-label">
                    {t("EDUCATIONS.ADD_EDUCATION.APPROVALL")}
                  </span>
                  <div className="radio-btn display-flex">
                    <label key="approval-yes">
                      <input
                        type="radio"
                        name="needs-approval"
                        value={true}
                        defaultChecked={approvalRequest}
                        onChange={handleApprovall}
                      />
                      <span className="checkmark">
                        {t("ACTION_BUTTONS.YES")}
                      </span>
                    </label>

                    <label key="approval-no">
                      <input
                        type="radio"
                        name="needs-approval"
                        value={false}
                        defaultChecked={!approvalRequest}
                        onChange={handleApprovall}
                      />
                      <span className="checkmark">
                        {t("ACTION_BUTTONS.NO")}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="drawer-spacer"></div>
              <div className="custom-mui-field counter-field display-flex">
                <span className="radio-btn-label">
                  {t("EDUCATIONS.ADD_EDUCATION.MAX_PARTICIPANTS")}
                </span>
                <div className="display-flex counter-holder">
                  <div
                    className={`counter-action decrement decrement-${
                      maxNumber === 1 && "stop"
                    }`}
                    onClick={handleDecrement}
                  >
                    -
                  </div>
                  <span className="counter-action counter">{maxNumber}</span>
                  <div
                    className="counter-action decrement"
                    onClick={handleIncrement}
                  >
                    +
                  </div>
                </div>
              </div>
              <div className="drawer-spacer"></div>
              <div className="upload-input-holder">
                <UploadInput
                  key="edit-education-upload"
                  reference="add-education"
                  text="Cover image"
                  maxFile={2}
                  value={selectedFile}
                  onChoseFile={chooseFile}
                />
                {selectedFile?.image !== "" && (
                  <img
                    onClick={removeEducationImage}
                    className="trash"
                    src={trashIcon}
                    alt="Delete"
                  />
                )}
              </div>
              <ImageUploadInfo />
            </div>
            <button id="edit-education-btn" type="submit" hidden></button>
          </form>
        </div>
      </Drawer>
    </div>
  );
};

export default EditEducation;
