import React from "react";
import avatar from "assets/images/icons/avatar.svg";
import { Typography } from "@material-ui/core";
import "./css/qa-item.css";
import { generateFullName } from "utils/generateFullName";
import { showRoleName } from "utils/generateRoleName";
import { SanitizeHTML } from "utils/sanitizeQAData";
import { useTranslation } from "react-i18next";

const Answer = (props) => {
  const { t } = useTranslation();
  const { comment } = props;
  return (
    <div
      key={comment?.id}
      className="qa-item qa-answer width-100 display-flex align-items-start"
    >
      <div className="icon">
        <img className="width-100" src={avatar} alt="Avatar" />
      </div>
      <div className="comment-content-main comment-content width-100">
        <div className="comment-header display-flex align-items-center justify-content-between">
          <div className="user-info">
            <Typography variant="h5" color="textPrimary">
              {generateFullName(
                comment?.user?.firstName,
                comment?.user?.lastName
              )}
            </Typography>
            <Typography variant="body1" className="user-type">
              {showRoleName(comment, t)}
            </Typography>
          </div>
        </div>
        <div className="comment-body">
          <SanitizeHTML html={comment?.comment} />
        </div>
      </div>
    </div>
  );
};

export default Answer;
