import React from "react";
import imagePreview from "assets/images/icons/image-preview.svg";
import editIcon from "assets/images/icons/edit-education.svg";
import blockIcon from "assets/images/icons/block.svg";
import { Typography } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import { ROLE } from "utils/ROLES";
import { useTranslation } from "react-i18next";

const Header = (props) => {
  const { loggedRole, hubHeader, editMode } = props;
  const { t } = useTranslation();

  /**
   * Handle action from header
   *
   * @param {*} type => type of action
   */
  const headerAction = (type) => {
    editMode(type);
  };

  return (
    <div
      className={`hub-header width-100 padding-right-layout padding-left-layout display-flex align-items-center justify-content-between ${
        hubHeader?.picture === "" && "auto"
      }`}
      style={{
        backgroundImage: `url(${
          hubHeader?.picture !== "" ? hubHeader?.picture : imagePreview
        })`,
      }}
    >
      <div className="information">
        <div className="hub-info">
          {hubHeader?.logo !== "" ? (
            <img src={hubHeader?.logo} alt="Hub logo" />
          ) : (
            <p>Logo</p>
          )}
          <Typography variant="subtitle1">{hubHeader?.name}</Typography>
        </div>
        <div className="hub-actions display-flex align-items-center">
          <div
            className="action action-edit display-flex justify-content-center align-items-center"
            data-tip
            data-for="edit-hub"
            onClick={() => headerAction("edit")}
          >
            <img src={editIcon} alt="Edit" />
            <ReactTooltip
              className="custom-react-tooltip"
              id="edit-hub"
              place="right"
              effect="solid"
            >
              {t("HUBS.TOOLTIP.EDIT")}
            </ReactTooltip>
          </div>
          {loggedRole === ROLE.SUPER_ADMIN && (
            <div
              className="action action-block display-flex justify-content-center align-items-center"
              data-tip
              data-for="block-hub"
              onClick={() => headerAction("block")}
            >
              <img src={blockIcon} alt="Block" />
              <ReactTooltip
                className="custom-react-tooltip"
                id="block-hub"
                place="right"
                effect="solid"
              >
                {t("HUBS.TOOLTIP.BLOCK")}
              </ReactTooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
