import React from "react";
import { Card, Typography } from "@material-ui/core";
import avatar from "assets/images/icons/avatar.svg";
import block from "assets/images/icons/block.svg";
import "./css/teachers-card.css";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const TeachersCard = (props) => {
  const { teacher, openBlockStatus } = props;

  const { t } = useTranslation();

  /**
   * Allow click on block icon if teacher is not blocked
   *
   * @returns false if teacher is blocked or if clicked on button for action
   */
  const openModal = () => {
    if (teacher?.status.blocked) {
      return;
    }
    openBlockStatus(teacher);
  };

  return (
    <Card
      key={teacher?.id}
      className={`teacher-card ${
        teacher?.status?.blocked && "blocked-teacher"
      }`}
    >
      <div className="card-header justify-content-between display-flex align-items-start">
        <div className="display-flex">
          <img src={avatar} alt="avatar" className="avatar" />
          <div className="teacher-info">
            <Typography variant="h5">{teacher.fullName}</Typography>
            <Typography variant="body1" color={"textSecondary"}>
              {teacher.email}
            </Typography>
          </div>
        </div>
        <img
          src={block}
          alt="block-teacher"
          onClick={openModal}
          className="block-teacher-btn"
          data-tip
          data-for={teacher.id}
        />
        {!teacher.status.blocked && (
          <ReactTooltip
            className="custom-react-tooltip"
            id={teacher.id}
            place="top"
            effect="solid"
          >
            {t("TEACHERS.TOOLTIP.BLOCK")}
          </ReactTooltip>
        )}
      </div>
    </Card>
  );
};

export default TeachersCard;
