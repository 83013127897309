import firebase from "firebase/app";

import { APPLICATION_URL } from "utils/appUrl";
import { ROUTES } from "router/CONSTANTS";

import { PARTICIPANT_STATUS } from "utils/participantStatus";
import { NOTIFICATION_TYPE } from "utils/notificationType";

import { createParticipant } from "services/educationService";
import { handleFileUpload } from "services/uploadService";
import { updateUserDetails } from "services/userService";
import { createNewNotification } from "services/notificationService";

const joinEducation = (education, cv, isApprovalRequired) => {
  const userId = localStorage.getItem("uid");
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const appliedDate = firebase.firestore.Timestamp.fromDate(new Date());
  const approvalDate = isApprovalRequired ? "" : appliedDate;

  const participant = {
    userId: `users/${userId}`,
    fullName: `${currentUser?.firstName} ${currentUser?.lastName}`,
    appliedDate,
    approvalDate,
    approvalStatus: isApprovalRequired
      ? PARTICIPANT_STATUS.PENDING
      : PARTICIPANT_STATUS.APPROVED,
  };

  const newNotificationData = {
    sender: currentUser?.email,
    recepient: education.primEducator.id,
    title: education?.title,
    content: "",
    linkTo: `${APPLICATION_URL}${ROUTES.EDUCATIONS}/${education.id}`,
    educationId: education.id,
    timelineId: "",
    type: NOTIFICATION_TYPE.APPROVAL_PENDING,
    notificationDateTime: firebase.firestore.Timestamp.fromDate(new Date()),
    seen: false,
  };

  return new Promise(async (resolve, reject) => {
    try {
      if (cv?.name !== "") {
        const useCv = await handleFileUpload(cv, "students-cv");
        await updateUserDetails(userId, { cvLink: useCv });
      }
      await createParticipant(education.id, participant);

      // first notify primary educator on education
      await createNewNotification(newNotificationData);
      newNotificationData.sender = education.primEducator.email;
      newNotificationData.recepient = currentUser.id;
      newNotificationData.type = NOTIFICATION_TYPE.EDUCATION_APPLIED;
      newNotificationData.linkTo = `${APPLICATION_URL}${ROUTES.EDUCATIONS}`;
      // then notify participant
      await createNewNotification(newNotificationData);
      resolve("participant-added");
    } catch (error) {
      reject(error);
    }
  });
};

export { joinEducation };
