import React, { useEffect, useState } from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useEducation } from "pages/Educations/context/EducationContext";

import { getSingleTimeline } from "services/educationService";

const useStyles = makeStyles(() =>
  createStyles({
    participantsNumber: {
      width: "100%",
      position: "relative",
      color: "white",
      paddingLeft: "1rem",
      marginTop: ".5rem",
    },
  })
);

const EducationTimer = () => {
  const [timelineTime, setTimelineTime]: any = useState("00:00:00");

  const classes = useStyles();
  const { singleEducation } = useEducation();

  useEffect(() => {
    let myInterval: any;
    let isSubscribed = true;

    const timeline = localStorage.getItem("selectedTimeline");

    const timer = async () => {
      if (timeline !== null) {
        const currentTimeline = JSON.parse(timeline)?.id;

        if (isSubscribed) {
          const response = await getSingleTimeline(
            singleEducation?.id,
            currentTimeline
          );

          if (response.exists) {
            const timelineData = response.data();

            const TODAY_HOURS = moment(new Date());
            const timelineStartDate = moment(timelineData.scheduled.toDate());

            const hoursDifference = moment(timelineStartDate).diff(
              moment(TODAY_HOURS)
            );

            const duration = moment.duration(hoursDifference);

            const start = new Date(Math.abs(hoursDifference));

            if (
              duration.hours() <= 0 &&
              duration.minutes() <= 0 &&
              duration.seconds() <= 0
            ) {
              myInterval = setInterval(() => {
                start.setSeconds(start.getSeconds() + 1);

                const format = (n: number) => {
                  return n < 10 ? "0" + n : "" + n;
                };

                const timeSpent = `${format(start.getUTCHours())}:${format(
                  start.getUTCMinutes()
                )}:${format(start.getUTCSeconds())}`;

                setTimelineTime(timeSpent);
              }, 1000);
            } else {
              clearInterval(myInterval);
            }
          }
        }
      }
    };

    timer();

    return () => {
      console.log("cleanup interval");
      clearInterval(myInterval);
      isSubscribed = false;
    };
  }, [singleEducation]);

  return (
    <div className={classes.participantsNumber}>
      {timelineTime !== "00:00:00" && timelineTime}
    </div>
  );
};

export { EducationTimer };
