import React, { useState, useEffect, FormEvent } from "react";
import DeviceSelectionScreen from "./DeviceSelectionScreen/DeviceSelectionScreen";
import IntroContainer from "../IntroContainer/IntroContainer";
import MediaErrorSnackbar from "./MediaErrorSnackbar/MediaErrorSnackbar";
import RoomNameScreen from "./RoomNameScreen/RoomNameScreen";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens(props: any) {
  const { getAudioAndVideoTracks } = useVideoContext();
  const [step, setStep] = useState(Steps.roomNameStep);
  const { selectedRoomName, readableRoomName, loggedUser } = props;

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch((error) => {
        console.log("Error acquiring local media:");
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes("twil.io")) {
      window.history.replaceState(
        null,
        "",
        window.encodeURI(
          `/room/${selectedRoomName}${window.location.search || ""}`
        )
      );
    }
    setStep(Steps.deviceSelectionStep);
  };

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={loggedUser}
          roomName={selectedRoomName}
          readableRoomName={readableRoomName}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen
          name={loggedUser}
          roomName={selectedRoomName}
          readableRoomName={readableRoomName}
          setStep={setStep}
        />
      )}
    </IntroContainer>
  );
}
