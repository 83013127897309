import React from "react";
import eLearningLogo from "assets/images/app-logo.svg";
import { useHistory } from "react-router-dom";
import { ROUTES } from "router/CONSTANTS";

const Logo = () => {
  const history = useHistory();

  // style for logo block
  const logoStyle = {
    width: "300px",
    minWidth: "250px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  };

  /**
   * Click on logo and go to home
   */
  const goToHome = () => {
    history.push(ROUTES.EDUCATIONS);
  };

  return (
    <>
      <div onClick={goToHome} className="logo" style={logoStyle}>
        <img src={eLearningLogo} className="width-100" alt="E Learning" />
      </div>
    </>
  );
};

export default Logo;
