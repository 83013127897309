import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import "../css/SidebarDrawer.css";
import { Button, TextField } from "@material-ui/core";
import AppDatePicker from "components/AppDatePicker";
import AppTimePicker from "components/AppTimePicker";
import LoadingButton from "components/LoadingButton";
import { useForm } from "react-hook-form";
import DropdownCheckbox from "components/DropdownCheckbox";
import FilterSelect from "components/FilterSelect";
import ImageUploadInfo from "components/ImageUploadInfo";
import UploadInput from "components/UploadInput";
import {
  createChat,
  createNewEducation,
  editEducation,
} from "services/educationService";
import { handleFileUpload } from "services/uploadService";
import moment from "moment";
import { getTeachers } from "services/teacherService";
import { useAuth } from "auth/context/AuthContext";
import { getMyHub } from "services/hubService";
import { setSubcollectionBlocked } from "services/blockedStatusService";
import { categories } from "utils/category";
import firebase from "firebase/app";
import { useTranslation } from "react-i18next";
import { NOTIFICATION_TYPE } from "utils/notificationType";
import { createNewNotification } from "services/notificationService";
import { useSnackbar } from "contexts/SnackbarProvider";
import trashIcon from "assets/images/icons/trash.svg";
import {
  disabledDates,
  disabledHours,
  disabledMinutes,
} from "utils/dateTimeValidation";
import { APPLICATION_URL } from "utils/appUrl";
import { ROUTES } from "router/CONSTANTS";
import { useEducation } from "../context/EducationContext";

const AddEducation = (props) => {
  const { t } = useTranslation();
  const { isVisible, onClose } = props;
  const { currentUser } = useAuth();
  const educationCategories = categories(t);
  const { setAddedEducation } = useEducation();

  // states
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [startDateValue, setStartDateValue] = useState(false);
  const [startTimeValue, setStartTimeValue] = useState(false);
  const [startDateTimeValue, setStartDateTimeValue] = useState("");

  const [endDateValue, setEndDateValue] = useState(false);
  const [endTimeValue, setEndTimeValue] = useState(false);
  const [endDateTimeValue, setEndDateTimeValue] = useState("");

  const [selectedPrimaryTeacher, setSelectedPrimaryTeacher] = useState(null);
  const [selectedSecondaryTeacher, setSelectedSecondaryTeacher] = useState([]);
  const [selectedEducationCategory, setSelectedEducationCategory] =
    useState(null);
  const [allTeachers, setAllTeachers] = useState({
    primary: [],
    secondary: [],
  });
  const [myHubData, setMyHubData] = useState(null);
  const [maxNumber, setMaxNumber] = useState(1);
  const [selectedFile, setSelectedFile] = useState({ name: "" });
  const [cvRequest, setCvRequest] = useState(true);
  const [approvalRequest, setApprovalRequest] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [reloadMinutes, setIsReloadMinutes] = useState(false);

  const dispatch = useSnackbar();

  // useForm properties
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    let mounted = true;
    if (!currentUser) return;
    getTeachers()
      .then((teachers) => {
        if (mounted) {
          const teachersList = teachers.docs.map((doc) => {
            const teacher = {
              id: doc.data().id,
              name: `${doc.data().firstName} ${doc.data().lastName}`,
              email: doc.data().email,
            };
            return teacher;
          });
          setAllTeachers({ primary: teachersList, secondary: teachersList });
          localStorage.setItem(
            "eduFilterTeachers",
            JSON.stringify(teachersList)
          );
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
      });

    return () => {
      mounted = false;
      localStorage.removeItem("eduFilterTeachers");
    };
  }, [currentUser]);

  useEffect(() => {
    let myHubMounted = true;
    let myHub = null;
    if (!currentUser) return;
    if (myHubMounted) {
      (async () => {
        return await getMyHub(currentUser.email)
          .then((res) => {
            const hubId = res.docs[0].id;
            myHub = {
              ...res.docs[0].data(),
              hubId,
            };
            if (myHub) {
              setMyHubData(myHub);
            }
          })
          .catch((err) => console.log("Cannot get hub: ", err));
      })();
    }
    return () => {
      myHubMounted = false;
      myHub = null;
      setMyHubData(null);
    };
  }, [currentUser]);

  // Mockup data
  const cvObligatory = [
    {
      id: 1,
      name: "cv-obligatory",
      value: true,
      text: t("ACTION_BUTTONS.YES"),
    },
    {
      id: 2,
      name: "cv-obligatory",
      value: false,
      text: t("ACTION_BUTTONS.NO"),
    },
  ];

  // Mockup data
  const needsApprovall = [
    {
      id: 1,
      name: "needs-approval",
      value: true,
      text: t("ACTION_BUTTONS.YES"),
    },
    {
      id: 2,
      name: "needs-approval",
      value: false,
      text: t("ACTION_BUTTONS.NO"),
    },
  ];

  /**
   * Select date from date picker
   *
   * @param {*} value => selected date from date picker
   */
  const handleDateChange = (value, formatedDate, type) => {
    const dateString = new Date(moment(value).format("YYYY, MM, DD, HH:mm"));

    if (type === "start-date") {
      setStartDateValue(dateString);
      setStartDateTimeValue("");
      setStartTimeValue(false);
      setEndDateValue(false);
      setEndTimeValue(false);
      return;
    }

    setEndDateValue(dateString);
    setEndTimeValue(false);
    setEndDateTimeValue("");
  };

  /**
   * Select time from time picker
   *
   * @param {*} value => selected time from time picker
   */
  const handleTimeChange = (value, formatedTime, type) => {
    const timeString = new Date(moment(value).format("YYYY, MM, DD, HH:mm"));

    if (type === "start-time") {
      setStartTimeValue(timeString);
      setStartDateTimeValue("");
      setEndTimeValue(false);

      return;
    }

    setEndTimeValue(timeString);
    setEndDateTimeValue("");
  };

  /**
   * Select time
   *
   * @param {*} value => selected value
   */
  const onSelectTime = (value, type) => {
    const selectedValue = moment(new Date(value));
    const selectedStart = moment(new Date(startTimeValue));

    const selectedHour = moment(new Date(value)).hour();
    const isToday = moment(startDateValue).isSame(moment(), "date");
    const todayHour = moment(new Date()).hour();
    const isSameDay =
      moment(new Date(startDateValue)).date() ===
      moment(new Date(endDateValue)).date();
    const selectedStartTime = moment(new Date(startTimeValue)).hour();

    if (isToday) {
      if (selectedHour > todayHour) {
        setIsReloadMinutes(true);
      } else {
        setIsReloadMinutes(false);
      }
    }

    if (isSameDay) {
      if (type === "end-minutes") {
        if (selectedValue.hour() === selectedStart.hour()) {
          setEndTimeValue(selectedStart.add(5, "minutes"));
        } else {
          setEndTimeValue(false);
        }
      }

      if (selectedHour > selectedStartTime) {
        setIsReloadMinutes(true);
      } else {
        setIsReloadMinutes(false);
      }
    }
  };

  /**
   * Select primary teacher from list
   *
   * @param {*} category => selected category
   */
  const handlePrimaryTeacherChange = (primaryTeacher) => {
    const selected = allTeachers.primary.find(
      (teacher) => teacher.id === primaryTeacher
    );

    const teachersCopy = JSON.parse(localStorage.getItem("eduFilterTeachers"));
    const filterSecondary = teachersCopy.filter(
      (secondary) => secondary.id !== primaryTeacher
    );

    setAllTeachers({
      primary: allTeachers.primary,
      secondary: filterSecondary,
    });

    setSelectedPrimaryTeacher(selected);
  };

  /**
   * Select secondary teachers
   *
   * @param {*} data => selected data
   */
  const secondaryTeacherCallback = (data) => {
    const selected = allTeachers.secondary.filter((teacher) =>
      data.some((id) => teacher.id === id)
    );

    const teachersCopy = JSON.parse(localStorage.getItem("eduFilterTeachers"));

    const filterPrimary = teachersCopy.filter((t) => !data.includes(t.id));

    setAllTeachers({
      primary: filterPrimary,
      secondary: allTeachers.secondary,
    });

    setSelectedSecondaryTeacher(selected);
  };

  /**
   * Select education category from list
   *
   * @param {*} secondaryTeacher => selected education category
   */
  const handleEducationCategoryChnage = (category) => {
    const selected = educationCategories.find(
      (education) => education.id === category
    );
    setSelectedEducationCategory(selected);
  };

  /**
   * Handle CV obligatory
   *
   * @param {*} event => selected radio btn for cv obligatory
   */
  const handleCvObligatory = (event) => {
    const value = event.target.value === "true" ? true : false;
    setCvRequest(value);
  };

  /**
   * Handle education approvall
   *
   * @param {*} event => selected radio btn for approval
   */
  const handleApprovall = (event) => {
    const value = event.target.value === "true" ? true : false;
    setApprovalRequest(value);
  };

  /**
   * Handle counter increment
   */
  const handleIncrement = () => {
    if (maxNumber === 50) {
      return;
    }
    setMaxNumber(maxNumber + 1);
  };

  /**
   * Handle counter decrement
   *
   * @returns counter
   */
  const handleDecrement = () => {
    if (maxNumber === 1) {
      setMaxNumber(1);
      return;
    }
    setMaxNumber(maxNumber - 1);
  };

  /**
   * Set chosed file from input
   *
   * @param {*} file => selected file form input
   */
  const chooseFile = (file) => {
    setSelectedFile(file);
  };

  /**
   * Generate date string for firebase timestamp
   *
   * @param {*} date => selected date
   * @param {*} time => selected time
   * @returns modified date and time
   */
  const generateDateString = (date, time) => {
    const dateModified =
      typeof date === "string"
        ? date.split("-")
        : moment(date).format("DD,MM,YYYY").split(",");
    const timeModified =
      typeof time === "string"
        ? time.split(":")
        : moment(time).format("HH:mm").split(":");

    const month = +dateModified[1] - 1;

    return new Date(
      dateModified[2],
      month,
      dateModified[0],
      timeModified[0],
      timeModified[1]
    );
  };

  /**
   * Check values for particular fields
   *
   * @returns boolean
   */
  const nonFormFieldsValidation = () => {
    if (
      selectedEducationCategory &&
      selectedPrimaryTeacher &&
      startDateValue &&
      startTimeValue &&
      endDateValue &&
      endTimeValue
    ) {
      return true;
    }
    return false;
  };

  /**
   * Show snackbar on some action
   */
  const handleSnackbar = (message, type) => {
    dispatch({
      message: message,
      success: type,
    });
  };

  /***
   * Remove selected image on click trash icon
   */
  const removeEducationImage = () => {
    setSelectedFile({ name: "" });
  };

  /**
   * All fields valid, make request
   *
   * @param {*} data => form fields
   */
  const onSubmitForm = async (data) => {
    if (!nonFormFieldsValidation()) return;

    setIsLoading(true);

    let educationDate = startDateTimeValue;
    if (typeof startDateTimeValue === "string") {
      educationDate = firebase.firestore.Timestamp.fromDate(
        generateDateString(startDateValue, startTimeValue)
      );
    }

    let endEducationDate = endDateTimeValue;
    if (typeof endDateTimeValue === "string") {
      endEducationDate = firebase.firestore.Timestamp.fromDate(
        generateDateString(endDateValue, endTimeValue)
      );
    }

    let educators = selectedSecondaryTeacher.map((teacher) => teacher.id);
    educators.push(selectedPrimaryTeacher.id);

    const newEducationData = {
      ...data,
      cvRequest,
      approvalRequest,
      maxNumber,
      hub: {
        id: myHubData.hubId,
        name: myHubData.name,
      },
      link: "",
      picture: "",
      primEducator: selectedPrimaryTeacher,
      secondEducator: selectedSecondaryTeacher,
      educators,
      startDate: educationDate,
      endDate: endEducationDate,
      category: selectedEducationCategory,
      created_at: firebase.firestore.Timestamp.fromDate(new Date()),
    };

    // create new education
    try {
      await createNewEducation(newEducationData)
        .then(async (value) => {
          const educationId = value.id;
          await editEducation(educationId, { id: educationId });
          await setSubcollectionBlocked("educations", educationId, {
            status: false,
          });
          await createChat(educationId, { educationId });
          // if file selected do uploading and then update picture field for education
          if ("name" in selectedFile) {
            if (selectedFile.name !== "") {
              const addedImage = await handleFileUpload(
                selectedFile,
                "educations"
              );
              await editEducation(educationId, { picture: addedImage });
              setIsLoading(false);
            }
          }

          const notifyEducators = educators.map(async (educatorId) => {
            const newNotificationData = {
              sender: currentUser?.email,
              recepient: educatorId,
              title: newEducationData?.title,
              content: "",
              linkTo: `${APPLICATION_URL}${ROUTES.EDUCATIONS}/${educationId}`,
              educationId: educationId,
              timelineId: "",
              type: NOTIFICATION_TYPE.EDUCATION_ASSIGNED,
              notificationDateTime: firebase.firestore.Timestamp.fromDate(
                new Date()
              ),
              seen: false,
            };

            const educatorNotification = await createNewNotification(
              newNotificationData
            );

            return educatorNotification;
          });

          await Promise.all(notifyEducators);
          setIsLoading(false);
          onClose();
          handleSnackbar(t("EDUCATIONS.ADD_EDUCATION.SUCCESS"), true);
          setAddedEducation(true);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Cannot create education: ", err);
        });
    } catch (error) {
      setIsLoading(false);
      handleSnackbar(t("EDUCATIONS.ADD_EDUCATION.ERROR"), false);
      console.log("Error: ", error);
    }
  };

  /**
   * Submit form
   */
  const submitForm = () => {
    const form = document.querySelector("#add-education-btn");
    form.click();
    setFormSubmitted(true);
  };

  return (
    <div className="add-education-sidebar">
      <Drawer
        title="New education"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={isVisible}
        footer={
          <div className="drawer-footer display-flex align-items-center">
            <LoadingButton
              onClick={submitForm}
              className="auto-width font-weight-600 add-education-btn"
              isLoading={isLoading}
              text={t("ACTION_BUTTONS.CREATE")}
            />
            <Button
              variant="outlined"
              className="auto-width font-weight-600 add-education-btn"
              onClick={onClose}
            >
              {t("ACTION_BUTTONS.CANCEL")}
            </Button>
          </div>
        }
      >
        <div className="add-education-form">
          <form
            className="width-100"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(onSubmitForm)}
          >
            <div className="width-100%">
              <TextField
                type="text"
                name="title"
                label={t("EDUCATIONS.ADD_EDUCATION.NAME")}
                variant="outlined"
                {...register("title", {
                  required: {
                    value: true,
                    message: t("EDUCATIONS.ADD_EDUCATION.NAME_ERROR"),
                  },
                })}
                error={Boolean(errors.title)}
                helperText={errors.title?.message}
              />
              <div className="drawer-spacer"></div>
              <TextField
                type="text"
                name="description"
                label={t("EDUCATIONS.ADD_EDUCATION.DESCRIPTION")}
                variant="outlined"
                className="education-description"
                multiline
                minRows={4}
                maxRows={4}
                {...register("description", {
                  required: {
                    value: true,
                    message: t("EDUCATIONS.ADD_EDUCATION.DESCRIPTION_ERROR"),
                  },
                })}
                error={Boolean(errors.description)}
                helperText={errors.description?.message}
              />
              <div className="drawer-spacer"></div>
              <div className="date-time-box display-flex justify-content-between">
                <AppDatePicker
                  dateTimeKey="add-edu-start-date"
                  submitted={formSubmitted}
                  value={startDateValue}
                  onChange={(value) =>
                    handleDateChange(value, "", "start-date")
                  }
                  placeholder="Start date"
                  disabled={false}
                  disabledDate={(value) =>
                    disabledDates(value, "start-date", startDateValue)
                  }
                />
                <AppTimePicker
                  dateTimeKey="add-edu-start-time"
                  submitted={formSubmitted}
                  value={startTimeValue}
                  onChange={(value) =>
                    handleTimeChange(value, "", "start-time")
                  }
                  placeholder="Start time"
                  disabled={startDateValue ? false : true}
                  disabledHours={() =>
                    disabledHours(
                      undefined,
                      startDateValue,
                      endDateValue,
                      startTimeValue
                    )
                  }
                  disabledMinutes={() =>
                    disabledMinutes(
                      undefined,
                      startDateValue,
                      endDateValue,
                      startTimeValue,
                      reloadMinutes
                    )
                  }
                  onSelect={onSelectTime}
                />
              </div>
              <div className="drawer-spacer"></div>
              <div className="date-time-box display-flex justify-content-between">
                <AppDatePicker
                  dateTimeKey="add-edu-end-date"
                  submitted={formSubmitted}
                  value={endDateValue}
                  onChange={(value) => handleDateChange(value, "", "end-date")}
                  placeholder="End date"
                  disabled={startTimeValue ? false : true}
                  disabledDate={(value) =>
                    disabledDates(value, "end-date", startDateValue)
                  }
                />
                <AppTimePicker
                  dateTimeKey="add-edu-end-time"
                  submitted={formSubmitted}
                  value={endTimeValue}
                  onChange={(value) => handleTimeChange(value, "", "end-time")}
                  placeholder="End time"
                  disabled={endDateValue ? false : true}
                  disabledHours={() =>
                    disabledHours(
                      "end-hours",
                      startDateValue,
                      endDateValue,
                      startTimeValue
                    )
                  }
                  disabledMinutes={() =>
                    disabledMinutes(
                      "end-minutes",
                      startDateValue,
                      endDateValue,
                      startTimeValue,
                      reloadMinutes
                    )
                  }
                  onSelect={(value) => onSelectTime(value, "end-minutes")}
                />
              </div>
              <div className="drawer-spacer"></div>
              <div className="date-time-box display-flex justify-content-between">
                <FilterSelect
                  key="add-education-select-primary"
                  placeholder={t("EDUCATIONS.TEACHERS.PRIMARY")}
                  options={allTeachers.primary}
                  value={selectedPrimaryTeacher?.id}
                  onChange={handlePrimaryTeacherChange}
                  requiredField={selectedPrimaryTeacher}
                  formSubmitted={formSubmitted}
                  errorMessage={t("EDUCATIONS.ADD_EDUCATION.ERROR_MESSAGE")}
                />
                <DropdownCheckbox
                  key="add-education-select-secondary"
                  placeholder={t("EDUCATIONS.TEACHERS.SECONDARY")}
                  options={allTeachers.secondary}
                  defaultValue={[]}
                  educationCallback={secondaryTeacherCallback}
                />
              </div>
              <div className="drawer-spacer"></div>
              <FilterSelect
                key="add-education-select-category"
                placeholder={t("EDUCATIONS.ADD_EDUCATION.CATEGORY")}
                options={educationCategories}
                value={selectedEducationCategory?.id}
                onChange={handleEducationCategoryChnage}
                requiredField={selectedEducationCategory}
                formSubmitted={formSubmitted}
                errorMessage={t("EDUCATIONS.ADD_EDUCATION.ERROR_MESSAGE")}
              />
              <div className="drawer-spacer"></div>
              <div className="display-flex cv-applied">
                <div className="custom-mui-field">
                  <span className="radio-btn-label">
                    {t("EDUCATIONS.ADD_EDUCATION.CV_OBLIGATORY")}
                  </span>
                  <div className="radio-btn display-flex">
                    {cvObligatory.map((obligatory) => (
                      <label key={obligatory.id}>
                        <input
                          type="radio"
                          name={obligatory.name}
                          value={obligatory.value}
                          defaultChecked={obligatory.value}
                          onChange={handleCvObligatory}
                        />
                        <span className="checkmark">{obligatory.text}</span>
                      </label>
                    ))}
                  </div>
                </div>
                <div className="custom-mui-field radio-approvall">
                  <span className="radio-btn-label">
                    {t("EDUCATIONS.ADD_EDUCATION.APPROVALL")}
                  </span>
                  <div className="radio-btn display-flex">
                    {needsApprovall.map((approvall) => (
                      <label key={approvall.id}>
                        <input
                          type="radio"
                          name={approvall.name}
                          value={approvall.value}
                          defaultChecked={approvall.value}
                          onChange={handleApprovall}
                        />
                        <span className="checkmark">{approvall.text}</span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
              <div className="drawer-spacer"></div>
              <div className="custom-mui-field counter-field display-flex">
                <span className="radio-btn-label">
                  {t("EDUCATIONS.ADD_EDUCATION.MAX_PARTICIPANTS")}
                </span>
                <div className="display-flex counter-holder">
                  <div
                    className={`counter-action decrement decrement-${
                      maxNumber === 1 && "stop"
                    }`}
                    onClick={handleDecrement}
                  >
                    -
                  </div>
                  <span className="counter-action counter">{maxNumber}</span>
                  <div
                    className="counter-action decrement"
                    onClick={handleIncrement}
                  >
                    +
                  </div>
                </div>
              </div>
              <div className="drawer-spacer"></div>
              <div className="upload-input-holder">
                <UploadInput
                  key="add-education-image"
                  reference="add-education"
                  text="Cover image"
                  maxFile={2}
                  value={selectedFile}
                  onChoseFile={chooseFile}
                />
                {selectedFile.name !== "" && (
                  <img
                    onClick={removeEducationImage}
                    className="trash"
                    src={trashIcon}
                    alt="Delete"
                  />
                )}
              </div>
              <ImageUploadInfo />
            </div>
            <button id="add-education-btn" type="submit" hidden></button>
          </form>
        </div>
      </Drawer>
    </div>
  );
};

export default AddEducation;
