import React from "react";
import { Drawer } from "antd";
import Logo from "./Logo";
import { NavLinks } from "router/NavigationLinks";
import "./css/mobile-naviagation.css";
import closeDrawer from "assets/images/icons/close-modal.svg";
import Languages from "./Languages";
import Logout from "./Logout";
import { useAuth } from "../auth/context/AuthContext";
import { ROLE } from "utils/ROLES";

const MobileNavigation = (props) => {
  const { currentUser, loggedRole } = useAuth();

  const { isMobileVisible, onCloseMobile } = props;

  /**
   * Close mobile sidebar
   */
  const closeMobileNavigation = () => {
    onCloseMobile(false);
  };

  /**
   * Check logged role and show / hide navigation per logged role
   *
   * @returns boolean
   */
  const showNavigation = () => {
    if (loggedRole === ROLE.SUPER_ADMIN || loggedRole === ROLE.HUB_ADMIN) {
      return true;
    }
    return false;
  };

  return (
    <div className="mobile-navigation">
      <Drawer
        title="Navigation"
        placement="right"
        closable={true}
        visible={isMobileVisible}
        onClose={closeMobileNavigation}
        footer={<Logo />}
        className="mobile-drawer"
        closeIcon={<img src={closeDrawer} alt="Close" />}
        maskClosable={closeMobileNavigation}
      >
        <div className="mobile-links">
          {showNavigation() && <NavLinks />}
          <div className="mobile-separator"></div>
        </div>
        <div className="language-switcher-mobile">
          <Languages />
          <div className="mobile-separator"></div>
        </div>
        <Logout isMobile={true} />
      </Drawer>
    </div>
  );
};

export default MobileNavigation;
