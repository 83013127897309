import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "auth/context/AuthContext";
import { GET_USERS } from "services/CONSTANTS";
import { db } from "../../../firebase/firebase";
import { ROLE } from "utils/ROLES";

const TeacherContext = React.createContext();

export const useTeacher = () => {
  return useContext(TeacherContext);
};

const TeacherProvider = ({ children }) => {
  const { currentUser } = useAuth();

  const { t } = useTranslation();

  const [teachersList, setTeachersList] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    if (!currentUser) return;
    setIsDataLoading(true);

    const getAllStudents = db
      .collection(GET_USERS())
      .where("id", "!=", null)
      .where("type.roleId", "==", ROLE.TEACHER)
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          const userData = snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              fullName: `${doc.data().firstName} ${doc.data().lastName}`,
              hubName: doc.data()?.hub?.name,
              status: { subCollectionId: "", blocked: doc.data().blocked },
            };
          });

          if (userData) {
            setTeachersList(userData);
            setTimeout(() => {
              setIsDataLoading(false);
            }, 700);
          }
        } else {
          setIsDataLoading(false);
        }
      });

    return () => {
      getAllStudents();
    };
  }, [currentUser]);

  // table headers
  const headers = [
    { key: "name", value: t("TEACHERS.TABLE_HEADER.NAME"), sort: "firstName" },
    { key: "email", value: t("TEACHERS.TABLE_HEADER.EMAIL"), sort: "email" },
    { key: "hub", value: t("TEACHERS.TABLE_HEADER.HUB"), sort: "hubName" },
    {
      key: "primary_teacher",
      value: t("TEACHERS.TABLE_HEADER.PRIMARY"),
      sort: false,
    },
    {
      key: "secondary_teacher",
      value: t("TEACHERS.TABLE_HEADER.SECONDARY"),
      sort: false,
    },
    { key: "empty", value: "", sort: false },
  ];

  const data = {
    teachersList,
    headers,
    isDataLoading,
  };

  return (
    <TeacherContext.Provider value={data}>{children}</TeacherContext.Provider>
  );
};

export { TeacherProvider };
