import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import EndCallButton from "../Buttons/EndCallButton/EndCallButton";
import { isMobile } from "../../utils";
import useRoomState from "../../hooks/useRoomState/useRoomState";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { Typography, Grid, Hidden } from "@material-ui/core";
import ToggleAudioButton from "../Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "../Buttons/ToggleVideoButton/ToggleVideoButton";
import ToggleScreenShareButton from "../Buttons/ToogleScreenShareButton/ToggleScreenShareButton";
import ToggleChatButton from "../Buttons/ToggleChatButton/ToggleChatButton";
import { useTranslation } from "react-i18next";
import GoBackButton from "../Buttons/BackButton/BackButton";
import ShowDocumentsButton from "../Buttons/ShowDocuments/ShowDocuments";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: "fixed",
      display: "flex",
      padding: "0 1.43em",
      zIndex: 10,
      [theme.breakpoints.down("sm")]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
        overflowY: "auto",
      },
    },
    screenShareBanner: {
      position: "fixed",
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: "104px",
      background: "rgba(0, 0, 0, 0.5)",
      "& h6": {
        color: "white",
      },
      "& button": {
        background: "white",
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: "0 2em",
        "&:hover": {
          color: "#600101",
          border: `2px solid #600101`,
          background: "#FFE9E7",
        },
      },
    },
    hideMobile: {
      display: "initial",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === "reconnecting";
  const { t } = useTranslation();

  /**
   * Generate text for end call button
   *
   * @returns text for end call button
   */
  const generateEndButtonText = () => {
    const getIsPrimary = localStorage.getItem("isPrimaryTeacher");

    if (getIsPrimary === "true") {
      return t("TWILIO.END_LESSON");
    }

    return t("TWILIO.LEAVE_MEETING");
  };

  return (
    <>
      {isSharingScreen && (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.screenShareBanner}
        >
          <Typography variant="h6">{t("TWILIO.SHARING")}</Typography>
          <Button onClick={() => toggleScreenShare()}>
            {t("TWILIO.STOP_SHARE")}
          </Button>
        </Grid>
      )}
      <footer className={classes.container}>
        <Grid container justify="space-around" alignItems="center">
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              {/* <Typography variant="body1">{room!.name}</Typography> */}
              <GoBackButton goBackText={t("ACTION_BUTTONS.GO_BACK")} />
              <ShowDocumentsButton
                documentText={t("ACTION_BUTTONS.DOCUMENTS")}
              />
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container justify="center">
              <ToggleAudioButton
                disabled={isReconnecting}
                noAudio={t("TWILIO.NO_AUDIO")}
                mute={t("TWILIO.MUTE")}
                unMute={t("TWILIO.UN_MUTE")}
              />
              <ToggleVideoButton
                disabled={isReconnecting}
                noVideo={t("TWILIO.NO_VIDEO")}
                stopVideo={t("TWILIO.STOP_VIDEO")}
                playVideo={t("TWILIO.PLAY_VIDEO")}
              />
              {!isSharingScreen && !isMobile && (
                <ToggleScreenShareButton
                  disabled={isReconnecting}
                  shareText={t("TWILIO.SHARE_SCREEN")}
                  stopShare={t("TWILIO.STOP_SHARE")}
                  shareInProgress={t("TWILIO.SHARE_IN_PROGRESS")}
                  shareNotSupported={t("TWILIO.SHARE_NOT_SUPPORTED")}
                />
              )}
              <ToggleChatButton />
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid container justify="flex-end">
                <EndCallButton leaveText={generateEndButtonText()} />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}
