import React, { useState } from "react";
import "../css/Header.css";
import headerBackground from "assets/images/single-education-header-example.png";
import { Typography } from "@material-ui/core";
import { ROUTES } from "router/CONSTANTS";
import { Link } from "react-router-dom";
import rightArrow from "assets/images/icons/right-arrow-white.svg";
import calendarIcon from "assets/images/icons/calendar.svg";
import blockIcon from "assets/images/icons/block.svg";
import editIcon from "assets/images/icons/edit-education.svg";
import ReactTooltip from "react-tooltip";
import { useEducation } from "../context/EducationContext";
import showChat from "assets/images/icons/group-chat-icon.svg";
import infoIcon from "assets/images/icons/white-info.svg";
import { ROLE } from "utils/ROLES";
import imagePreview from "assets/images/icons/image-preview.svg";
import { getFormattedDate } from "utils/dateFormat";
import { useTranslation } from "react-i18next";
import { EDUCATION_CATEGORY } from "utils/category";
import { educationLabelText } from "utils/educationLabel";

const Header = (props) => {
  // get selected tab from Education Context
  const { selectTab, selectedTab } = useEducation();
  const { t } = useTranslation();

  const {
    educationHeader,
    chatWindow,
    editSidebar,
    role,
    isOtherEducation,
    isReadOnly,
    blockEducation,
    headerAction,
    headerDetails,
  } = props;

  /**
   * Handle header action
   *
   * @param {*} action => selected action can be join-education or start-lesson
   */
  const handleHeaderAction = (action) => {
    headerAction(action);
  };

  /**
   * Check when to show education label
   *
   * @returns boolean
   */
  const handleEducationStatusLabel = () => {
    if (role === ROLE.STUDENT && isOtherEducation && headerDetails.status) {
      return true;
    }
    return false;
  };

  /**
   * Check when to show button for join
   *
   * @returns boolean
   */
  const handleJoinEducationButton = () => {
    if (isOtherEducation && headerDetails.apply) {
      return true;
    }

    return false;
  };

  const handleSelectedTab = () => {
    if (role === ROLE.SUPER_ADMIN || role === ROLE.STUDENT) {
      if (selectedTab === 0) {
        return false;
      }
    } else {
      if (selectedTab === 2) {
        return false;
      }
    }

    return true;
  };

  return (
    <header
      className={`single-education display-flex ${
        educationHeader?.picture === "" && "auto"
      }`}
      style={{
        backgroundImage: `url(${
          educationHeader?.picture !== ""
            ? educationHeader?.picture
            : imagePreview
        })`,
      }}
    >
      <div
        className={`header-holder-block display-flex align-items-center justify-content-between ${
          role === ROLE.SUPER_ADMIN ? "justify-content-start" : null
        }`}
      >
        <div className="information">
          <div className="breadcrumbs display-flex align-items-center">
            <Link className="previous-page" to={ROUTES.EDUCATIONS}>
              {t("EDUCATIONS.TITLE")}
            </Link>
            <div className="breadcrumb-separator display-flex justify-content-center align-items-center">
              <img src={rightArrow} alt="Breadcrumb separator" />
            </div>
            <span className="current-page">{educationHeader?.title}</span>
          </div>
          <div className="education-information">
            <Typography variant="subtitle2">
              {educationHeader?.title}
            </Typography>
            <div className="additional-information display-flex align-items-center">
              <div className="lesson">
                {educationLabelText(educationHeader?.category?.id, t)}
              </div>
              {handleEducationStatusLabel() ? (
                <div className="lesson pending display-flex align-items-center">
                  <img src={infoIcon} alt="Pending" /> {t("EDUCATIONS.PENDING")}
                </div>
              ) : (
                <>
                  {!isReadOnly && role !== ROLE.TEACHER && (
                    <div className="date display-flex align-items-center">
                      <img src={calendarIcon} alt="Calendar" />
                      <span>
                        {getFormattedDate(educationHeader?.startDate, "-")}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className={`actions display-flex align-items-center ${
            role === ROLE.SUPER_ADMIN ? "super-admin-actions" : null
          }`}
        >
          {role !== ROLE.STUDENT ? (
            <>
              {role !== ROLE.TEACHER && (
                <div
                  onClick={blockEducation}
                  data-tip
                  data-for="blockEducation"
                  className="block-education education-action display-flex align-items-center justify-content-center"
                >
                  <img src={blockIcon} alt="Block education" />

                  <ReactTooltip
                    className="custom-react-tooltip"
                    id="blockEducation"
                    place="right"
                    effect="solid"
                  >
                    {t("EDUCATIONS.TOOLTIP.BLOCK")}
                  </ReactTooltip>
                </div>
              )}
              {role === ROLE.HUB_ADMIN && (
                <div
                  onClick={editSidebar}
                  data-tip
                  data-for="editEducation"
                  className="edit-education education-action display-flex align-items-center justify-content-center"
                >
                  <img src={editIcon} alt="Block education" />

                  <ReactTooltip
                    className="custom-react-tooltip"
                    id="editEducation"
                    place="right"
                    effect="solid"
                  >
                    {t("EDUCATIONS.TOOLTIP.EDIT")}
                  </ReactTooltip>
                </div>
              )}
              {handleSelectedTab() && (
                <>
                  {role !== ROLE.HUB_ADMIN && (
                    <div
                      onClick={() => handleHeaderAction("start-lesson")}
                      className="start-lesson"
                    >
                      {t("ACTION_BUTTONS.START_LESSON")}
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {handleJoinEducationButton() ? (
                <div
                  className="apply-now"
                  onClick={() => handleHeaderAction("join-education")}
                >
                  {t("ACTION_BUTTONS.APPLY_NOW")}
                </div>
              ) : (
                <>
                  {handleSelectedTab() && headerDetails.apply && (
                    <div
                      className="start-lesson"
                      onClick={() => handleHeaderAction("start-lesson")}
                    >
                      {t("ACTION_BUTTONS.START_LESSON")}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <div
          className="show-chat display-flex align-items-center justify-content-center"
          onClick={chatWindow}
        >
          <img src={showChat} alt="Group chat" />
        </div>
      </div>
    </header>
  );
};

export default Header;
