import React from "react";
import logoutIcon from "assets/images/icons/logout-icon.svg";
import { logoutUser } from "services/authService";
import { useTranslation } from "react-i18next";

const Logout = (props) => {
  const { isMobile } = props;

  const { t } = useTranslation();

  /**
   * Logout user from app
   */
  const logout = async () => {
    await logoutUser();
  };

  return (
    <>
      <div className="logout" style={{ cursor: "pointer" }} onClick={logout}>
        <img src={logoutIcon} alt="Logout" />
        {isMobile && <p>{t("ACTION_BUTTONS.LOGOUT")}</p>}
      </div>
    </>
  );
};

export default Logout;
