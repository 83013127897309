import React from "react";
import { TimePicker } from "antd";
import moment from "moment";
import "./css/app-date-time-picker.css";
import { useTranslation } from "react-i18next";

const AppTimePicker = (props) => {
  const {
    dateTimeKey,
    submitted,
    value,
    onChange,
    pickerLabel,
    placeholder,
    disabled,
    disabledHours,
    disabledMinutes,
    onSelect,
  } = props;

  const timeFormat = "HH:mm";
  const { t } = useTranslation();

  return (
    <div
      className={`app-date-time-picker ${
        submitted && !value && "picker-error"
      }`}
    >
      {pickerLabel && <label className="picker-label">{pickerLabel}</label>}

      <TimePicker
        // placeholder={t("DATE_PICKER.TIME_PICKER")}
        key={dateTimeKey}
        placeholder={placeholder}
        inputReadOnly={true}
        clearIcon={null}
        format={timeFormat}
        suffixIcon={null}
        minuteStep={5}
        value={value ? moment(value, timeFormat) : value}
        onChange={onChange}
        disabled={disabled}
        disabledHours={disabledHours}
        disabledMinutes={disabledMinutes}
        onSelect={onSelect}
        hideDisabledOptions={true}
        defaultValue={false}
      />
      {submitted && !value && (
        <p className="error-message">{t("DATE_PICKER.REQUIRED_TIME")}</p>
      )}
    </div>
  );
};

export default AppTimePicker;
