import { GET_BLOCKED, GET_USERS, INVITATIONS } from "./CONSTANTS";
import { db } from "../firebase/firebase";
import { ROLE } from "utils/ROLES";

// get user details
const getUserDetails = (uid) => {
  return new Promise((resolve, reject) => {
    try {
      db.collection(GET_USERS())
        .doc(uid)
        .get()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      console.log("ERROR: ", error);
      reject("SYSTEM ERROR");
    }
  });
};

// create new user
const createNewUser = (uid, user) => {
  return new Promise((resolve, reject) => {
    try {
      db.collection(GET_USERS())
        .doc(uid)
        .set(user)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      console.log("ERROR: ", error);
      console.log("SYSTEM ERROR");
    }
  });
};

// update user details
const updateUserDetails = (uid, user) => {
  return new Promise((resolve, reject) => {
    try {
      db.collection(GET_USERS())
        .doc(uid)
        .update(user)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      console.log("ERROR: ", error);
      reject("SYSTEM ERROR");
    }
  });
};

// get user status
const getUserStatus = (uid) => {
  return new Promise((resolve, reject) => {
    try {
      db.collection(GET_USERS())
        .doc(uid)
        .collection("blocked")
        .get()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// set subcollection for block status
const setSubcollectionBlocked = (uid, status) => {
  return new Promise((resolve, reject) => {
    try {
      db.collection(GET_USERS())
        .doc(uid)
        .collection(GET_BLOCKED())
        .add(status)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR");
    }
  });
};

// get all students
const getStudents = () => {
  return new Promise((resolve, reject) => {
    try {
      db.collection(GET_USERS())
        .where("id", "!=", null)
        .where("type.roleId", "==", ROLE.STUDENT)
        .get()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      console.log("SYSTEM ERROR");
      reject(error);
    }
  });
};

const createInvitation = (user) => {
  return new Promise(async (resolve, reject) => {
    try {
      const newInvitation = await db.collection(INVITATIONS()).add(user);
      resolve(newInvitation);
    } catch (error) {
      console.log("SYSTEM ERROR: ", error);
      reject(error);
    }
  });
};

const getUserBlockedStatus = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userRef = await db.collection(GET_USERS()).doc(id).get();
      const userRefBlocked = await userRef.ref.collection(GET_BLOCKED()).get();
      const data = userRefBlocked.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      resolve(data[0]);
    } catch (error) {
      reject(error);
      console.log("Cannot resolve request: ", error);
    }
  });
};

export {
  getUserDetails,
  createNewUser,
  updateUserDetails,
  getUserStatus,
  setSubcollectionBlocked,
  getStudents,
  createInvitation,
  getUserBlockedStatus,
};
