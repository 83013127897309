import React from "react";
import { Typography } from "@material-ui/core";

const PageTitle = ({ title }) => {
  const titleColor = {
    color: "#2C3033",
  };

  return (
    <Typography style={titleColor} variant="h1">
      {title}
    </Typography>
  );
};

export default PageTitle;
