import React, { useState, useEffect } from "react";
import LoadingButton from "components/LoadingButton";
import { useForm, Controller } from "react-hook-form";
import { Drawer } from "antd";
import { Button, TextField } from "@material-ui/core";
import FilterSelect from "components/FilterSelect";
import ImageUploadInfo from "components/ImageUploadInfo";
import UploadInput from "components/UploadInput";
import { countries } from "utils/country";
import { editHub } from "services/hubService";
import { useTranslation } from "react-i18next";
import { handleFileUpload } from "services/uploadService";
import { useSnackbar } from "contexts/SnackbarProvider";
import trashIcon from "assets/images/icons/trash.svg";

const EditSingleHub = (props) => {
  const { t } = useTranslation();

  const { isVisible, selectedHub, onClose } = props;
  const countriesSelection = countries(t);

  const [selectedCountry, setSelectedCountry] = useState(
    selectedHub.data?.address?.country
  );
  const [selectedHubLogo, setSelectedHubLogo] = useState({
    image: selectedHub.data?.logo,
    name: selectedHub.data?.logo,
  });
  const [selectedHubPicture, setSelectedHubPicture] = useState({
    image: selectedHub.data?.picture,
    name: selectedHub.data?.picture,
  });

  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const dispatch = useSnackbar();

  useEffect(() => {
    // get image name form url
    const getImageName = (image) => {
      if (image !== "") {
        return image.split("/").pop().split("?")[0].split("F")[1];
      }

      return "";
    };

    setSelectedHubLogo({
      image: selectedHub.data?.logo,
      name: getImageName(selectedHub.data?.logo),
    });

    setSelectedHubPicture({
      image: selectedHub.data?.picture,
      name: getImageName(selectedHub.data?.picture),
    });

    return () => {
      setSelectedHubLogo({ image: "", name: "" });
      setSelectedHubPicture({ image: "", name: "" });
      getImageName("");
    };
  }, [selectedHub]);

  // useForm properties
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  /**
   * Select country from list
   *
   * @param {*} country => selected country
   */
  const handleCountryChange = (countryId) => {
    const selected = countriesSelection.find(
      (country) => country.id === countryId
    );

    setSelectedCountry(selected);
  };

  /**
   * Set chosed file from input
   *
   * @param {*} file => selected file form input
   */
  const chooseFile = (file, reference) => {
    if (reference === "edit-hub-logo") {
      setSelectedHubLogo(file);
      return;
    }

    setSelectedHubPicture(file);
  };

  /**
   * Generate value for image hub image, can be string if not updated or new File
   *
   * @param {*} src => selected hub image
   * @returns full src for image (string / File)
   */
  const generateValueForImage = (src) => {
    let fullPath = src;
    if ("image" in fullPath) {
      if (fullPath.image !== "") {
        fullPath = src.image;
      } else {
        fullPath = "";
      }
    }
    return fullPath;
  };

  /**
   * Show snackbar on some action
   */
  const handleSnackbar = (message, type) => {
    dispatch({
      message: message,
      success: type,
    });
  };

  /***
   * Remove selected image on click trash icon
   */
  const removeHubImage = (type) => {
    if (type === "logo") {
      setSelectedHubLogo({ image: "", name: "" });
    } else {
      setSelectedHubPicture({ image: "", name: "" });
    }
  };

  /**
   * Submit form and proceed
   *
   * @param {*} data => HUB data
   */
  const onSubmitForm = async (data) => {
    // generate logo value
    let logo = generateValueForImage(selectedHubLogo);

    // generate picture value
    let picture = generateValueForImage(selectedHubPicture);

    // hub
    const hub = {
      address: {
        city: data.city === undefined ? "" : data.city,
        country: selectedCountry,
        street: data.street === undefined ? "" : data.street,
        zip: data.zip === undefined ? "" : data.zip,
      },
      phone: data.phone === undefined ? "" : data.phone,
      name: data.name,
      email: data.email === undefined ? "" : data.email,
      logo: typeof logo === "object" ? "" : logo,
      picture: typeof picture === "object" ? "" : picture,
    };

    try {
      setIsLoadingButton(true);
      await editHub(selectedHub?.data?.hubId, hub);
      // if new logo added update hub object
      if (typeof logo !== "string") {
        const updatedHubLogo = await handleFileUpload(selectedHubLogo, "hubs");
        hub.logo = updatedHubLogo;
      }
      // if new picture added update hub object
      if (typeof picture !== "string") {
        const updatedHubPicture = await handleFileUpload(
          selectedHubPicture,
          "hubs"
        );
        hub.picture = updatedHubPicture;
      }
      await editHub(selectedHub?.data?.hubId, hub);
      setIsLoadingButton(false);
      onClose();
      handleSnackbar(t("GENERAL_FEEDBACK.SUCCESS"), true);
    } catch (error) {
      setIsLoadingButton(false);
      handleSnackbar(t("GENERAL_FEEDBACK.ERROR"), false);
      console.log("Cannot edit hub: ", error);
    }
  };

  /**
   * Submit form
   */
  const submitForm = () => {
    const form = document.querySelector("#edit-hub-btn");
    form.click();
  };

  return (
    <div className="add-education-sidebar">
      <Drawer
        title="Edit HUB"
        placement="right"
        onClose={onClose}
        closable={false}
        visible={isVisible}
        footer={
          <div className="drawer-footer display-flex align-items-center">
            <LoadingButton
              onClick={submitForm}
              className="auto-width font-weight-600 add-education-btn"
              isLoading={isLoadingButton}
              text={t("ACTION_BUTTONS.SAVE")}
            />
            <Button
              onClick={onClose}
              variant="outlined"
              className="auto-width font-weight-600 edit-education-btn"
              disabled={isLoadingButton}
            >
              {t("ACTION_BUTTONS.CANCEL")}
            </Button>
          </div>
        }
      >
        <div className="add-education-form">
          <form
            className="width-100"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(onSubmitForm)}
          >
            <div className="form-block hubs-info">
              <Controller
                control={control}
                name="name"
                rules={{
                  required: {
                    value: true,
                    message: t("HUBS.ADD_HUB.NAME_ERROR"),
                  },
                }}
                defaultValue={selectedHub.data?.name}
                render={({ field: { onChange, ref } }) => (
                  <TextField
                    className="hub-input"
                    type="text"
                    label={t("HUBS.ADD_HUB.NAME")}
                    variant="outlined"
                    inputRef={ref}
                    onChange={onChange}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                  />
                )}
              />
              <div className="drawer-spacer"></div>
              <Controller
                control={control}
                name="email"
                rules={{
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: t("HUBS.ADD_HUB.EMAIL_ERROR"),
                  },
                }}
                defaultValue={selectedHub.data?.email}
                render={({ field: { onChange, ref } }) => (
                  <TextField
                    className="hub-input"
                    type="email"
                    label={t("HUBS.ADD_HUB.EMAIL")}
                    variant="outlined"
                    inputRef={ref}
                    onChange={onChange}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                  />
                )}
              />
              <div className="drawer-spacer"></div>
              <Controller
                control={control}
                name="street"
                defaultValue={selectedHub.data?.address.street}
                render={({ field: { onChange, ref } }) => (
                  <TextField
                    className="hub-input"
                    type="text"
                    label={t("HUBS.ADD_HUB.ADDRESS")}
                    variant="outlined"
                    inputRef={ref}
                    onChange={onChange}
                  />
                )}
              />
              <div className="drawer-spacer"></div>
              <div className="date-time-box justify-content-between display-flex">
                <FilterSelect
                  key="edit-hub-select-country"
                  placeholder={t("HUBS.ADD_HUB.COUNTRY")}
                  options={countriesSelection}
                  defaultValue={selectedCountry.id}
                  onChange={handleCountryChange}
                  filterStyle="hub-select"
                />
                <Controller
                  control={control}
                  name="city"
                  defaultValue={selectedHub.data.address.city}
                  render={({ field: { onChange, ref } }) => (
                    <TextField
                      className="input-city"
                      type="text"
                      label={t("HUBS.ADD_HUB.CITY")}
                      variant="outlined"
                      inputRef={ref}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="drawer-spacer"></div>
              <div className="date-time-box display-flex justify-content-between">
                <Controller
                  control={control}
                  name="zip"
                  defaultValue={selectedHub.data?.address.zip}
                  render={({ field: { onChange, ref } }) => (
                    <TextField
                      className="input-number"
                      type="number"
                      label={t("HUBS.ADD_HUB.POSTAL")}
                      variant="outlined"
                      inputRef={ref}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="phone"
                  defaultValue={selectedHub.data?.phone}
                  render={({ field: { onChange, ref } }) => (
                    <TextField
                      className="input-number"
                      type="number"
                      label={t("HUBS.ADD_HUB.PHONE")}
                      variant="outlined"
                      inputRef={ref}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="drawer-spacer"></div>
              <div className="upload-input-holder">
                <UploadInput
                  key="edit-hub-logo"
                  reference="edit-hub-logo"
                  text={t("HUBS.ADD_HUB.FILE_LABEL.LOGO")}
                  maxFile={2}
                  value={selectedHubLogo}
                  onChoseFile={chooseFile}
                />
                {selectedHubLogo?.name !== "" && (
                  <img
                    onClick={() => removeHubImage("logo")}
                    className="trash"
                    src={trashIcon}
                    alt="Delete"
                  />
                )}
              </div>

              <div className="drawer-spacer"></div>
              <div className="upload-input-holder">
                <UploadInput
                  key="edit-hub-picture"
                  reference="edit-hub-picture"
                  text={t("HUBS.ADD_HUB.FILE_LABEL.PICTURE")}
                  maxFile={2}
                  value={selectedHubPicture}
                  onChoseFile={chooseFile}
                />
                {selectedHubPicture?.name !== "" && (
                  <img
                    onClick={() => removeHubImage("picture")}
                    className="trash"
                    src={trashIcon}
                    alt="Delete"
                  />
                )}
              </div>
              <div className="drawer-spacer"></div>
              <ImageUploadInfo />
            </div>
            <button id="edit-hub-btn" type="submit" hidden></button>
          </form>
        </div>
      </Drawer>
    </div>
  );
};

export default EditSingleHub;
