import React from "react";
import {
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  InputLabel,
  Theme,
} from "@material-ui/core";
import { useAppState } from "../../../state";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: "1em",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1.5em 0 3.5em",
    "& div:not(:last-child)": {
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "1.5em 0 2em",
    },
  },
  textFieldContainer: {
    width: "100%",
  },
  continueButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

// interface RoomNameScreenProps {
//   name: string;
//   roomName: string;
//   setName: (name: string) => void;
//   setRoomName: (roomName: string) => void;
//   handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
// }

export default function RoomNameScreen({
  name,
  roomName,
  readableRoomName,
  handleSubmit,
}: any) {
  const classes = useStyles();
  const { user } = useAppState();
  const { t } = useTranslation();

  const hasUsername =
    !window.location.search.includes("customIdentity=true") &&
    user?.displayName;

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        {t("TWILIO.JOIN_ROOM")}
      </Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          {!hasUsername && (
            <div className={classes.textFieldContainer}>
              <InputLabel shrink htmlFor="input-user-name">
                {t("TWILIO.YOUR_NAME")}
              </InputLabel>
              <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                value={name}
                inputProps={{
                  "aria-readonly": true,
                  readOnly: true,
                }}
              />
            </div>
          )}
          <div
            className={classes.textFieldContainer}
            style={{ display: "none" }}
          >
            <InputLabel shrink htmlFor="input-room-name">
              {t("TWILIO.ROOM_NAME")}
            </InputLabel>
            <TextField
              autoCapitalize="false"
              id="input-room-name"
              variant="outlined"
              fullWidth
              size="small"
              value={roomName}
              inputProps={{
                "aria-readonly": true,
                readOnly: true,
              }}
            />
          </div>
          <div className={classes.textFieldContainer}>
            <InputLabel shrink htmlFor="input-room-name-readable">
              {t("TWILIO.ROOM_NAME")}
            </InputLabel>
            <TextField
              autoCapitalize="false"
              id="input-room-name-readable"
              variant="outlined"
              fullWidth
              size="small"
              value={readableRoomName}
              inputProps={{
                "aria-readonly": true,
                readOnly: true,
              }}
            />
          </div>
        </div>
        <Grid container justify="flex-end">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!name || !roomName}
            className={classes.continueButton}
          >
            {t("TWILIO.CONTINUE")}
          </Button>
        </Grid>
      </form>
    </>
  );
}
