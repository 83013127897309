import React, { useState, useEffect } from "react";
import "./css/timeline.css";
import TimelineAppItem from "components/TimelineItem";
import PageTitle from "components/PageTitle";
import Timeline from "@material-ui/lab/Timeline";
import { Button } from "@material-ui/core";
import addEventIcon from "assets/images/icons/plus.svg";
import AppModal from "components/AppModal";
import AddEvent from "../AddEvent";
import EditEvent from "../EditEvent";
import { ROLE } from "utils/ROLES";
import DefaultTeacher from "components/DefaultTeacher";
import PreventStartLesson from "components/PreventStartLesson";
import { useAuth } from "auth/context/AuthContext";
import { db } from "../../../../firebase/firebase";
import firebase from "firebase/app";
import { EDUCATIONS, TIMELINE } from "services/CONSTANTS";
import {
  generateTimestampForTimeline,
  getFormattedDate,
  getFormattedTime,
} from "utils/dateFormat";
import moment from "moment";
import { TIMELINE_STATUS } from "utils/timelineStatus";
import {
  createTimeline,
  deleteTimeline,
  editTimeline,
} from "services/educationService";
import ConfirmationModalContent from "components/ConfirmationModalContent";
import { useEducation } from "pages/Educations/context/EducationContext";
import { useTranslation } from "react-i18next";
import JoinLesson from "components/JoinLesson";
import { generateFullName } from "utils/generateFullName";

import { MuiThemeProvider, styled, Theme } from "@material-ui/core/styles";
import useHeight from "pages/Twilio/hooks/useHeight/useHeight";

import Room from "pages/Twilio/components/Room/Room";
import useRoomState from "pages/Twilio/hooks/useRoomState/useRoomState";
import MobileTopMenuBar from "pages/Twilio/components/MobileTopMenuBar/MobileTopMenuBar";
import MenuBar from "pages/Twilio/components/MenuBar/MenuBar";
import ReconnectingNotification from "pages/Twilio/components/ReconnectingNotification/ReconnectingNotification";
import RecordingNotifications from "pages/Twilio/components/RecordingNotifications/RecordingNotifications";
import theme from "theme";
import UnsupportedBrowserWarning from "pages/Twilio/components/UnsupportedBrowserWarning/UnsupportedBrowserWarning";
import useVideoContext from "pages/Twilio/hooks/useVideoContext/useVideoContext";
import { useAppState } from "pages/Twilio/state";
import DataLoader from "components/DataLoader";

import EmptyState from "components/EmptyState";
import EmptyImage from "assets/images/empty-state/timeline.svg";
import EmptyImageOther from "assets/images/empty-state/timeline-other.svg";
import { ROUTES } from "router/CONSTANTS";
import { useSnackbar } from "contexts/SnackbarProvider";
import { EndLessonDialog } from "pages/Twilio/components/EndLessonDialog/EndLessonDialog";

const CourseTimeline = (props) => {
  const { educationId } = props;
  const { currentUser, loggedRole } = useAuth();
  const { t } = useTranslation();
  const {
    singleEducation,
    isStartLessonFromHeader,
    selectFromHeader,
    isLessonEnded,
    setLessonEndStatus,
  } = useEducation();

  const dispatch = useSnackbar();

  const roomState = useRoomState();
  const height = useHeight();

  const { isConnecting } = useVideoContext();
  const { isJoiningFinished } = useAppState();

  const [timelineData, setTimelineData] = useState([]);

  const { className, isReadOnly } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isStartLesson, setIsStartLesson] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState({ data: null });

  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const [isEditModal, setIsEditModal] = useState(false);

  const [isDateTimeError, setIsDateTimeError] = useState(false);

  const [selectedTimeline, setSelectedTimeline] = useState(null);

  const [isJoiningLessonVisible, setIsJoiningLessonVisible] = useState(0);

  const [loggedUser, setLoggedUser] = useState();
  const [roomName, setRoomName] = useState("");
  const [readableRoomName, setReadableRoomName] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(true);

  // props for confirmation delete modal
  const confirmationModalData = {
    type: "delete",
    actionButtonText: t("ACTION_BUTTONS.DELETE"),
    title: t("EDUCATIONS.TIMELINE.DELETE_EVENT.TITLE"),
    description: t("EDUCATIONS.TIMELINE.DELETE_EVENT.DESCRIPTION"),
  };

  useEffect(() => {
    if (!currentUser) return;

    if (loggedRole === null || loggedRole === undefined) return;

    if (!educationId) return;

    const today = moment(new Date());

    const user = JSON.parse(localStorage.getItem("user"));
    setLoggedUser(user);

    const getTimeline = db
      .collection(EDUCATIONS())
      .doc(educationId)
      .collection(TIMELINE())
      .orderBy("scheduled")
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          const timeline = snapshot?.docs.map((doc) => {
            const getScheduledTime = moment(doc.data().scheduled.toDate());
            const timeDifference = getScheduledTime.diff(today, "minutes");
            return {
              id: doc?.id,
              linkTo: doc.data().linkTo,
              status:
                timeDifference <= -30 || doc.data().ended
                  ? TIMELINE_STATUS.COMPLETED
                  : doc.data().status,
              name: doc.data().name,
              scheduled: doc.data().scheduled,
              timelineDate: getFormattedDate(doc.data()?.scheduled, "."),
              timelineTime: getFormattedTime(doc.data()?.scheduled),
              timeDifference,
            };
          });

          if (timeline) {
            const findFirst = timeline.find(
              (t) => t.status.id === TIMELINE_STATUS.PLANNED.id
            );

            if (findFirst !== undefined) {
              const findFirstPlanned = {
                ...findFirst,
                next: true,
              };

              const copyTimeline = timeline;
              copyTimeline[
                copyTimeline.findIndex((el) => el.id === findFirstPlanned.id)
              ] = findFirstPlanned;

              setTimelineData(copyTimeline);
              setIsDataLoading(false);

              if (isStartLessonFromHeader) {
                const nextLesson = document.querySelector(
                  ".select-next-lesson .start-lesson"
                );
                if (nextLesson) {
                  nextLesson.click();
                }
              }

              const search = window.location.href;

              if (search !== "") {
                const splitUrl = search.split("?")[1];
                const timelineId = new URLSearchParams(splitUrl).get("tid");
                if (timelineId !== null) {
                  const timelines = document.querySelectorAll(
                    ".single-timeline-event"
                  );

                  const matchTimeline = [...timelines].map(
                    (timelineElement) => {
                      let t;
                      const elementId = timelineElement.id.split("tid-")[1];

                      if (elementId === timelineId) {
                        t = timelineElement;
                      }

                      return t;
                    }
                  );

                  const matchedTimeline = matchTimeline.filter(
                    (values) => values !== undefined
                  )[0];

                  window.history.replaceState(
                    null,
                    "",
                    window.encodeURI(`/educations/${educationId}`)
                  );

                  matchedTimeline.classList.add("matched-url-timeline");

                  const matchedElementTopPosition =
                    matchedTimeline.getBoundingClientRect().y - 16;

                  window.scroll({
                    top: matchedElementTopPosition,
                    behavior: "smooth",
                  });

                  setTimeout(() => {
                    matchedTimeline.classList.remove("matched-url-timeline");
                  }, 3100);
                }
              }
            } else {
              setTimelineData(timeline);
              setIsDataLoading(false);
            }

            const currentUrl = window.location.href.split("?")[1];
            const timelineId = new URLSearchParams(currentUrl).get("for");
            const isEnded = new URLSearchParams(currentUrl).get("end");
            const isPrimaryTeacher = localStorage.getItem("isPrimaryTeacher");

            if (isLessonEnded) {
              if (timelineId !== null && isEnded !== null) {
                if (isPrimaryTeacher !== null && isPrimaryTeacher === "true") {
                  (async () => {
                    await editTimeline(educationId, timelineId, {
                      ended: true,
                    });
                    setTimeout(() => {
                      window.history.replaceState(
                        null,
                        "",
                        window.encodeURI(`/educations/${educationId}`)
                      );
                    }, 700);
                  })();
                }
              }
            }
          }
        } else {
          setTimelineData(snapshot.docs);
          setIsDataLoading(false);
        }
      });

    return () => {
      getTimeline();
    };
  }, [
    currentUser,
    loggedRole,
    educationId,
    singleEducation,
    isStartLessonFromHeader,
    isLessonEnded,
  ]);

  /**
   * Click on button and show modal for add new event
   */
  const showModal = () => {
    setIsModalVisible(true);
  };

  /**
   * Hide modal when this function is called
   */
  const hideModal = () => {
    handleDateTimeError(false);
    setIsModalVisible(false);
  };

  // show delete modal
  const showDeleteModal = (timeline) => {
    setSelectedTimeline(timeline);
    setIsDeleteModal(true);
  };

  // hide delete modal
  const hideDeleteModal = () => {
    setIsDeleteModal(false);
  };

  /**
   * Show edit modal
   *
   * @param {*} timeline => timeline for edit
   */
  const showEditModal = (timeline) => {
    setIsEditModal(true);
    setSelectedTimeline(timeline);
  };

  /**
   * Hide edit modal
   */
  const hideEditModal = () => {
    handleDateTimeError(false);
    setIsEditModal(false);
  };

  /**
   * Reset date time error
   *
   * @param {*} value => false
   */
  const handleDateTimeError = (value) => {
    setIsDateTimeError(value);
  };

  /**
   * Handle date validation
   *
   * @param {*} scheduledData => chosed date and time
   * @returns difference between two dates
   */
  const setDateTimeValidation = (scheduledData, mode, selected) => {
    const currentTimeline = timelineData;
    const formatScheduled = moment(scheduledData.toDate());
    const today = moment(new Date());

    let difference;

    if (currentTimeline.length > 0) {
      const firstPlannedTimeline = currentTimeline.find(
        (t) => t.status.id === TIMELINE_STATUS.PLANNED.id
      );

      if (firstPlannedTimeline !== undefined) {
        const formatTimelineDate = moment(
          firstPlannedTimeline.scheduled.toDate()
        );

        if (mode === "edit-event") {
          difference = formatScheduled.diff(today, "minutes");
          if (difference > 0) {
            if (!selected.next) {
              difference = formatScheduled.diff(formatTimelineDate, "minutes");
            }
          }
        } else {
          difference = formatScheduled.diff(formatTimelineDate, "minutes");
        }
      } else {
        const lastTimeline = currentTimeline[currentTimeline.length - 1];
        const formatLastTimelineDate = moment(lastTimeline.scheduled.toDate());

        difference = formatScheduled.diff(formatLastTimelineDate, "minutes");
      }
    } else {
      difference = formatScheduled.diff(today, "minutes");
    }

    return difference;
  };

  const Container = styled("div")({
    display: "grid",
    gridTemplateRows: "1fr auto",
  });

  const Main = styled("main")(({ theme }) => ({
    overflow: "hidden",
    paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
    background: "black",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
    },
  }));

  /**
   * Add event
   *
   * @param {*} formData => values from modal
   */
  const addEvent = async (formData) => {
    const scheduled = firebase.firestore.Timestamp.fromDate(
      generateTimestampForTimeline(formData)
    );

    const dateTimeDifference = setDateTimeValidation(scheduled, "add-event");

    if (dateTimeDifference <= 0) {
      setIsDateTimeError(true);
      return;
    }

    setIsDateTimeError(false);

    const newEvent = {
      name: formData.name,
      status: TIMELINE_STATUS.PLANNED,
      linkTo: "",
      scheduled,
    };

    setIsLoadingButton(true);

    try {
      await createTimeline(educationId, newEvent);
      setIsLoadingButton(false);
      hideModal();
    } catch (error) {
      console.log("Cannot create timeline: ", error);
      setIsLoadingButton(false);
    }
  };

  /**
   * Edit timeline
   *
   * @param {*} formData => data from from
   * @returns if date and time not good
   */
  const editEvent = async (formData) => {
    const scheduled = firebase.firestore.Timestamp.fromDate(
      generateTimestampForTimeline(formData)
    );

    const dateTimeDifference = setDateTimeValidation(
      scheduled,
      "edit-event",
      selectedTimeline
    );

    if (dateTimeDifference <= 0) {
      setIsDateTimeError(true);
      return;
    }

    setIsDateTimeError(false);

    const editedEvent = {
      name: formData.name,
      status: TIMELINE_STATUS.PLANNED,
      linkTo: "",
      scheduled,
    };

    setIsLoadingButton(true);

    try {
      await editTimeline(educationId, selectedTimeline.id, editedEvent);
      setIsLoadingButton(false);
      hideEditModal();
    } catch (error) {
      console.log("Cannot edit timeline: ", error);
      setIsLoadingButton(false);
    }
  };

  /**
   * Delete event
   */
  const deleteEvent = async () => {
    setIsLoadingButton(true);
    try {
      await deleteTimeline(educationId, selectedTimeline.id);
      setIsLoadingButton(false);
      hideDeleteModal();
    } catch (error) {
      console.log("Cannot delete timeline: ", error);
      setIsLoadingButton(false);
    }
  };

  /**
   * Show snackbar on some action
   */
  const handleSnackbar = (message, type) => {
    dispatch({
      message: message,
      success: type,
    });
  };

  /**
   * Copy timeline url
   *
   * @param {*} timeline => selected timeline
   */
  const copyLink = (timeline) => {
    setSelectedTimeline(timeline);
    const appUrl = window.location.href;

    const getInput = document.querySelector("#input-copy-link");
    getInput.value = `${appUrl}?tid=${timeline?.id}`;

    getInput.select();
    getInput.setSelectionRange(0, 99999);
    document.execCommand("copy");

    handleSnackbar(t("EDUCATIONS.TIMELINE.COPY_LINK_SUCCESS"), true);
  };

  /**
   * Close Prevent Lesson Modal
   */
  const closeStartLesson = () => {
    setIsStartLesson(false);
  };

  /**
   * Select lesson
   *
   * @param {*} timeline => timeline data
   */
  const openSelectedLesson = (timeline) => {
    setIsStartLesson(true);
    setSelectedLesson({ data: timeline });
  };

  /**
   * Show modal for joining
   * @param {*} timeline => selected timeline from list
   */
  const showJoinLessonModal = (timeline) => {
    localStorage.setItem("lessonChosed", "true");
    setIsJoiningLessonVisible((value) => value + 1);
    const roomName = timeline.id;
    const readable = timeline.name;
    setRoomName(roomName);
    setReadableRoomName(readable);
    localStorage.setItem("selectedTimeline", JSON.stringify(timeline));
    renderElements();
    selectFromHeader(false);
    setLessonEndStatus(false);
  };

  /**
   * Handle show / hide content
   * @returns boolean
   */
  const handleDataVisibility = () => {
    if (!isDataLoading && timelineData.length > 0) {
      return true;
    }
    return false;
  };

  /**
   * Empty state data
   * @returns text for empty state
   */
  const emptyStateData = () => {
    let data = {
      title: t("EDUCATIONS.TIMELINE.EMPTY_STATE.TITLE"),
      caption: t("EDUCATIONS.TIMELINE.EMPTY_STATE.DESCRIPTION"),
      buttonTitle: t("ACTION_BUTTONS.ADD_EVENT"),
      hasButton: true,
      image: EmptyImage,
      link: "/",
    };

    if (loggedRole !== ROLE.TEACHER) {
      data.title = t("EDUCATIONS.TIMELINE.EMPTY_STATE.TITLE_OTHER");
      data.caption = t("EDUCATIONS.TIMELINE.EMPTY_STATE.DESCRIPTION_OTHER");
      data.buttonTitle = t("ACTION_BUTTONS.GO_BACK_HOME");
      data.hasButton = true;
      data.image = EmptyImageOther;
      data.link = ROUTES.EDUCATIONS;
    }

    return data;
  };

  const handleEmptyState = () => {
    showModal();
  };

  const renderElements = () => {
    const lessonChosed = localStorage.getItem("lessonChosed");

    if (lessonChosed === "true" || isConnecting) {
      if (isJoiningLessonVisible > 0) {
        return (
          <AppModal
            isVisible={lessonChosed === "true" ? true : false}
            content={
              <JoinLesson
                selectedRoomName={roomName}
                readableRoomName={readableRoomName}
                loggedUser={generateFullName(
                  loggedUser.firstName,
                  loggedUser.lastName
                )}
              />
            }
          />
        );
      }
    }

    return (
      <>
        {roomState === "disconnected" ? (
          <>
            <input type="text" defaultValue="" id="input-copy-link" />
            {isReadOnly && (
              <div className="education-description">
                <PageTitle title={t("EDUCATIONS.TIMELINE.ABOUT")} />
                <p>{singleEducation?.description}</p>
              </div>
            )}
            {handleDataVisibility() && (
              <>
                <div className="timeline-header display-flex align-items-center justify-content-between">
                  <PageTitle title={t("EDUCATIONS.TIMELINE.TITLE")} />
                  {loggedRole === ROLE.TEACHER && (
                    <Button
                      className="add-event"
                      variant="outlined"
                      startIcon={<img src={addEventIcon} alt="Add event" />}
                      onClick={showModal}
                    >
                      {t("ACTION_BUTTONS.ADD_EVENT")}
                    </Button>
                  )}
                  <p className="swipe-for-more">{t("SWIPE")}</p>
                </div>
                <div className="course-timeline">
                  <Timeline>
                    {timelineData.map((timeline) => (
                      <TimelineAppItem
                        key={timeline.id}
                        timeline={timeline}
                        role={loggedRole}
                        isReadOnly={isReadOnly}
                        selectLesson={openSelectedLesson}
                        showDeleteModal={showDeleteModal}
                        showEditModal={showEditModal}
                        linkCopied={copyLink}
                        onJoinLesson={showJoinLessonModal}
                      />
                    ))}
                  </Timeline>
                </div>
              </>
            )}
            {isReadOnly && (
              <div className="education-teachers">
                <div className="primary-teachers">
                  <PageTitle title={t("EDUCATIONS.TIMELINE.PRIMARY_TEACHER")} />
                  <DefaultTeacher teacher={singleEducation?.primEducator} />
                </div>
                <div className="secondary-teachers">
                  <PageTitle title={t("EDUCATIONS.TEACHERS.SECONDARY")} />
                  <div className="display-flex secondary-content">
                    {singleEducation?.secondEducator?.map((teacher) => (
                      <DefaultTeacher teacher={teacher} key={teacher.id} />
                    ))}
                  </div>
                </div>
              </div>
            )}
            <AppModal
              isVisible={isModalVisible}
              content={
                <AddEvent
                  isLoadingButton={isLoadingButton}
                  addEvent={addEvent}
                  handleDateTimeError={handleDateTimeError}
                  isDateTimeError={isDateTimeError}
                  onCloseModal={hideModal}
                />
              }
            />
            <AppModal
              isVisible={isEditModal}
              content={
                <EditEvent
                  isLoadingButton={isLoadingButton}
                  editEvent={editEvent}
                  handleDateTimeError={handleDateTimeError}
                  isDateTimeError={isDateTimeError}
                  onCloseModal={hideEditModal}
                  timeline={selectedTimeline}
                />
              }
            />
            <AppModal
              isVisible={isDeleteModal}
              content={
                <ConfirmationModalContent
                  onConfirmAction={deleteEvent}
                  data={confirmationModalData}
                  isLoadingButton={isLoadingButton}
                  onCancel={hideDeleteModal}
                />
              }
            />
            <AppModal
              isVisible={isStartLesson}
              content={
                <PreventStartLesson
                  onCloseModal={closeStartLesson}
                  selectedLesson={selectedLesson}
                  onJoinLesson={showJoinLessonModal}
                />
              }
            />
          </>
        ) : (
          <UnsupportedBrowserWarning>
            <div className="video-conference-wrapper">
              <EndLessonDialog />
              <MuiThemeProvider
                theme={roomState !== "disconnected" ? theme : false}
              >
                <Container style={{ height }}>
                  <Main>
                    <ReconnectingNotification />
                    <RecordingNotifications />
                    <MobileTopMenuBar />
                    <Room />
                    <MenuBar />
                  </Main>
                </Container>
              </MuiThemeProvider>
            </div>
          </UnsupportedBrowserWarning>
        )}
      </>
    );
  };

  return (
    <div id="education-timeline" className={`${className}`}>
      {renderElements()}
      {isDataLoading && <DataLoader />}
      {!isDataLoading && timelineData.length <= 0 && !isReadOnly && (
        <EmptyState
          image={emptyStateData().image}
          title={emptyStateData().title}
          caption={emptyStateData().caption}
          hasButton={emptyStateData().hasButton}
          emptyStateAction={handleEmptyState}
          buttonTitle={emptyStateData().buttonTitle}
          link={emptyStateData().link}
        />
      )}
    </div>
  );
};

export default CourseTimeline;
