import React from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Button,
  Theme,
  Hidden,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import LocalVideoPreview from "./LocalVideoPreview/LocalVideoPreview";
import ToggleAudioButton from "../../Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "../../Buttons/ToggleVideoButton/ToggleVideoButton";
import { useAppState } from "../../../state";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: "1em",
  },
  marginTop: {
    marginTop: "1em",
  },
  deviceButton: {
    width: "100%",
    border: "1px solid #aaa",
    margin: "1em 0",
  },
  localPreviewContainer: {
    paddingRight: "2em",
    [theme.breakpoints.down("sm")]: {
      padding: "0em",
    },
  },
  joinButtons: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
      "& button": {
        margin: "0.5em 0",
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      margin: "1.5em 0 1em",
      flexDirection: "column",
    },
  },
  mobileButton: {
    padding: "0.8em 0",
    margin: 0,
    width: "48%",
    border: "1px solid #aaa",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:first-child": {
        marginBottom: "10px",
      },
    },
  },
  joiningLoader: {
    position: "relative",
    "& svg": {
      width: "100% !important",
      height: "100% !important",
    },
  },
}));

// interface DeviceSelectionScreenProps {
//   name: string;
//   roomName: string;
// }

export default function DeviceSelectionScreen({
  name,
  roomName,
  readableRoomName,
}: any) {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const {
    connect: videoConnect,
    isAcquiringLocalTracks,
    isConnecting,
  } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const { t } = useTranslation();

  const handleJoin = () => {
    getToken(name, roomName).then(({ token }) => {
      videoConnect(token);
      localStorage.removeItem("lessonChosed");
      //process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== "true";
    });
  };

  if (isFetching || isConnecting) {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        style={{ height: "100%" }}
      >
        <div className="joining-meeting-loader">
          <CircularProgress
            variant="indeterminate"
            className={classes.joiningLoader}
          />
        </div>
        <div>
          <Typography
            variant="body2"
            style={{ fontWeight: "bold", fontSize: "16px", marginTop: "20px" }}
          >
            {t("TWILIO.JOINING")}
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        {readableRoomName}
      </Typography>

      <Grid container justify="center">
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton
                className={classes.mobileButton}
                disabled={disableButtons}
                noAudio={t("TWILIO.NO_AUDIO")}
                mute={t("TWILIO.MUTE")}
                unMute={t("TWILIO.UN_MUTE")}
              />
              <ToggleVideoButton
                className={classes.mobileButton}
                disabled={disableButtons}
                noVideo={t("TWILIO.NO_VIDEO")}
                stopVideo={t("TWILIO.STOP_VIDEO")}
                playVideo={t("TWILIO.PLAY_VIDEO")}
              />
            </Hidden>
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid
            container
            direction="column"
            justify="space-between"
            style={{ height: "100%" }}
          >
            <div>
              <Hidden smDown>
                <ToggleAudioButton
                  className={classes.deviceButton}
                  disabled={disableButtons}
                  noAudio={t("TWILIO.NO_AUDIO")}
                  mute={t("TWILIO.MUTE")}
                  unMute={t("TWILIO.UN_MUTE")}
                />
                <ToggleVideoButton
                  className={classes.deviceButton}
                  disabled={disableButtons}
                  noVideo={t("TWILIO.NO_VIDEO")}
                  stopVideo={t("TWILIO.STOP_VIDEO")}
                  playVideo={t("TWILIO.PLAY_VIDEO")}
                />
              </Hidden>
            </div>
            <div className={classes.joinButtons}>
              <Button
                variant="contained"
                color="primary"
                data-cy-join-now
                onClick={handleJoin}
                disabled={disableButtons}
              >
                {t("TWILIO.JOIN_NOW")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
