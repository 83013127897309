import React from "react";
import Navigation from "components/Navigation";
import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "router/RouterConfig";
import { useAuth } from "../auth/context/AuthContext";
import Footer from "components/Footer";

const AppRouter = () => {
  // use auth
  const { currentUser } = useAuth();

  return (
    <>
      <BrowserRouter>
        {currentUser && <Navigation />}
        <RouterConfig />
        {currentUser && <Footer />}
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
