import React from "react";
import { HubProvider } from "../context/HubContext";
import List from "./List";

const Hubs = () => {
  return (
    <HubProvider>
      <List />
    </HubProvider>
  );
};

export default Hubs;
