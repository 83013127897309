import React, { useState, useEffect } from "react";
import "auth/css/auth.css";
import Logo from "components/Logo";
import Ilustration from "./Ilustration";
import TextField from "@material-ui/core/TextField";
import { Typography, InputAdornment } from "@material-ui/core";
import showPasswordEye from "assets/images/icons/password-eye-open.svg";
import { useHistory } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import { ROUTES } from "router/CONSTANTS";
import hidePasswordEye from "assets/images/icons/password-eye-closed.svg";
import { useForm } from "react-hook-form";
import LoadingButton from "components/LoadingButton";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "contexts/SnackbarProvider";
import { handleErrorMessages } from "utils/handleAuthErrors";

const ResetPassword = (props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const {
    location: { search },
  } = props;

  const code = new URLSearchParams(search).get("oobCode");

  // use auth
  const {
    resetPassword,
    verifyOobCode,
    authHeightState,
    setAuthContainerHeight,
  } = useAuth();

  useEffect(() => {
    setAuthContainerHeight();

    return () => {};
  }, [setAuthContainerHeight]);

  // use snackbar
  const dispatch = useSnackbar();

  /**
   * Show snackbar
   *
   * @param {*} message => message in snackbar
   * @param {*} type => type of snackbar (true/false)
   */
  const handleSnackbar = (message, type) => {
    dispatch({
      message: message,
      success: type,
    });
  };

  // states
  const [passwordType, setPasswordType] = useState(true);
  const [loading, setLoading] = useState(false);

  // useForm properties
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  /**
   * Check firebase verfication code
   *
   * @param {*} password => new password
   */
  const checkVerificationCode = async (password) => {
    try {
      setLoading(true);
      await verifyOobCode(code);
      resetPasswordRequest(code, password);
    } catch (error) {
      setLoading(false);
      handleSnackbar(handleErrorMessages(error, t), false);
    }
  };

  /**
   * Submit form when all fields are valid
   * @param {*} data => form fields
   */
  const onSubmitForm = (data) => {
    if (Object.entries(errors).length === 0) {
      checkVerificationCode(data.password);
    }
  };

  /**
   * Signup request to firebase
   * @param {*} email => user email
   * @param {*} password => user password
   */
  const resetPasswordRequest = async (code, password) => {
    try {
      await resetPassword(code, password);
      history.push(ROUTES.LOGIN);
    } catch (error) {
      console.log("Error from firebase: ", error);
      handleSnackbar(handleErrorMessages(error, t), false);
    }
    setLoading(false);
  };

  /**
   * Show / hide password on click eye icon
   * @param {*} type => can be true or false
   */
  const showHidePassword = (type) => {
    setPasswordType(!type);
  };

  return (
    <div className="auth-container" style={{ height: authHeightState }}>
      <div className="auth-content display-flex">
        <div className="auth-form">
          <div className="data-holder">
            <Logo />
            <div className="form-box">
              <Typography style={{ marginBottom: "12px" }} variant="subtitle2">
                {t("AUTH.FORGOT_PASSWORD.TITLE")}
              </Typography>
              <p style={{ marginBottom: "48px" }}>
                {t("AUTH.FORGOT_PASSWORD.DESCRIPTION")}
              </p>
              <form
                className="width-100"
                autoComplete="off"
                noValidate
                onSubmit={handleSubmit(onSubmitForm)}
              >
                <TextField
                  type={passwordType ? "password" : "text"}
                  label={t("AUTH.INPUTS.NEW_PASSWORD")}
                  name="password"
                  variant="outlined"
                  style={{ marginBottom: "20px" }}
                  {...register("password", {
                    required: {
                      value: true,
                      message: t("AUTH.ERROR.REQUIRED"),
                    },
                  })}
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          onClick={() => showHidePassword(passwordType)}
                          src={passwordType ? showPasswordEye : hidePasswordEye}
                          alt="Password"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  type={passwordType ? "password" : "text"}
                  label={t("AUTH.INPUTS.CONFIRM_PASSWORD")}
                  name="confirmPassword"
                  variant="outlined"
                  {...register("confirmPassword", {
                    required: {
                      value: true,
                      message: t("AUTH.ERROR.REQUIRED"),
                    },
                    validate: (value) =>
                      value === getValues("password") ||
                      t("AUTH.ERROR.CONFIRM_PASSWORD"),
                  })}
                  error={Boolean(errors.confirmPassword)}
                  helperText={errors.confirmPassword?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          onClick={() => showHidePassword(passwordType)}
                          src={passwordType ? showPasswordEye : hidePasswordEye}
                          alt="Password"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <LoadingButton
                  isLoading={loading}
                  text={t("ACTION_BUTTONS.RESET_PASSWORD")}
                />
              </form>
            </div>
          </div>
        </div>
        <Ilustration />
      </div>
    </div>
  );
};

export default ResetPassword;
