import sanitizeHtml from "sanitize-html";
import { Typography } from "@material-ui/core";
// options
const defaultOptions = {
  allowedTags: ["b", "i", "em", "strong", "a", "br", "p", "span"],
  allowedAttributes: {
    p: ["style"],
    span: ["style"],
  },
};

// sanitaze html tags
const sanitize = (dirty, options) => ({
  __html: sanitizeHtml(dirty, { ...defaultOptions, ...options }),
});

// Use this in render
const SanitizeHTML = ({ html, options }) => (
  <Typography
    variant="h5"
    className="comment-text"
    dangerouslySetInnerHTML={sanitize(html, options)}
  />
);

export { SanitizeHTML };
