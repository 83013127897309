import React from "react";
import "../css/HubAdminTeachers.css";
import TeachersCard from "components/TeachersCard";

const HubAdminTeachers = (props) => {
  //get teachers from Teacher Context
  const { teachers, openBlockStatus } = props;

  /**
   * Open Block Modal
   * @param {*} teacher => teacher
   */
  const openBlockModal = (teacher) => {
    openBlockStatus(teacher);
  };

  return (
    <div id="teachers-list">
      <div className="list display-flex flex-wrap">
        {teachers.map((teacher) => (
          <TeachersCard
            teacher={teacher}
            key={teacher.id}
            openBlockStatus={openBlockModal}
          />
        ))}
      </div>
    </div>
  );
};

export default HubAdminTeachers;
