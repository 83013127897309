import { GET_USERS, EDUCATIONS } from "./CONSTANTS";
import { db } from "../firebase/firebase";
import { ROLE } from "utils/ROLES";

// get teachers from users collection
const getTeachers = () => {
  return new Promise((resolve, reject) => {
    try {
      db.collection(GET_USERS())
        .where("id", "!=", null)
        .where("type.roleId", "==", ROLE.TEACHER)
        .get()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      console.log("SYSTEM ERROR");
      reject(error);
    }
  });
};

// get educations list for teacher like primary
const getEducationsLikePrimary = (teacherId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const educations = db
        .collection(EDUCATIONS())
        .where("primEducator.id", "==", teacherId)
        .get();

      const data = await educations;

      if (data.empty) return;

      resolve(data);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// get educations list for teacher like secondary
const getEducationsLikeSecondary = (teacherId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const educations = db
        .collection(EDUCATIONS())
        .where("primEducator.id", "!=", teacherId)
        .where("educators", "array-contains", teacherId)
        .get();

      const data = await educations;

      if (data.empty) return;

      resolve(data);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// get educators for filters
const getEducators = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const educatorsRef = await db
        .collection(GET_USERS())
        .where("id", "!=", null)
        .where("type.roleId", "==", ROLE.TEACHER)
        .get();

      const educators = educatorsRef.docs.map((doc) => {
        return {
          id: doc.id,
          name: `${doc.data().firstName} ${doc.data().lastName}`,
          ...doc.data(),
        };
      });

      resolve(educators);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR");
    }
  });
};

export {
  getTeachers,
  getEducationsLikePrimary,
  getEducationsLikeSecondary,
  getEducators,
};
