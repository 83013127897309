import i18n from "../i18n/i18n";

const NOTIFICATION_TYPE = {
  REGISTRATION_HUB_ADMIN: { id: 1, name: "Registered HUB Admin" },
  REGISTRATION_TEACHER: { id: 2, name: "Registered teacher" },
  VERIFICATION: { id: 3, name: "Verification" },
  PASSWORD_RESET: { id: 4, name: "Password reset" },
  EDUCATION_ASSIGNED: { id: 5, name: "Education assigned" },
  APPROVAL_PENDING: { id: 6, name: "Approval pending" },
  NEW_POST: { id: 7, name: "New post" },
  NEW_DOCUMENT_ADDED: { id: 8, name: "New document added" },
  EDUCATION_APPLIED: { id: 9, name: "Education applied" },
  EDUCATION_APPROVED: { id: 10, name: "Education approved" },
  EDUCATION_DISSAPROVED: { id: 11, name: "Education dissaproved" },
  EVENT_START: { id: 12, name: "Event start" },
  NEW_ASSIGNMENT: { id: 13, name: "New assignment" },
};

/**
 * Generate notificaiton text to user
 *
 * @param {*} notification => notification from list
 * @returns text
 */
const notificationText = (notification) => {
  let text = "";
  switch (notification.type.id) {
    case NOTIFICATION_TYPE.VERIFICATION.id:
      text = i18n.t("NOTIFICATIONS.VERIFY_PROFILE");
      break;

    case NOTIFICATION_TYPE.PASSWORD_RESET.id:
      text = i18n.t("NOTIFICATIONS.RESET_PASSWORD");
      break;

    case NOTIFICATION_TYPE.EDUCATION_ASSIGNED.id:
      text = `${i18n.t("NOTIFICATIONS.EDUCATION_ASSIGNED")} ${
        notification?.title
      }`;
      break;

    case NOTIFICATION_TYPE.APPROVAL_PENDING.id:
      text = `${i18n.t("NOTIFICATIONS.STUDENT_REQUEST")}`;
      break;

    case NOTIFICATION_TYPE.NEW_POST.id:
      text = `${i18n.t("NOTIFICATIONS.QA.WHO")} ${
        notification?.qa?.creator
      } ${i18n.t("NOTIFICATIONS.QA.ACTION")} ${notification?.title} ${i18n.t(
        "NOTIFICATIONS.QA.WHERE"
      )}`;

      break;

    case NOTIFICATION_TYPE.NEW_DOCUMENT_ADDED.id:
      text = `${i18n.t("NOTIFICATIONS.DOCUMENT.WHO")} ${
        notification?.document?.creator
      } ${i18n.t("NOTIFICATIONS.DOCUMENT.ACTION")} ${notification?.title}`;
      break;

    case NOTIFICATION_TYPE.EDUCATION_APPLIED.id:
      text = `${i18n.t("NOTIFICATIONS.STUDENT_APPLIED")} ${
        notification.title
      }. ${i18n.t("NOTIFICATIONS.STUDENT_APPLIED_STATUS")}`;
      break;

    case NOTIFICATION_TYPE.EDUCATION_APPROVED.id:
      text = `${i18n.t("NOTIFICATIONS.YOUR_APPLICATION")} ${
        notification.title
      } ${i18n.t("NOTIFICATIONS.REQUEST_APPROVED")}`;
      break;

    case NOTIFICATION_TYPE.EDUCATION_DISSAPROVED.id:
      text = `${i18n.t("NOTIFICATIONS.YOUR_APPLICATION")} ${
        notification.title
      } ${i18n.t("NOTIFICATIONS.REQUEST_DISSAPROVED")}`;
      break;

    case NOTIFICATION_TYPE.EVENT_START.id:
      text = `${i18n.t("NOTIFICATIONS.EVENT")} ${
        notification?.timelineName
      } ${i18n.t("NOTIFICATIONS.EVENT_ONE_HOUR")}`;
      break;

    default:
      text = i18n.t("NOTIFICATIONS.INVITED_TO_SYSTEM");
      break;
  }

  return text;
};

export { NOTIFICATION_TYPE, notificationText };
