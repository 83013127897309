import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import closeModal from "assets/images/icons/close-modal.svg";
import educationIcon from "assets/images/icons/plus-check-icon.svg";
import "../css/TeacherEducations.css";
import { useTranslation } from "react-i18next";
import {
  getEducationsLikePrimary,
  getEducationsLikeSecondary,
} from "services/teacherService";

const TeacherEducations = (props) => {
  const { teacher, teacherType, onCloseModal } = props;
  const [teacherEducations, setTeacherEducations] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    let serviceResponse;

    if (mounted && teacher) {
      (async () => {
        if (teacherType === "primary") {
          serviceResponse = await getEducationsLikePrimary(teacher.data.id);
        } else {
          serviceResponse = await getEducationsLikeSecondary(teacher.data.id);
        }

        if (serviceResponse) {
          const educations = serviceResponse.docs.map((doc) => doc.data());
          setTeacherEducations(educations);
        }
      })();
    }

    return () => {
      mounted = false;
      serviceResponse = null;
      setTeacherEducations([]);
    };
  }, [teacher, teacherType]);

  return (
    <div className="teacher-educations width-100">
      <div className="header">
        <Typography variant="h2">{teacher.data.fullName}</Typography>
        <p>
          {teacherType === "primary"
            ? t("TEACHERS.PRIMARY")
            : t("TEACHERS.SECONDARY")}
        </p>
        <img src={closeModal} alt="Close" onClick={onCloseModal} />
      </div>
      <div className="educations-list width-100">
        <ul className="width-100">
          {teacherEducations.length > 0 ? (
            <>
              {teacherEducations?.map((education) => (
                <li
                  key={education?.id}
                  className="display-flex align-items-center"
                >
                  <img src={educationIcon} alt="Icon" />
                  {education?.title}
                </li>
              ))}
            </>
          ) : (
            <li>{t("TEACHERS.NO_EDUCATIONS")}</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default TeacherEducations;
