import React, { useContext, useState, useEffect } from "react";
import { auth } from "../../firebase/firebase";
import { getUserDetails } from "services/userService";
import { getBlockedStatus } from "services/blockedStatusService";
import { logoutUser } from "services/authService";
import { useSnackbar } from "contexts/SnackbarProvider";
import { useTranslation } from "react-i18next";
import { handleErrorMessages } from "utils/handleAuthErrors";

const AuthContext = React.createContext();

// useAuth custom hook
export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [loggedRole, setLoggedRole] = useState(null);
  const { t } = useTranslation();

  const dispatch = useSnackbar();

  const [authHeightState, setAuthHeightState] = useState();

  useEffect(() => {
    // set user
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user !== null && user !== undefined) {
        localStorage.setItem("uid", user.uid);
      }

      if (user) {
        getUserDetails(user.uid)
          .then(async (res) => {
            const logged = res.data();
            localStorage.setItem("user", JSON.stringify(logged));
            try {
              const blockedStatus = await getBlockedStatus(logged.id);
              if (blockedStatus?.status) {
                localStorage.setItem("isUserBlocked", "true");
                await logoutUser();
                return;
              }
            } catch (error) {
              console.log("Cannot get blocked status: ", error);
            }
            setLoggedRole(logged?.type.roleId);
            localStorage.setItem("uType", logged?.type.roleId);
          })
          .catch((error) => {
            console.log("Cannot get logged user information: ", error);
          });
      } else {
        const isUserBlocked = localStorage.getItem("isUserBlocked");
        if (isUserBlocked === "true") {
          dispatch({
            message: t("AUTH.ERROR.BLOCKED_USER"),
            success: false,
          });
        }
        localStorage.removeItem("isUserBlocked");
        localStorage.removeItem("uType");
        setLoggedRole(null);
      }

      setLoading(false);
    });

    return unsubscribe;
  }, [dispatch, t]);

  const setAuthContainerHeight = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth > 480) {
      const bodyElement = document.querySelector("body");

      const getAuthContainer = setInterval(() => {
        const authContainerElement = document.querySelector(".auth-container");
        if (authContainerElement !== null) {
          clearInterval(getAuthContainer);
          const bodyHeight = bodyElement.clientHeight;
          const authHeight = authContainerElement.clientHeight;

          if (bodyHeight > authHeight) {
            setAuthHeightState(bodyHeight);
          } else {
            setAuthHeightState(authHeight);
          }
        }
      }, 400);
    }
  };

  // signup function
  const signUp = (email, password) => {
    return new Promise((resolve, reject) => {
      try {
        auth
          .createUserWithEmailAndPassword(email, password)
          .then((res) => {
            console.log("User registered");
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            dispatch({
              message: handleErrorMessages(err, t),
              success: false,
            });
            reject(err);
          });
      } catch (error) {
        console.log("SYSTEM ERROR: ", error);
        reject(error);
      }
    });
    // return auth.createUserWithEmailAndPassword(email, password);
  };

  // login function
  const login = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  // send email to user for reset password
  const sendEmailForResetPassword = (email) => {
    return auth.sendPasswordResetEmail(email);
  };

  // verify password reset code
  const verifyOobCode = (code) => {
    return auth.verifyPasswordResetCode(code);
  };

  // reset password
  const resetPassword = (code, newPassword) => {
    return auth.confirmPasswordReset(code, newPassword);
  };

  const data = {
    currentUser,
    login,
    signUp,
    sendEmailForResetPassword,
    resetPassword,
    verifyOobCode,
    loggedRole,
    authHeightState,
    setAuthContainerHeight,
  };

  return (
    <AuthContext.Provider value={data}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
