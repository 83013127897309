import { Button, TextField, Typography } from "@material-ui/core";
import React from "react";
import closeModal from "assets/images/icons/close-modal.svg";
import LoadingButton from "components/LoadingButton";
import { useForm } from "react-hook-form";
import "../css/AddTeacher.css";
import { useTranslation } from "react-i18next";

const AddTeacher = (props) => {
  const {
    isLoadingButton,
    addTeacher,
    onCloseModal,
    isUserExist,
    setUserExist,
  } = props;
  const { t } = useTranslation();

  // useForm properties
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Submit form and proceed
   */
  const onSubmitForm = (data) => {
    addTeacher(data);
  };

  const removeUserExistError = () => {
    setUserExist(false);
  };

  return (
    <div className="add-teacher">
      <div className="teacher-header display-flex align-items-center justify-content-between">
        <Typography variant="h1">{t("TEACHERS.ADD_TEACHER.NEW")}</Typography>
        <img src={closeModal} alt="Close modal" onClick={onCloseModal} />
      </div>
      <form
        className="width-100"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onSubmitForm)}
      >
        <div className="form-content width-100%">
          <TextField
            type="text"
            name="name"
            label={t("TEACHERS.ADD_TEACHER.FULL_NAME")}
            variant="outlined"
            style={{ marginBottom: "20px" }}
            {...register("name", {
              required: {
                value: true,
                message: t("TEACHERS.ADD_TEACHER.NAME_ERROR"),
              },
            })}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
          />
          <TextField
            type="email"
            name="email"
            label={t("TEACHERS.ADD_TEACHER.EMAIL")}
            variant="outlined"
            {...register("email", {
              required: t("TEACHERS.ADD_TEACHER.EMAIL_ERROR"),
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t("TEACHERS.ADD_TEACHER.VALID_EMAIL"),
              },
            })}
            onInput={removeUserExistError}
            error={Boolean(
              isUserExist && !errors.email ? isUserExist : errors.email
            )}
            helperText={
              isUserExist && !errors.email?.message
                ? t("HUBS.ADD_HUB.EMAIL_EXIST")
                : errors.email?.message
            }
          />
        </div>
        <div className="footer display-flex align-items-center">
          <LoadingButton
            className="font-weight-600"
            isLoading={isLoadingButton}
            text={t("ACTION_BUTTONS.ADD_TEACHER")}
          />
          <Button
            className="font-weight-600"
            variant="outlined"
            disabled={isLoadingButton}
            onClick={onCloseModal}
          >
            {t("ACTION_BUTTONS.CANCEL")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddTeacher;
