import React from "react";
import "./css/app-modal.css";

const AppModal = ({ isVisible, content, className }) => {
  const body = document.querySelector("body");
  body.classList.remove("overflow-hidden");

  if (isVisible) {
    body.classList.add("overflow-hidden");
  }

  return (
    <>
      {isVisible && (
        <div
          className={`app-modal display-flex justify-content-center align-items-start ${className}`}
        >
          <div className="app-modal-container">
            <div className="app-modal-content width-100">{content}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default AppModal;
