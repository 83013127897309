import { Typography } from "@material-ui/core";
import PageTitle from "components/PageTitle";
import React, { useEffect, useState, useCallback } from "react";
import avatar from "assets/images/icons/avatar.svg";
import "../css/Lesson.css";
import LoadingButton from "components/LoadingButton";
import SendMessageInput from "components/SendMessageInput";
import { useTranslation } from "react-i18next";
import AppModal from "components/AppModal";
import JoinLesson from "components/JoinLesson";
import { generateFullName } from "utils/generateFullName";
import { useParams } from "react-router";

import useHeight from "../../Twilio/hooks/useHeight/useHeight";
import useRoomState from "../../Twilio/hooks/useRoomState/useRoomState";

import Room from "../../Twilio/components/Room/Room";
import PreJoinScreens from "../../Twilio/components/PreJoinScreens/PreJoinScreens";

import { styled, Theme } from "@material-ui/core/styles";
import { useAppState } from "pages/Twilio/state";
import useVideoContext from "pages/Twilio/hooks/useVideoContext/useVideoContext";

const Container = styled("div")({
  display: "grid",
  gridTemplateRows: "1fr auto",
});

const Lesson = () => {
  const { id } = useParams();

  const roomState = useRoomState();
  const { getToken, isFetching } = useAppState();
  const {
    connect: videoConnect,
    isAcquiringLocalTracks,
    isConnecting,
  } = useVideoContext();
  const height = useHeight();

  const { t } = useTranslation();

  const participants = [
    { id: 1, name: "Mirko Novakovic", role: "Student" },
    { id: 2, name: "Bojan Djurdjevic", role: "Teacher" },
    { id: 3, name: "Branko Vasiljevic", role: "Student" },
    { id: 4, name: "Mirko Martic", role: "Student" },
    { id: 5, name: "Darko Bogojevic", role: "Teacher" },
    { id: 6, name: "Natasa Vukovic", role: "Student" },
  ];

  const messages = [
    {
      id: 1,
      author: "Mirko Novakovic",
      message:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis",
    },

    {
      id: 2,
      author: "Mirko Novakovic",
      message:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do sed do eiusmod tempor",
    },

    {
      id: 3,
      author: "Mirko Novakovic",
      message:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis",
    },

    {
      id: 4,
      author: "Mirko Novakovic",
      message:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
    },

    {
      id: 5,
      author: "Mirko Novakovic",
      message:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis",
    },

    {
      id: 6,
      author: "Mirko Novakovic",
      message:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis",
    },

    {
      id: 7,
      author: "Mirko Novakovic",
      message:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis",
    },
  ];

  const [chatMessages, setChatMessages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleJoin = useCallback(() => {
    if (!id) return;

    const roomName = id;
    const user = JSON.parse(localStorage.getItem("user"));
    const name = generateFullName(user.firstName, user.lastName);

    // getToken(name, roomName).then(({ token }) => {
    //   videoConnect(token);
    // });
  }, [id]);

  useEffect(() => {
    console.log("Mounted lesson component!");
    handleJoin();

    return () => {
      console.log("Unmounted lesson component");
    };
  }, [handleJoin]);

  useEffect(() => {
    // set dynamic max height for chat messages block
    const participantsBlock = document.querySelector(".lesson-participants");

    if (participantsBlock !== null) {
      const chatMessagesHeight = participantsBlock.offsetHeight + 500 - 64 - 44;
      setChatMessages(chatMessagesHeight);

      const chatMessagesBlock = document.querySelector(".chat-messages");
      setTimeout(() => {
        chatMessagesBlock.scroll({
          top: chatMessagesBlock.scrollHeight,
          behavior: "smooth",
        });
      }, 1000);

      return setChatMessages(chatMessagesHeight);
    }
  }, []);

  /**
   * Send new message for lesson
   *
   * @param {*} data => recieved message from component
   */
  const sendMessage = (data) => {
    setIsLoading(true);
    const { message } = data;

    // replace with real api request
    setTimeout(() => {
      console.log("New message: ", message);
      setIsLoading(false);
    }, 2000);
  };

  if (!isFetching || isConnecting) {
    return <AppModal isVisible={true} content={<JoinLesson />} />;
  }

  return (
    <div className="lesson-wrapper padding-left-layout padding-right-layout">
      <div className="lesson-content width-100 display-flex align-items-start justify-content-between">
        <div className="lesson">
          <div className="lesson-header display-flex align-items-center justify-content-between">
            <PageTitle title="Live lesson" />
            <div className="end-lesson">{t("ACTION_BUTTONS.END_LESSON")}</div>
          </div>
          <div className="video-lesson-wrapper width-100 display-flex align-items-center justify-content-center">
            <div className="video-lesson">
              <Container style={{ height }}>
                {roomState !== "disconnected" ? <Room /> : null}
              </Container>
            </div>
          </div>
          <div className="lesson-participants width-100">
            <Typography variant="h3">
              {t("EDUCATIONS.LIVE_LESSON.PARTICIPANTS")}
            </Typography>
            <div className="participants-list">
              {participants.map((participant) => (
                <div
                  key={participant.id}
                  className="participant display-flex align-items-center justify-content-between"
                >
                  <div className="info display-flex align-items-center">
                    <img src={avatar} alt="Participant" />
                    <div className="participant-data">
                      <Typography variant="h6" color="textPrimary">
                        {participant.name}
                      </Typography>
                      <p>{participant.role}</p>
                    </div>
                  </div>
                  <div className="participant-action display-flex align-items-center">
                    <p>
                      {" "}
                      {t("EDUCATIONS.LIVE_LESSON.PARTICIPANT.DESCRIPTION")}
                    </p>
                    <LoadingButton
                      isLoading={false}
                      text={t("ACTION_BUTTONS.ALLOW")}
                      className="allow-participant font-weight-600 auto-width"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="chat">
          <div className="chat-header">
            <Typography variant="h3">
              {t("EDUCATIONS.LIVE_LESSON.CHAT.TITLE")}
            </Typography>
          </div>
          <div className="chat-content width-100">
            <div className="chat-messages" style={{ maxHeight: chatMessages }}>
              {messages.map((message) => (
                <div key={message.id} className="message display-flex">
                  <img src={avatar} alt="Avatar" />
                  <div className="message-content">
                    <p>{message.author}</p>
                    <p className="message-text">{message.message}</p>
                    <span className="message-time">4:19PM</span>
                  </div>
                </div>
              ))}
            </div>
            <div className="new-message width-100">
              <SendMessageInput
                onSendMessage={sendMessage}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lesson;
