import React from "react";
import { Button, Card, CardMedia, Typography } from "@material-ui/core";
import "../css/HubCard.css";
import imagePreview from "assets/images/icons/image-preview.svg";
import editHub from "assets/images/icons/edit-education.svg";
import blockHub from "assets/images/icons/block.svg";
import unblockHub from "assets/images/icons/unblock.svg";
import { useHistory } from "react-router-dom";
import { ROUTES } from "router/CONSTANTS";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const HubCard = (props) => {
  const { hub, chooseSelectHub } = props;
  const { t } = useTranslation();
  /**
   * select HUB
   */
  const choseHub = (type) => {
    chooseSelectHub(hub, type);
  };

  let history = useHistory();

  /**
   * Check hub picture and show particular element
   *
   * @returns JSX element
   */
  const handleHubPicture = () => {
    if (hub?.picture !== "") {
      return <CardMedia image={hub?.picture} className="card-media" />;
    }

    return (
      <div className="card-media card-no-picture display-flex align-items-center justify-content-center">
        <img src={imagePreview} alt="Hub preview" />
        <Typography variant="subtitle1">{t("HUBS.IMAGE")}</Typography>
      </div>
    );
  };

  /**
   * Handle click for hub card
   *
   * @param {*} e => clicked area of div
   * @returns false if hub is blocked or if cliced on buttons for action
   */
  const openSingle = (e) => {
    if (hub.status.blocked) {
      return;
    }

    const clickedTarget = e.target;

    if (clickedTarget.classList.contains("hub-action")) {
      return;
    }

    history.push(`${ROUTES.HUBS}/${hub.hubId}`);
  };

  return (
    <Card
      key={hub?.hubId}
      className="hub-card display-flex"
      onClick={openSingle}
    >
      {handleHubPicture()}
      <div className="card-content">
        {hub?.status.blocked && <div className="blocked-hub"></div>}
        <div className="header display-flex justify-content-between">
          <Typography className="title">{hub?.name}</Typography>
          <div className="btn display-flex">
            {!hub?.status.blocked && (
              <Button
                onClick={() => choseHub("edit")}
                variant="outlined"
                className="hub-action"
              >
                <img
                  src={editHub}
                  alt="edit"
                  className="hub-action"
                  data-tip
                  data-for="edit-hub"
                />
                <ReactTooltip
                  className="custom-react-tooltip"
                  id="edit-hub"
                  place="right"
                  effect="solid"
                >
                  {t("HUBS.TOOLTIP.EDIT")}
                </ReactTooltip>
              </Button>
            )}
            <Button
              onClick={() => choseHub("block")}
              variant="outlined"
              className="hub-action"
            >
              <img
                src={hub?.status.blocked ? unblockHub : blockHub}
                alt="block"
                className="hub-action"
                data-tip
                data-for={`hubs-${hub?.hubId}`}
              />

              <ReactTooltip
                className="custom-react-tooltip"
                id={`hubs-${hub?.hubId}`}
                place="right"
                effect="solid"
              >
                {hub?.status.blocked
                  ? t("HUBS.TOOLTIP.UNBLOCK")
                  : t("HUBS.TOOLTIP.BLOCK")}
              </ReactTooltip>
            </Button>
          </div>
        </div>
        <div className="hub-info">
          <div className="admin-info">
            <Typography color="textSecondary">{t("HUBS.ADDRESS")}</Typography>

            <Typography variant="h4" color="textPrimary">
              {hub?.fullAddress}
            </Typography>
          </div>

          <div className="admin-info">
            <Typography color="textSecondary">{t("HUBS.ADMIN")}</Typography>

            <Typography variant="h4" color="textPrimary">
              {hub?.administrator?.name}
            </Typography>
          </div>

          <div className="admin-info">
            <Typography color="textSecondary">
              {t("HUBS.ADMIN_EMAIL")}
            </Typography>

            <Typography variant="h4" color="textPrimary">
              {hub?.administrator?.email}
            </Typography>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default HubCard;
