import React from "react";
import loader from "assets/images/icons/loader.svg";
import "./css/loader.css";

const DataLoader = (props) => {
  const { className } = props;

  return (
    <div className={`loader-data ${className && className}`}>
      <img src={loader} alt="Loader data" />
    </div>
  );
};

export default DataLoader;
