import React, { useState, useEffect } from "react";
import { Typography, Button } from "@material-ui/core";
import "./css/start-lesson.css";
import cancel from "assets/images/icons/close-modal.svg";
import clock from "assets/images/icons/grey-clock.svg";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PreventStartLesson = (props) => {
  const { onCloseModal, selectedLesson, onJoinLesson } = props;
  const [timeLeft, setTimeLeft] = useState();

  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  // Show time remaining
  useEffect(() => {
    const TODAY = moment(new Date());
    const TIME_END = "00:00:00";
    const timelineDate = moment(selectedLesson.data.scheduled.toDate());
    const monthDiff = timelineDate.diff(TODAY, "months");
    const dayDiff = timelineDate.diff(TODAY, "days");

    if (monthDiff > 0) {
      setTimeLeft(`${monthDiff} ${t("DATE_PICKER.MONTHS")}`);
      return;
    }

    if (dayDiff > 0) {
      setTimeLeft(`${dayDiff} ${t("DATE_PICKER.DAYS")}`);
      return;
    }

    // count hours
    const countHours = setInterval(() => {
      const TODAY_HOURS = moment(new Date());
      const lessonDate = moment(selectedLesson.data.scheduled.toDate());

      const hoursDifference = moment(lessonDate, "DD/MM/YYYY HH:mm:ss").diff(
        moment(TODAY_HOURS, "DD/MM/YYYY HH:mm:ss")
      );
      const duration = moment.duration(hoursDifference);
      const hours =
        Math.floor(duration.asHours()) < 10
          ? `0${Math.floor(duration.asHours())}`
          : Math.floor(duration.asHours());
      const minutes =
        duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes();
      const seconds =
        duration.seconds() < 10 ? `0${duration.seconds()}` : duration.seconds();

      const displayData = `${hours}:${minutes}:${seconds}`;

      if (displayData === TIME_END) {
        clearInterval(countHours);
        onCloseModal();
        onJoinLesson(selectedLesson.data);
        // history.push(`${id}/lesson/${selectedLesson.data.id}`);
        return;
      }

      setTimeLeft(displayData);
    });

    return () => clearInterval(countHours);
  }, [onCloseModal, selectedLesson, id, history, t, onJoinLesson]);

  return (
    <div className="start-lesson-modal">
      <div className="start-header">
        <div className="modal-top display-flex align-items-start justify-content-between">
          <Typography variant="h1">{selectedLesson.data.name}</Typography>
          <img
            src={cancel}
            alt="close-modal"
            className="close-lesson-modal"
            onClick={onCloseModal}
          />
        </div>
        <div className="display-flex">
          <img src={clock} alt="start-lesson-clock" className="clock" />
          <div className="countdown">
            <Typography color="textSecondary">
              {t("EDUCATIONS.TIMELINE.START_LESSON.STARTS_IN")}
            </Typography>
            <Typography variant="h3">{timeLeft}</Typography>
          </div>
        </div>
      </div>
      <div className="footer display-flex justify-content-start">
        <Button
          onClick={onCloseModal}
          variant="contained"
          color="primary"
          className="start-lesson-ok"
        >
          {t("ACTION_BUTTONS.OK")}
        </Button>
        <Button variant="outlined" onClick={onCloseModal}>
          {t("ACTION_BUTTONS.CANCEL")}
        </Button>
      </div>
    </div>
  );
};

export default PreventStartLesson;
