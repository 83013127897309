import { EDUCATION_CATEGORY } from "./category";

/**
 * Generate education label text
 * @param {*} educationCategoryId => category education id
 * @param {*} t => translate instance
 * @returns education label text
 */
const educationLabelText = (educationCategoryId, t) => {
  let label = "";
  switch (educationCategoryId) {
    case EDUCATION_CATEGORY.WEB.id:
      label = t("CATEGORIES.WEB_PROGRAMMING");
      break;

    case EDUCATION_CATEGORY.MOBILE.id:
      label = t("CATEGORIES.MOBILE_PROGRAMMING");
      break;

    case EDUCATION_CATEGORY.DESIGN.id:
      label = t("CATEGORIES.DESIGN");
      break;

    default:
      label = t("CATEGORIES.OTHER");
      break;
  }

  return label;
};

export { educationLabelText };
