import { Typography, Button } from "@material-ui/core";
import React from "react";
import blockIcon from "assets/images/icons/block-white.svg";
import deleteIcon from "assets/images/icons/delete-warning.svg";
import "./css/confirmation-modal-content.css";
import LoadingButton from "./LoadingButton";
import { useTranslation } from "react-i18next";

const ConfirmationModalContent = (props) => {
  const { data, isLoadingButton, onConfirmAction, onCancel } = props;
  const { t } = useTranslation();
  return (
    <div className="confirmation-modal-content">
      <div className="header display-flex align-items-start">
        <div
          className={`icon icon-${data.type} display-flex align-items-center justify-content-center`}
        >
          <img
            src={data.type === "block" ? blockIcon : deleteIcon}
            alt="Confirmation icon"
          />
        </div>
        <div className="information">
          <Typography variant="h1">{data.title}</Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            className="description"
          >
            {data.description}
          </Typography>
        </div>
      </div>
      <div className="footer confirmation-footer display-flex justify-content-center">
        <LoadingButton
          isLoading={isLoadingButton}
          text={data.actionButtonText}
          variant={data.variant || "outlined"}
          color={data.color || "default"}
          onClick={onConfirmAction}
          className="font-weight-600"
        />
        <Button
          variant="outlined"
          className="font-weight-600"
          onClick={onCancel}
        >
          {t("ACTION_BUTTONS.CANCEL")}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmationModalContent;
