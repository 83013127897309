import React, { useState } from "react";
import Switch from "@material-ui/core/Switch";
import ReactTooltip from "react-tooltip";
import "../css/Item.css";
import { useTranslation } from "react-i18next";

const Item = (props) => {
  // get selected tab from Student Context
  const { student, choseStudent } = props;
  const { t } = useTranslation();

  const [toggleState, setToggleState] = useState(false);

  /**
   * select Student
   */
  const selectStudent = () => {
    choseStudent(student);
  };

  /**
   * Handle toggle change event
   *
   * @param {*} event => selected toogle box
   */
  const handleToggleChange = (event) => {
    setToggleState({
      ...toggleState,
      [event.target.name]: event.target.checked,
    });
  };

  /**
   * Genereta full name for student per name and lastname
   *
   * @returns full name for student
   */
  const generateFullName = () => {
    return `${student?.firstName} ${student?.lastName}`;
  };

  return (
    <div
      key={student?.studentId}
      className={`student-item width-100 display-flex align-items-center ${
        student?.status?.blocked && "blocked-student"
      }`}
    >
      <div className="item-column">
        <p>{generateFullName()}</p>
      </div>
      <div className="item-column">
        <p>{student?.email}</p>
      </div>
      <div className="item-column">
        <div
          className="switcher"
          data-tip
          data-for={`switch-student-${student?.id}`}
          onClick={selectStudent}
        >
          <Switch
            checked={student?.status?.blocked}
            onChange={handleToggleChange}
            name={`student-${student?.id}`}
            color="default"
          />
          <ReactTooltip
            className="custom-react-tooltip"
            id={`switch-student-${student?.id}`}
            place="top"
            effect="solid"
          >
            {student?.status?.blocked
              ? t("STUDENTS.TOOLTIP.UNBLOCK")
              : t("STUDENTS.TOOLTIP.BLOCK")}
          </ReactTooltip>
        </div>
      </div>
    </div>
  );
};

export default Item;
