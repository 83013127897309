import React from "react";
import Item from "./Item";
import sortUp from "assets/images/icons/sort-arrow-up.svg";
import sortDown from "assets/images/icons/sort-arrow-down.svg";
import "../../../styles/css/custom-table.css";
import TeacherEducations from "./TeacherEducations";
import AppModal from "components/AppModal";

const SuperAdminTeachers = (props) => {
  const {
    teachers,
    headers,
    openBlockStatus,
    showTeacherEducations,
    sortedTeachers,
  } = props;

  /**
   * Open Block Modal
   * @param {*} teacher => teacher
   */
  const openBlockModal = (teacher) => {
    openBlockStatus(teacher);
  };

  /**
   * Open modal and show teacher for education
   *
   * @param {*} teacher => selected teacher
   * @param {*} type => type od teacher (primary / secndary)
   */
  const openTeacherEducations = (teacher, type) => {
    showTeacherEducations(teacher, type);
  };

  return (
    <div className="custom-table-list width-100">
      <div className="item-headers display-flex">
        {headers.map((header) => (
          <div
            key={header.key}
            className={`column-header column-header-${header.key} display-flex justify-content-between align-items-center`}
          >
            <p>{header.value}</p>
            {header.sort && (
              <div className="sort-icons display-flex flex-direction-column">
                <img
                  className="arrow-sort"
                  src={sortUp}
                  alt="Sort"
                  onClick={(element) =>
                    sortedTeachers(header.sort, "desc", element)
                  }
                />
                <img
                  className="arrow-sort"
                  src={sortDown}
                  alt="Sort"
                  onClick={(element) =>
                    sortedTeachers(header.sort, "asc", element)
                  }
                />
              </div>
            )}
          </div>
        ))}
      </div>

      {teachers.map((teacher) => (
        <Item
          openBlockStatus={openBlockModal}
          teacher={teacher}
          key={teacher.id}
          showTeacherEducations={openTeacherEducations}
        />
      ))}
    </div>
  );
};

export default SuperAdminTeachers;
