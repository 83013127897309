import React from "react";
import { Select } from "antd";
import "./css/filter-select.css";

const { Option } = Select;

const FilterSelect = (props) => {
  const {
    placeholder,
    options,
    defaultValue,
    value,
    onChange,
    filterStyle,
    requiredField,
    formSubmitted,
    errorMessage,
    selectLabel,
    disabledFilter,
  } = props;

  /**
   * Dispatch data to component
   *
   * @param {*} data => selected option
   */
  const dispatchSelected = (data) => {
    onChange(data);
  };

  /**
   * Check foor error if select component is in form
   *
   * @returns boolean
   */
  const checkError = () => {
    if (requiredField === null && formSubmitted) {
      return true;
    }
    return false;
  };

  return (
    <div
      className={`filter-select ${filterStyle} ${
        checkError() ? "error-on-select" : null
      }`}
    >
      {selectLabel && <label className="select-label">{selectLabel}</label>}
      <Select
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={dispatchSelected}
        disabled={disabledFilter}
      >
        {options.map((option) => (
          <Option key={option.id} value={option.id}>
            {option.name}
          </Option>
        ))}
      </Select>
      {checkError() && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default FilterSelect;
