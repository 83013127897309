// Routes in application
const ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_MODE: "/(\\?mode=.*)&",
  NOTIFICATIONS: "/notifications",
  EDUCATIONS: "/educations",
  EDUCATIONS_ID: "/educations/:id",
  HUBS: "/hubs",
  HUBS_ID: "/hubs/:id",
  MY_HUB: "/my-hub",
  TEACHERS: "/teachers",
  STUDENTS: "/students",
  LESSON: "/educations/:id/lesson/:id",
};

export { ROUTES };
