import SingleEducationTabBar from "components/SingleEducationTabBar";
import React, { useState, useEffect } from "react";
import Header from "./Header";
import EducationLiveChat from "components/EducationLiveChat";
import EditEducation from "./EditEducation";
import CourseTimeline from "./tabs/Timeline";
import { ROLE } from "utils/ROLES";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useAuth } from "auth/context/AuthContext";
import { useEducation } from "../context/EducationContext";
import { ROUTES } from "router/CONSTANTS";
import { updateBlockedStatus } from "services/blockedStatusService";
import { EDUCATIONS } from "services/CONSTANTS";
import ConfirmationModalContent from "components/ConfirmationModalContent";
import AppModal from "components/AppModal";
import "../css/SingleEducation.css";
import { useTranslation } from "react-i18next";
import { EDUCATION_STATUS } from "utils/educationStatus";
import JoinEducation from "./JoinEducation";
import { joinEducation } from "utils/educationJoin";
import { useSnackbar } from "contexts/SnackbarProvider";
import ApprovalModal from "./ApprovalModal";
import { PARTICIPANT_STATUS } from "utils/participantStatus";
import DataLoader from "components/DataLoader";
import { checkPrimEducatorForEducation } from "services/educationService";

const SingleEducation = () => {
  const {
    singleEducation,
    selectTab,
    selectFromHeader,
    singleEducationLoader,
    setEditedEducation,
  } = useEducation();
  const { loggedRole } = useAuth();
  const query = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();

  // states
  const [showChat, setShowChat] = useState(false);
  const [isEditSidebar, setIsEditSidebar] = useState(false);
  const [isOtherEducation, setIsOtherEducation] = useState(false);
  const [isReadOnlyEducation, setIsReadOnlyEducation] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isJoinVisible, setIsJoinVisible] = useState(false);
  const [selectedForJoining, setSelectedForJoining] = useState(null);
  const [joiningEducationDescription, setJoiningEducationDescription] =
    useState(null);
  const [approvalModalVisible, setApprovalModalVisible] = useState(false);

  const [headerDetails, setHeaderDetails] = useState({
    status: false,
    apply: true,
  });

  const [selectedTab, setSelectedTab] = useState(0);

  const confirmationModalData = {
    data: {
      type: "block",
      actionButtonText: t("ACTION_BUTTONS.BLOCK"),
      title: t("EDUCATIONS.BLOCK_EDUCATION.BLOCK"),
      description: `${t(
        "EDUCATIONS.SINGLE_EDUCATION.BLOCK_EDUCATION.PART_ONE"
      )} ${singleEducation?.title}? ${t(
        "EDUCATIONS.SINGLE_EDUCATION.BLOCK_EDUCATION.SECOND_PART"
      )}`,
    },
  };

  const dispatch = useSnackbar();

  useEffect(() => {
    const search = window.location.href;

    if (search !== "") {
      const splitUrl = search.split("?")[1];
      const timelineId = new URLSearchParams(splitUrl).get("tid");
      const educationTab = new URLSearchParams(splitUrl).get("tab");

      if (timelineId !== null && loggedRole === ROLE.TEACHER) {
        setSelectedTab(2);
        selectTab(2);
      }

      if (educationTab === "documents" && educationTab !== null) {
        if (loggedRole !== ROLE.TEACHER) {
          setSelectedTab(2);
          selectTab(2);
        } else {
          setSelectedTab(1);
          selectTab(1);
        }
      }
    }

    if (!singleEducation) return false;

    const loggedUser = localStorage.getItem("uid");

    if (singleEducation?.educationStatus === EDUCATION_STATUS.PLANNED.id) {
      setHeaderDetails({
        status: false,
        apply: false,
      });
      return;
    }

    if (loggedRole === ROLE.STUDENT) {
      if (singleEducation?.participants.length > 0) {
        const participants = singleEducation.participants
          .filter((participant) => {
            let matchedParticipant;
            const participantId = participant.userId.split("/")[1];
            if (participantId === loggedUser) {
              matchedParticipant = participant;
            }

            return matchedParticipant;
          })
          .map((item) => item.approvalStatus)[0];

        if (participants !== undefined) {
          if (participants.id !== PARTICIPANT_STATUS.APPROVED.id) {
            setHeaderDetails({
              status: true,
              apply: false,
            });
            return;
          }
        }
      }
    }
    return () => {};
  }, [singleEducation, loggedRole, selectTab]);

  useEffect(() => {
    // check if student is on single page for other education
    let mounted = true;
    if (mounted) {
      if (!singleEducation) return;

      if (singleEducation.status.blocked) {
        history.push(ROUTES.EDUCATIONS);
        return;
      }

      const isLoggedTeacherPrimary = async () => {
        const response = await checkPrimEducatorForEducation(
          singleEducation.id
        );

        const loggedUserId = JSON.parse(localStorage.getItem("user")).id;
        const primEducatorId = response.id;

        const isPrimary = loggedUserId === primEducatorId ? true : false;

        localStorage.setItem("isPrimaryTeacher", isPrimary);
      };

      isLoggedTeacherPrimary();

      if (loggedRole === ROLE.STUDENT) {
        const queryValue = query.search;
        if (queryValue.length > 0) {
          const splitQuery = query.search.split("?")[1].split("=");
          if (splitQuery[0] === "type" && splitQuery[1] === "other") {
            setIsOtherEducation(true);
          }
        }
      }
    }

    return () => {
      mounted = false;
      localStorage.removeItem("isPrimaryTeacher");
      localStorage.removeItem("lessonChosed");
      setIsOtherEducation(false);
    };
  }, [query, loggedRole, singleEducation, history]);

  useEffect(() => {
    localStorage.removeItem("lessonChosed");
    // check which users only has readonly access to single education page
    const readOnlyEducation = () => {
      if (!singleEducation) return;

      if (loggedRole === ROLE.STUDENT) {
        if (isOtherEducation) {
          setIsReadOnlyEducation(true);
          return;
        }
      }

      if (loggedRole === ROLE.HUB_ADMIN) {
        setIsReadOnlyEducation(true);
        return;
      }

      setIsReadOnlyEducation(false);
      return;
    };

    return readOnlyEducation();
  }, [isOtherEducation, loggedRole, singleEducation]);

  /**
   * Show snackbar on some action
   */
  const handleSnackbar = (message, type) => {
    dispatch({
      message: message,
      success: type,
    });
  };

  /**
   * Handle chat window appearance
   */
  const handleChatWindow = () => {
    setShowChat(!showChat);
  };

  /**
   * Show sidebar for edit education
   */
  const handleEditSidebar = () => {
    setIsEditSidebar(true);
  };

  /**
   * Hide sidebar for edit education
   */
  const hideEditSidebar = () => {
    setIsEditSidebar(false);
  };

  /**
   * Show modal
   */
  const showModal = () => {
    setIsModalVisible(true);
  };

  /**
   * Hide modal
   */
  const closeModal = () => {
    setIsModalVisible(false);
  };

  /**
   * Show joning modal
   *
   * @param {*} type => join or approval
   * @param {*} education => selected education
   * @returns which modal needs to be opened
   */
  const showJoiningModal = (type, education) => {
    if (type === "join") {
      setSelectedForJoining(education);
      const description = `${t("EDUCATIONS.JOIN_EDUCATION.CAPTION_PART_ONE")} ${
        education?.hub?.name
      }, ${t("EDUCATIONS.JOIN_EDUCATION.CAPTION_SECOND_PART")}`;
      setJoiningEducationDescription(description);
      setIsJoinVisible(true);
      return;
    }

    setApprovalModalVisible(true);
  };

  /**
   * Hide modal for joining on education
   *
   * @param {*} type => join or approval
   * @param {*} action => apply
   * @returns modal which needs to be hidden
   */
  const hideJoinigModal = (type, action) => {
    if (type === "join") {
      setIsJoinVisible(false);
      if (action === "apply") {
        showJoiningModal("approval");
      }
      return;
    }

    setApprovalModalVisible(false);
  };

  /**
   * Handle header action
   *
   * @param {*} action => selected action can be join-education or start-lesson
   */
  const handleHeaderAction = (action) => {
    if (loggedRole === ROLE.STUDENT && action === "join-education") {
      showJoiningModal("join", singleEducation);
    }

    if (action === "start-lesson") {
      selectFromHeader(true);
      const tabNumber = loggedRole !== ROLE.TEACHER ? 0 : 2;
      setSelectedTab(tabNumber);
      selectTab(tabNumber);
    }
  };

  /**
   * Proceed student request for joining
   *
   * @param {*} education => selected education
   * @param {*} cv => uploaded cv if required
   * @param {*} isApprovalRequired => return true or false
   */
  const joinEducationRequest = async (education, cv, isApprovalRequired) => {
    setIsLoadingButton(true);
    try {
      await joinEducation(education, cv, isApprovalRequired);
      setIsLoadingButton(false);
      hideJoinigModal("join", isApprovalRequired ? "apply" : "no-apply");
      setEditedEducation(true);
    } catch (error) {
      setIsLoadingButton(false);
      handleSnackbar("This action failed, an error ocured.", false);
      console.log("Cannot join participant: ", error);
    }
  };

  /**
   * Block education
   */
  const blockEducation = () => {
    setIsLoadingButton(true);
    updateBlockedStatus(
      EDUCATIONS(),
      singleEducation.id,
      singleEducation.status.subcollectionId,
      { status: true }
    )
      .then(() => {
        setIsLoadingButton(false);
        closeModal();
        history.push(ROUTES.EDUCATIONS);
      })
      .catch((err) => {
        setIsLoadingButton(false);
        console.log(err);
      });
  };

  const onTabChange = (event, newValue) => {
    if (newValue !== selectedTab) {
      setSelectedTab(newValue);
      selectTab(newValue);
    }
  };

  /**
   * Wait for loader and then show data
   *
   * @returns boolean
   */
  const handleContentVisibility = () => {
    if (!singleEducationLoader) {
      return true;
    }
    return false;
  };

  return (
    <div id="single-education">
      {handleContentVisibility() && (
        <>
          {singleEducation?.status?.blocked !== true ? (
            <>
              <Header
                educationHeader={singleEducation}
                chatWindow={handleChatWindow}
                editSidebar={handleEditSidebar}
                role={loggedRole}
                isOtherEducation={isOtherEducation}
                isReadOnly={isReadOnlyEducation}
                blockEducation={showModal}
                headerAction={handleHeaderAction}
                headerDetails={headerDetails}
              />
              {isReadOnlyEducation ? (
                <CourseTimeline
                  className="student-timeline"
                  role={loggedRole}
                  isReadOnly={isReadOnlyEducation}
                  educationId={id}
                />
              ) : (
                <SingleEducationTabBar
                  onTabChange={onTabChange}
                  selectedTab={selectedTab}
                  role={loggedRole}
                  isReadOnly={isReadOnlyEducation}
                />
              )}
              {showChat && (
                <EducationLiveChat
                  chatWindow={handleChatWindow}
                  showChat={showChat}
                  education={singleEducation}
                />
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
      {singleEducationLoader && <DataLoader />}
      {isEditSidebar && (
        <EditEducation
          isVisible={isEditSidebar}
          selectedEducation={singleEducation}
          onClose={hideEditSidebar}
        />
      )}
      <AppModal
        isVisible={isModalVisible}
        content={
          <ConfirmationModalContent
            data={confirmationModalData.data}
            isLoadingButton={isLoadingButton}
            onConfirmAction={blockEducation}
            onCancel={closeModal}
          />
        }
      />
      <AppModal
        isVisible={isJoinVisible}
        content={
          <JoinEducation
            onCloseModal={hideJoinigModal}
            selectedForJoining={selectedForJoining}
            modalDescription={joiningEducationDescription}
            joinEducationRequest={joinEducationRequest}
            isLoading={isLoadingButton}
          />
        }
      />
      <AppModal
        isVisible={approvalModalVisible}
        content={<ApprovalModal onCloseModal={hideJoinigModal} />}
      />
    </div>
  );
};

export default SingleEducation;
