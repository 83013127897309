import React from "react";
import Approve from "assets/images/icons/approve-status.svg";
import Decline from "assets/images/icons/decline-status.svg";
import { Typography, Button } from "@material-ui/core";
import "./css/student-status.css";
import LoadingButton from "./LoadingButton";
import { useTranslation } from "react-i18next";

function StudentStatus(props) {
  const { t } = useTranslation();
  const { studentData, onCancel, onConfirmAction, isLoadingButton } = props;
  return (
    <div className="student-status-content justify-content-center">
      <div className="modal-header">
        <div className="modal-icon">
          <img
            src={studentData.type === "approve" ? Approve : Decline}
            alt="approve-student"
          />
        </div>
        <div className="modal-title">
          <Typography variant="h1">{studentData.title}</Typography>
        </div>
        <div className="modal-description">
          <Typography color="textPrimary">{studentData.description}</Typography>
        </div>
      </div>
      <div className="modal-actions display-flex justify-content-center">
        <LoadingButton
          isLoading={isLoadingButton}
          text={studentData.buttonText}
          variant={studentData.type === "approve" ? "contained" : "outlined"}
          color={studentData.type === "approve" ? "primary" : "default"}
          onClick={onConfirmAction}
        />
        <Button onClick={onCancel} variant="outlined">
          {t("ACTION_BUTTONS.NO")}
        </Button>
      </div>
    </div>
  );
}

export default StudentStatus;
