import {
  EDUCATIONS,
  GET_MY_EDUCATIONS,
  GET_OTHER_EDUCATIONS,
  HUBS,
  PARTICIPANTS,
  CHAT,
  DOCUMENTS,
  TIMELINE,
  TWILIO_TOKEN,
} from "./CONSTANTS";
import { db, eLearningFunctions } from "../firebase/firebase";

// get super admin educations
const getSuperAdminEducations = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const educations = db.collection(EDUCATIONS()).get();
      const data = await educations;
      resolve(data);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// get hub admin educations
const getHubAdminEducations = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      // first get myHub
      const myHub = db
        .collection(HUBS())
        .where("administrator.email", "==", email)
        .get();
      const hubDetails = await myHub;
      if (hubDetails.empty) return;
      const hubId = hubDetails.docs[0].id;
      resolve(hubId);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// get teacher educations
const getTeacherEducations = (teacherId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const educations = db
        .collection(EDUCATIONS())
        .where("educators", "array-contains", teacherId)
        .get();
      const data = await educations;
      resolve(data);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// get student educations
const getStudentEducations = (studentId, type) => {
  const functionUrl =
    type === "my-educations" ? GET_MY_EDUCATIONS() : GET_OTHER_EDUCATIONS();
  return new Promise(async (resolve, reject) => {
    try {
      const educationFunction = eLearningFunctions.httpsCallable(functionUrl);
      const studentEducations = await educationFunction({
        userId: "users/" + studentId,
      });

      resolve(studentEducations.data);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// get education details
const getEducationDetails = (id) => {
  return new Promise((resolve, reject) => {
    try {
      db.collection(EDUCATIONS())
        .doc(id)
        .get()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      console.log("ERROR: ", error);
      reject("SYSTEM ERROR");
    }
  });
};

// create new education
const createNewEducation = (education) => {
  return new Promise((resolve, reject) => {
    try {
      db.collection(EDUCATIONS())
        .add(education)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      console.log("ERROR: ", error);
      reject("SYSTEM ERROR");
    }
  });
};

// edit education
const editEducation = (educationId, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const collectionRef = db.collection(EDUCATIONS()).doc(educationId);
      const doUpdate = await collectionRef.update(data);
      resolve(doUpdate);
    } catch (error) {
      console.log("ERROR: ", error);
      reject("SYSTEM ERROR");
    }
  });
};

// create participant subcollection
const createParticipant = (educationId, newParticipant) => {
  return new Promise(async (resolve, reject) => {
    try {
      const educations = db.collection(EDUCATIONS()).doc(educationId);
      const participant = await educations
        .collection(PARTICIPANTS())
        .add(newParticipant);
      resolve(participant);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// get participants for education
const getParticipants = (educationId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const educations = db.collection(EDUCATIONS()).doc(educationId);
      const participants = await educations.collection(PARTICIPANTS()).get();
      resolve(participants);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// update participnat status on education
const updateParticipantStatus = (educationId, participantId, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const educationRef = db.collection(EDUCATIONS()).doc(educationId);
      const participantsRef = await educationRef
        .collection(PARTICIPANTS())
        .where("userId", "==", `users/${participantId}`)
        .get();
      const participant = participantsRef.docs[0];
      if (!participant.exists) return;

      const doUpdate = await participant.ref.update(data);
      resolve(doUpdate);
    } catch (error) {
      console.log("ERROR: ", error);
      reject("SYSTEM ERROR");
    }
  });
};

// check if logged teacher is primary for education
const checkPrimEducatorForEducation = (educationId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const education = await db
        .collection(EDUCATIONS())
        .doc(educationId)
        .get();
      const primaryEducator = education.data()?.primEducator;
      resolve(primaryEducator);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// create chat subcollection
const createChat = (educationId, message) => {
  return new Promise(async (resolve, reject) => {
    try {
      const education = db.collection(EDUCATIONS()).doc(educationId);
      const chat = await education.collection(CHAT()).add(message);
      resolve(chat);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// create timeline
const createTimeline = (educationId, newLesson) => {
  return new Promise(async (resolve, reject) => {
    try {
      const education = db.collection(EDUCATIONS()).doc(educationId);
      const lesson = await education.collection(TIMELINE()).add(newLesson);
      resolve(lesson);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// edit timeline
const editTimeline = (educationId, timelineId, lessonData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const education = db.collection(EDUCATIONS()).doc(educationId);
      const lesson = await education
        .collection(TIMELINE())
        .doc(timelineId)
        .update(lessonData);
      resolve(lesson);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// get timeline
const getTimeline = (educationId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const education = db.collection(EDUCATIONS()).doc(educationId);
      const timeline = await education.collection(TIMELINE()).get();
      resolve(timeline);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// get timeline
const getSingleTimeline = (educationId, timelineId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const education = db.collection(EDUCATIONS()).doc(educationId);
      const timeline = await education
        .collection(TIMELINE())
        .doc(timelineId)
        .get();
      resolve(timeline);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// delete document from timeline
const deleteTimeline = (educationId, timelineId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const education = db.collection(EDUCATIONS()).doc(educationId);
      const lesson = await education
        .collection(TIMELINE())
        .doc(timelineId)
        .delete();
      resolve(lesson);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// create document for education
const createDocument = (educationId, newDocument) => {
  return new Promise(async (resolve, reject) => {
    try {
      const education = db.collection(EDUCATIONS()).doc(educationId);
      const document = await education.collection(DOCUMENTS()).add(newDocument);
      resolve(document);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// get documents
const getDocuments = (educationId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const education = db.collection(EDUCATIONS()).doc(educationId);
      const documents = await education
        .collection(DOCUMENTS())
        .orderBy("uploadDate", "desc")
        .get();
      resolve(documents);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// delete document
const deleteDocumentFromCollection = (educationId, documentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const education = db.collection(EDUCATIONS()).doc(educationId);
      const document = await education
        .collection(DOCUMENTS())
        .doc(documentId)
        .delete();
      resolve(document);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

/**
 * Get token from twilio
 *
 * @param {*} user_identity => user identity
 * @param {*} room_name => room name (id of timeline)
 * @returns token and room type
 */
const getTwilioToken = (user_identity, room_name) => {
  return new Promise(async (resolve, reject) => {
    try {
      const twilioToken = eLearningFunctions.httpsCallable(TWILIO_TOKEN());
      const twilioTokenResponse = await twilioToken({
        user_identity,
        room_name,
        create_conversation: true,
      });

      resolve(twilioTokenResponse.data.body);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

/**
 * Create chat for timeline in education
 *
 * @param {*} educationId => education id
 * @param {*} timelineId => timeline id
 * @param {*} message => message
 * @returns new Promise
 */
const createTimelineChat = (educationId, timelineId, message) => {
  return new Promise(async (resolve, reject) => {
    try {
      const education = db.collection(EDUCATIONS()).doc(educationId);
      const timeline = education.collection(TIMELINE()).doc(timelineId);
      const chat = await timeline.collection(CHAT()).add(message);
      resolve(chat);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

export {
  getSuperAdminEducations,
  getHubAdminEducations,
  getTeacherEducations,
  getStudentEducations,
  getEducationDetails,
  checkPrimEducatorForEducation,
  createNewEducation,
  getParticipants,
  editEducation,
  createParticipant,
  updateParticipantStatus,
  createChat,
  createTimeline,
  editTimeline,
  getTimeline,
  getSingleTimeline,
  deleteTimeline,
  createDocument,
  getDocuments,
  deleteDocumentFromCollection,
  getTwilioToken,
  createTimelineChat,
};
