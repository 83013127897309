const countries = (t) => {
  return [
    { id: 1, name: t("COUNTRIES.AFGHANISTAN") },
    { id: 2, name: t("COUNTRIES.ALBANIA") },
    { id: 3, name: t("COUNTRIES.ALGERIA") },
    { id: 4, name: t("COUNTRIES.ANDORRA") },
    { id: 5, name: t("COUNTRIES.ANGOLA") },
    { id: 6, name: t("COUNTRIES.ANTIGUA_AND_BARBUDA") },
    { id: 7, name: t("COUNTRIES.ARGENTINA") },
    { id: 8, name: t("COUNTRIES.ARMENIA") },
    { id: 9, name: t("COUNTRIES.AUSTRALIA") },
    { id: 10, name: t("COUNTRIES.AUSTRIA") },
    { id: 11, name: t("COUNTRIES.AZERBAIJAN") },
    { id: 12, name: t("COUNTRIES.BAHAMAS") },
    { id: 13, name: t("COUNTRIES.BAHRAIN") },
    { id: 14, name: t("COUNTRIES.BANGLADESH") },
    { id: 15, name: t("COUNTRIES.BARBADOS") },
    { id: 16, name: t("COUNTRIES.BELARUS") },
    { id: 17, name: t("COUNTRIES.BELGIUM") },
    { id: 18, name: t("COUNTRIES.BELIZE") },
    { id: 19, name: t("COUNTRIES.BENIN") },
    { id: 20, name: t("COUNTRIES.BHUTAN") },
    { id: 21, name: t("COUNTRIES.BOLIVIA") },
    { id: 22, name: t("COUNTRIES.BOSNIA_AND_HERZEGOVINA") },
    { id: 23, name: t("COUNTRIES.BOTSWANA") },
    { id: 24, name: t("COUNTRIES.BRAZIL") },
    { id: 25, name: t("COUNTRIES.BRUNEI") },
    { id: 26, name: t("COUNTRIES.BULGARIA") },
    { id: 27, name: t("COUNTRIES.BURKINA_FASO") },
    { id: 28, name: t("COUNTRIES.BURUNDI") },
    { id: 29, name: t("COUNTRIES.COTE_D'IVOIRE") },
    { id: 30, name: t("COUNTRIES.CABO_VERDE") },
    { id: 31, name: t("COUNTRIES.CAMBODIA") },
    { id: 32, name: t("COUNTRIES.CAMEROON") },
    { id: 33, name: t("COUNTRIES.CANADA") },
    { id: 34, name: t("COUNTRIES.CENTRAL_AFRICAN_REPUBLIC") },
    { id: 35, name: t("COUNTRIES.CHAD") },
    { id: 36, name: t("COUNTRIES.CHILE") },
    { id: 37, name: t("COUNTRIES.CHINA") },
    { id: 38, name: t("COUNTRIES.COLOMBIA") },
    { id: 39, name: t("COUNTRIES.COMOROS") },
    { id: 40, name: t("COUNTRIES.CONGO") },
    { id: 41, name: t("COUNTRIES.COSTA_RICA") },
    { id: 42, name: t("COUNTRIES.CROATIA") },
    { id: 43, name: t("COUNTRIES.CUBA") },
    { id: 44, name: t("COUNTRIES.CYPRUS") },
    { id: 45, name: t("COUNTRIES.CZECHIA") },
    { id: 46, name: t("COUNTRIES.DEMOCRATIC_REPUBLIC_OF_CONGO") },
    { id: 47, name: t("COUNTRIES.DENMARK") },
    { id: 48, name: t("COUNTRIES.DJIBOUTI") },
    { id: 49, name: t("COUNTRIES.DOMINICA") },
    { id: 50, name: t("COUNTRIES.DOMINICAN_REPUBLIC") },
    { id: 51, name: t("COUNTRIES.ECUADOR") },
    { id: 52, name: t("COUNTRIES.EGYPT") },
    { id: 53, name: t("COUNTRIES.EL_SALVADOR") },
    { id: 54, name: t("COUNTRIES.EQUATORIAL_GUINEA") },
    { id: 55, name: t("COUNTRIES.ERITREA") },
    { id: 56, name: t("COUNTRIES.ESTONIA") },
    { id: 57, name: t("COUNTRIES.ESWATINI") },
    { id: 58, name: t("COUNTRIES.ETHIOPIA") },
    { id: 59, name: t("COUNTRIES.FIJI") },
    { id: 60, name: t("COUNTRIES.FINLAND") },
    { id: 61, name: t("COUNTRIES.FRANCE") },
    { id: 62, name: t("COUNTRIES.GABON") },
    { id: 63, name: t("COUNTRIES.GAMBIA") },
    { id: 64, name: t("COUNTRIES.GEORGIA") },
    { id: 65, name: t("COUNTRIES.GERMANY") },
    { id: 66, name: t("COUNTRIES.GHANA") },
    { id: 67, name: t("COUNTRIES.GREECE") },
    { id: 68, name: t("COUNTRIES.GRENADA") },
    { id: 69, name: t("COUNTRIES.GUATEMALA") },
    { id: 70, name: t("COUNTRIES.GUINEA") },
    { id: 71, name: t("COUNTRIES.GUINEA-BISSAU") },
    { id: 72, name: t("COUNTRIES.GUYANA") },
    { id: 73, name: t("COUNTRIES.HAITI") },
    { id: 74, name: t("COUNTRIES.HOLY_SEE") },
    { id: 75, name: t("COUNTRIES.HONDURAS") },
    { id: 76, name: t("COUNTRIES.HUNGARY") },
    { id: 77, name: t("COUNTRIES.ICELAND") },
    { id: 78, name: t("COUNTRIES.INDIA") },
    { id: 79, name: t("COUNTRIES.INDONESIA") },
    { id: 80, name: t("COUNTRIES.IRAN") },
    { id: 81, name: t("COUNTRIES.IRELAND") },
    { id: 82, name: t("COUNTRIES.IRAQ") },
    { id: 83, name: t("COUNTRIES.ISRAEL") },
    { id: 84, name: t("COUNTRIES.ITALY") },
    { id: 85, name: t("COUNTRIES.JAMAICA") },
    { id: 86, name: t("COUNTRIES.JAPAN") },
    { id: 87, name: t("COUNTRIES.JORDAN") },
    { id: 88, name: t("COUNTRIES.KAZAKHSTAN") },
    { id: 89, name: t("COUNTRIES.KENYA") },
    { id: 90, name: t("COUNTRIES.KIRIBATI") },
    { id: 91, name: t("COUNTRIES.KUWAIT") },
    { id: 92, name: t("COUNTRIES.KYRGYZSTAN") },
    { id: 93, name: t("COUNTRIES.LAOS") },
    { id: 94, name: t("COUNTRIES.LATVIA") },
    { id: 95, name: t("COUNTRIES.LEBANON") },
    { id: 96, name: t("COUNTRIES.LESOTHO") },
    { id: 97, name: t("COUNTRIES.LIBERIA") },
    { id: 98, name: t("COUNTRIES.LIBYA") },
    { id: 99, name: t("COUNTRIES.LIECHTENSTEIN") },
    { id: 100, name: t("COUNTRIES.LITHUANIA") },
    { id: 101, name: t("COUNTRIES.LUXEMBOURG") },
    { id: 102, name: t("COUNTRIES.MADAGASCAR") },
    { id: 103, name: t("COUNTRIES.MALAWI") },
    { id: 104, name: t("COUNTRIES.MALAYSIA") },
    { id: 105, name: t("COUNTRIES.MALDIVES") },
    { id: 106, name: t("COUNTRIES.MALI") },
    { id: 107, name: t("COUNTRIES.MALTA") },
    { id: 108, name: t("COUNTRIES.MARSHALL_ISLANDS") },
    { id: 109, name: t("COUNTRIES.MAURITANIA") },
    { id: 110, name: t("COUNTRIES.MAURITIUS") },
    { id: 111, name: t("COUNTRIES.MEXICO") },
    { id: 112, name: t("COUNTRIES.MICRONESIA") },
    { id: 113, name: t("COUNTRIES.MOLDOVA") },
    { id: 114, name: t("COUNTRIES.MONACO") },
    { id: 115, name: t("COUNTRIES.MONGOLIA") },
    { id: 116, name: t("COUNTRIES.MONTENEGRO") },
    { id: 117, name: t("COUNTRIES.MOROCCO") },
    { id: 118, name: t("COUNTRIES.MOZAMBIQUE") },
    { id: 119, name: t("COUNTRIES.MYANMAR") },
    { id: 120, name: t("COUNTRIES.NAMIBIA") },
    { id: 121, name: t("COUNTRIES.NAURU") },
    { id: 122, name: t("COUNTRIES.NEPAL") },
    { id: 123, name: t("COUNTRIES.NETHERLANDS") },
    { id: 124, name: t("COUNTRIES.NEW_ZEALAND") },
    { id: 125, name: t("COUNTRIES.NICARAGUA") },
    { id: 126, name: t("COUNTRIES.NIGER") },
    { id: 127, name: t("COUNTRIES.NIGERIA") },
    { id: 128, name: t("COUNTRIES.NORTH_KOREA") },
    { id: 129, name: t("COUNTRIES.NORTH_MACEDONIA") },
    { id: 130, name: t("COUNTRIES.NORWAY") },
    { id: 131, name: t("COUNTRIES.OMAN") },
    { id: 132, name: t("COUNTRIES.PAKISTAN") },
    { id: 133, name: t("COUNTRIES.PALAU") },
    { id: 134, name: t("COUNTRIES.PALESTINE_STATE") },
    { id: 135, name: t("COUNTRIES.PANAMA") },
    { id: 136, name: t("COUNTRIES.PAPUA_NEW_GUINEA") },
    { id: 137, name: t("COUNTRIES.PARAGUAY") },
    { id: 138, name: t("COUNTRIES.PERU") },
    { id: 139, name: t("COUNTRIES.PHILIPPINES") },
    { id: 140, name: t("COUNTRIES.POLAND") },
    { id: 141, name: t("COUNTRIES.PORTUGAL") },
    { id: 142, name: t("COUNTRIES.QATAR") },
    { id: 143, name: t("COUNTRIES.ROMANIA") },
    { id: 144, name: t("COUNTRIES.RUSSIA") },
    { id: 145, name: t("COUNTRIES.RWANDA") },
    { id: 146, name: t("COUNTRIES.SAINT_KITTS_AND_NEVIS") },
    { id: 147, name: t("COUNTRIES.SAINT_LUCIA") },
    { id: 148, name: t("COUNTRIES.SAINT_VINCENT_AND_THE_GRENADINES") },
    { id: 149, name: t("COUNTRIES.SAMOA") },
    { id: 150, name: t("COUNTRIES.SAN_MARINO") },
    { id: 151, name: t("COUNTRIES.SAO_TOME_AND_PRINCIPE") },
    { id: 152, name: t("COUNTRIES.SAUDI_ARABIA") },
    { id: 153, name: t("COUNTRIES.SENEGAL") },
    { id: 154, name: t("COUNTRIES.SERBIA") },
    { id: 155, name: t("COUNTRIES.SEYCHELLES") },
    { id: 156, name: t("COUNTRIES.SIERRA_LEONE") },
    { id: 157, name: t("COUNTRIES.SINGAPORE") },
    { id: 158, name: t("COUNTRIES.SLOVAKIA") },
    { id: 159, name: t("COUNTRIES.SLOVENIA") },
    { id: 160, name: t("COUNTRIES.SOLOMON_ISLANDS") },
    { id: 161, name: t("COUNTRIES.SOMALIA") },
    { id: 162, name: t("COUNTRIES.SOUTH_AFRICA") },
    { id: 163, name: t("COUNTRIES.SOUTH_KOREA") },
    { id: 164, name: t("COUNTRIES.SOUTH_SUDAN") },
    { id: 165, name: t("COUNTRIES.SPAIN") },
    { id: 166, name: t("COUNTRIES.SRI_LANKA") },
    { id: 167, name: t("COUNTRIES.SUDAN") },
    { id: 168, name: t("COUNTRIES.SURINAME") },
    { id: 169, name: t("COUNTRIES.SWEDEN") },
    { id: 170, name: t("COUNTRIES.SWITZERLAND") },
    { id: 171, name: t("COUNTRIES.SYRIA") },
    { id: 172, name: t("COUNTRIES.TAJIKISTAN") },
    { id: 173, name: t("COUNTRIES.TANZANIA") },
    { id: 174, name: t("COUNTRIES.THAILAND") },
    { id: 175, name: t("COUNTRIES.TIMOR_LESTE") },
    { id: 176, name: t("COUNTRIES.TOGO") },
    { id: 177, name: t("COUNTRIES.TONGA") },
    { id: 178, name: t("COUNTRIES.TRINIDAD_AND_TOBAGO") },
    { id: 179, name: t("COUNTRIES.TUNISIA") },
    { id: 180, name: t("COUNTRIES.TURKEY") },
    { id: 181, name: t("COUNTRIES.TURKMENISTAN") },
    { id: 182, name: t("COUNTRIES.TUVALU") },
    { id: 183, name: t("COUNTRIES.UGANDA") },
    { id: 184, name: t("COUNTRIES.UKRAINE") },
    { id: 185, name: t("COUNTRIES.UNITED_ARAB_EMIRATES") },
    { id: 186, name: t("COUNTRIES.UNITED_KINGDOM") },
    { id: 187, name: t("COUNTRIES.USA") },
    { id: 188, name: t("COUNTRIES.URUGUAY") },
    { id: 189, name: t("COUNTRIES.UZBEKISTAN") },
    { id: 190, name: t("COUNTRIES.VANUATU") },
    { id: 191, name: t("COUNTRIES.VENEZUELA") },
    { id: 192, name: t("COUNTRIES.VIETNAM") },
    { id: 193, name: t("COUNTRIES.YEMEN") },
    { id: 194, name: t("COUNTRIES.ZAMBIA") },
    { id: 195, name: t("COUNTRIES.ZIMBABWE") },
  ];
};

export { countries };
