import React, { useState, useEffect } from "react";
import { useHub } from "../context/HubContext";
import HubCard from "./HubCard";
import PageTitle from "../../../components/PageTitle";
import Pagination from "@material-ui/lab/Pagination";
import "../css/List.css";
import { Button } from "@material-ui/core";
import plus from "assets/images/icons/white-plus.svg";
import EmptyState from "components/EmptyState";
import EmptyImage from "assets/images/empty-state/hubs.svg";
import AppModal from "components/AppModal";
import AddHub from "./AddHub";
import EditHub from "./EditHub";
import ConfirmationModalContent from "components/ConfirmationModalContent";
import { updateBlockedStatus } from "services/blockedStatusService";
import { HUBS } from "services/CONSTANTS";
import { useTranslation } from "react-i18next";
import DataLoader from "components/DataLoader";
import { editHub, getHubBlockedStatus } from "services/hubService";

const List = () => {
  const PER_PAGE = 4;
  const HUBS_COUNT = 1;

  const { hubsList, isDataLoading } = useHub();
  const { t } = useTranslation();

  const [isAddVisible, setIsAddVisible] = useState(false);
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isBlockVisible, setIsBlockVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [page, setPage] = useState(1);
  const [paginateHubs, setPaginateHubs] = useState([]);
  const [hubsCount, setHubsCount] = useState(HUBS_COUNT);

  const [selectedHub, setSelectedHub] = useState({ data: null });

  const [confirmationModalData, setConfirmationModalData] = useState({
    data: null,
  });

  useEffect(() => {
    if (!hubsList) return;
    setPaginateHubs(hubsList.slice(0, PER_PAGE));
    const count = hubsList.length;
    setHubsCount(Math.ceil(count / PER_PAGE));
  }, [hubsList]);

  /**
   * Change page
   * @param {*} event => event
   * @param {*} value => selected value
   */
  const handleChange = (event, value) => {
    const selectedPage = value * PER_PAGE;
    const previousPage = selectedPage - PER_PAGE;
    setPaginateHubs(hubsList.slice(previousPage, selectedPage));
    setPage(value);
  };

  /**
   * Show modal
   * @param {*} type => modal type
   */
  const showModal = (type) => {
    setIsModalVisible(true);
    switch (type) {
      case "add":
        setIsEditVisible(false);
        setIsBlockVisible(false);
        setIsAddVisible(true);
        break;

      case "edit":
        setIsAddVisible(false);
        setIsBlockVisible(false);
        setIsEditVisible(true);
        break;

      default:
        setIsAddVisible(false);
        setIsEditVisible(false);
        setIsBlockVisible(true);
        break;
    }
  };

  /**
   * Hide modal when this function is called
   */
  const hideModal = () => {
    setIsModalVisible(false);
    setIsAddVisible(false);
    setIsEditVisible(false);
    setIsBlockVisible(false);
  };

  /**
   * Check which modal needs to be showed
   *
   * @returns booelean
   */
  const handleModalState = () => {
    if (isAddVisible) {
      return "add-visible";
    }

    if (isEditVisible) {
      return "edit-visible";
    }

    if (isBlockVisible) {
      return "block-visible";
    }

    return false;
  };

  /**
   * Select HUB
   *
   * @param {*} hub => hub
   * @param {*} type => type of modal
   */
  const selectHub = (hub, type) => {
    setSelectedHub({ data: hub });
    if (type === "block") {
      setConfirmationModalData(() => ({
        data: {
          type: `${hub?.status.blocked}` ? "block" : "unblock",
          title: `${
            hub?.status.blocked
              ? `${t("HUBS.BLOCK_HUB.UNBLOCK")} ` + hub.name
              : `${t("HUBS.BLOCK_HUB.BLOCK")} ` + hub.name
          }`,
          variant: hub?.status.blocked ? "contained" : "outlined",
          color: hub?.status.blocked ? "primary" : "default",
          actionButtonText: hub?.status.blocked
            ? t("ACTION_BUTTONS.UNBLOCK")
            : t("ACTION_BUTTONS.BLOCK"),
          description: hub?.status.blocked
            ? t("HUBS.BLOCK_HUB.DESCRIPTION_UNBLOCK")
            : t("HUBS.BLOCK_HUB.DESCRIPTION_BLOCK"),
        },
      }));
    }
    showModal(type);
  };

  /**
   * Block/Unblock hub when user confirm that in modal
   */
  const upadateHubBlockStatus = async () => {
    setIsLoadingButton(true);

    try {
      const hubStatus = await getHubBlockedStatus(selectedHub.data.hubId);

      await editHub(selectedHub.data.hubId, {
        blocked: selectedHub.data.status.blocked ? false : true,
      });

      // update status in subcollection
      updateBlockedStatus(HUBS(), selectedHub.data.hubId, hubStatus.id, {
        status: selectedHub.data.status.blocked ? false : true,
      })
        .then(() => {
          setIsLoadingButton(false);
          hideModal();
        })
        .catch((err) => {
          setIsLoadingButton(false);
          console.log(err);
        });
    } catch (error) {
      setIsLoadingButton(false);
      console.log(error);
    }
  };

  /**
   * Show modal for adding new hub from button in empty state component
   */
  const handleEmptyState = () => {
    showModal("add");
  };

  const addResponsiveClass = () => {
    if (isAddVisible || isEditVisible) {
      return true;
    }
    return false;
  };

  /**
   * Handle when to show / hide wmpty state component
   *
   * @returns boolean
   */
  const handleEmptyStateVisibility = () => {
    if (!isDataLoading && paginateHubs.length <= 0) {
      return true;
    }
    return false;
  };

  /**
   * Handle when to show / hide cards
   *
   * @returns boolean
   */
  const handleHubCardsVisibility = () => {
    if (!isDataLoading && paginateHubs.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <div className="hub-list padding-right-layout padding-left-layout padding-top-layout padding-bottom-layout routes-layout">
      {handleHubCardsVisibility() && (
        <>
          <div className="header display-flex justify-content-between">
            <PageTitle title={t("HUBS.TITLE")} />
            <Button
              onClick={() => showModal("add")}
              className="btn btn-add-hub"
              color="primary"
              variant="contained"
              startIcon={<img src={plus} alt="New hub" />}
            >
              {t("ACTION_BUTTONS.ADD_HUB")}
            </Button>
          </div>
          {paginateHubs.map((hub) => (
            <HubCard key={hub?.hubId} hub={hub} chooseSelectHub={selectHub} />
          ))}

          <Pagination
            count={hubsCount}
            page={page}
            shape="rounded"
            onChange={handleChange}
          />
        </>
      )}

      {handleEmptyStateVisibility() && (
        <EmptyState
          image={EmptyImage}
          title={t("HUBS.EMPTY_STATE.TITLE")}
          caption={t("HUBS.EMPTY_STATE.DESCRIPTION")}
          buttonTitle={t("ACTION_BUTTONS.ADD_HUB")}
          hasButton={true}
          emptyStateAction={handleEmptyState}
          link="/"
        />
      )}

      {isDataLoading && <DataLoader />}
      <AppModal
        isVisible={isModalVisible}
        className={addResponsiveClass() && "add-edit-hub-modal"}
        content={
          handleModalState() === "add-visible" ? (
            <AddHub onCloseModal={hideModal} />
          ) : handleModalState() === "edit-visible" ? (
            <EditHub selectedHub={selectedHub} onCloseModal={hideModal} />
          ) : (
            <ConfirmationModalContent
              data={confirmationModalData.data}
              selectedHub={selectedHub}
              isLoadingButton={isLoadingButton}
              onConfirmAction={upadateHubBlockStatus}
              onCancel={hideModal}
            />
          )
        }
      />
    </div>
  );
};

export default List;
