import React, { useEffect, useCallback, useRef } from "react";
import "./css/snackbar.css";
import successSnackbar from "assets/images/icons/snackbar-success.svg";
import errorSnackbar from "assets/images/icons/snackbar-error.svg";
import closeSnackbar from "assets/images/icons/white-x.svg";

const Snackbar = (props) => {
  // get props
  const { id, dispatch, message, success } = props;

  //ref to snackbar
  const snackbarRef = useRef(null);

  // hanlde close snackbar
  const handleCloseSnackbar = useCallback(() => {
    setTimeout(() => {
      if (snackbarRef.current !== null) {
        dispatch({
          type: "HIDE_SNACKBAR",
          id,
        });
      }
    }, 3000);
  }, [dispatch, id]);

  useEffect(() => {
    handleCloseSnackbar();
  }, [handleCloseSnackbar]);

  return (
    <div
      ref={snackbarRef}
      className={`snackbar-item display-flex align-items-center ${
        success ? "success" : "error"
      }`}
    >
      <img
        className="info"
        src={success ? successSnackbar : errorSnackbar}
        alt="Snackbar"
      />
      <p>{message}</p>
      <img
        className="close"
        src={closeSnackbar}
        alt="Close snackbar"
        onClick={() =>
          dispatch({
            type: "HIDE_SNACKBAR",
            id,
          })
        }
      />
    </div>
  );
};

export default Snackbar;
