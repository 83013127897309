import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import "./css/qa-editor.css";
import { useTranslation } from "react-i18next";

const QAEditor = (props) => {
  const { placeholder, editorChange, isError } = props;
  const { t } = useTranslation();
  // ref to editor
  const editorRef = useRef(null);

  // get content from editor
  const getEditorContent = () => {
    if (editorRef.current) {
      const value = editorRef.current.getContent();
      editorChange(value);
    }
  };
  return (
    <div>
      <Editor
        apiKey="x15bmsmdm50fzxg7pg8eo79rm4vcx1asr0ybph32gz554l2a"
        onInit={(evt, editor) => (editorRef.current = editor)}
        onEditorChange={getEditorContent}
        init={{
          height: 250,
          menubar: false,
          statusbar: false,
          branding: false,
          resize: false,
          content_css: "./css/app-ck-editor.css",
          plugins: [],
          placeholder: placeholder
            ? placeholder
            : t("EDUCATIONS.Q&A.NEW_QUESTION.PLACEHOLDER"),
          toolbar:
            "bold italic underline alignleft alignjustify alignright aligncenter",
          content_style:
            "body { font-family:'Montserrat', sans-serif; font-size:14px }",
        }}
      />
      {isError && (
        <p className="error-message">
          {t("EDUCATIONS.Q&A.NEW_QUESTION.ERROR")}
        </p>
      )}
    </div>
  );
};

export default QAEditor;
