import React from "react";
import "./css/timeline-item.css";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import calendarIcon from "assets/images/icons/date-picker-icon.svg";
import editIcon from "assets/images/icons/edit-timeline.svg";
import copyIcon from "assets/images/icons/content-copy.svg";
import deleteIcon from "assets/images/icons/delete.svg";
import timelineClock from "assets/images/icons/clock.svg";
import lessonEnd from "assets/images/icons/error-gray.svg";
import { Typography, Button } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import { ROLE } from "utils/ROLES";
import moment from "moment";
import { TIMELINE_STATUS } from "utils/timelineStatus";
import { useTranslation } from "react-i18next";

const TimelineAppItem = (props) => {
  const {
    timeline,
    role,
    isReadOnly,
    selectLesson,
    showDeleteModal,
    showEditModal,
    linkCopied,
    onJoinLesson,
  } = props;
  const { t } = useTranslation();

  /**
   * Check remaining time until lesson start
   *
   * @param {*} timeline => timeline data
   * @returns boolean
   */
  const isTimeRemaining = (timeline) => {
    const today = moment(new Date());
    const timelineDate = moment(timeline.scheduled.toDate());
    const timeRemaining = timelineDate.diff(today, "seconds");

    if (timeRemaining <= 0) {
      return true;
    }

    return false;
  };

  /**
   * Prevent if time is still remaining
   *
   * @returns choose lesson
   */
  const preventLessonModal = () => {
    if (isTimeRemaining(timeline)) {
      onJoinLesson(timeline);
      return;
    }
    chooseLesson();
  };

  /**
   * Choose lesson
   */
  const chooseLesson = () => {
    selectLesson(timeline);
  };

  /**
   * Show delete modal
   */
  const showDelete = () => {
    showDeleteModal(timeline);
  };

  /**
   * Open modal for edit
   */
  const showEdit = () => {
    showEditModal(timeline);
  };

  const copyLink = () => {
    linkCopied(timeline);
  };

  const showStartLessonButton = () => {
    if (timeline?.next) {
      return true;
    }
    return false;
  };

  const showTimelineActions = () => {
    if (timeline.timeDifference <= 0) {
      return false;
    }
    return true;
  };

  return (
    <TimelineItem
      id={`tid-${timeline?.id}`}
      className={`single-timeline-event ${
        timeline?.next && "select-next-lesson"
      }`}
    >
      <TimelineSeparator
        className={`${
          timeline?.next
            ? "active-lesson"
            : timeline?.status.id === TIMELINE_STATUS.COMPLETED.id
            ? "not-active-lesson"
            : ""
        }`}
      >
        <TimelineDot className="display-flex align-items-center justify-content-center">
          {timeline?.status?.id === TIMELINE_STATUS.COMPLETED.id ? (
            <img src={timelineClock} alt="Timeline clock" />
          ) : (
            <div className="indicator"></div>
          )}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent
        className={`display-flex justify-content-between ${timeline.status?.name}`}
      >
        <div className="course-info display-flex">
          <div className="date-time display-flex">
            <img src={calendarIcon} alt="Calendar" />
            <div className="data-info">
              <p>{t("EDUCATIONS.TIMELINE.LESSON_START")}</p>
              <p>
                {timeline?.timelineDate}, {timeline?.timelineTime}
              </p>
            </div>
          </div>
          <Typography className="lesson-title" variant="h5">
            {timeline?.name}
          </Typography>
        </div>
        {!isReadOnly && (
          <>
            {timeline?.status?.id !== TIMELINE_STATUS.COMPLETED.id ? (
              <div className="course-actions display-flex align-items-center">
                {showStartLessonButton() && (
                  <Button onClick={preventLessonModal} className="start-lesson">
                    {role === ROLE.STUDENT
                      ? t("ACTION_BUTTONS.JOIN_LESSON")
                      : t("ACTION_BUTTONS.START_LESSON")}
                  </Button>
                )}
                <div className="action-group display-flex">
                  {role === ROLE.TEACHER && showTimelineActions() && (
                    <div
                      data-tip
                      data-for="edit-event"
                      className="action-icon-holder"
                      onClick={showEdit}
                    >
                      <img src={editIcon} alt="Edit" />
                      <ReactTooltip
                        className="custom-react-tooltip"
                        id="edit-event"
                        place="right"
                        effect="solid"
                      >
                        {t("EDUCATIONS.TIMELINE.EDIT_EVENT")}
                      </ReactTooltip>
                    </div>
                  )}
                  <div
                    data-tip
                    data-for="copy-link"
                    className="action-icon-holder"
                    onClick={copyLink}
                  >
                    <img src={copyIcon} alt="Copy" />
                    <ReactTooltip
                      className="custom-react-tooltip"
                      id="copy-link"
                      place="right"
                      effect="solid"
                    >
                      {t("EDUCATIONS.TIMELINE.COPY_LINK")}
                    </ReactTooltip>
                  </div>
                  {role === ROLE.TEACHER && showTimelineActions() && (
                    <div
                      data-tip
                      data-for="delete-event"
                      className="action-icon-holder"
                      onClick={showDelete}
                    >
                      <img src={deleteIcon} alt="Delete" />
                      <ReactTooltip
                        className="custom-react-tooltip"
                        id="delete-event"
                        place="right"
                        effect="solid"
                      >
                        {t("EDUCATIONS.TIMELINE.DELETE_EVENT.DELETE_ICON")}
                      </ReactTooltip>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="lesson-end" data-tip data-for="lesson-end">
                <img className="width-100" src={lessonEnd} alt="Lesson end" />
                <ReactTooltip
                  className="custom-react-tooltip"
                  id="lesson-end"
                  effect="solid"
                  place="left"
                >
                  {t("EDUCATIONS.TIMELINE.LESSON_END")}
                </ReactTooltip>
              </div>
            )}
          </>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};

export default TimelineAppItem;
