import React from "react";
import { LinkRoute } from "components/LinkRoute";
import { ROUTES } from "./CONSTANTS";
import { useLocation } from "react-router-dom";
import educationLinkIcon from "assets/images/nav-links/educations.svg";
import educationActiveLinkIcon from "assets/images/nav-links/educations-active.svg";
import teacherLinkIcon from "assets/images/nav-links/teachers.svg";
import teacherActiveLinkIcon from "assets/images/nav-links/teachers-active.svg";
import hubLinkIcon from "assets/images/nav-links/hubs.svg";
import hubActiveLinkIcon from "assets/images/nav-links/hubs-active.svg";
import studentLinkIcon from "assets/images/nav-links/students.svg";
import studentActiveLinkIcon from "assets/images/nav-links/students-active.svg";
import { useAuth } from "auth/context/AuthContext";
import { ROLE } from "utils/ROLES";
import { useTranslation } from "react-i18next";

export const NavLinks = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { loggedRole } = useAuth();

  /**
   * Show navigation links per logged role.
   * Navigation links are showen only for super admin and hub admin role.
   *
   * @returns navigation links per logged role
   */
  const renderNavigationLinks = () => {
    if (loggedRole === ROLE.SUPER_ADMIN) {
      return (
        <>
          <LinkRoute to={ROUTES.HUBS}>
            <img
              src={
                location.pathname === ROUTES.HUBS ||
                location.pathname.split("/")[1] === "hubs"
                  ? hubActiveLinkIcon
                  : hubLinkIcon
              }
              alt="Hubs"
            />
            {t("NAVIGATION.HUBS")}
          </LinkRoute>
          <LinkRoute to={ROUTES.TEACHERS}>
            <img
              src={
                location.pathname === ROUTES.TEACHERS
                  ? teacherActiveLinkIcon
                  : teacherLinkIcon
              }
              alt="Teachers"
            />
            {t("NAVIGATION.TEACHERS")}
          </LinkRoute>
          <LinkRoute to={ROUTES.STUDENTS}>
            <img
              src={
                location.pathname === ROUTES.STUDENTS
                  ? studentActiveLinkIcon
                  : studentLinkIcon
              }
              alt="Students"
            />
            {t("NAVIGATION.STUDENTS")}
          </LinkRoute>
          <LinkRoute to={ROUTES.EDUCATIONS}>
            <img
              src={
                location.pathname === ROUTES.EDUCATIONS ||
                location.pathname.split("/")[1] === "educations"
                  ? educationActiveLinkIcon
                  : educationLinkIcon
              }
              alt="Educations"
            />
            {t("NAVIGATION.EDUCATIONS")}
          </LinkRoute>
        </>
      );
    }

    return (
      <>
        <LinkRoute to={ROUTES.EDUCATIONS}>
          <img
            src={
              location.pathname === ROUTES.EDUCATIONS ||
              location.pathname.split("/")[1] === "educations"
                ? educationActiveLinkIcon
                : educationLinkIcon
            }
            alt="Educations"
          />
          {t("NAVIGATION.EDUCATIONS")}
        </LinkRoute>
        <LinkRoute to={ROUTES.TEACHERS}>
          <img
            src={
              location.pathname === ROUTES.TEACHERS
                ? teacherActiveLinkIcon
                : teacherLinkIcon
            }
            alt="Teachers"
          />
          {t("NAVIGATION.TEACHERS")}
        </LinkRoute>
        <LinkRoute to={ROUTES.MY_HUB}>
          <img
            src={
              location.pathname === ROUTES.HUBS ||
              location.pathname.split("/")[1] === "my-hub"
                ? hubActiveLinkIcon
                : hubLinkIcon
            }
            alt="Hubs"
          />
          {t("NAVIGATION.HUB")}
        </LinkRoute>
      </>
    );
  };

  return <>{renderNavigationLinks()}</>;
};
