import React, { createContext, useContext, useReducer } from "react";
import Snackbar from "../components/Snackbar";
import { v4 } from "uuid";

// create snackbar context
const SnackbarContext = createContext();

// snackbar provider
const SnackbarProvider = (props) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "SHOW_SNACKBAR":
        return [...state, { ...action.payload }];
      case "HIDE_SNACKBAR":
        return state.filter((el) => el.id !== action.id);
      default:
        return state;
    }
  }, []);

  return (
    <SnackbarContext.Provider value={dispatch}>
      <>
        {state.map((note) => {
          return <Snackbar dispatch={dispatch} key={note.id} {...note} />;
        })}
      </>
      {props.children}
    </SnackbarContext.Provider>
  );
};

// use snackbar
export const useSnackbar = () => {
  const dispatch = useContext(SnackbarContext);

  return (props) => {
    dispatch({
      type: "SHOW_SNACKBAR",
      payload: {
        id: v4(),
        ...props,
      },
    });
  };
};

export default SnackbarProvider;
