import React, { useEffect } from "react";
import { Select } from "antd";
import whiteCheckmark from "assets/images/icons/white-checkmark.svg";
import "./css/filter-select.css";
import "./css/dropdown-checkbox.css";

const DropdownCheckbox = (props) => {
  const { Option } = Select;

  const { placeholder, options, defaultValue, educationCallback, selectLabel } =
    props;

  // create custom span for displaying number of selected secondary teachers
  useEffect(() => {
    const textField = document.createElement("span");
    textField.classList.add("display-teachers-count", "display-none");
    const element = document.querySelector(
      ".dropdown-checkbox-holder .ant-select-selector .ant-select-selection-overflow"
    );
    element.appendChild(textField);

    if (defaultValue !== undefined && defaultValue.length > 0) {
      const teachersCounter = document.querySelector(".display-teachers-count");
      teachersCounter.classList.remove("display-none");
      teachersCounter.innerHTML = `${defaultValue.length} selected`;
    }

    return element;
  }, [defaultValue]);

  /**
   * Select/deselect teacher from list
   *
   * @param {*} item => selected secondary teachers
   */
  const handleCheckboxChange = (teachers) => {
    const teachersLength = teachers.length;
    const text = document.querySelector(".display-teachers-count");

    if (teachersLength > 0) {
      text.classList.remove("display-none");
      text.innerHTML = `${teachersLength} selected`;
    } else {
      text.classList.add("display-none");
    }
    educationCallback(teachers);
  };

  return (
    <div className="filter-select dropdown-checkbox dropdown-checkbox-holder">
      {selectLabel && <label className="select-label">{selectLabel}</label>}

      <Select
        dropdownClassName="dropdown-checkbox"
        placeholder={placeholder}
        onChange={handleCheckboxChange}
        defaultValue={defaultValue}
        showSearch={false}
        mode="multiple"
        suffixIcon={false}
        clearIcon={false}
        autoClearSearchValue={false}
      >
        {options.map((option) => (
          <Option key={`dropdown-checkbox-${option.id}`} value={option.id}>
            <div className="option-checkbox display-flex align-items-center justify-content-center">
              <img src={whiteCheckmark} alt="White checkmark" />
            </div>
            {option.name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default DropdownCheckbox;
