import React from "react";
import "./css/image-upload-info.css";
import infoIcon from "assets/images/icons/info.svg";
import { useTranslation } from "react-i18next";

const ImageUploadInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="image-upload-info width-100 display-flex">
      <img src={infoIcon} alt="Info icon" />
      <div>
        <p>
          {t("IMAGE_UPLOAD.MAX_FILE")} <span>2 MB.</span>
        </p>
        <p>
          {t("IMAGE_UPLOAD.RECOMMENDED")} <span>4 : 3 (1024px x 768px).</span>
        </p>
      </div>
    </div>
  );
};

export default ImageUploadInfo;
