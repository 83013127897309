import React from "react";
import "../css/SingleHub.css";
import { HubProvider } from "../context/HubContext";
import SingleHub from "./SingleHub";

const HubPage = () => {
  return (
    <HubProvider>
      <SingleHub />
    </HubProvider>
  );
};

export default HubPage;
