import React, { useState, useEffect, useCallback, useRef } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { useEducation } from "../context/EducationContext";
import EducationCard from "./EducationCard";
import "../css/List.css";
import PageTitle from "components/PageTitle";
import FilterSelect from "components/FilterSelect";
import {
  Typography,
  Button,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import reloadFiltersIcon from "assets/images/icons/reload-filters.svg";
import EmptyState from "components/EmptyState";
import EmptyImage from "assets/images/empty-state/educations.svg";
import plus from "assets/images/icons/white-plus.svg";
import AddEducation from "./AddEducation";
import AppModal from "components/AppModal";
import JoinEducation from "./JoinEducation";
import ApprovalModal from "./ApprovalModal";
import { ROLE } from "utils/ROLES";
import searchIcon from "assets/images/icons/search-icon.svg";
import { useAuth } from "auth/context/AuthContext";
import ConfirmationModalContent from "components/ConfirmationModalContent";
import { updateBlockedStatus } from "services/blockedStatusService";
import { EDUCATIONS } from "services/CONSTANTS";
import { useTranslation } from "react-i18next";
import { getParticipantStatus } from "utils/participantStatus";
import { useSnackbar } from "contexts/SnackbarProvider";
import DataLoader from "components/DataLoader";
import { categories } from "utils/category";
import { getEducationStatus } from "utils/educationStatus";
import { getEducators } from "services/teacherService";
import { hubsForFilter } from "services/hubService";
import { joinEducation } from "utils/educationJoin";

const EducationsList = () => {
  const PER_PAGE = 9;
  const EDUCATIONS_COUNT = 1;

  // get educations from Education Context
  const {
    educationsList,
    showStudentEducations,
    isDataLoading,
    setAddedEducation,
  } = useEducation();

  const { loggedRole, currentUser } = useAuth();
  const { t } = useTranslation();

  const educationCategories = categories(t);
  const educationStatuses = getEducationStatus(t);
  const approvalStatusList = getParticipantStatus(t);

  const [educatorsList, setEducatorsList] = useState([]);
  const [hubs, setHubs] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedEducator, setSelectedEducator] = useState(null);
  const [selectedEducation, setSelectedEducation] = useState(null);
  const [selectedHub, setSelectedHub] = useState(null);
  const [selectedApproval, setSelectedApproval] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [drawerVisible, setIsDrawerVisible] = useState(false);
  const [isJoinVisible, setIsJoinVisible] = useState(false);
  const [approvalModalVisible, setApprovalModalVisible] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isUnblockVisible, setIsUnblockVisible] = useState(false);
  const [selectEducation, setSelectEducation] = useState({ data: null });
  const [confirmationModalData, setConfirmationModalData] = useState({
    data: null,
  });
  const [selectedForJoining, setSelectedForJoining] = useState(null);
  const [joiningEducationDescription, setJoiningEducationDescription] =
    useState(null);

  const [paginateEducations, setPaginateEducations] = useState(educationsList);
  const [defaultEducations, setDefaultEducations] = useState();
  const [localStoredEducations, setLocalStoredEducations] = useState();
  const [educationsCount, setEducationsCount] = useState(EDUCATIONS_COUNT);
  const [page, setPage] = useState(1);

  const [isFiltersDisabled, setIsFiltersDisabled] = useState(true);

  const dispatch = useSnackbar();

  let searchTimeout = null;

  const searchField = useRef(null);

  const FILTERS = {
    CATEGORY: "category",
    EDUCATOR: "educator",
    EDUCATION: "education",
    HUB: "hub",
    APPROVAL: "approval",
    STATUS: "status",
  };

  /**
   * Update state for educations
   *
   * @param {*} educations => list of educations
   */
  const updateEducationState = (educations) => {
    setPaginateEducations(educations.slice(0, PER_PAGE));
    const count = educations.length;
    setEducationsCount(Math.ceil(count / PER_PAGE));
  };

  /**
   * Reload filters in localstorage
   */
  const reloadLocalStorageFilters = () => {
    localStorage.removeItem("category-filter");
    localStorage.removeItem("education-filter");
    localStorage.removeItem("hub-filter");
    localStorage.removeItem("educator-filter");
    localStorage.removeItem("approval-filter");
    localStorage.removeItem("status-filter");
    localStorage.removeItem("filteredEducations");
    localStorage.removeItem("isFiltered");
    localStorage.removeItem("searched");
  };

  /**
   * Reset values for filters
   */
  const reloadFilters = useCallback(() => {
    setSelectedCategory(null);
    setSelectedEducator(null);
    setSelectedEducation(null);
    setSelectedHub(null);
    setSelectedApproval(null);
    setSelectedStatus(null);
    reloadLocalStorageFilters();
    updateEducationState(educationsList);

    if (searchField.current !== null) {
      searchField.current.value = "";
    }
  }, [educationsList]);

  /**
   * Handle active tab indicator
   *
   * @param {*} type => selected education type (my-educations/other)
   * @param {*} element => selected tab
   */
  const setTabIndicator = useCallback(
    (type, element) => {
      if (isPageLoaded) {
        reloadFilters();
      }
    },
    [isPageLoaded, reloadFilters]
  );

  useEffect(() => {
    let isSubscribed = true;

    const educators = async () => {
      if (isSubscribed) {
        const response = await getEducators();
        setEducatorsList(response);
      }
    };

    const hubs = async () => {
      if (isSubscribed) {
        const response = await hubsForFilter();
        setHubs(response);
      }
    };

    educators();
    hubs();

    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    reloadLocalStorageFilters();

    if (!educationsList) return;

    setIsFiltersDisabled(false);
    updateEducationState(educationsList);
    setDefaultEducations(educationsList);
  }, [educationsList]);

  useEffect(() => {
    if (loggedRole === ROLE.STUDENT) {
      localStorage.setItem("studentEducationType", "my-educations");
      setIsPageLoaded(true);
    }

    return () => {
      localStorage.removeItem("studentEducationType");
      setIsPageLoaded(false);
    };
  }, [loggedRole]);

  /**
   * Handle pagination
   *
   * @param {*} event => on change
   * @param {*} value => selected page
   */
  const handleChange = (event, value) => {
    const selectedPage = value * PER_PAGE;
    const previousPage = selectedPage - PER_PAGE;
    setPaginateEducations(educationsList.slice(previousPage, selectedPage));
    setPage(value);
  };

  /**
   * Search educations
   *
   * @param {*} value => value from input
   */
  const searchEducations = (value) => {
    clearTimeout(searchTimeout);

    const filterExists = localStorage.getItem("isFiltered");

    const arrayForSearch =
      filterExists !== null ? localStoredEducations : defaultEducations;

    const filteredEducations = arrayForSearch.filter((education) => {
      return education.title.toLowerCase().includes(value.toLowerCase());
    });

    searchTimeout = setTimeout(() => {
      if (filteredEducations.length === 0) {
        localStorage.setItem("searched", "true");
      }
      updateEducationState(filteredEducations);
      setPage(1);
    }, 500);
  };

  /**
   * Show /hide educator filter per logged role
   *
   * @returns boolean
   */
  const showEducatorFilter = () => {
    if (
      loggedRole === ROLE.SUPER_ADMIN ||
      loggedRole === ROLE.HUB_ADMIN ||
      localStorage.getItem("studentEducationType") === "other"
    ) {
      return true;
    }
    return false;
  };

  /**
   * Show / hide hub filter per logged role
   *
   * @returns boolean
   */
  const showHubFilter = () => {
    if (
      loggedRole === ROLE.SUPER_ADMIN ||
      localStorage.getItem("studentEducationType") === "other"
    ) {
      return true;
    }
    return false;
  };

  /**
   * Show / hide education filter
   *
   * @returns boolean
   */
  const educationFilter = () => {
    if (
      loggedRole === ROLE.STUDENT &&
      localStorage.getItem("studentEducationType") === "my-educations"
    ) {
      return true;
    }
    return false;
  };

  /**
   * Show / hide status filter
   *
   * @returns boolean
   */
  const showStatusFilter = () => {
    if (
      loggedRole === ROLE.SUPER_ADMIN ||
      (loggedRole === ROLE.STUDENT &&
        localStorage.getItem("studentEducationType") !== "my-educations")
    ) {
      return true;
    }
    return false;
  };

  /**
   * Show / hide approval filter
   *
   * @returns boolean
   */
  const showApprovallFilter = () => {
    if (
      loggedRole === ROLE.STUDENT &&
      localStorage.getItem("studentEducationType") === "my-educations"
    ) {
      return true;
    }
    return false;
  };

  /**
   * Show / hide search field per logged user
   *
   * @returns boolean (show/hide search field)
   */
  const showSearchField = () => {
    if (
      loggedRole === ROLE.SUPER_ADMIN ||
      (loggedRole === ROLE.STUDENT &&
        localStorage.getItem("studentEducationType") === "other")
    ) {
      return true;
    }
    return false;
  };

  /**
   * Show sidebar drawer
   */
  const showSidebarDrawer = () => {
    if (loggedRole === ROLE.HUB_ADMIN) {
      setIsDrawerVisible(true);
    }
  };

  /**
   * Hide sidebar drawer
   */
  const hideSidebarDrawer = () => {
    setIsDrawerVisible(false);
  };

  /**
   * Click on tab and select educations
   *
   * @param {*} type => selected education type
   * @param {*} element => selected tab
   */
  const selectEducationType = (type, element) => {
    if (isDataLoading) return;

    const studentEducationTypeStored = localStorage.getItem(
      "studentEducationType"
    );

    setTabIndicator(type, element);

    if (type !== studentEducationTypeStored) {
      setIsFiltersDisabled(true);
      showStudentEducations(type);
      localStorage.setItem("studentEducationType", type);
    }
  };

  /**
   * Show Modal
   *
   * @param {*} type => approval / join
   * @returns is Join Modal visible
   */
  const showModal = (type, education) => {
    if (type === "join") {
      setSelectedForJoining(education);
      const description = `${t("EDUCATIONS.JOIN_EDUCATION.CAPTION_PART_ONE")} ${
        education?.hub?.name
      }, ${t("EDUCATIONS.JOIN_EDUCATION.CAPTION_SECOND_PART")}`;
      setJoiningEducationDescription(description);
      setIsJoinVisible(true);
      return;
    }
    setApprovalModalVisible(true);
  };

  /**
   * Open Unblock Education modal
   */
  const unblockModal = () => {
    setIsUnblockVisible(true);
  };

  /**
   * Close Unblock Education Modal
   */
  const closeModal = () => {
    setIsUnblockVisible(false);
  };

  /**
   * Hide Modal
   *
   * @param {*} type => approval/join
   * @param {*} action => apply/cancel
   * @returns is Join Modal visible
   */
  const hideModal = (type, action) => {
    if (type === "join") {
      setIsJoinVisible(false);
      if (action === "apply") {
        showModal("approval");
      }
      return;
    }
    setApprovalModalVisible(false);
  };

  /**
   * Get selected filters
   *
   * @returns selected filters
   */
  const selectedFilters = () => {
    const categoryFilter = JSON.parse(localStorage.getItem("category-filter"));
    const educatorFilter = JSON.parse(localStorage.getItem("educator-filter"));
    const hubFilter = JSON.parse(localStorage.getItem("hub-filter"));
    const statusFilter = JSON.parse(localStorage.getItem("status-filter"));
    const approvalFilter = JSON.parse(localStorage.getItem("approval-filter"));

    const filtersObject = {
      category: categoryFilter !== null && [categoryFilter.category],
      educators: educatorFilter !== null && [educatorFilter.educators],
      hub: hubFilter !== null && [hubFilter.hub],
      educationStatus: statusFilter !== null && [statusFilter.educationStatus],
      approvalFilter: approvalFilter !== null && [approvalFilter.approval],
    };

    for (const key in filtersObject) {
      if (filtersObject.hasOwnProperty(key) && filtersObject[key] === false) {
        delete filtersObject[key];
      }
    }

    return filtersObject;
  };

  /**
   * Filter educations list per selected filter
   */
  const generalFilter = () => {
    localStorage.setItem("isFiltered", "true");
    const selected = selectedFilters();
    const filterArray = Object.values(selected).flat();

    const filteredEducations = defaultEducations.filter(({ filters }) => {
      const objectFilters = Object.values(filters).flat();

      return filterArray.every((value) => objectFilters.includes(value));
    });

    updateEducationState(filteredEducations);
    setLocalStoredEducations(filteredEducations);
    setPage(1);
  };

  /**
   * Handle filter change
   *
   * @param {*} filter => selected filter
   * @param {*} value => selected value from filter
   */
  const handleFilterChange = (filter, value) => {
    switch (filter) {
      case FILTERS.CATEGORY:
        localStorage.setItem(
          "category-filter",
          JSON.stringify({ category: value })
        );
        setSelectedCategory(value);
        generalFilter();
        break;

      case FILTERS.EDUCATOR:
        localStorage.setItem(
          "educator-filter",
          JSON.stringify({ educators: value })
        );
        setSelectedEducator(value);
        generalFilter();
        break;

      case FILTERS.EDUCATION:
        localStorage.setItem(
          "status-filter",
          JSON.stringify({ educationStatus: value })
        );
        setSelectedEducation(value);
        generalFilter();
        break;

      case FILTERS.HUB:
        localStorage.setItem("hub-filter", JSON.stringify({ hub: value }));
        setSelectedHub(value);
        generalFilter();
        break;

      case FILTERS.APPROVAL:
        localStorage.setItem(
          "approval-filter",
          JSON.stringify({ approval: value })
        );
        setSelectedApproval(value);
        generalFilter();
        break;

      case FILTERS.STATUS:
        localStorage.setItem(
          "status-filter",
          JSON.stringify({ educationStatus: value })
        );
        setSelectedStatus(value);
        generalFilter();
        break;

      default:
        reloadFilters();
        break;
    }
  };

  /**
   * Select Education
   *
   * @param {*} education => education
   */
  const selectEducationCard = (education) => {
    setSelectEducation({ data: education });
    setConfirmationModalData(() => ({
      data: {
        type: "block",
        actionButtonText: "Unblock",
        title: `Unblock ${education?.title}`,
        variant: education?.status.blocked ? "contained" : "outlined",
        color: education?.status.blocked ? "primary" : "default",
        description: `Are you sure you want to block education program ${education?.title}? By doing this the education program will not be available for students any more.`,
      },
    }));
    unblockModal();
  };

  /**
   * Block education when user confirm that in modal
   */
  const unblockEducation = () => {
    setIsLoadingButton(true);
    updateBlockedStatus(
      EDUCATIONS(),
      selectEducation.data.id,
      selectEducation.data.status.subCollectionId,
      { status: false }
    )
      .then(() => {
        setIsLoadingButton(false);
        closeModal();
        setAddedEducation(true);
      })
      .catch((err) => {
        setIsLoadingButton(false);
        console.log(err);
      });
  };

  /**
   * Show snackbar on some action
   */
  const handleSnackbar = (message, type) => {
    dispatch({
      message: message,
      success: type,
    });
  };

  /**
   * Join student on selected education
   *
   * @param {*} education => selected education for joining
   * @param  {*} cv => user cv
   */
  const joinEducationRequest = async (education, cv, isApprovalRequired) => {
    setIsLoadingButton(true);
    try {
      await joinEducation(education, cv, isApprovalRequired);
      setIsLoadingButton(false);
      hideModal("join", isApprovalRequired ? "apply" : "no-apply");
      setAddedEducation(true);
    } catch (error) {
      setIsLoadingButton(false);
      handleSnackbar("This action failed, an error ocured.", false);
      console.log("Cannot join participant: ", error);
    }
  };

  /**
   * Generate data for empty state
   *
   * @returns data for empty state
   */
  const emptyStateData = () => {
    let data = {
      title: t("EDUCATIONS.EMPTY_STATE.TITLE"),
      caption: t("EDUCATIONS.EMPTY_STATE.DESCRIPTION"),
      buttonTitle:
        loggedRole !== ROLE.HUB_ADMIN
          ? t("ACTION_BUTTONS.CONTACT_HUB_ADMIN")
          : t("ACTION_BUTTONS.CREATE_NEW_EDUCATION"),
      className: "",
    };

    const isFiltered = localStorage.getItem("isFiltered");
    const isSearched = localStorage.getItem("searched");

    if (
      (paginateEducations?.length <= 0 && isFiltered === "true") ||
      isSearched === "true"
    ) {
      data.title = t("EDUCATIONS.EMPTY_STATE.TITLE_FILTERS");
      data.caption = t("EDUCATIONS.EMPTY_STATE.DESCRIPTION_FILTERS");
      data.buttonTitle = t("ACTION_BUTTONS.RESET_FILTERS");
      data.className = "filters-empty";
    }

    return data;
  };

  /**
   * Handle action on button click in empty state
   */
  const emptyStateAction = () => {
    const isFiltered = localStorage.getItem("isFiltered");
    const isSearched = localStorage.getItem("searched");
    if (
      (paginateEducations?.length <= 0 && isFiltered === "true") ||
      isSearched === "true"
    ) {
      reloadFilters();
    } else {
      showSidebarDrawer();
    }
  };

  /**
   * Handle filters block
   *
   * @returns when to show filters block
   */
  const showFilters = () => {
    if (loggedRole === ROLE.TEACHER) {
      return false;
    }

    if (defaultEducations !== undefined) {
      if (defaultEducations <= 0 && !isDataLoading) {
        return false;
      }

      if (defaultEducations.length > 0 && !isDataLoading) {
        return true;
      }
    }
  };

  return (
    <>
      {loggedRole === ROLE.STUDENT && (
        <div className="student-header padding-right-layout padding-left-layout">
          <div className="student-header-content">
            <PageTitle title={t("EDUCATIONS.TITLE")} />
            <div
              className={`student-pages ${localStorage.getItem(
                "studentEducationType"
              )}`}
            >
              <div
                className={`page ${isDataLoading ? "disable-tabbars" : null}`}
                onClick={(e) => selectEducationType("my-educations", e.target)}
              >
                {t("EDUCATIONS.MY_EDUCATIONS")}
              </div>
              <div
                className={`page ${isDataLoading ? "disable-tabbars" : null}`}
                onClick={(e) => selectEducationType("other", e.target)}
              >
                {t("EDUCATIONS.OTHER_EDUCATIONS")}
              </div>
              <span className="active-page-indicator"></span>
            </div>
          </div>
        </div>
      )}
      <div
        id="educations-list"
        className="routes-layout padding-right-layout padding-left-layout padding-top-layout padding-bottom-layout"
      >
        {loggedRole !== ROLE.STUDENT && (
          <PageTitle title={t("EDUCATIONS.TITLE")} />
        )}
        {showFilters() && (
          <div className="width-100 education-filters-holder display-flex align-items-center justify-content-between">
            <div
              className={`filters display-flex align-items-center ${
                loggedRole === ROLE.STUDENT ? "student-filters" : null
              }`}
            >
              <Typography variant="h5" color="textPrimary">
                {t("EDUCATIONS.FILTERS.TITLE")}
              </Typography>
              {educationFilter() && (
                <FilterSelect
                  placeholder={t("EDUCATIONS.FILTERS.EDUCATION")}
                  options={educationStatuses}
                  defaultValue={selectedEducation}
                  value={selectedEducation}
                  onChange={(value) => handleFilterChange("education", value)}
                  disabledFilter={isFiltersDisabled}
                />
              )}
              <FilterSelect
                placeholder={t("EDUCATIONS.FILTERS.CATEGORY")}
                options={educationCategories}
                defaultValue={selectedCategory}
                value={selectedCategory}
                onChange={(value) => handleFilterChange("category", value)}
                disabledFilter={isFiltersDisabled}
              />
              {showHubFilter() && (
                <FilterSelect
                  placeholder="Hub"
                  options={hubs}
                  defaultValue={selectedHub}
                  value={selectedHub}
                  onChange={(value) => handleFilterChange("hub", value)}
                  disabledFilter={isFiltersDisabled}
                />
              )}
              {showEducatorFilter() && (
                <FilterSelect
                  placeholder={t("EDUCATIONS.FILTERS.EDUCATOR")}
                  options={educatorsList}
                  defaultValue={selectedEducator}
                  value={selectedEducator}
                  onChange={(value) => handleFilterChange("educator", value)}
                  disabledFilter={isFiltersDisabled}
                />
              )}
              {showStatusFilter() && (
                <FilterSelect
                  placeholder={t("EDUCATIONS.FILTERS.STATUS")}
                  options={educationStatuses}
                  defaultValue={selectedStatus}
                  value={selectedStatus}
                  onChange={(value) => handleFilterChange("status", value)}
                  disabledFilter={isFiltersDisabled}
                />
              )}
              {showApprovallFilter() && (
                <FilterSelect
                  placeholder="Approval"
                  options={approvalStatusList}
                  defaultValue={selectedApproval}
                  value={selectedApproval}
                  onChange={(value) => handleFilterChange("approval", value)}
                  disabledFilter={isFiltersDisabled}
                />
              )}
              <img
                className="reload-filters"
                src={reloadFiltersIcon}
                alt="Reload"
                onClick={handleFilterChange}
              />
            </div>

            {loggedRole === ROLE.HUB_ADMIN && (
              <Button
                onClick={showSidebarDrawer}
                startIcon={<img src={plus} alt="New education" />}
                color="primary"
                variant="contained"
                className="add-new-education font-weight-600 auto-width"
              >
                {t("ACTION_BUTTONS.ADD_EDUCATION")}
              </Button>
            )}
            {showSearchField() && (
              <div
                className={`search-educations ${
                  isFiltersDisabled && "disabled-search"
                }`}
              >
                <TextField
                  inputRef={searchField}
                  variant="outlined"
                  type="text"
                  name="search"
                  placeholder={t("EDUCATIONS.SEARCH_PLACEHOLDER")}
                  autoComplete="off"
                  onInput={(e) => searchEducations(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={searchIcon} alt="Search icon" />
                      </InputAdornment>
                    ),
                    disabled: isFiltersDisabled,
                  }}
                />
              </div>
            )}
          </div>
        )}
        {paginateEducations?.length > 0 && !isDataLoading ? (
          <div className="list display-flex flex-wrap">
            {paginateEducations?.map((education) => (
              <EducationCard
                key={education?.id}
                education={education}
                showJoinModal={showModal}
                unblockModal={unblockModal}
                chooseEducation={selectEducationCard}
              />
            ))}
          </div>
        ) : (
          <>
            {isDataLoading && <DataLoader />}
            {!isDataLoading && paginateEducations?.length <= 0 && (
              <EmptyState
                image={EmptyImage}
                title={emptyStateData().title}
                caption={emptyStateData().caption}
                buttonTitle={emptyStateData().buttonTitle}
                hasButton={true}
                emptyStateAction={emptyStateAction}
                link="/"
                className={emptyStateData().className}
              />
            )}
          </>
        )}

        {paginateEducations?.length > 0 && !isDataLoading && (
          <Pagination
            count={educationsCount}
            page={page}
            shape="rounded"
            onChange={handleChange}
          />
        )}
      </div>
      {drawerVisible && (
        <AddEducation isVisible={drawerVisible} onClose={hideSidebarDrawer} />
      )}
      <AppModal
        isVisible={isJoinVisible}
        content={
          <JoinEducation
            onCloseModal={hideModal}
            selectedForJoining={selectedForJoining}
            modalDescription={joiningEducationDescription}
            joinEducationRequest={joinEducationRequest}
            isLoading={isLoadingButton}
          />
        }
      />
      <AppModal
        isVisible={approvalModalVisible}
        content={<ApprovalModal onCloseModal={hideModal} />}
      />
      <AppModal
        isVisible={isUnblockVisible}
        content={
          <ConfirmationModalContent
            data={confirmationModalData.data}
            selectEducation={selectEducation}
            isLoadingButton={isLoadingButton}
            onConfirmAction={unblockEducation}
            onCancel={closeModal}
          />
        }
      />
    </>
  );
};

export default EducationsList;
