import React from "react";
import clsx from "clsx";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { ROUTES } from "router/CONSTANTS";

import { useEducation } from "pages/Educations/context/EducationContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: "white",
      "&:hover": {
        background: "lightgray",
        color: "black",
      },
    },
  })
);

export default function GoBackButton(props: {
  className?: string;
  goBackText?: string;
}) {
  const { singleEducation } = useEducation();

  const classes = useStyles();

  /**
   * Go back to education details page
   */
  const goBack = () => {
    const timeline = localStorage.getItem("selectedTimeline");

    if (timeline !== null) {
      const timelineId = singleEducation?.id;
      const splitUrl = window.location.href.split("/room");
      const appDomen = splitUrl[0];
      const backUrl = `${appDomen}${ROUTES.EDUCATIONS}/${timelineId}`;
      window.open(backUrl, "_blank");
    }
  };

  return (
    <Button onClick={goBack} className={clsx(classes.button, props.className)}>
      {props.goBackText}
    </Button>
  );
}
