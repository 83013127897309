import React from "react";
import Approve from "assets/images/icons/approve-status.svg";
import { Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const ApprovalModal = ({ onCloseModal }) => {
  const { t } = useTranslation();
  return (
    <div className="student-status-content justify-content-center">
      <div className="modal-header">
        <div className="modal-icon">
          <img src={Approve} alt="approve-student" />
        </div>
        <div className="modal-title">
          <Typography variant="h1">{t("EDUCATIONS.APPROVAL.TITLE")}</Typography>
        </div>
        <div className="modal-description">
          <Typography color="textPrimary">
            {t("EDUCATIONS.APPROVAL.DESCRIPTION")}
          </Typography>
        </div>
      </div>
      <div className="modal-actions display-flex justify-content-center">
        <Button variant="contained" color="primary" onClick={onCloseModal}>
          {t("ACTION_BUTTONS.OK_FINE")}
        </Button>
      </div>
    </div>
  );
};

export default ApprovalModal;
