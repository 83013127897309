import React, { useState, useEffect } from "react";
import "auth/css/auth.css";
import Logo from "components/Logo";
import Ilustration from "./Ilustration";
import TextField from "@material-ui/core/TextField";
import { Typography, InputAdornment } from "@material-ui/core";
import showPasswordEye from "assets/images/icons/password-eye-open.svg";
import hidePasswordEye from "assets/images/icons/password-eye-closed.svg";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import { ROUTES } from "router/CONSTANTS";
import { useForm, Controller } from "react-hook-form";
import LoadingButton from "components/LoadingButton";
import { useTranslation } from "react-i18next";
import { createNewUser, setSubcollectionBlocked } from "services/userService";
import { checkInvitedUsers, getInvites, setInvite } from "services/authService";
import { ROLE } from "utils/ROLES";
import * as uuid from "uuid";
import { useSnackbar } from "contexts/SnackbarProvider";
import { handleErrorMessages } from "utils/handleAuthErrors";
import { getHubDetails } from "services/hubService";

const SignUp = () => {
  const { t } = useTranslation();
  const query = useLocation();
  const dispatch = useSnackbar();

  // states
  const [passwordType, setPasswordType] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isPredefinedEmail, setIsPredefinedEmail] = useState(false);
  // default value is 3 which means that role is student
  const [userType, setUserType] = useState(ROLE.STUDENT);
  const [hubId, setHubId] = useState();

  // useForm properties
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  /**
   * Show snackbar
   *
   * @param {*} message => message in snackbar
   * @param {*} type => type of snackbar (true/false)
   */
  const handleSnackbar = (message, type) => {
    dispatch({
      message: message,
      success: type,
    });
  };

  useEffect(() => {
    const getUserEmail = () => {
      const param = query.search;

      if (param.length > 0) {
        const splitParam = param.split("?")[1].split("=");
        const email = new URLSearchParams(param).get("email");
        const userTypeInvite = +new URLSearchParams(param).get("type");
        const hubIdParam = new URLSearchParams(param).get("hubid");
        const paramType = splitParam[0];
        const emailRegex = /\S+@\S+\.\S+/;
        const encodedEmail = email.replace(" ", "+");
        if (paramType === "email") {
          if (encodedEmail !== undefined && encodedEmail.length > 0) {
            if (emailRegex.test(encodedEmail)) {
              setValue("email", encodedEmail);
              setIsPredefinedEmail(true);

              if (userTypeInvite !== 0) {
                setUserType(userTypeInvite);
              }

              if (hubIdParam !== "") {
                setHubId(hubIdParam);
              }
            }
          }
        }
      }
    };
    return getUserEmail();
  }, [query, setValue]);

  /**
   * Submit form when all fields are valid
   * @param {*} data => form fields
   */
  const onSubmitForm = (data) => {
    if (Object.entries(errors).length === 0) {
      const formData = {
        ...data,
        email: data.email.toLowerCase(),
      };
      signUpRequest(formData);
    }
  };

  const history = useHistory();

  // use auth
  const { signUp, authHeightState, setAuthContainerHeight } = useAuth();

  useEffect(() => {
    setAuthContainerHeight();

    return () => {};
  }, [setAuthContainerHeight]);

  /**
   * Signup request to firebase
   * @param {*} email => user email
   * @param {*} password => user password
   */
  const signUpRequest = async ({
    email,
    password,
    firstName,
    lastName,
    type,
  }) => {
    const invite = uuid.v4();

    try {
      setLoading(true);
      const isInvitationExist = await checkInvitedUsers(email);

      if (!isPredefinedEmail) {
        if (isInvitationExist) {
          handleSnackbar(t("AUTH.ERROR.EMAIL_EXIST"), false);
          setLoading(false);
          return;
        }
      }
      getInvites(invite)
        .then((res) => {
          const invites = res.docs.map((doc) => doc.data());
          if (invites.length === 0) {
            setInvite(invite).then(() => {
              (async () => {
                try {
                  let user = {
                    firstName,
                    lastName,
                    email,
                    id: "",
                    type: {
                      name:
                        userType === ROLE.HUB_ADMIN
                          ? "hub admin"
                          : userType === ROLE.TEACHER
                          ? "teacher"
                          : "student",
                      roleId: userType,
                    },
                    blocked: false,
                  };

                  if (userType === ROLE.TEACHER) {
                    const hubDetails = await getHubDetails(hubId);
                    const hub = {
                      id: hubDetails.id,
                      name: hubDetails.data().name,
                    };

                    user = Object.assign(user, { hub });
                  }
                  const status = false;
                  signUp(email, password)
                    .then((res) => {
                      user.id = res.user.uid;
                      createNewUser(res.user.uid, user)
                        .then(() => {
                          setSubcollectionBlocked(user.id, { status })
                            .then((res) => {
                              setLoading(false);
                              history.push(ROUTES.EDUCATIONS);
                            })
                            .catch((err) => {
                              handleSnackbar(
                                handleErrorMessages(err, t),
                                false
                              );
                              setLoading(false);
                            });
                        })
                        .catch((err) => {
                          handleSnackbar(handleErrorMessages(err, t), false);
                        });
                    })
                    .catch((err) => {
                      handleSnackbar(err.message, false);
                      setLoading(false);
                    });
                } catch (error) {
                  handleSnackbar(handleErrorMessages(error, t), false);
                  console.log("Register user error: ", error);
                  setLoading(false);
                }
              })();
            });
          }
        })
        .catch((err) => {
          handleSnackbar(handleErrorMessages(err, t), false);
          setLoading(false);
        });
    } catch (error) {
      handleSnackbar(handleErrorMessages(error, t), false);
      setLoading(false);
    }
  };

  /**
   * Show / hide password on click eye icon
   * @param {*} type => can be true or false
   */
  const showHidePassword = (type) => {
    setPasswordType(!type);
  };

  return (
    <div className="auth-container" style={{ height: authHeightState }}>
      <div className="auth-content display-flex">
        <div className="auth-form">
          <div className="data-holder">
            <Logo />
            <div className="form-box">
              <Typography style={{ marginBottom: "12px" }} variant="subtitle2">
                {t("AUTH.SIGN_UP.TITLE")}
              </Typography>
              <p style={{ marginBottom: "48px" }}>
                {t("AUTH.SIGN_UP.DESCRIPTION")}
              </p>
              <form
                className="width-100"
                autoComplete="off"
                noValidate
                onSubmit={handleSubmit(onSubmitForm)}
              >
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: {
                      value: true,
                      message: t("AUTH.ERROR.REQUIRED"),
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: t("AUTH.ERROR.EMAIL_FORMAT"),
                    },
                  }}
                  render={({ field: { onChange, ref } }) => (
                    <TextField
                      type="email"
                      variant="outlined"
                      label={t("AUTH.INPUTS.EMAIL")}
                      inputRef={ref}
                      onChange={onChange}
                      style={{ marginBottom: "20px" }}
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                      inputProps={{
                        "aria-readonly": isPredefinedEmail,
                        readOnly: isPredefinedEmail,
                      }}
                    />
                  )}
                />
                <TextField
                  type="text"
                  label={t("AUTH.INPUTS.FIRST_NAME")}
                  name="firstName"
                  variant="outlined"
                  style={{ marginBottom: "20px" }}
                  {...register("firstName", {
                    required: {
                      value: true,
                      message: t("AUTH.ERROR.REQUIRED"),
                    },
                  })}
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName?.message}
                />
                <TextField
                  type="text"
                  label={t("AUTH.INPUTS.LAST_NAME")}
                  name="lastName"
                  variant="outlined"
                  style={{ marginBottom: "20px" }}
                  {...register("lastName", {
                    required: {
                      value: true,
                      message: t("AUTH.ERROR.REQUIRED"),
                    },
                  })}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName?.message}
                />
                <TextField
                  type={passwordType ? "password" : "text"}
                  label={t("AUTH.INPUTS.PASSWORD")}
                  name="password"
                  variant="outlined"
                  style={{ marginBottom: "20px" }}
                  {...register("password", {
                    required: {
                      value: true,
                      message: t("AUTH.ERROR.REQUIRED"),
                    },
                  })}
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          onClick={() => showHidePassword(passwordType)}
                          src={passwordType ? showPasswordEye : hidePasswordEye}
                          alt="Password"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  type={passwordType ? "password" : "text"}
                  name="confirmPassword"
                  label={t("AUTH.INPUTS.REPEAT_PASSWORD")}
                  variant="outlined"
                  {...register("confirmPassword", {
                    required: {
                      value: true,
                      message: t("AUTH.ERROR.REQUIRED"),
                    },
                    validate: (value) =>
                      value === getValues("password") ||
                      t("AUTH.ERROR.CONFIRM_PASSWORD"),
                  })}
                  error={Boolean(errors.confirmPassword)}
                  helperText={errors.confirmPassword?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          onClick={() => showHidePassword(passwordType)}
                          src={passwordType ? showPasswordEye : hidePasswordEye}
                          alt="Password"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <LoadingButton
                  isLoading={loading}
                  text={t("ACTION_BUTTONS.SIGN_UP")}
                />
              </form>
            </div>
          </div>
        </div>
        <Ilustration />
      </div>
    </div>
  );
};

export default SignUp;
