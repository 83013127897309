// A wrapper for <Route> that redirects to educations if user is authenticated

import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import ResetPassword from "auth/ResetPassword";
import { ROUTES } from "router/CONSTANTS";
import { useAuth } from "./context/AuthContext";

// screen if you're authenticated.
function AuthRoute({ children, roles, ...rest }) {
  const { currentUser } = useAuth();
  const pathSearch = useLocation().search;
  const searchValue = pathSearch.split("&")[0].split("=")[1];

  return (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser) {
          return (
            <Redirect
              to={{
                pathname: ROUTES.EDUCATIONS,
                state: { from: props.location },
              }}
            />
          );
        }

        if (searchValue === "resetPassword") {
          return <ResetPassword {...props} />;
        }

        // authorised so return component
        return <>{children}</>;
      }}
    />
  );
}

export default AuthRoute;
