import { useAuth } from "auth/context/AuthContext";
import moment from "moment";
import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router";
import { GET_BLOCKED, PARTICIPANTS, EDUCATIONS } from "services/CONSTANTS";
import {
  getEducationDetails,
  getHubAdminEducations,
  getStudentEducations,
} from "services/educationService";
import { EDUCATION_STATUS } from "utils/educationStatus";
import { PARTICIPANT_STATUS } from "utils/participantStatus";
import { ROLE } from "utils/ROLES";
import { db } from "../../../firebase/firebase";
import { useHistory } from "react-router-dom";
import { ROUTES } from "router/CONSTANTS";

const EducationContext = React.createContext();

// useEducation custom hook
export const useEducation = () => {
  return useContext(EducationContext);
};

// create ref for educations
const educationsRef = db.collection(EDUCATIONS()).orderBy("created_at", "desc");

// education provider
const EducationProvider = ({ children }) => {
  const { currentUser, loggedRole } = useAuth();
  const educationId = useParams();

  const history = useHistory();

  // on single education set selected tab to 0
  const [selectedTab, setSelectedTab] = useState(0);
  const [isStartLessonFromHeader, setIsStartLessonFromHeader] = useState(false);

  const [educationsList, setEducationsList] = useState();
  const [singleEducation, setSingleEducation] = useState("");
  const [studentEducations, setStudentEducations] = useState("my-educations");
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isAddedEducation, setIsAddedEducation] = useState(false);
  const [isEditedEducation, setIsEditedEducation] = useState(false);
  const [singleEducationLoader, setSingleEducationLoader] = useState(true);
  const [isLessonEnded, setIsLessonEnded] = useState(false);

  /**
   * Update state on change
   *
   * @param {*} collections => whole firestore collection
   */
  const updateState = (collections) => {
    const uid = localStorage.getItem("uid");
    if (collections.length > 0) {
      const TODAY = moment(new Date());
      const userRole = +localStorage.getItem("uType");

      /**
       * Handle education status
       *
       * @param {*} startDate => start date for education
       * @param {*} endDate => end date for education
       * @returns education status (scheduled, active, finalized)
       */
      const handleEducationStatus = (startDate, endDate) => {
        if (startDate !== undefined && endDate !== undefined) {
          const start =
            userRole !== ROLE.STUDENT
              ? moment(new Date(startDate.toDate()))
              : moment(new Date(startDate));
          const end =
            userRole !== ROLE.STUDENT
              ? moment(new Date(endDate.toDate()))
              : moment(new Date(endDate));

          let result;

          if (TODAY.isBefore(start)) {
            result = EDUCATION_STATUS.SCHEDULED.id;
            return result;
          }

          if (TODAY.isBetween(start, end)) {
            result = EDUCATION_STATUS.ACTIVE.id;
            return result;
          }

          if (TODAY.isAfter(end)) {
            result = EDUCATION_STATUS.PLANNED.id;
            return result;
          }
        }
      };

      Promise.all(
        collections.map(async (res) => {
          // get blocked status
          const blockedCollection = await db
            .collection(EDUCATIONS())
            .doc(res.id)
            .collection(GET_BLOCKED())
            .get();

          const status = {
            subCollectionId: blockedCollection.docs[0].id,
            blocked: blockedCollection.docs[0].data().status,
          };

          // get participants for education
          const participantsCollection = await db
            .collection(EDUCATIONS())
            .doc(res.id)
            .collection(PARTICIPANTS())
            .get();
          // map participants
          const participants = participantsCollection.docs.map((doc) =>
            doc.data()
          );

          // get approval status on education
          const approvalStatus = await db
            .collection(EDUCATIONS())
            .doc(res.id)
            .collection(PARTICIPANTS())
            .where("userId", "==", `users/${uid}`)
            .get();

          const firestoreReponse = approvalStatus;
          let approvalFilter;
          let approvalStatusData = false;

          if (firestoreReponse.docs.length > 0) {
            approvalStatusData = firestoreReponse.docs[0].data();

            if (
              approvalStatusData.approvalStatus.id ===
              PARTICIPANT_STATUS.PENDING.id
            ) {
              approvalFilter = "pending-status";
            }

            if (
              approvalStatusData.approvalStatus.id ===
              PARTICIPANT_STATUS.APPROVED.id
            ) {
              approvalFilter = "approved-status";
            }

            if (
              approvalStatusData.approvalStatus.id ===
              PARTICIPANT_STATUS.DISSAPROVED.id
            ) {
              approvalFilter = "dissaproved-status";
            }
          }

          // handle start and end date
          const educationStatus = status.blocked
            ? EDUCATION_STATUS.BLOCKED.id
            : handleEducationStatus(res.startDate, res.endDate);

          return {
            ...res,
            status,
            participants,
            approvalStatusData,
            filters: {
              category: [res.category.id],
              educationStatus: [educationStatus],
              hub: [res.hub.id],
              educators: res.educators,
              approval: [approvalFilter],
            },
          };
        })
      )
        .then((values) => {
          if (values.length > 0) {
            setEducationsList(values);
          }
          setIsAddedEducation(false);
          setIsDataLoading(false);
        })
        .catch((err) => {
          console.log("Cannot get educations", err);
          setIsDataLoading(false);
          setIsAddedEducation(false);
        });
    } else {
      setEducationsList([]);
      setIsDataLoading(false);
      setIsAddedEducation(false);
    }
  };

  // get all educations
  useEffect(() => {
    let mounted = true;
    setIsDataLoading(true);
    if (!currentUser) return;
    if (Object.keys(educationId).length === 0) {
      if (mounted) {
        if (loggedRole !== null && loggedRole !== undefined) {
          const uid = localStorage.getItem("uid");
          (async () => {
            switch (loggedRole) {
              case ROLE.HUB_ADMIN:
                const myHubId = await getHubAdminEducations(currentUser?.email);
                await educationsRef
                  .where("hub.id", "==", myHubId)
                  .get()
                  .then((collections) => {
                    const parsedDocuments = collections.docs.map((doc) =>
                      doc.data()
                    );
                    updateState(parsedDocuments);
                  });
                break;

              case ROLE.TEACHER:
                await educationsRef
                  .where("educators", "array-contains", uid)
                  .get()
                  .then((collections) => {
                    const parsedDocuments = collections.docs.map((doc) =>
                      doc.data()
                    );
                    updateState(parsedDocuments);
                  });
                break;

              case ROLE.STUDENT:
                const educationForStudent = await getStudentEducations(
                  uid,
                  studentEducations
                );

                let parsedResponse = educationForStudent;

                if (educationForStudent.length > 0) {
                  parsedResponse = await educationForStudent.map((doc) =>
                    Object.assign(doc.data, { id: doc.id })
                  );
                }

                updateState(parsedResponse);

                break;

              default:
                await educationsRef.get().then((documents) => {
                  const parsedDocuments = documents.docs.map((doc) =>
                    doc.data()
                  );
                  updateState(parsedDocuments);
                });
                break;
            }
          })();
        }
      }
    }
    return () => {
      mounted = false;
      setIsAddedEducation(false);
      setEducationsList([]);
    };
  }, [
    educationId,
    currentUser,
    loggedRole,
    studentEducations,
    isAddedEducation,
  ]);

  // get single education
  useEffect(() => {
    let singleEducationMounted = true;
    let educationDetails = null;
    setSingleEducationLoader(true);
    if (!currentUser) return;
    if (Object.keys(educationId).length > 0) {
      const { id } = educationId;
      const TODAY = moment(new Date());

      /**
       * Handle education status
       *
       * @param {*} startDate => start date for education
       * @param {*} endDate => end date for education
       * @returns education status (scheduled, active, finalized)
       */
      const handleEducationStatus = (startDate, endDate) => {
        if (startDate !== undefined && endDate !== undefined) {
          const start = moment(new Date(startDate.toDate()));
          const end = moment(new Date(endDate.toDate()));

          let result;

          if (TODAY.isBefore(start)) {
            result = EDUCATION_STATUS.SCHEDULED.id;
            return result;
          }

          if (TODAY.isBetween(start, end)) {
            result = EDUCATION_STATUS.ACTIVE.id;
            return result;
          }

          if (TODAY.isAfter(end)) {
            result = EDUCATION_STATUS.PLANNED.id;
            return result;
          }
        }
      };

      if (singleEducationMounted) {
        (async () => {
          return await getEducationDetails(id)
            .then(async (res) => {
              const subcollection = await res.ref
                .collection(GET_BLOCKED())
                .get();

              const subcollectionId = await subcollection.docs[0].id;
              const blocked = await subcollection.docs[0].data().status;

              // get participants for education
              const participantsCollection = await db
                .collection(EDUCATIONS())
                .doc(res.data().id)
                .collection(PARTICIPANTS())
                .get();
              // map participants
              const participants = participantsCollection.docs.map((doc) =>
                doc.data()
              );

              // handle start and end date
              const educationStatus = blocked
                ? EDUCATION_STATUS.BLOCKED.id
                : handleEducationStatus(
                    res.data().startDate,
                    res.data().endDate
                  );

              educationDetails = {
                ...res.data(),
                status: { subcollectionId, blocked },
                educationStatus,
                participants,
              };

              if (educationDetails) {
                setSingleEducation(educationDetails);
                setIsEditedEducation(false);
                setSingleEducationLoader(false);
              }
            })
            .catch((err) => {
              console.log("Cannot get education details: ", err);
              setIsEditedEducation(false);
              setSingleEducationLoader(false);
            });
        })();
      }
    }
    return () => {
      singleEducationMounted = false;
      educationDetails = null;
      setIsEditedEducation(false);
      setSingleEducation(null);
    };
  }, [educationId, currentUser, isEditedEducation]);

  // data for Education Context
  const data = {
    selectedTab,
    selectTab: (value) => setSelectedTab(value),
    isStartLessonFromHeader,
    selectFromHeader: (value) => setIsStartLessonFromHeader(value),
    educationsList,
    singleEducation,
    paginateEducations: (page) => setEducationsList(page),
    showStudentEducations: (value) => setStudentEducations(value),
    isDataLoading,
    isAddedEducation,
    setAddedEducation: (value) => setIsAddedEducation(value),
    setEditedEducation: (value) => setIsEditedEducation(value),
    singleEducationLoader,
    isLessonEnded,
    setLessonEndStatus: (value) => setIsLessonEnded(value),
  };

  return (
    <EducationContext.Provider value={data}>
      {children}
    </EducationContext.Provider>
  );
};

export { EducationProvider };
