import React, { useContext, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { ROUTES } from "router/CONSTANTS";
import { useAuth } from "auth/context/AuthContext";
import { db } from "../../../firebase/firebase";
import { HUBS } from "services/CONSTANTS";
import { useHistory } from "react-router-dom";

const HubContext = React.createContext();

export const useHub = () => {
  return useContext(HubContext);
};

const HubProvider = ({ children }) => {
  const { currentUser, loggedRole } = useAuth();
  const hubId = useParams();
  const location = useLocation();
  const history = useHistory();
  const [hubsList, setHubsList] = useState([]);
  const [hubDetails, setHubDetails] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(true);

  // hubs list
  useEffect(() => {
    if (!currentUser) return;
    if (loggedRole === undefined || loggedRole === null) return;
    if (Object.keys(hubId).length !== 0 && location.pathname === ROUTES.MY_HUB)
      return;
    setIsDataLoading(true);
    const getHubsList = db
      .collection(HUBS())
      .orderBy("created_at", "desc")
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          const hubsData = snapshot?.docs.map((doc) => {
            const address =
              doc.data().address?.city !== ""
                ? `${doc.data().address?.city}, `
                : "";
            const country =
              doc.data().address?.country !== ""
                ? `${doc.data().address?.country?.name}, `
                : "";
            const zip =
              doc.data().address.zip !== "" ? `${doc.data().address.zip}` : "";

            const fullAddress = address + country + zip;

            return {
              ...doc.data(),
              hubId: doc.id,
              fullAddress: fullAddress !== "" ? fullAddress : "/",
              status: { subcollectionId: "", blocked: doc.data().blocked },
            };
          });

          if (hubsData) {
            setHubsList(hubsData);
            setIsDataLoading(false);
          }
        } else {
          setIsDataLoading(false);
          setHubsList(snapshot.docs);
        }
      });
    return () => {
      getHubsList();
    };
  }, [currentUser, loggedRole, hubId, location]);

  // single hub
  useEffect(() => {
    setIsDataLoading(true);
    if (!currentUser) return;
    if (loggedRole === undefined || loggedRole === null) return;
    if (Object.keys(hubId).length <= 0) return;
    const { id } = hubId;

    const singleHubData = db
      .collection(HUBS())
      .doc(id)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          if (snapshot.data().blocked) {
            history.push(ROUTES.HUBS);
            return;
          }

          const hub = {
            ...snapshot.data(),
            hubId: snapshot.id,
            status: { subcollectionId: "", blocked: snapshot.data()?.blocked },
          };

          if (hub) {
            setHubDetails(hub);
            setIsDataLoading(false);
          }
        } else {
          history.push(ROUTES.HUBS);
          return;
        }
      });

    return () => {
      singleHubData();
    };
  }, [currentUser, loggedRole, hubId, history]);

  // get my hub
  useEffect(() => {
    if (!currentUser) return;
    if (loggedRole === undefined || loggedRole === null) return;
    if (location.pathname !== ROUTES.MY_HUB) return;

    const myHubData = db
      .collection(HUBS())
      .where("administrator.email", "==", currentUser.email)
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          const hub = {
            hubId: snapshot.docs[0].id,
            ...snapshot.docs[0].data(),
          };

          if (hub) {
            setHubDetails(hub);
            setIsDataLoading(false);
          }
        } else {
          history.push(ROUTES.HUBS);
          return;
        }
      });
    return () => {
      myHubData();
    };
  }, [currentUser, loggedRole, location, history]);

  const data = { hubsList, hubDetails, isDataLoading };

  return <HubContext.Provider value={data}>{children}</HubContext.Provider>;
};

export { HubProvider };
