import { db } from "../firebase/firebase";
import { NOTIFICATIONS } from "./CONSTANTS";
import { getDocuments } from "./educationService";

// get notifications for logged user
const getAllNotifications = (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const notifications = await db
        .collection(NOTIFICATIONS())
        .where("recepient", "==", userId)
        .orderBy("notificationDateTime", "desc")
        .get();
      resolve(notifications);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

// create new notification
const createNewNotification = (newNotification) => {
  return new Promise(async (resolve, reject) => {
    try {
      const createNotification = await db
        .collection(NOTIFICATIONS())
        .add(newNotification);
      resolve(createNotification);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

const readNotificationsInDropdown = (documents) => {
  return new Promise(async (resolve, reject) => {
    try {
      const notificationRef = documents.map((document) => {
        return db.collection(NOTIFICATIONS()).doc(document);
      });

      const updateSeenField = notificationRef.map(
        async (notification) => await notification.update({ seen: true })
      );

      await Promise.all(updateSeenField);

      resolve(true);
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

export {
  getAllNotifications,
  createNewNotification,
  readNotificationsInDropdown,
};
