import React, { useState, useEffect } from "react";
import "auth/css/auth.css";
import Logo from "components/Logo";
import Ilustration from "./Ilustration";
import TextField from "@material-ui/core/TextField";
import { Typography, Button } from "@material-ui/core";
import resetLinkIlustration from "assets/images/auth/auth-success.svg";
import { useForm } from "react-hook-form";
import { useAuth } from "./context/AuthContext";
import { useHistory } from "react-router-dom";
import { ROUTES } from "router/CONSTANTS";
import LoadingButton from "components/LoadingButton";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "contexts/SnackbarProvider";
import { handleErrorMessages } from "utils/handleAuthErrors";

/**
 * Info message window
 * @returns InfoMessage component after succes API call for Reset password
 */
const InfoMessage = ({ hideInfoMessage, title, description, button }) => {
  const history = useHistory();

  /**
   * Redirect to login after success email
   */
  const redirectToLogin = () => {
    hideInfoMessage();
    history.push(ROUTES.LOGIN);
  };
  return (
    <div className="info-message-reset-password display-flex justify-content-center align-items-start">
      <div className="box">
        <img src={resetLinkIlustration} alt="Reset password" />
        <Typography className="h1" variant="h1">
          {title}
        </Typography>
        <Typography variant="body1">{description}</Typography>
        <Button variant="contained" color="primary" onClick={redirectToLogin}>
          {button}
        </Button>
      </div>
    </div>
  );
};

const ForgotPassword = () => {
  const { t } = useTranslation();
  // use snackbar
  const dispatch = useSnackbar();

  /**
   * Show snackbar
   *
   * @param {*} message => message in snackbar
   * @param {*} type => type of snackbar (true/false)
   */
  const handleSnackbar = (message, type) => {
    dispatch({
      message: message,
      success: type,
    });
  };

  // useForm properties
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  /**
   * Submit form when all fields are valid
   * @param {*} data => form fields
   */
  const onSubmitForm = (data) => {
    if (Object.entries(errors).length === 0) {
      resetPasswordRequest(data);
    }
  };

  // states
  const [infoMessage, setInfoMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const { sendEmailForResetPassword, authHeightState, setAuthContainerHeight } =
    useAuth();

  useEffect(() => {
    setAuthContainerHeight();

    return () => {};
  }, [setAuthContainerHeight]);

  /**
   * Handle info mesage box
   */
  const handleInfoMessage = () => {
    setInfoMessage(!infoMessage);
  };

  /**
   * Reset password to firebase
   * @param {*} email => user email
   */
  const resetPasswordRequest = async ({ email }) => {
    try {
      setLoading(true);
      await sendEmailForResetPassword(email);
      setValue("email", "");
      handleInfoMessage();
    } catch (error) {
      handleSnackbar(handleErrorMessages(error, t), false);
    }

    setLoading(false);
  };

  return (
    <div className="auth-container" style={{ height: authHeightState }}>
      <div className="auth-content display-flex">
        <div className="auth-form">
          <div className="data-holder">
            <Logo />
            <div className="form-box">
              <Typography style={{ marginBottom: "12px" }} variant="subtitle2">
                {t("AUTH.RESET_PASSWORD.TITLE")}
              </Typography>
              <p style={{ marginBottom: "48px" }}>
                {t("AUTH.RESET_PASSWORD.DESCRIPTION")}
              </p>
              <form
                className="width-100"
                autoComplete="off"
                noValidate
                onSubmit={handleSubmit(onSubmitForm)}
              >
                <TextField
                  type="email"
                  label={t("AUTH.INPUTS.EMAIL")}
                  name="email"
                  variant="outlined"
                  {...register("email", {
                    required: {
                      value: true,
                      message: t("AUTH.ERROR.REQUIRED"),
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: t("AUTH.ERROR.EMAIL_FORMAT"),
                    },
                  })}
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                />
                <LoadingButton
                  isLoading={loading}
                  text={t("ACTION_BUTTONS.SEND_RESET_LINK")}
                />
              </form>
            </div>
          </div>
        </div>
        <Ilustration />
      </div>

      {infoMessage && (
        <InfoMessage
          title={t("AUTH.RESET_PASSWORD.RESET_LINK.TITLE")}
          description={t("AUTH.RESET_PASSWORD.RESET_LINK.DESCRIPTION")}
          button={t("AUTH.RESET_PASSWORD.RESET_LINK.BUTTON")}
          hideInfoMessage={handleInfoMessage}
        />
      )}
    </div>
  );
};

export default ForgotPassword;
