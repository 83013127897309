import React from "react";
import avatar from "assets/images/icons/avatar.svg";
import { Typography } from "@material-ui/core";
import "./css/default-teacher.css";

const DefaultTeacher = (props) => {
  // get teachers data
  const { teacher } = props;

  return (
    <>
      <div className="default-teacher-card display-flex">
        <img src={avatar} alt="Avatar" />
        <div className="teacher-information">
          <Typography variant="h5" color="textPrimary">
            {teacher?.name}
          </Typography>
          <p>{teacher?.email}</p>
        </div>
      </div>
    </>
  );
};

export default DefaultTeacher;
