const PARTICIPANT_STATUS = {
  PENDING: { id: 0, name: "Pending" },
  APPROVED: { id: 1, name: "Approve" },
  DISSAPROVED: { id: 2, name: "Dissaproved" },
};

const getParticipantStatus = (t) => {
  return [
    { id: "pending-status", name: t("EDUCATIONS.PARTICIPANT_STATUS.PENDING") },
    {
      id: "approved-status",
      name: t("EDUCATIONS.PARTICIPANT_STATUS.APPROVED"),
    },
    {
      id: "dissaproved-status",
      name: t("EDUCATIONS.PARTICIPANT_STATUS.DISSAPROVED"),
    },
  ];
};

export { PARTICIPANT_STATUS, getParticipantStatus };
