import moment from "moment";

/**
 * Disable dates in date picker
 *
 * @param {*} current => selected date
 * @param {*} type => type for date picker (start or end) date
 * @returns disabled date
 */
const disabledDates = (current, type, startDateValue) => {
  const startOfTheDay = moment().startOf("day");
  if (type === "start-date") {
    return current && current < startOfTheDay;
  }
  return current && current < startDateValue;
};

/**
 * Disable hours in time picker
 *
 * @param {*} type => type for time picker (start or end) time
 * @returns hours (also disabled)
 */
const disabledHours = (type, startDateValue, endDateValue, startTimeValue) => {
  const isToday = moment(startDateValue).isSame(moment(), "date");
  const today = moment(new Date()).hour();
  const selectedStartTime = moment(new Date(startTimeValue)).hour();
  const isSameDay =
    moment(new Date(startDateValue)).date() ===
    moment(new Date(endDateValue)).date();
  let hours = [];

  if (isToday && !type) {
    for (let i = 0; i < today; i++) {
      hours.push(i);
    }
  }

  if (type === "end-hours" && isSameDay) {
    for (let i = 0; i < selectedStartTime; i++) {
      hours.push(i);
    }
  }

  return hours;
};

/**
 * Disable minutes in time picker
 *
 * @param {*} type => type for time picker (start or end) time
 * @returns minutes (also disabled)
 */
const disabledMinutes = (
  type,
  startDateValue,
  endDateValue,
  startTimeValue,
  reloadMinutes
) => {
  const isToday = moment(startDateValue).isSame(moment(), "day");
  const minutesForToday = moment(new Date()).minute() + 1;

  const selectedStartTime = moment(new Date(startTimeValue)).minute() + 1;
  const isSameDay =
    moment(new Date(startDateValue)).date() ===
    moment(new Date(endDateValue)).date();

  let minutes = [];

  if (isToday && type !== "end-minutes" && !reloadMinutes) {
    for (let i = 0; i < minutesForToday; i++) {
      minutes.push(i);
    }
  }

  if (type === "end-minutes" && isSameDay && !reloadMinutes) {
    for (let i = 0; i < selectedStartTime; i++) {
      minutes.push(i);
    }
  }

  return minutes;
};

export { disabledDates, disabledHours, disabledMinutes };
