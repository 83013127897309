import { GET_BLOCKED, GET_USERS } from "./CONSTANTS";
import { db } from "../firebase/firebase";

// set subcollection for block status
const setSubcollectionBlocked = (collectionName, uid, status) => {
  return new Promise((resolve, reject) => {
    try {
      db.collection(collectionName)
        .doc(uid)
        .collection(GET_BLOCKED())
        .add(status)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR");
    }
  });
};

// update blocked status
const updateBlockedStatus = (
  collectionName,
  collectionDocUid,
  subCollectionDocUid,
  status
) => {
  return new Promise((resolve, reject) => {
    try {
      db.collection(collectionName)
        .doc(collectionDocUid)
        .collection(GET_BLOCKED())
        .doc(subCollectionDocUid)
        .update(status)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR");
    }
  });
};

// get blocked status
const getBlockedStatus = (docPath) => {
  return new Promise(async (resolve, reject) => {
    try {
      const status = await db
        .collection(GET_USERS())
        .doc(docPath)
        .collection(GET_BLOCKED())
        .get();
      resolve(status.docs[0].data());
    } catch (error) {
      reject(error);
      console.log("SYSTEM ERROR: ", error);
    }
  });
};

export { setSubcollectionBlocked, updateBlockedStatus, getBlockedStatus };
