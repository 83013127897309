import React from "react";
import "./css/empty-state.css";
import { Link } from "react-router-dom";
import { Typography, Button } from "@material-ui/core";
import plusIcon from "assets/images/icons/white-plus.svg";

const EmptyState = (props) => {
  const {
    title,
    image,
    caption,
    buttonTitle,
    hasButton,
    emptyStateAction,
    link,
    hasButtonIcon,
    className,
  } = props;

  return (
    <div
      className={`empty-state display-flex flex-direction-column align-items-center justify-content-center ${className}`}
    >
      <div className="image">
        <img src={image} alt="empy-state" className="width-100" />
      </div>
      <div className="content">
        <Typography variant="subtitle2">{title}</Typography>
        <Typography>{caption}</Typography>
      </div>
      {hasButton && (
        <div className="link-btn display-flex">
          {link === "/" ? (
            <Button
              onClick={emptyStateAction}
              variant="contained"
              color="primary"
              className="font-weight-600 empty-state-btn"
              startIcon={
                hasButtonIcon ? (
                  <img
                    style={{ width: "17px", height: "17px" }}
                    src={plusIcon}
                    alt="Plus icon"
                  />
                ) : null
              }
            >
              {buttonTitle}
            </Button>
          ) : (
            <Link to={link}>{buttonTitle}</Link>
          )}
        </div>
      )}
    </div>
  );
};

export default EmptyState;
