import TextField from "@material-ui/core/TextField";
import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import LoadingButton from "components/LoadingButton";
import "./css/send-message-input.css";
import { useTranslation } from "react-i18next";

const SendMessageInput = (props) => {
  const { onSendMessage, isLoading } = props;
  const { t } = useTranslation();
  // useForm properties
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      message: "",
    },
  });

  const inputRef = useRef(null);

  // states
  const [activeClass, setActiveClass] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);

  /**
   * Submit form and send message
   *
   * @param {*} data => message from input
   */
  const onSubmitForm = async (data) => {
    await onSendMessage(data);
    const isMessageSent = localStorage.getItem("isMessageSent");
    if (isMessageSent === "true") {
      if (inputRef.current !== null) {
        setValue("message", "");
        inputRef.current.value = "";
        handleButtonDisable(inputRef, true);
      }
    }
  };

  /**
   * Toggle class on parent div of input
   */
  const toggleActiveClass = () => {
    setActiveClass(!activeClass);
  };

  /**
   * Handle button disable state
   *
   * @param {*} e => input
   * @param {*} cleard => is input cleared after message
   * @returns input state
   */
  const handleButtonDisable = (e, cleared) => {
    const value = cleared ? e.current.value : e.target.value;

    if (value.trim() === "") {
      setDisableBtn(true);
      return;
    }

    if (value.length > 1) {
      return;
    }

    setDisableBtn(false);
  };

  /**
   * Submit form when enter is pressed
   * @param {*} e => event for input
   */
  const handleEnterPress = (e) => {
    const isEnterPressed = e.code === "Enter" || e.which === 13 ? true : false;

    if (isEnterPressed) {
      const inputValue = inputRef.current.value.trim();

      if (inputValue !== "" && !isLoading) {
        onSubmitForm({ message: inputValue });
      }
    }
  };

  return (
    <div
      onFocus={toggleActiveClass}
      onBlur={toggleActiveClass}
      className={`send-message-input width-100 ${
        activeClass && "active-input"
      }`}
    >
      <form
        className="width-100 display-flex align-items-center"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onSubmitForm)}
      >
        <Controller
          control={control}
          name="message"
          rules={{
            required: {
              value: true,
              message: t("EDUCATIONS.LIVE_LESSON.CHAT.CHAT_ERROR"),
            },
          }}
          render={({ field: { onChange } }) => (
            <TextField
              type="text"
              placeholder={t("EDUCATIONS.LIVE_LESSON.CHAT.PLACEHOLDER")}
              variant="outlined"
              className="send-message-input-field"
              multiline
              maxRows={2}
              onChange={onChange}
              onInput={(e) => handleButtonDisable(e)}
              onKeyPress={handleEnterPress}
              inputRef={inputRef}
              inputProps={{
                readOnly: isLoading,
              }}
            />
          )}
        />
        <LoadingButton
          isLoading={isLoading}
          disabled={disableBtn}
          text={t("ACTION_BUTTONS.SEND")}
          className="auto-width font-weight-600 send-message-btn"
        />
      </form>
    </div>
  );
};

export default SendMessageInput;
