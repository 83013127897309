import React from "react";
import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "12px",
    height: "100%",
  },
  container: {
    position: "relative",
    flex: "1",
  },
  innerContainer: {
    display: "flex",
    width: "888px",
    height: "auto",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px 0px rgba(40, 42, 43, 0.3)",
    overflow: "hidden",
    position: "relative",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      height: "auto",
      margin: "auto",
      maxWidth: "700px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      height: "auto",
      width: "100%",
      margin: "auto",
      maxWidth: "300px",
    },
  },
  content: {
    background: "white",
    width: "100%",
    padding: "4em",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      padding: "2em",
    },
  },
  title: {
    color: "white",
    margin: "1em 0 0",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      fontSize: "1.1rem",
    },
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();
  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.content}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default IntroContainer;
