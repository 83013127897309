const EDUCATION_CATEGORY = {
  WEB: { id: 1, name: "Web programming" },
  MOBILE: { id: 2, name: "Mobile programming" },
  DESIGN: { id: 3, name: "Design" },
  OTHER: { id: 4, name: "Other" },
};

const categories = (t) => {
  return [
    { id: 1, name: t("CATEGORIES.WEB_PROGRAMMING") },
    { id: 2, name: t("CATEGORIES.MOBILE_PROGRAMMING") },
    { id: 3, name: t("CATEGORIES.DESIGN") },
    { id: 4, name: t("CATEGORIES.OTHER") },
  ];
};

export { categories, EDUCATION_CATEGORY };
