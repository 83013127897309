import React, { useState } from "react";
import Switch from "@material-ui/core/Switch";
import ReactTooltip from "react-tooltip";
import "../../../styles/css/custom-table.css";
import "../css/Item.css";
import { useTranslation } from "react-i18next";

const Item = (props) => {
  // get teacher
  const { teacher, openBlockStatus, showTeacherEducations } = props;
  const { t } = useTranslation();

  /**
   * Open modal for teacher educations
   *
   * @param {*} type => primary / secondary
   */
  const showModal = (type) => {
    showTeacherEducations(teacher, type);
  };

  return (
    <div
      className={`item-data width-100 display-flex align-items-center ${
        teacher?.status?.blocked && "blocked-teacher"
      }`}
    >
      <div className="item-column item-column-name">
        <p>{teacher?.fullName}</p>
      </div>
      <div className="item-column item-column-email">
        <p>{teacher?.email}</p>
      </div>
      <div className="item-column item-column-hub">
        <p>{teacher?.hub?.name}</p>
      </div>
      <div className="item-column item-column-primary-teacher display-flex justify-content-center">
        <div
          className="show-teachers display-flex align-items-center justify-content-center"
          onClick={() => showModal("primary")}
        >
          {t("ACTION_BUTTONS.VIEW")}
        </div>
      </div>
      <div className="item-column item-column-secondary-teacher display-flex justify-content-center">
        <div
          className="show-teachers display-flex justify-content-center align-items-center"
          onClick={() => showModal("secondary")}
        >
          {t("ACTION_BUTTONS.VIEW")}
        </div>
      </div>
      <div className="item-column">
        <div
          className="switcher"
          data-tip
          data-for={`switch-teacher-${teacher?.id}`}
        >
          <Switch
            checked={teacher.status.blocked}
            onChange={() => openBlockStatus(teacher)}
            name={`teacher-${teacher?.id}`}
            color="default"
          />
          <ReactTooltip
            className="custom-react-tooltip"
            id={`switch-teacher-${teacher?.id}`}
            place="top"
            effect="solid"
          >
            {teacher.status.blocked
              ? t("TEACHERS.TOOLTIP.UNBLOCK")
              : t("TEACHERS.TOOLTIP.BLOCK")}
          </ReactTooltip>
        </div>
      </div>
    </div>
  );
};

export default Item;
