import { Button, Typography } from "@material-ui/core";
import React, { useState } from "react";
import closeModal from "assets/images/icons/close-modal.svg";
import QAEditor from "./QAEditor";
import "./css/add-question.css";
import LoadingButton from "./LoadingButton";
import { useTranslation } from "react-i18next";

const AddQuestion = (props) => {
  const { isButtonLoading, onConfirmAction, onCancel } = props;
  const { t } = useTranslation();

  const [editorValue, setEditorValue] = useState("");
  const [editorError, setEditorError] = useState(false);

  /**
   * Update value from editor on change
   *
   * @param {*} editor => value from QA editor
   */
  const handleEditorChange = (editor) => {
    setEditorValue(editor);
  };

  /**
   * Submit QA editor
   */
  const submitted = () => {
    if (editorValue.length <= 0) {
      setEditorError(true);
    } else {
      onConfirmAction(editorValue);
      setEditorError(false);
    }
  };

  return (
    <div className="add-question">
      <div className="content">
        <div className="header display-flex align-items-center justify-content-between">
          <Typography variant="h1">
            {t("EDUCATIONS.Q&A.NEW_QUESTION.TITLE")}
          </Typography>
          <img src={closeModal} alt="Close modal" onClick={onCancel} />
        </div>
        <div className="modal-content">
          <div className="qa-editor">
            <QAEditor editorChange={handleEditorChange} isError={editorError} />
          </div>
        </div>
      </div>
      <div className="footer display-flex align-items-center">
        <LoadingButton
          isLoading={isButtonLoading}
          text={t("ACTION_BUTTONS.PUBLISH")}
          variant="contained"
          onClick={submitted}
        />
        <Button
          variant="outlined"
          onClick={onCancel}
          disabled={isButtonLoading}
        >
          {t("ACTION_BUTTONS.CANCEL")}
        </Button>
      </div>
    </div>
  );
};

export default AddQuestion;
