import { Button, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import "../css/AddEvent.css";
import closeModal from "assets/images/icons/close-modal.svg";
import AppDatePicker from "components/AppDatePicker";
import AppTimePicker from "components/AppTimePicker";
import LoadingButton from "components/LoadingButton";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const AddEvent = (props) => {
  const { t } = useTranslation();

  const {
    isLoadingButton,
    addEvent,
    handleDateTimeError,
    isDateTimeError,
    onCloseModal,
  } = props;
  // useForm properties
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // states
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [dateValue, setDateValue] = useState(false);
  const [timeValue, setTimeValue] = useState(false);

  /**
   * Select date from date picker
   *
   * @param {*} value => selected date from date picker
   */
  const handleDateChange = (value) => {
    setDateValue(value);
    handleDateTimeError(false);
  };

  /**
   * Select time from time picker
   *
   * @param {*} value => selected time from time picker
   */
  const handleTimeChange = (value) => {
    setTimeValue(value);
    handleDateTimeError(false);
  };

  /**
   * Submit form and proceed
   *
   * @param {*} data => submitted values from form
   */
  const onSubmitForm = (data) => {
    if (dateValue && timeValue) {
      const formData = {
        ...data,
        date: dateValue,
        time: timeValue,
      };

      addEvent(formData);
    }
  };

  return (
    <div className="add-event">
      <div className="event-header display-flex align-items-center justify-content-between">
        <Typography variant="h1">
          {t("EDUCATIONS.TIMELINE.NEW_EVENT.TITLE")}
        </Typography>
        <img src={closeModal} alt="Close modal" onClick={onCloseModal} />
      </div>
      <form
        className="width-100"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onSubmitForm)}
      >
        <div className="form-content width-100%">
          <TextField
            type="text"
            name="name"
            label={t("EDUCATIONS.TIMELINE.NEW_EVENT.EVENT_NAME.TITLE")}
            variant="outlined"
            {...register("name", {
              required: {
                value: true,
                message: t("EDUCATIONS.TIMELINE.NEW_EVENT.EVENT_NAME.ERROR"),
              },
            })}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
          />
          <div className="date-time-box display-flex justify-content-between flex-wrap">
            <AppDatePicker
              submitted={formSubmitted}
              value={dateValue}
              onChange={handleDateChange}
              placeholder={t(
                "EDUCATIONS.TIMELINE.NEW_EVENT.START_DATE.TITLE_2"
              )}
            />
            <AppTimePicker
              submitted={formSubmitted}
              value={timeValue}
              onChange={handleTimeChange}
            />
            {isDateTimeError && (
              <p className="error-message width-100">
                {t("EDUCATIONS.TIMELINE.NEW_EVENT.START_DATE.CHECK")}
              </p>
            )}
          </div>
        </div>
        <div className="footer display-flex align-items-center">
          <LoadingButton
            isLoading={isLoadingButton}
            text={t("ACTION_BUTTONS.CREATE")}
            onClick={() => setFormSubmitted(true)}
          />
          <Button variant="outlined" onClick={onCloseModal}>
            {t("ACTION_BUTTONS.CANCEL")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddEvent;
