import React from "react";
import { StudentProvider } from "../context/StudentContext";
import List from "./List";

const Students = () => {
  return (
    <StudentProvider>
      <List />
    </StudentProvider>
  );
};

export default Students;
