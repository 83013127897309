import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiSwitch: {
      disableRipple: true,
      disableFocusRipple: true,
    },
  },
  palette: {
    primary: {
      main: "#003399",
      textPrimary: "#3A4140",
    },
    secondary: {
      main: "#EC5707",
      textSecondary: "#868A89",
    },
    error: {
      main: "#DF2935",
    },
  },
  typography: {
    fontFamily: ["Montserrat"],
    body1: {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "-0.015em",
      fontWeight: 400,
      color: "#000000",
    },
    body2: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      letterSpacing: "-0.015em",
      textAlign: "left",
      color: " #3A4140",
    },
    subtitle1: {
      fontSize: "48px",
      lineHeight: "54px",
      fontWeight: 600,
      letterSpacing: "-0.01em",
      color: "#000000",
    },
    subtitle2: {
      fontSize: "40px",
      lineHeight: "44px",
      fontWeight: 600,
      letterSpacing: "-0.01em",
      color: "#000000",
    },
    h1: {
      fontSize: "32px",
      lineHeight: "40px",
      fontWeight: 600,
      letterSpacing: "-0.015em",
      color: "#000000",
    },
    h2: {
      fontSize: "28px",
      lineHeight: "38px",
      fontWeight: 600,
      color: "#000000",
    },
    h3: {
      fontSize: "24px",
      lineHeight: "28px",
      fontWeight: 600,
      color: "#2C3033",
      fontStyle: "normal",
      letterSpacing: "0em",
      textAlign: "left",
    },
    h4: {
      fontSize: "20px",
      lineHeight: "28px",
      fontWeight: 600,
      color: "#000000",
    },
    h5: {
      fontSize: "18px",
      lineHeight: "26px",
      fontWeight: 600,
      color: "#000000",
    },
    h6: {
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: 600,
      color: "#000000",
    },
    caption: {
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    button: {
      textTransform: "none",
    },
  },
  overrides: {
    MuiFormControl: {
      root: {
        width: "100%",
        "&.send-message-input-field": {
          "& .MuiInputBase-input, & fieldset": {
            border: "0px",
            borderWidth: "0px",
            padding: "0px",
          },
          "& .MuiInputBase-input": {
            fontSize: "15px",
            lineHeight: "23px",
            fontWeight: "400",
          },
          "& .MuiOutlinedInput-multiline": {
            padding: "0px",
          },
        },
      },
    },
    MuiInputBase: {
      root: {
        width: "100%",
        minWidth: "100%",
        "&.Mui-disabled input": {
          background: "#E5E7E7",
          borderColor: "#E5E7E7",
          color: "#3A4140",
        },
        "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: "#E5E7E7",
          borderWidth: "0px",
        },
      },
      formControl: {
        width: "100%",
      },
      input: {
        background: "#FFFFFF",
        color: "#3A4140",
        borderColor: "#B6BABA",
        borderRadius: "4px",
        fontSize: "15px",
        lineHeight: "15px",
      },
    },
    MuiInputLabel: {
      formControl: {
        color: "#868A89",
      },
    },
    MuiButtonBase: {
      root: {
        width: "100%",
        minHeight: "56px",
        color: "white",
        fontSize: "17px",
        lineHeight: "21px",
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&.MuiButton-outlined": {
          color: "#3A4140",
        },
        "& span.MuiButton-label": {
          fontWeight: 700,
        },
        "&.hub-action": {
          minHeight: "45px",
          "& span.MuiButton-label": {
            justifyContent: "center",
          },
        },
        "&.add-event": {
          width: "auto",
          padding: "19px 28px",
          color: "#3A4140",
          fontSize: "16px",
          lineHeight: "26px",
          fontWeight: "600",
          borderColor: "#BDC0C7",
          borderRadius: "4px",
          "& .MuiButton-startIcon": {
            marginLeft: "0px",
            marginRight: "14px",
          },
        },
        "&.add-question": {
          width: "auto",
          padding: "20px 24px 20px 28px",
          fontSize: "18px",
          lineHeight: "26px",
          fontWeight: 600,
          cursor: "pointer",
          "& .MuiButton-label": {
            fontWeight: 600,
          },
          "& .MuiButton-startIcon": {
            marginLeft: "0px",
            marginRight: "14px",
          },
        },
        "&.add-new-education": {
          fontSize: "18px",
          lineHeight: "26px",
          padding: "14px 24px 14px 28px",
        },
        "&.font-weight-600": {
          "& span.MuiButton-label": {
            fontWeight: "600",
          },
        },
        "&.add-education-btn": {
          fontSize: "18px",
          lineHeight: "26px",
          padding: "14px 24px",
        },
        "&.allow-participant": {
          fontSize: "16px",
          lineHeight: "26px",
          padding: "6px 16px",
          height: "auto",
          minHeight: "auto",
        },
        "&.empty-state-btn": {
          minHeight: "auto",
          padding: "15px 24px",
          fontSize: "18px",
          lineHeight: "24px",
        },
        "&.send-message-btn": {
          padding: "6px 16px",
          fontSize: "16px",
          lineHeight: "26px",
          minWidth: "75px",
          minHeight: "unset",
        },
        "&.start-lesson-ok": {
          padding: "0px 42px",
        },
        "&.upload-doc-btn": {
          lineHeight: "26px",
        },
        "&.reply-answer": {
          width: "auto",
          padding: "10px 24px 10px 28px",
          fontSize: "17px",
          lineHeight: "26px",
          fontWeight: 600,
          cursor: "pointer",
          boxShadow: "0px 2px 4px 0px #0033994D",
          marginTop: "12px",
          marginLeft: "auto",
          letterSpacing: "0.05rem",
          "& .MuiButton-label": {
            fontWeight: 600,
          },
          "& .MuiButton-startIcon": {
            marginLeft: "0px",
            marginRight: "14px",
          },
        },
      },
    },
    MuiButton: {
      root: {
        "&.hub-action": {
          minWidth: "45px",
          padding: "0px",
        },
        "&.auto-width": {
          width: "auto",
        },
      },
      startIcon: {
        height: "16px",
      },
    },
    MuiOutlinedInput: {
      root: {
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgba(0, 0, 0, 0.23)",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px",
          borderColor: "#003399",
        },
        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: "#DF2935",
          borderWidth: "1px",
        },
      },
    },
    MuiInputAdornment: {
      root: {
        cursor: "pointer",
      },
    },
    MuiAppBar: {
      root: {
        height: "100%",
        "&.MuiAppBar-colorPrimary": {
          backgroundColor: "white",
          color: "#686D6C",
          boxShadow: "unset",
        },
      },
    },
    MuiTabs: {
      root: {
        height: "100%",
        "& .MuiTabs-flexContainer": {
          height: "100%",
        },
        "& .MuiTabs-indicator": {
          backgroundColor: "#295FCC",
          height: "4px",
          borderRadius: "50px 50px 0px 0px",
        },
      },
    },
    MuiTab: {
      root: {
        width: "unset",
        minWidth: "unset !important",
        maxWidth: "unset",
        textTransform: "none",
        padding: "0px",
        marginRight: "48px",
        fontSize: "16px",
        lineHeight: "21px",
        letterSpacing: "-0.015em",
        color: "#686D6C",
        fontWeight: "500",
        "&.MuiTab-textColorInherit": {
          opacity: "1",
        },
        "&.MuiTab-textColorInherit.Mui-selected": {
          color: "#3A4140",
        },
      },
    },
    MuiCircularProgress: {
      root: {
        width: "100%",
        height: "100%",
        minWidth: "100%",
        minHeight: "100%",
        maxWidth: "100%",
        maxHeight: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: "0",
        right: "0",
        left: "0",
        bottom: "0",
        "&.MuiCircularProgress-indeterminate": {
          animation: "none",
          "& .MuiCircularProgress-svg": {
            width: "20px",
            height: "20px",
          },
        },
      },
    },
    MuiCard: {
      root: {
        borderRadius: "12px",
        "&.education-card": {
          width: "400px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
        },
        "&.student-card": {
          width: "400px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
        },
        "&.hub-card": {
          width: "100%",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        },
        "&.teacher-card": {
          width: "400px",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        },
      },
    },
    MuiSwitch: {
      root: {
        overflow: "unset",
        "& .MuiSwitch-switchBase": {
          top: "-10px",
          padding: "0px",
          transform: "translateX(-20px)",
          "& .MuiSwitch-input": {
            maxWidth: "58px",
            left: "unset",
          },
          "&.Mui-checked": {
            color: "#003399",
            "& + .MuiSwitch-track": {
              opacity: "1",
              backgroundColor: "#99B6F0",
              background: "#99B6F0",
            },
          },
        },
      },
    },
  },
});

export { theme };
