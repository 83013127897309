import React from "react";
import { Card, CardMedia, Typography, Button } from "@material-ui/core";
import date from "assets/images/icons/education-date.svg";
import teacher from "assets/images/icons/class.svg";
import students from "assets/images/icons/students-icon.svg";
import hub from "assets/images/icons/hubs.svg";
import info from "assets/images/icons/info.svg";
import ReactTooltip from "react-tooltip";
import "../css/EducationCard.css";
import { useHistory } from "react-router-dom";
import { ROLE } from "utils/ROLES";
import { EDUCATION_STATUS } from "utils/educationStatus";
import imagePreview from "assets/images/icons/image-preview.svg";
import { ROUTES } from "router/CONSTANTS";
import approvedUser from "assets/images/icons/approve-info.svg";
import dissaprovedUser from "assets/images/icons/dissaprove-info.svg";
import pendingUser from "assets/images/icons/pending-info.svg";
import { PARTICIPANT_STATUS } from "utils/participantStatus";
import { getFormattedDate } from "utils/dateFormat";
import unblockEducation from "assets/images/icons/unblock.svg";
import { useTranslation } from "react-i18next";
import { EDUCATION_CATEGORY } from "utils/category";
import { educationLabelText } from "utils/educationLabel";
import Clock from "assets/images/icons/clock-gray.svg";
import moment from "moment";

const EducationCard = (props) => {
  const { education, showJoinModal, chooseEducation } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const LOGGED_ROLE = +localStorage.getItem("uType");

  /**
   * Check education picture and show particular element
   *
   * @returns JSX element
   */
  const handleEducationPicture = () => {
    if (education?.picture !== "") {
      return <CardMedia className="card-img" image={education?.picture} />;
    }

    return (
      <div className="card-media card-no-picture display-flex align-items-center justify-content-center">
        <img src={imagePreview} alt="Hub preview" />
        <Typography variant="subtitle1">Education image</Typography>
      </div>
    );
  };

  /**
   * Show / hide hub information per logged role
   *
   * @returns boolean (show/hide hub per logged role)
   */
  const showHub = () => {
    if (LOGGED_ROLE === ROLE.SUPER_ADMIN || LOGGED_ROLE === ROLE.STUDENT) {
      return true;
    }
    return false;
  };

  /**
   * Show / hide teachers information per logged role
   *
   * @returns boolean (show/hide teachers per logged role)
   */
  const showTeachers = () => {
    if (LOGGED_ROLE !== ROLE.TEACHER) {
      return true;
    }
    return false;
  };

  /**
   * Show / hide students information per logged role
   *
   * @returns boolean (show/hide) students
   */
  const showStudents = () => {
    if (LOGGED_ROLE !== ROLE.STUDENT) {
      return true;
    }
    return false;
  };

  /**
   * Show image and color per user status on education
   *
   * @returns education status object
   */
  const showEducationStatus = () => {
    if (!education?.approvalStatusData) return false;
    // pending => default
    let educationStatus = {
      image: pendingUser,
      color: "pending",
      value: t("EDUCATIONS.PARTICIPANT_STATUS.PENDING"),
    };

    // approved
    if (
      education?.approvalStatusData?.approvalStatus?.id ===
      PARTICIPANT_STATUS.APPROVED.id
    ) {
      educationStatus = {
        image: approvedUser,
        color: "approved",
        value: t("EDUCATIONS.PARTICIPANT_STATUS.APPROVED"),
      };
    }

    // dissaproved
    if (
      education?.approvalStatusData?.approvalStatus?.id ===
      PARTICIPANT_STATUS.DISSAPROVED.id
    ) {
      educationStatus = {
        image: dissaprovedUser,
        color: "dissaproved",
        value: t("EDUCATIONS.PARTICIPANT_STATUS.DISSAPROVED"),
      };
    }

    return educationStatus;
  };

  const showApplyButton = () => {
    if (education?.status?.blocked) return false;

    if (education?.filters?.educationStatus[0] === EDUCATION_STATUS.PLANNED.id)
      return false;

    if (LOGGED_ROLE === ROLE.STUDENT && !education?.approvalStatusData) {
      return true;
    }
    return false;
  };

  /**
   * Open Join Education Modal
   */
  const openModal = () => {
    showJoinModal("join", education);
  };

  /**
   * Open Unblock Education modal
   */
  const unblockModal = () => {
    chooseEducation(education);
  };

  /**
   * Handle click on Education card
   *
   * @param {*} e => clicked area of div
   * @returns
   */
  const openSingle = (e) => {
    const clickedTarget = e.target;

    if (education?.status?.blocked) {
      return;
    }

    if (
      clickedTarget.classList.contains("MuiButton-root") ||
      clickedTarget.classList.contains("MuiButton-label")
    ) {
      openModal();
      return;
    }

    if (LOGGED_ROLE === ROLE.STUDENT) {
      if (education?.approvalStatusData) {
        if (
          education?.approvalStatusData?.approvalStatus?.id !==
          PARTICIPANT_STATUS.APPROVED.id
        ) {
          history.push(`${ROUTES.EDUCATIONS}/${education.id}?type=other`);
          return;
        }
      }

      if (!education?.approvalStatusData) {
        history.push(`${ROUTES.EDUCATIONS}/${education.id}?type=other`);
        return;
      }
    }

    history.push(`${ROUTES.EDUCATIONS}/${education.id}`);
  };

  /**
   * Show unblock button
   *
   * @returns true if logged role is super admin or hub admin
   */
  const showUnblock = () => {
    if (LOGGED_ROLE === ROLE.SUPER_ADMIN || LOGGED_ROLE === ROLE.HUB_ADMIN) {
      return true;
    }
    return false;
  };

  /**
   * Handle education text per education status
   *
   * @returns education status text
   */
  const educationStatusText = () => {
    if (
      education.filters.educationStatus[0] === EDUCATION_STATUS.SCHEDULED.id
    ) {
      return t("EDUCATIONS.SCHEDULED");
    }

    if (education.filters.educationStatus[0] === EDUCATION_STATUS.ACTIVE.id) {
      return t("EDUCATIONS.ACTIVE");
    }

    if (education.filters.educationStatus[0] === EDUCATION_STATUS.PLANNED.id) {
      return t("EDUCATIONS.FINALIZED");
    }

    return t("EDUCATIONS.BLOCKED_STATUS");
  };

  /**
   * Handle color for education label
   *
   * @returns css class for education label
   */
  const educationLabel = () => {
    if (
      education?.filters?.educationStatus[0] === EDUCATION_STATUS.SCHEDULED.id
    ) {
      return "scheduled";
    }

    if (
      education?.filters?.educationStatus[0] === EDUCATION_STATUS.PLANNED.id
    ) {
      return "finalized";
    }

    return "active";
  };

  // generate time
  const generateEducationTime = () => {
    const timeFormat = "HH:mm";

    const educationStartDate =
      LOGGED_ROLE !== ROLE.STUDENT
        ? education.startDate.toDate()
        : education.startDate;

    const time = moment(educationStartDate).format(timeFormat);

    return time;
  };

  return (
    <Card
      key={education.id}
      className={`education-card ${
        education?.status?.blocked && "blocked-education"
      }`}
      onClick={openSingle}
    >
      {education?.status?.blocked && (
        <>
          <div className="blocked-element"></div>
          {showUnblock() && (
            <div className="unblock-btn" onClick={unblockModal}>
              <img
                src={unblockEducation}
                alt="Unblock education"
                data-tip
                data-for="unblockEducation"
              />
              <ReactTooltip
                className="custom-react-tooltip"
                id="unblockEducation"
                place="right"
                effect="solid"
              >
                {t("EDUCATIONS.TOOLTIP.UNBLOCK")}
              </ReactTooltip>
            </div>
          )}
        </>
      )}
      <div className="image-holder">
        {showEducationStatus() && (
          <div className={`user-status ${showEducationStatus()?.color}`}>
            <img src={showEducationStatus()?.image} alt="Approved" />
            {showEducationStatus()?.value}
          </div>
        )}
        {handleEducationPicture()}
        {!education?.status?.blocked && (
          <div className="labels">
            <span>{educationLabelText(education?.category?.id, t)}</span>
            <span className={educationLabel()}>{educationStatusText()}</span>
          </div>
        )}
      </div>
      <div className="education-footer">
        <Typography variant="h3">{education?.title}</Typography>

        {!education?.status?.blocked ? (
          <div className="education-info">
            <div className="first-row justify-content-between display-flex">
              <div className="display-flex holder">
                <img src={date} alt="calendar" className="icons" />
                <Typography variant="caption">
                  {LOGGED_ROLE !== ROLE.STUDENT
                    ? getFormattedDate(education?.startDate, "-")
                    : getFormattedDate(education?.startDate, "-", "student")}
                </Typography>
              </div>

              <div className="holder display-flex align-items-center">
                <img src={Clock} alt="Clock" className="clock icons" />
                <Typography variant="caption">
                  {generateEducationTime()}
                </Typography>
              </div>

              {showStudents() && (
                <div
                  className="students display-flex holder"
                  data-tip
                  data-for={`${
                    education?.participants?.length > 0
                      ? `students-${education.id}`
                      : "none"
                  }`}
                >
                  <img src={students} alt="students" className="icons" />
                  <Typography variant="caption">
                    {t("EDUCATIONS.REGISTERED_USERS")}(
                    {education?.participants?.length})
                  </Typography>
                  <ReactTooltip
                    id={`students-${education.id}`}
                    place="bottom"
                    effect="solid"
                    className="tooltip students-tooltip"
                    arrowColor="transparent"
                    fontSize="16px"
                  >
                    <div className="registered-users">
                      {education?.participants?.map((participant) => (
                        <Typography key={participant?.userId} variant="body1">
                          {participant?.fullName}
                        </Typography>
                      ))}
                    </div>
                  </ReactTooltip>
                </div>
              )}

              {showHub() && (
                <div className="display-flex holder">
                  <img src={hub} alt="hub" className="icons" />
                  <Typography variant="caption" className="hub-name">
                    {education?.hub?.name}
                  </Typography>
                </div>
              )}

              {showTeachers() && (
                <div
                  className="teacher display-flex holder"
                  data-tip
                  data-for={`teachers-${education?.id}`}
                >
                  <img src={teacher} alt="teachers" className="icons" />
                  <Typography variant="caption">
                    {t("EDUCATIONS.TEACHERS.TITLE")} (
                    {education?.educators?.length})
                  </Typography>
                  <ReactTooltip
                    id={`teachers-${education?.id}`}
                    place="bottom"
                    effect="solid"
                    className="tooltip teacher-tooltip"
                    arrowColor="transparent"
                    fontSize="16px"
                  >
                    <div className="columns display-flex">
                      <div className="primary">
                        <div className="registered-users">
                          <Typography className="column-title">
                            {t("EDUCATIONS.TEACHERS.PRIMARY")}
                          </Typography>

                          <Typography>
                            {education?.primEducator?.name}
                          </Typography>
                        </div>
                      </div>
                      <div>
                        <div className="registered-users">
                          <Typography className="column-title">
                            {t("EDUCATIONS.TEACHERS.SECONDARY")}
                          </Typography>

                          {education?.secondEducator?.map((second) => (
                            <Typography key={second?.id}>
                              {second?.name}
                            </Typography>
                          ))}
                        </div>
                      </div>
                    </div>
                  </ReactTooltip>
                </div>
              )}
            </div>

            {/* <div className="second-row justify-content-between display-flex">

            </div> */}

            {/* <div className="second-row">

            </div> */}
          </div>
        ) : (
          <div className="block-info display-flex align-items-center">
            <img src={info} alt="info" className="info-icon" />
            <div className="info-text">
              <Typography variant="body2">{t("EDUCATIONS.BLOCKED")}</Typography>
            </div>
          </div>
        )}

        {showApplyButton() && (
          <div className="apply-button">
            <Button
              variant="contained"
              color="primary"
              className="auto-width font-weight-600'"
            >
              {t("ACTION_BUTTONS.APPLY_NOW")}
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};

export default EducationCard;
