import React, { useEffect, useState } from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import { useEducation } from "pages/Educations/context/EducationContext";

import { EDUCATIONS, TIMELINE } from "services/CONSTANTS";
import { db } from "../../../../firebase/firebase";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() =>
  createStyles({
    endLessonDialog: {
      width: "100%",
      height: "100vh",
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      backgroundColor: "rgba(0, 0, 0, .5)",
      zIndex: 100000,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    endLessonBlock: {
      width: "auto",
      height: "auto",
      background: "white",
      borderRadius: "4px",
      boxShadow: "0px 0px 27px 1px rgba(0, 0, 0, .5)",
      padding: "12px",
      marginTop: "50px",
    },
    p: {
      color: "#686d6c",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: 500,
    },
  })
);

const EndLessonDialog = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { singleEducation } = useEducation();

  const [isEndLesson, setIsEndLesson] = useState(false);

  useEffect(() => {
    if (!singleEducation) return;

    const selectedTimeline = localStorage.getItem("selectedTimeline");
    const timelineId =
      selectedTimeline !== null ? JSON.parse(selectedTimeline).id : "";

    const unsubscribe = db
      .collection(EDUCATIONS())
      .doc(singleEducation?.id)
      .collection(TIMELINE())
      .doc(timelineId)
      .onSnapshot((timeline: any) => {
        if (timeline.exists) {
          const timelineData = timeline.data();
          const isPrimaryTeacher = localStorage.getItem("isPrimaryTeacher");

          if (
            timelineData &&
            isPrimaryTeacher !== null &&
            isPrimaryTeacher !== "true"
          ) {
            if (timelineData.ended) {
              setIsEndLesson(true);
              const roomUrl = window.location.href.split("room/");
              const newUrl = `${roomUrl[0]}educations/${singleEducation.id}`;
              setTimeout(() => {
                window.location.href = newUrl;
              }, 5000);
            }
          }
        }
      });

    return () => {
      console.log("Unsubscribe from end lesson");
      setIsEndLesson(false);
      unsubscribe();
    };
  }, [singleEducation]);

  return (
    <>
      {isEndLesson && (
        <div className={classes.endLessonDialog}>
          <div className={classes.endLessonBlock}>
            <p className={classes.p}>{t("TWILIO.END_LESSON_INFO")}</p>
          </div>
        </div>
      )}
    </>
  );
};

export { EndLessonDialog };
