import React from "react";
import PageTitle from "components/PageTitle";
import DefaultTeacher from "components/DefaultTeacher";
import { useEducation } from "pages/Educations/context/EducationContext";
import { useTranslation } from "react-i18next";

const Teachers = () => {
  const { singleEducation } = useEducation();
  const { t } = useTranslation();
  return (
    <div className="education-teachers display-flex">
      <div className="primary-teachers">
        <PageTitle title={t("EDUCATIONS.TEACHERS.PRIMARY")} />
        <DefaultTeacher teacher={singleEducation?.primEducator} />
      </div>
      <div className="secondary-teachers">
        <PageTitle title={t("EDUCATIONS.TEACHERS.SECONDARY")} />
        <div className="display-flex secondary-content">
          {singleEducation?.secondEducator?.map((teacher) => (
            <DefaultTeacher teacher={teacher} key={teacher.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Teachers;
