import React from "react";
import authIlustration from "../assets/images/auth/auth-ilustration.svg";

const Ilustration = () => {
  return (
    <div className="auth-ilustration width-100 display-flex align-items-center justify-content-center">
      <img src={authIlustration} alt="E Learning" />
    </div>
  );
};

export default Ilustration;
